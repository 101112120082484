import React from "react";
import "./style.scss";
import { withRouter } from "react-router-dom";
import DocumentTitle from "react-document-title";

const Page = ({ history }) => {
  return (
    <DocumentTitle title="Page not found">
      <div className="NotFoundPage">
        <div className="content">
          <div className="title">OPPS! ERROR 404!</div>
          <div className="title-bold">Page not found</div>
          <div className="text">
            The system is unable to find the requested action. Please contact the system administrator if you believe this error is incorrect.
          </div>
          <div className="wrapper">
            <button onClick={() => history.replace("/")}>Go home</button>
            <button onClick={() => history.replace("/contact-us")}>Contact us</button>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export const NotFoundPage = withRouter(Page);
