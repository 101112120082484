import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import isDecimal from "validator/lib/isDecimal";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select } from "@Root/components";

export const FeesForm = ({ permissions, isNew, initialData, options, cancelHandler, deleteHandler, saveHandler }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);
  const [error, setError] = useState(null);

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    const { gross_fee, deductions } = data;
    if (gross_fee && !isDecimal(gross_fee)) {
      showError("gross_fee", "Invalid value. Should be a decimal");
      return false;
    }
    if (deductions && !isDecimal(deductions)) {
      showError("deductions", "Invalid value. Should be a decimal");
      return false;
    }
    return true;
  };

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    if (isNew) {
      cancelHandler();
    } else {
      setData(initialData);
      setIsEditable(false);
    }
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      data.fees_id,
      () => {
        setIsSavind(true);
      },
      () => {
        FeesForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSavind(true);
    const modifiedData = switchEmptyStringsToNullsInObject(data);
    await saveHandler(modifiedData, () => {
      FeesForm.isMounted && setIsEditable(false);
    });
    FeesForm.isMounted && setIsSavind(false);
  };

  useEffect(() => {
    FeesForm.isMounted = true;
    return () => {
      FeesForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    FeesForm.isMounted && setData(initialData);
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  return (
    <FormWrapper
      buttons={isNew ? ["cancel", "save"] : isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete these fees" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isNew ? "Create Fees Details" : isEditable ? "Edit Fees Details" : "Fees Details"} />
        <div className={classes.wrapper}>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Main Source of Fee HESA" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            options={options}
            value={data.main_source_of_fee}
            changeHandler={value => handleChangeInput("main_source_of_fee", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Gross Fee" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.gross_fee}
            changeHandler={value => handleChangeInput("gross_fee", value)}
            isDisabled={!isEditable}
            error={errorMessage("gross_fee")}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Deductions" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.deductions}
            changeHandler={value => handleChangeInput("deductions", value)}
            isDisabled={!isEditable}
            error={errorMessage("deductions")}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

FeesForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  isNew: PropTypes.bool,
  initialData: PropTypes.shape({
    main_source_of_fee: PropTypes.number,
    gross_fee: PropTypes.string,
    deductions: PropTypes.string
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

FeesForm.defaultProps = {
  permissions: [],
  isNew: false,
  initialData: {
    main_source_of_fee: null,
    gross_fee: null,
    deductions: null
  },
  options: [],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};
