import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import isEmail from "validator/lib/isEmail";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, DatePicker, Select } from "@Root/components";

export const AdmissionsForm = ({ permissions, isNew, initialData, cancelHandler, deleteHandler, saveHandler, options }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [isSpinning, setIsSpinning] = useState(false);
  const [error, setError] = useState(null);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    const { DDO_Email } = data;
    if (DDO_Email && !isEmail(DDO_Email)) {
      showError("DDO_Email", "Invalid email");
      return false;
    }

    return true;
  };

  const handleClickCancelButton = () => {
    if (isNew) {
      cancelHandler();
    } else {
      setData(initialData);
      setIsEditable(false);
    }
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      data.admission_id,
      () => {
        setIsSpinning(true);
      },
      () => {
        AdmissionsForm.isMounted && setIsSpinning(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSpinning(true);
    const modifiedData = switchEmptyStringsToNullsInObject(data);
    await saveHandler(modifiedData, () => {
      AdmissionsForm.isMounted && setIsEditable(false);
    });
    AdmissionsForm.isMounted && setIsSpinning(false);
  };

  useEffect(() => {
    AdmissionsForm.isMounted = true;
    return () => {
      AdmissionsForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    AdmissionsForm.isMounted && setData(initialData);
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  const {
    studiesStudyProgrammeFormOptions: { admission_bap_statuses },
    admissionsApplicationsOptions: { applications_status }
  } = options;
  return (
    <FormWrapper
      buttons={isNew ? ["cancel", "save"] : isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete these admissions" }]}
      buttonsAreDisabled={isSpinning}
      isSpinning={isSpinning}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isNew ? "Create Admissions Details" : isEditable ? "Edit Admissions Details" : "Admissions Details"} />
        <div className={classes.wrapper}>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Enquiry Date" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.enquiry_date}
            changeHandler={value => handleChangeInput("enquiry_date", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Application Date" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.application_date}
            changeHandler={value => handleChangeInput("application_date", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Open Day Date" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.open_day_date}
            changeHandler={value => handleChangeInput("open_day_date", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="BAP Date" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.bap_date}
            changeHandler={value => handleChangeInput("bap_date", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Interview Date" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.interview_date}
            changeHandler={value => handleChangeInput("interview_date", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="BAP Status" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ width: 260, position: "absolute", left: 150 }}
            options={admission_bap_statuses}
            value={data.bap_status}
            changeHandler={value => handleChangeInput("bap_status", value)}
            maxVisibleOptionsQuantity={5}
            error={errorMessage("bap_status")}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Application Status" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            options={applications_status}
            value={data.application_status}
            changeHandler={value => handleChangeInput("application_status", value)}
            maxVisibleOptionsQuantity={5}
            error={errorMessage("application_status")}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Ref Sent Date" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.ref_sent_date}
            changeHandler={value => handleChangeInput("ref_sent_date", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Ref Received Date" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.ref_received_date}
            changeHandler={value => handleChangeInput("ref_received_date", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="DDO Name" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.DDO_name}
            changeHandler={value => handleChangeInput("DDO_name", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="DDO Email" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.DDO_Email}
            changeHandler={value => handleChangeInput("DDO_Email", value)}
            isDisabled={!isEditable}
            error={errorMessage("DDO_Email")}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Sponsor Name" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.sponsor_name}
            changeHandler={value => handleChangeInput("sponsor_name", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Visa Status" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.visa_status}
            changeHandler={value => handleChangeInput("visa_status", value)}
            isDisabled={!isEditable}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

AdmissionsForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  isNew: PropTypes.bool,
  initialData: PropTypes.shape({
    enquiry_date: PropTypes.string,
    application_date: PropTypes.string,
    open_day_date: PropTypes.string,
    bap_date: PropTypes.string,
    bap_status: PropTypes.string,
    interview_date: PropTypes.string,
    ref_sent_date: PropTypes.string,
    ref_received_date: PropTypes.string,
    DDO_name: PropTypes.string,
    DDO_Email: PropTypes.string,
    sponsor_name: PropTypes.string,
    visa_status: PropTypes.string
  }),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

AdmissionsForm.defaultProps = {
  permissions: [],
  isNew: false,
  initialData: {
    enquiry_date: null,
    application_date: null,
    open_day_date: null,
    bap_date: null,
    interview_date: null,
    bap_status: null,
    ref_sent_date: null,
    ref_received_date: null,
    DDO_name: null,
    DDO_Email: null,
    sponsor_name: null,
    visa_status: null,
    application_status: null
  },
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};
