import React from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { withPromise, errorMessage } from "@Root/helpers";
import { booleanOptions, createDataConfig, trashInformationMandatoryRules } from "@Root/configs";
import { Spinner, ActionsDropdown, Table } from "@Root/components";

class Component extends React.Component {
  state = {
    isShown: false,
    shouldBeUpdated: false
  };

  recordsOfStudiesTableData = () => ({
    table: () => {
      const {
        academic_year_list,
        status,
        study_years,
        sponsors,
        accommodation,
        managed_by,
        ordinand,
        learning_centre,
        model_of_study,
        SLC_Loan,
        session_years,
        In_HEAPES
      } = this.props.studiesStudyProgrammeFormOptions;
      const institutions = this.props.adminInstitutionOptions;
      const mandatoryRules = trashInformationMandatoryRules;
      const columns = () => [
        {
          name: "Name",
          field: "name",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Student ID",
          field: "student_id",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Programme",
          field: "programme_name",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Academic year",
          field: "academic_year",
          searchInputType: "select",
          options: academic_year_list.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Status",
          field: "status",
          searchInputType: "select",
          options: status.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Study year",
          field: "study_year",
          searchInputType: "select",
          options: study_years.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Context",
          field: "context",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Sponsor",
          field: "sponsor",
          searchInputType: "select",
          options: sponsors.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Accomm",
          field: "accommodation",
          searchInputType: "select",
          options: accommodation.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Programme Manager",
          field: "management_by",
          searchInputType: "select",
          options: managed_by.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Ordinand",
          field: "ordinand",
          searchInputType: "select",
          options: ordinand.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Institution",
          field: "institution",
          searchInputType: "select",
          options: institutions.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Learning Centre",
          field: "training_mode",
          searchInputType: "select",
          options: learning_centre.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Mode of study",
          field: "mode_of_study",
          searchInputType: "select",
          options: model_of_study.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Diocese",
          field: "diocese",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Start date (help)",
          field: "start_date",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Leaving (help)",
          field: "leaving_date",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Finish (help)",
          field: "finishing_date",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Credits",
          field: "credits",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Support",
          field: "support",
          searchInputType: "select",
          options: ["Yes", "No"],
          cellType: "text"
        },
        {
          name: "SLC this year",
          field: "SLC_this_year",
          searchInputType: "select",
          options: SLC_Loan.map(x => x.label),
          cellType: "text"
        },
        {
          name: "DSA this year",
          field: "DSA",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Heapes help",
          field: "heapes",
          searchInputType: "select",
          options: In_HEAPES.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Final Mark",
          field: "final_mark",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Final Outcome",
          field: "final_outcome",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Supervisor 1",
          field: "supervisor_1",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Supervisor 2",
          field: "supervisor_2",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Supervisor 3",
          field: "supervisor_3",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Examiner",
          field: "examiner",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Pastoral Tutor",
          field: "pastoral_tutor",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Session Year",
          field: "session_year",
          searchInputType: "select",
          options: session_years.map(x => x.label),
          cellType: "text"
        }
      ];
      return {
        ...createDataConfig(
          "suspensionsStudentDetails",
          mandatoryRules,
          columns(),
          "getRecordsOfStudies",
          "records-of-studies",
          true,
          true,
          true,
          false,
          false,
          false
        ),
        clickLinkHandlers: {
          Name: row => this.props.history.push(`/home/contacts/students/${row.student_id}`),
          Programme: row => this.props.history.push(`/home/studies/programmes/study/${row.student_id}/${row.id}`)
        }
      };
    }
  });

  programmesTableData = () => ({
    actions: [
      ...(this.props.permissions.includes("create")
        ? [
            {
              name: "Create a Programme",
              handler: () => this.props.history.push(`/home/studies/programmes/new`)
            }
          ]
        : [])
    ],
    table: () => {
      const { program_types, programme_modes } = this.props.studiesProgrammeOptions;
      const columns = () => [
        {
          name: "Programme",
          field: "programme_name",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Subject Code",
          field: "subject_code",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Programme Type",
          field: "programme_type",
          searchInputType: "select",
          options: program_types.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Programme Mode",
          field: "programme_mode",
          searchInputType: "select",
          options: programme_modes.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Available",
          field: "available",
          searchInputType: "select",
          options: booleanOptions.map(x => x.label),
          cellType: "text"
        }
      ];
      return {
        ...createDataConfig(
          "studiesProgrammes",
          [],
          columns(),
          "getProgrammes",
          "programmes",
          true,
          true,
          false,
          false,
          this.props.permissions.includes("import"),
          false
        ),
        clickImportButtonHandler: () => this.importCSV("programmes"),
        clickLinkHandlers: {
          Programme: row => this.props.history.push(`/home/studies/programmes/${row.id}`)
        }
      };
    }
  });

  modulesTableData = () => ({
    actions: [
      ...(this.props.permissions.includes("create")
        ? [
            {
              name: "Create a Module",
              handler: () => this.props.history.push(`/home/studies/modules/new`)
            }
          ]
        : [])
    ],
    table: () => {
      const { credit_transfer_scheme, level_of_credit_points, mark_scheme } = this.props.studiesModuleOptions;
      const columns = () => [
        {
          name: "Identifier",
          field: "module_identifier",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Title",
          field: "module_title",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Credit Value",
          field: "credit_value_of_module",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Credit Transfer Scheme",
          field: "credit_transfer_scheme",
          searchInputType: "select",
          options: credit_transfer_scheme.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Full Time Equivalence",
          field: "full_time_equivalence",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Level Of Credit Points",
          field: "level_of_credit_points_id",
          searchInputType: "select",
          options: level_of_credit_points.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Mark Scheme",
          field: "mark_scheme",
          searchInputType: "select",
          options: mark_scheme.map(x => x.label),
          cellType: "text"
        }
      ];
      return {
        ...createDataConfig(
          "studiesModules",
          [],
          columns(),
          "getModules",
          "modules",
          true,
          true,
          false,
          false,
          this.props.permissions.includes("import"),
          false
        ),
        clickImportButtonHandler: () => this.importCSV("modules"),
        clickLinkHandlers: {
          Title: row => this.props.history.push(`/home/studies/modules/${row.module_id}`)
        }
      };
    }
  });

  engagementsTableData = () => ({
    table: () => {
      const { fee_eligibility, fee_status } = this.props.studiesEngagementOptions;
      return {
        name: "engagements",
        columns: [
          {
            name: "Engagement Number",
            field: "engagement_number",
            searchInputType: "textInput",
            cellType: "link"
          },
          {
            name: "Start Date",
            field: "start_date",
            searchInputType: "textInput",
            cellType: "link"
          },
          {
            name: "End Date",
            field: "end_date",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Fee Eligibility",
            field: "fee_eligibility",
            searchInputType: "select",
            options: fee_eligibility.map(x => x.label),
            cellType: "text"
          },
          {
            name: "Fee Status",
            field: "fee_status",
            searchInputType: "select",
            options: fee_status.map(x => x.label),
            cellType: "text"
          },
          {
            name: "Incoming Exchange",
            field: "incoming_exchange",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Lead School",
            field: "lead_school",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "NHS Employer",
            field: "nhs_employer",
            searchInputType: "textInput",
            cellType: "text"
          }
        ],
        fetchDataHandler: params => API.getEngagements(params),
        fetchSaveColumnOptions: options => API.saveColumnOptions("engagements", options),
        fetchColumnOptions: () => API.getColumnOptions("engagements"),
        hasRules: true,
        hasFilters: true,
        hasImport: false,
        clickLinkHandlers: {
          "Engagement Number": row => this.props.history.push(`/home/contacts/details/${row.engagement_number}`)
        }
      };
    }
  });

  postgradStudentDetailsTableData = () => ({
    table: () => {
      const { academic_year_list, study_years, managed_by, reason_for_student_course_session_ending, status } = this.props.studiesStudyProgrammeFormOptions;
      const { programme_modes } = this.props.studiesProgrammeOptions;
      const mandatoryRules = trashInformationMandatoryRules;
      const columns = () => [
        {
          name: "Student ID",
          field: "student_id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Name",
          field: "name",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Email",
          field: "email",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Programme",
          field: "program_name",
          searchInputType: "select",
          options: this.props.programmesListOptions.map(x => x.label),
          cellType: "link"
        },
        {
          name: "Mode",
          field: "mode",
          searchInputType: "select",
          options: programme_modes.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Programme Status",
          field: "status_program",
          searchInputType: "select",
          options: reason_for_student_course_session_ending.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Academic Year",
          field: "academic_year",
          searchInputType: "select",
          options: academic_year_list.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Study Year",
          field: "study_year",
          searchInputType: "select",
          options: study_years.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Programme Manager",
          field: "managed_by",
          searchInputType: "select",
          options: managed_by.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Supervisor 1",
          field: "supervisor_1",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Supervisor 2",
          field: "supervisor_2",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Supervisor 3",
          field: "supervisor_3",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Status",
          field: "status",
          searchInputType: "select",
          options: status.map(x => x.label),
          cellType: "text"
        }
      ];
      return {
        ...createDataConfig(
          "postgradStudentDetails",
          mandatoryRules,
          columns(),
          "getPostGrads",
          "postgrad-student-details",
          true,
          true,
          false,
          false,
          false,
          false
        ),
        clickLinkHandlers: {
          "Student ID": row => this.props.history.push(`/home/contacts/students/${row.student_id}`),
          Name: row => this.props.history.push(`/home/contacts/students/${row.student_id}`),
          Programme: row => this.props.history.push(`/home/studies/programmes/study/${row.student_id}/${row.id}`)
        }
      };
    }
  });

  suspensionsStudentDetailsTableData = () => ({
    table: () => {
      const { institution, reason } = this.props.studiesSuspensionsOptions;
      const columns = () => [
        {
          name: "SID",
          field: "sid",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Name",
          field: "name",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Federation House",
          field: "institution",
          searchInputType: "select",
          options: institution,
          cellType: "text"
        },
        {
          name: "Date From",
          field: "date_from",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Date To",
          field: "date_to",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Reason",
          field: "reason",
          searchInputType: "select",
          options: reason,
          cellType: "text"
        }
      ];
      return {
        ...createDataConfig("suspensionsStudentDetails", [], columns(), "getSuspensions", "suspensions", true, true, false, false, false, false)
      };
    }
  });

  tableData = tab => {
    switch (tab) {
      case "records-of-studies":
        return this.recordsOfStudiesTableData();
      case "programmes":
        return this.programmesTableData();
      case "modules":
        return this.modulesTableData();
      case "engagements":
        return this.engagementsTableData();
      case "postgrad-student-details":
        return this.postgradStudentDetailsTableData();
      case "suspensions":
        return this.suspensionsStudentDetailsTableData();
      default:
        return;
    }
  };

  pushProgramme = async () => {
    try {
      await API.pushProgrammesToForm();
      this.props.setSnackbar({ text: "Programmes pushed successfully" });
    } catch (error) {
      this.props.setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  fetchOptions = async () => {
    const {
      history,
      location,
      studiesProgrammeOptions,
      getStudiesProgrammeOptions,
      getProgrammesListOptions,
      getStudiesStudyProgrammeFormOptions,
      studiesStudyProgrammePostgradOptions,
      getStudiesStudyProgrammePostgradOptions,
      studiesModuleOptions,
      getStudiesModuleOptions,
      getAdminInstitutionOptions,
      getStudiesEngagementOptions,
      studiesEngagementOptions,
      getStudiesSuspensionsOptions,
      studiesSuspensionsOptions,
      setSnackbar
    } = this.props;
    const tab = location.pathname.split("/")[3];
    try {
      tab === "records-of-studies" && (await Promise.all([getStudiesStudyProgrammeFormOptions(), getAdminInstitutionOptions()]));
      tab === "programmes" && (await getStudiesProgrammeOptions());
      tab === "postgrad-student-details" &&
        (await Promise.all([
          !studiesProgrammeOptions ? getStudiesProgrammeOptions() : null,
          getStudiesStudyProgrammeFormOptions(),
          getProgrammesListOptions(),
          !studiesStudyProgrammePostgradOptions ? getStudiesStudyProgrammePostgradOptions() : null
        ]));
      tab === "modules" && !studiesModuleOptions && (await getStudiesModuleOptions());
      tab === "engagements" && !studiesEngagementOptions && (await getStudiesEngagementOptions());
      tab === "suspensions" && !studiesSuspensionsOptions && (await getStudiesSuspensionsOptions());
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.replace("/contact-us");
    }
  };

  refreshTable = () => {
    this.setState({ shouldBeUpdated: true }, () => this.setState({ shouldBeUpdated: false }));
  };

  importCSV = type => {
    const config = {
      programmes: {
        postCsvFileHandler: API.postStudiesProgrammesCsvFile,
        importDataPath: "programs",
        fields: ["programme", "programme_type", "programme_mode"],
        importRollbackHandler: API.postStudiesProgrammesImportRollback
      },
      modules: {
        postCsvFileHandler: API.postStudiesModulesCsvFile,
        importDataPath: "modules",
        fields: ["module_title"],
        importRollbackHandler: API.postStudiesModulesImportRollback
      }
    }[type];
    const { showModal, hideModal, setSnackbar } = this.props;
    const { refreshTable } = this;
    let importData = {};
    new Promise((resolve, reject) => {
      showModal("UploadingModal", {
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    })
      .then(
        file => {
          return config.postCsvFileHandler(file);
        },
        () => {
          hideModal();
          Promise.resolve();
        }
      )
      .then(
        ({ data }) => {
          importData = data.data;
          return new Promise((resolve, reject) => {
            showModal("ImportModal", {
              headerText: "Results",
              importDescription: importData[config.importDataPath].length ? `The following ${type} were imported:` : `No ${type} were imported.`,
              importData: { data: importData[config.importDataPath], fields: config.fields },
              leftButtonText: importData[config.importDataPath].length ? "Roll back" : null,
              rightButtonText: "Ok",
              clickRejectButtonHandler: reject,
              clickResolveButtonHandler: resolve
            });
          });
        },
        error => {
          new Promise((resolve, reject) => {
            showModal("NotificationModal", {
              text: `Unable to process the data. Please check your template. Error message: ${errorMessage(error)}`,
              clickRejectButtonHandler: reject,
              clickResolveButtonHandler: resolve
            });
          }).then(
            () => {
              hideModal();
            },
            () => {
              hideModal();
            }
          );
        }
      )
      .then(
        button => {
          if (button === "left") {
            new Promise((resolve, reject) => {
              showModal("ImportModal", {
                headerText: "Warning",
                importDescription: `The ${type} from the most recent import will be deleted. Click the delete button to delete the records:`,
                importData: { data: importData[config.importDataPath], fields: config.fields },
                leftButtonText: "Cancel Rollback",
                rightButtonText: "Delete",
                clickRejectButtonHandler: reject,
                clickResolveButtonHandler: resolve
              });
            }).then(
              button => {
                if (button === "left") {
                  hideModal();
                  refreshTable();
                } else if (button === "right") {
                  config.importRollbackHandler({ rollback: importData.rollback }).then(
                    () => {
                      new Promise((resolve, reject) => {
                        showModal("NotificationModal", {
                          text: "Your records were deleted.",
                          clickRejectButtonHandler: reject,
                          clickResolveButtonHandler: resolve
                        });
                      }).then(
                        () => {
                          hideModal();
                        },
                        () => {
                          hideModal();
                        }
                      );
                    },
                    error => {
                      setSnackbar({ text: `Unable to roll back. Error message: ${errorMessage(error)}`, isError: true });
                      hideModal();
                      refreshTable();
                    }
                  );
                }
              },
              () => {
                hideModal();
                Promise.resolve();
              }
            );
          } else if (button === "right") {
            hideModal();
            refreshTable();
          }
        },
        () => {
          hideModal();
          Promise.resolve();
        }
      );
  };

  async componentDidMount() {
    await this.fetchOptions();
    !this.isUnmounted && this.setState({ isShown: true });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { isShown, shouldBeUpdated } = this.state;
    const { location, permissions, setSnackbar, setSuccessNotification } = this.props;
    const { tableData, pushProgramme } = this;
    const tab = location.pathname.split("/")[3];
    const { actions = [], table } = tableData(tab);
    return (
      <div className={classes.wrapper}>
        {!!actions.length && (
          <ActionsDropdown
            actions={actions.map(action => action.name)}
            clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
            style={{ position: "absolute", right: 10, top: -80 }}
          />
        )}
        {isShown ? (
          <>
            {tab === "programmes" && permissions.includes("edit") && (
              <div className={classes.tablePushBar}>
                <div className={classes.tableBarLeft}>
                  <p>
                    Data must be pushed into the online application from each time a programme is created or its availiability is changed. To renew all data
                    push this button please
                  </p>
                </div>
                <div className={classes.tableBarRight}>
                  <p>All programmes with a study finish date greater than today</p>
                  <div onClick={() => pushProgramme()} className={classes.pushBtn}>
                    Push all Programmes to application form
                  </div>
                </div>
              </div>
            )}
            <Table
              datePeriodDropdownStyle={permissions.includes("edit") && tab === "programmes" ? { top: -210 } : null}
              cleanFiltersStyle={permissions.includes("edit") && tab === "programmes" ? { top: -90 } : null}
              key={table().name}
              name={table().name}
              mandatoryRules={table().mandatoryRules}
              columns={table().columns}
              fetchDataHandler={table().fetchDataHandler}
              fetchColumnOptions={table().fetchColumnOptions}
              fetchSaveColumnOptions={table().fetchSaveColumnOptions}
              hasImport={table().hasImport}
              hasSorting={table().hasSorting}
              hasFilters={table().hasFilters}
              clickImportButtonHandler={table().clickImportButtonHandler}
              fetchExportedDataHander={table().fetchExportedDataHander}
              clickLinkHandlers={table().clickLinkHandlers}
              hasRules={table().hasRules}
              errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
              successHandler={success => setSuccessNotification({ text: success })}
              shouldBeUpdated={shouldBeUpdated}
            />
          </>
        ) : (
          <Spinner style={{ position: "absolute" }} />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  studiesStudyProgrammeFormOptions: inputDataListsReducer.studiesStudyProgrammeFormOptions,
  studiesProgrammeOptions: inputDataListsReducer.studiesProgrammeOptions,
  programmesListOptions: inputDataListsReducer.programmesListOptions,
  studiesStudyProgrammePostgradOptions: inputDataListsReducer.studiesStudyProgrammePostgradOptions,
  studiesModuleOptions: inputDataListsReducer.studiesModuleOptions,
  studiesEngagementOptions: inputDataListsReducer.studiesEngagementOptions,
  studiesSuspensionsOptions: inputDataListsReducer.studiesSuspensionsOptions,
  adminInstitutionOptions: inputDataListsReducer.adminInstitutionOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesStudyProgrammeFormOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammeFormOptions),
    getStudiesProgrammeOptions: () => withPromise(dispatch, actions.getStudiesProgrammeOptions),
    getProgrammesListOptions: () => withPromise(dispatch, actions.getProgrammesListOptions),
    getStudiesStudyProgrammePostgradOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammePostgradOptions),
    getStudiesModuleOptions: () => withPromise(dispatch, actions.getStudiesModuleOptions),
    getStudiesEngagementOptions: () => withPromise(dispatch, actions.getStudiesEngagementOptions),
    getStudiesSuspensionsOptions: () => withPromise(dispatch, actions.getStudiesSuspensionsOptions),
    getAdminInstitutionOptions: () => withPromise(dispatch, actions.getAdminInstitutionOptions),
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    setSuccessNotification: data => dispatch(actions.setSuccessNotification(data)),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal())
  };
};

export const StudiesTable = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
