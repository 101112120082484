import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { localLongDate, errorMessage } from "@Root/helpers";
import { Spinner, NewSnapshotForm, SnapshotForm } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";

const Snapshot = ({ location, history, permissions, showModal, hideModal, setSnackbar }) => {
  const snapshotId = location.pathname.split("/").pop();

  const [snapshot, setSnapshot] = useState(undefined);
  const [isShown, setIsShown] = useState(false);

  const getSnapshot = async () => {
    try {
      const { data } = await API.getAdminSnapshot(snapshotId);
      const { created_at, contact_name } = data.data;
      setSnapshot({
        created_at: localLongDate(created_at.date),
        contact_name
      });
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const postSnapshot = async payload => {
    try {
      await API.postAdminSnapshot(payload);
      history.push("/home/admin/snapshots");
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteSnapshot = callback => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This snapshot will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        callback(true);
        try {
          await API.deleteAdminSnapshot(snapshotId);
          history.push("/home/admin/snapshots");
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        callback(false);
      },
      () => {
        hideModal();
      }
    );
  };

  useEffect(() => {
    AdminSnapshot.isMounted = true;
    (async () => {
      snapshotId !== "new" && (await getSnapshot());
      AdminSnapshot.isMounted && setIsShown(true);
    })();
    return () => {
      AdminSnapshot.isMounted = false;
    };
  }, []);

  const form = {
    new: <NewSnapshotForm fetchContactHandler={params => API.getContacts(params, "student")} saveHandler={payload => postSnapshot(payload)} />,
    existent: <SnapshotForm permissions={permissions} initialData={snapshot} deleteHandler={callback => deleteSnapshot(callback)} />
  }[snapshotId === "new" ? "new" : "existent"];

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper hasBackButton backButtonText="Snapshots" clickBackButtonHandler={() => history.push("/home/admin/snapshots")}>
          {form}
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => ({
  permissions: authReducer.user.permissions
});

const mapDispatchToProps = dispatch => {
  return {
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const AdminSnapshot = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Snapshot)
);
