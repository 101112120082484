import React, { useState, useMemo, useEffect } from "react";
import classes from "./style.module.scss";
import { Accordion, PermissionsCheckboxes, Select } from "@Root/components";

export const RoleManager = ({
  initialData,
  onSave,
  title,
  defaultRole,
  showRolesSelect,
  showSaveButton,
  isEditable,
  onChangeData,
  showInstruction,
  fullPermissionData
}) => {
  const [values, setValue] = useState(defaultRole);
  const [data, setData] = useState(initialData);
  const [activeData, setActiveData] = useState(data);
  const [roles, setRole] = useState(null);

  useEffect(() => {
    if (fullPermissionData) {
      const roles = fullPermissionData.map(item => {
        const { options, role } = item;
        return role;
      });
      setRole(roles);
    }
  }, [fullPermissionData]);

  const onChangeValue = (fieldName, value) => {
    const newDataObject = fullPermissionData.find(item => item.role.label === value);
    setValue(fullPermissionData.find(item => item.role.label === value).role);
    setActiveData(newDataObject.options);
  };

  const onDataChange = type => tabs => {
    setData(prevState => {
      const newItem = {
        ...prevState,
        actions: prevState.actions.map(option => {
          if (option.title === type) {
            return {
              ...option,
              tabs
            };
          }
          return option;
        })
      };
      setActiveData(newItem);
      onChangeData && onChangeData(newItem);
      return newItem;
    });
  };

  const onChangeCheckBoxesPermissions = data => {
    setData(prevState => {
      const newItem = {
        ...prevState,
        additional: data
      };
      setActiveData(newItem);
      onChangeData && onChangeData(newItem);
      return newItem;
    });
  };

  const onSaveChanges = () => {
    onSave({ activeData, role_name: values.label });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.manager_Wrapper}>
        <div className={classes.first_block}>
          {title && <h2 className={classes.title}>{title}</h2>}
          {showInstruction && (
            <p className={classes.instruction}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
              velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
              id est laborum.
            </p>
          )}
          {showRolesSelect && roles && (
            <>
              <span className={classes.little_title}>Assigned Role</span>
              <Select
                inputStyle={{ width: 160, background: "#ffffff" }}
                options={roles}
                value={values.label}
                changeHandler={value => onChangeValue("role", value)}
                optionsStyle={{ width: 160 }}
                hideCross
                isDisabled={!isEditable}
              />
            </>
          )}
        </div>
        <div>
          <Accordion
            data={activeData.actions}
            activeRole={values.label}
            onDataChange={onDataChange}
            onDataUserRolesChange={onDataChange}
            isEditable={isEditable}
          />
        </div>
        <div>
          <PermissionsCheckboxes initialData={activeData.additional} onChangeHandler={onChangeCheckBoxesPermissions} isEditable={isEditable} />
        </div>
      </div>
      {showSaveButton && (
        <div className={classes.buttons}>
          <button className={classes.button_save} onClick={onSaveChanges}>
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};
