import React from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { withPromise, errorMessage } from "@Root/helpers";
import { Spinner, ActionsDropdown, Table } from "@Root/components";
import { trashInformationMandatoryRules, createDataConfig } from "@Root/configs";
class Component extends React.Component {
  state = {
    isShown: false,
    shouldBeUpdated: false,
    programmeTypes: []
  };

  actions = [
    {
      name: "Add a person",
      handler: () => this.props.history.push("/home/contacts/all-contacts/new-person")
    },
    {
      name: "Add an organisation",
      handler: () => this.props.history.push("/home/contacts/all-contacts/new-organisation")
    }
  ];

  handleClickName = row => {
    this.props.history.push(`/home/contacts/${this.props.location.pathname.split("/")[3]}/${row.id}`);
  };

  handleClickEmail = row => {
    const win = window.open(`mailto:${row.email}`);
    win.focus();
    setTimeout(() => {
      win.close();
    }, 100);
  };

  tableColumns = () => {
    const { programmeTypes } = this.state;
    const {
      location,
      titleOptions,
      genderOptions,
      personRelationshipOptions,
      organisationRelationshipOptions,
      contactsStudentTypeOptions,
      institutionOptions
    } = this.props;
    const tab = location.pathname.split("/")[3];
    const personTabs = ["students", "academics", "staff", "clergy", "associations", "all-contacts"];
    return [
      {
        name: "Photo",
        field: "img",
        searchInputType: null,
        cellType: "image"
      },
      {
        name: "Name",
        field: "name",
        searchInputType: "textInput",
        cellType: "link"
      },
      {
        name: "Title",
        field: "title",
        searchInputType: "select",
        options: titleOptions.map(x => x.label),
        cellType: "text"
      },
      {
        name: "Known as",
        field: "known_as",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Mobile",
        field: "phone",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Gender",
        field: "gender",
        searchInputType: "select",
        options: genderOptions.map(x => x.label),
        cellType: "text"
      },
      ...(tab === "all-contacts"
        ? [
            {
              name: "Relationship",
              field: "relationship",
              searchInputType: "select",
              options: [...personRelationshipOptions, ...organisationRelationshipOptions].map(x => x.label),
              cellType: "text"
            }
          ]
        : []),
      ...(tab !== "other"
        ? [
            {
              name: "Federation House",
              field: "institution",
              searchInputType: "select",
              options: [...institutionOptions].map(x => x.label),
              cellType: "text"
            }
          ]
        : []),
      ...(tab !== "students"
        ? [
            {
              name: "Trashed",
              field: "trashed",
              searchInputType: "select",
              options: ["Yes", "No"],
              cellType: "text"
            }
          ]
        : []),
      ...(tab === "students"
        ? [
            {
              name: "SID",
              field: "sid",
              searchInputType: "textInput",
              cellType: "text"
            }
          ]
        : []),
      {
        name: "Student types",
        field: "student_types",
        searchInputType: "select",
        options: contactsStudentTypeOptions.map(x => x.label),
        cellType: "text"
      },
      {
        name: "Programme types",
        field: "programme_types",
        searchInputType: "select",
        options: programmeTypes,
        cellType: "text"
      },
      {
        name: "Attractive",
        field: "attractive",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "City",
        field: "city",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Postcode",
        field: "postcode",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Address",
        field: "address",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Email",
        field: "email",
        searchInputType: "textInput",
        cellType: "mailLink"
      },
      ...(personTabs.includes(tab)
        ? [
            {
              name: "Second Email",
              field: "second_email",
              searchInputType: "textInput",
              cellType: "text"
            }
          ]
        : []),
      {
        name: "Country",
        field: "country",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "County",
        field: "county",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Birthday",
        field: "birthday",
        searchInputType: "textInput",
        cellType: "text"
      },
      ...(tab === "all-contacts" || tab === "students"
        ? [
            {
              name: "Age",
              field: "age",
              searchInputType: "textInput",
              cellType: "text"
            }
          ]
        : []),
      ...(personTabs.includes(tab) || tab === "other"
        ? [
            {
              name: "Spouse",
              field: "spouse_full_name",
              searchInputType: "textInput",
              cellType: "text"
            }
          ]
        : []),
      ...(personTabs.includes(tab) || tab === "other"
        ? [
            {
              name: "Dietary requirements",
              field: "dietary_requirements",
              searchInputType: "textInput",
              cellType: "text"
            }
          ]
        : []),
      {
        name: "Updated at",
        field: "updated_at",
        searchInputType: "datePicker",
        cellType: "text"
      },
      ...(personTabs.includes(tab)
        ? [
            {
              name: "Pruning review date",
              field: "pruning_review_date",
              searchInputType: "datePicker",
              cellType: "text"
            }
          ]
        : []),
      ...(personTabs.includes(tab)
        ? [
            {
              name: "University card barcode",
              field: "university_card_barcode",
              searchInputType: "textInput",
              cellType: "text"
            }
          ]
        : []),
      ...(tab === "students" && tab === "all-contacts"
        ? [
            {
              name: "University card date applied for",
              field: "university_card_date_applied_for",
              searchInputType: "datePicker",
              cellType: "text"
            }
          ]
        : [])
    ];
  };

  tableData = () => {
    const { permissions } = this.props;
    const mandatoryRules = trashInformationMandatoryRules;
    return {
      "all-contacts": {
        ...createDataConfig("contactsAllContacts", mandatoryRules, this.tableColumns(), "getContacts", "all-contacts", true, true, true),
        clickLinkHandlers: {
          Name: this.handleClickName,
          Email: this.handleClickEmail
        }
      },
      students: {
        ...createDataConfig(
          "contactsStudents",
          mandatoryRules,
          this.tableColumns(),
          "getContacts",
          "student",
          true,
          true,
          true,
          true,
          permissions.includes("importCSV"),
          true
        ),
        fetchExportedDataHander: API.fetchExportedAllContacts,
        clickImportButtonHandler: this.importCSV,
        clickLinkHandlers: {
          Name: this.handleClickName
        }
      },
      academics: {
        ...createDataConfig("contactsAcademics", mandatoryRules, this.tableColumns(), "getContacts", "academic", true, true, true, false, false, true),
        clickLinkHandlers: {
          Name: this.handleClickName
        }
      },
      staff: {
        ...createDataConfig("contactsStaff", mandatoryRules, this.tableColumns(), "getContacts", "staff", true, true, true, false, false, true),
        clickLinkHandlers: {
          Name: this.handleClickName
        }
      },
      clergy: {
        ...createDataConfig("contactsClergy", mandatoryRules, this.tableColumns(), "getContacts", "clergy", true, true, true, false, false, true),
        clickLinkHandlers: {
          Name: this.handleClickName
        }
      },
      associations: {
        ...createDataConfig("contactsAssociations", mandatoryRules, this.tableColumns(), "getContacts", "association", true, true, true, false, false, true),
        clickLinkHandlers: {
          Name: this.handleClickName
        }
      },
      churches: {
        ...createDataConfig("contactsChurches", mandatoryRules, this.tableColumns(), "getContacts", "church", true, true, true, false, false, true),
        clickLinkHandlers: {
          Name: this.handleClickName
        }
      },
      dioceses: {
        ...createDataConfig("contactsDioceses", mandatoryRules, this.tableColumns(), "getContacts", "diocese", true, true, true, false, false, true),
        clickLinkHandlers: {
          Name: this.handleClickName
        }
      },
      "training-facilities": {
        ...createDataConfig(
          "contactsTrainingFacilities",
          mandatoryRules,
          this.tableColumns(),
          "getContacts",
          "training-facilities",
          true,
          true,
          true,
          false,
          false,
          true
        ),
        fetchDataHandler: params => API.getContacts(params, "trainingfacility"),
        clickLinkHandlers: {
          Name: this.handleClickName
        }
      },
      other: {
        ...createDataConfig("other", mandatoryRules, this.tableColumns(), "getContacts", "other", true, true, true, false, false, true),
        clickLinkHandlers: {
          Name: this.handleClickName
        }
      }
    };
  };

  fetchOptions = async () => {
    const {
      history,
      titleOptions,
      genderOptions,
      personRelationshipOptions,
      organisationRelationshipOptions,
      getTitles,
      getGenders,
      getPersonRelationships,
      getOrganisationRelationships,
      getContactsStudentTypeOptions,
      setSnackbar,
      contactsStudentTypeOptions,
      getInstitutionOptions,
      institutionOptions
    } = this.props;
    try {
      const data = await Promise.all([
        API.getAdminProgrammeTypes(),
        !titleOptions ? getTitles() : null,
        !genderOptions ? getGenders() : null,
        !personRelationshipOptions ? getPersonRelationships() : null,
        !organisationRelationshipOptions ? getOrganisationRelationships() : null,
        !contactsStudentTypeOptions ? getContactsStudentTypeOptions() : null,
        !institutionOptions ? getInstitutionOptions() : null
      ]);
      this.setState({ programmeTypes: data[0].data.data.map(x => x.name), isShown: true });
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.replace("/contact-us");
    }
  };

  refreshTable = () => {
    this.setState({ shouldBeUpdated: true }, () => this.setState({ shouldBeUpdated: false }));
  };

  importCSV = () => {
    const { showModal, hideModal, setSnackbar } = this.props;
    const { refreshTable } = this;
    let importData = {};
    new Promise((resolve, reject) => {
      showModal("UploadingModal", {
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    })
      .then(
        file => {
          return API.postContactsStudentsCsvFile(file);
        },
        () => {
          hideModal();
          Promise.resolve();
        }
      )
      .then(
        ({ data }) => {
          importData = data.data;
          return new Promise((resolve, reject) => {
            showModal("ImportModal", {
              headerText: "Results",
              importDescription: importData.contacts.length ? "The following student records were imported:" : "No students were imported.",
              importData: { data: importData.contacts, fields: ["sid", "name", "email"] },
              invalidImportDescription: importData.invalid_students.length
                ? "The following student records were not imported because they already exist in the database."
                : "No import conflicts were detected.",
              invalidImportData: { data: importData.invalid_students, fields: ["sid", "name", "email"] },
              leftButtonText: importData.contacts.length ? "Roll back" : null,
              rightButtonText: "Ok",
              clickRejectButtonHandler: reject,
              clickResolveButtonHandler: resolve
            });
          });
        },
        error => {
          const convertErrors = serverError => {
            try {
              const errorsObj = serverError.response.data.errors;
              return Object.keys(errorsObj)
                .sort((a, b) => {
                  const x = +a.slice(11);
                  const y = +b.slice(11);
                  if (x > y) return 1;
                  if (x < y) return -1;
                  return 0;
                })
                .map(key => ({
                  title: key,
                  rows: errorsObj[key]
                }));
            } catch (error) {
              console.log("Unable to convert errors");
              let message = serverError.response.data.message;
              return [{ title: "Error Message", rows: [message] }];
            }
          };

          const errors = convertErrors(error);

          new Promise((resolve, reject) => {
            showModal("ImportModal", {
              headerText: "Results",
              importDescription: `Unable to process the data. Please check your template.${errors.length ? " Errors list:" : ""}`,
              errors,
              rightButtonText: "Ok",
              clickRejectButtonHandler: reject,
              clickResolveButtonHandler: resolve
            });
          }).then(
            () => {
              hideModal();
            },
            () => {
              hideModal();
            }
          );
        }
      )
      .then(
        button => {
          if (button === "left") {
            new Promise((resolve, reject) => {
              showModal("ImportModal", {
                headerText: "Warning",
                importDescription: "The student records from the most recent import will be deleted. Click the delete button to delete the records:",
                importData: { data: importData.contacts, fields: ["sid", "name", "email"] },
                leftButtonText: "Cancel Rollback",
                rightButtonText: "Delete",
                clickRejectButtonHandler: reject,
                clickResolveButtonHandler: resolve
              });
            }).then(
              button => {
                if (button === "left") {
                  hideModal();
                  refreshTable();
                } else if (button === "right") {
                  API.postContactsStudentsImportRollback({ rollback: importData.rollback }).then(
                    () => {
                      new Promise((resolve, reject) => {
                        showModal("NotificationModal", {
                          text: "Your records were deleted.",
                          clickRejectButtonHandler: reject,
                          clickResolveButtonHandler: resolve
                        });
                      }).then(
                        () => {
                          hideModal();
                        },
                        () => {
                          hideModal();
                        }
                      );
                    },
                    error => {
                      setSnackbar({ text: `Unable to roll back. Error message: ${errorMessage(error)}`, isError: true });
                      hideModal();
                      refreshTable();
                    }
                  );
                }
              },
              () => {
                hideModal();
                Promise.resolve();
              }
            );
          } else if (button === "right") {
            hideModal();
            refreshTable();
          }
        },
        () => {
          hideModal();
          Promise.resolve();
        }
      );
  };

  async componentDidMount() {
    await this.fetchOptions();
    !this.isUnmounted && this.setState({ isShown: true });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { isShown, shouldBeUpdated } = this.state;
    const { location, setSnackbar, permissions, setSuccessNotification } = this.props;
    const { actions, tableData } = this;
    const tab = location.pathname.split("/")[3];

    return (
      <div className={classes.wrapper}>
        {permissions.includes("create") && (
          <ActionsDropdown
            actions={actions.map(action => action.name)}
            clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
            style={{ position: "absolute", right: 10, top: -80 }}
          />
        )}
        {isShown ? (
          <Table
            key={tableData()[tab].name}
            name={tableData()[tab].name}
            mandatoryRules={tableData()[tab].mandatoryRules}
            columns={tableData()[tab].columns}
            fetchDataHandler={tableData()[tab].fetchDataHandler}
            fetchSaveColumnOptions={tableData()[tab].fetchSaveColumnOptions}
            fetchColumnOptions={tableData()[tab].fetchColumnOptions}
            fetchExportedDataHander={tableData()[tab].fetchExportedDataHander}
            clickLinkHandlers={tableData()[tab].clickLinkHandlers}
            hasRules={tableData()[tab].hasRules}
            hasExport={tableData()[tab].hasExport}
            hasImport={tableData()[tab].hasImport}
            hasFilters={tableData()[tab].hasFilters}
            hasSorting={tableData()[tab].hasSorting}
            clickImportButtonHandler={tableData()[tab].clickImportButtonHandler}
            errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
            successHandler={success => setSuccessNotification({ text: success })}
            shouldBeUpdated={shouldBeUpdated}
          />
        ) : (
          <Spinner style={{ position: "absolute" }} />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  titleOptions: inputDataListsReducer.titles,
  genderOptions: inputDataListsReducer.genders,
  personRelationshipOptions: inputDataListsReducer.personRelationships,
  organisationRelationshipOptions: inputDataListsReducer.organisationRelationships,
  contactsStudentTypeOptions: inputDataListsReducer.contactsStudentTypeOptions,
  institutionOptions: inputDataListsReducer.institutionOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getTitles: () => withPromise(dispatch, actions.getTitles),
    getGenders: () => withPromise(dispatch, actions.getGenders),
    getPersonRelationships: () => withPromise(dispatch, actions.getPersonRelationships),
    getOrganisationRelationships: () => withPromise(dispatch, actions.getOrganisationRelationships),
    getContactsStudentTypeOptions: () => withPromise(dispatch, actions.getContactsStudentTypeOptions),
    getInstitutionOptions: () => withPromise(dispatch, actions.getInstitutionOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    setSuccessNotification: data => dispatch(actions.setSuccessNotification(data))
  };
};

export const ContactsTable = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
