import React, { Component } from "react";
import "./style.scss";
import DocumentTitle from "react-document-title";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { PasswordChangingForm } from "@Root/components";

class Page extends Component {
  checkRecoveryToken = () => {
    const { checkRecoveryToken, location, history } = this.props;
    checkRecoveryToken(location.hash.slice(1), history);
  };

  componentDidMount() {
    this.checkRecoveryToken();
  }

  render() {
    const { recoveryToken, error, history, sendNewPassword } = this.props;

    return (
      <DocumentTitle title="Password changing">
        <div className="PasswordChangingPage">
          {recoveryToken && <PasswordChangingForm error={error} history={history} newPasswordHandler={sendNewPassword} />}
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({ error: authReducer.error, recoveryToken: authReducer.recoveryToken });

const mapDispatchToProps = dispatch => {
  return {
    checkRecoveryToken: (token, history) => dispatch(actions.checkRecoveryToken(token, history)),
    sendNewPassword: (password, history) => dispatch(actions.sendNewPassword(password, history))
  };
};

export const PasswordChangingPage = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
