import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, Select, InputLabel, TextInput } from "@Root/components";
import { EmbeddedForm } from "./EmbeddedForm";

export const QualificationForm = ({ permissions, isNew, title, options, initialData, buttons, deleteHandler, saveHandler }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [isSpinning, setIsSpinning] = useState(false);
  const [error, setError] = useState(null);

  const awardingBodyRoleConfig = {
    path: "awarding_body_roles",
    inputs: [
      {
        label: "Awarding Body Identifier",
        field: "awarding_body_id",
        type: "select",
        options: options.awarding_body_role.awarding_body,
        validations: ["required"]
      },
      {
        label: "Relationship",
        field: "relationship_id",
        type: "select",
        options: options.awarding_body_role.relationship,
        validations: ["required"]
      }
    ],
    emptyRow: {
      awarding_body_id: null,
      relationship_id: null
    }
  };

  const qualificationSubjectConfig = {
    path: "qualification_subjects",
    inputs: [
      {
        label: "Qualification subject",
        field: "qual_subject_id",
        type: "select",
        options: options.qualification_subject.qualification_subject,
        validations: ["required"]
      },
      {
        label: "Qualification Proportion",
        field: "qualification_proportion",
        type: "textInput",
        validations: ["required"]
      },
      {
        label: "Z: Qualification subject CAH 1",
        field: "qualification_subject_cah_id",
        type: "select",
        options: options.qualification_subject.qualification_subject_cah,
        validations: ["required"]
      }
    ],
    emptyRow: {
      qual_subject_id: null,
      qualification_proportion: null,
      qualification_subject_cah_id: null
    }
  };

  const zQualificationSubjectConfig = {
    path: "z_qualification_subjects",
    inputs: [
      {
        label: "Z: Qualification subject",
        field: "z_qual_subject_id",
        type: "select",
        options: options.z_qualification_subject.z_qual_subject,
        validations: ["required"]
      },
      {
        label: "Z: Qualification Proportion",
        field: "z_qualification_proportion",
        type: "textInput",
        validations: ["required"]
      }
    ],
    emptyRow: {
      z_qual_subject_id: null,
      z_qualification_proportion: null
    }
  };

  const handleAddRow = ({ path, emptyRow }) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy[path].push(emptyRow);
    setData(dataCopy);
  };

  const handleChangeRowInput = ({ path }, rowIndex, field, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy[path][rowIndex][field] = value;
    setData(dataCopy);
  };

  const handleDeleteRow = ({ path }, rowIndex) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy[path] = dataCopy[path].filter((row, i) => i !== rowIndex);
    setData(dataCopy);
  };

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const showError = (path, index, field, message) => setError({ path, index, field, message });

  const embeddedFormIsValid = (path, form) => {
    for (let index = 0; index < form.length; index++) {
      const row = form[index];
      for (const field in row) {
        if (!row[field]) {
          showError(path, index, field, "Required");
          return false;
        }
      }
    }
    return true;
  };

  const formIsValid = () => {
    const {
      // qualification_id,
      qualification_category_id,
      qualification_title,
      z_level_of_study_six_way_split,
      z_level_of_study_ten_way_split,
      awarding_body_roles,
      qualification_subjects,
      z_qualification_subjects
    } = data;
    // if (!qualification_id) {
    //   showError(null, null, "qualification_id", "Required");
    //   return false;
    // }
    if (!qualification_title) {
      showError(null, null, "qualification_title", "Required");
      return false;
    }
    if (!qualification_category_id) {
      showError(null, null, "qualification_category_id", "Required");
      return false;
    }
    if (!z_level_of_study_six_way_split) {
      showError(null, null, "z_level_of_study_six_way_split", "Required");
      return false;
    }
    if (!z_level_of_study_ten_way_split) {
      showError(null, null, "z_level_of_study_ten_way_split", "Required");
      return false;
    }
    if (!embeddedFormIsValid("awarding_body_roles", awarding_body_roles)) return false;
    if (!embeddedFormIsValid("qualification_subjects", qualification_subjects)) return false;
    if (!embeddedFormIsValid("z_qualification_subjects", z_qualification_subjects)) return false;
    return true;
  };

  const rootErrorMessage = field => (error && !error.path && error.field === field ? error.message : null);

  const embeddedFormError = path => {
    return error && error.path === path ? { index: error.index, field: error.field, message: error.message } : null;
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSpinning(true);
      },
      () => {
        QualificationForm.isMounted && setIsSpinning(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSpinning(true);
    const modifiedData = switchEmptyStringsToNullsInObject(data);
    await saveHandler(modifiedData, () => {
      QualificationForm.isMounted && setIsEditable(false);
    });
    QualificationForm.isMounted && setIsSpinning(false);
  };

  useEffect(() => {
    QualificationForm.isMounted = true;
    return () => {
      QualificationForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    QualificationForm.isMounted && setData(initialData);
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  return (
    <FormWrapper
      buttons={isEditable ? buttons : []}
      buttonsNames={[{ button: "delete", name: "Delete this Qualification" }]}
      buttonsAreDisabled={isSpinning}
      isSpinning={isSpinning}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.title}>
        <SectionTitle title={isEditable ? title.onEdit : title.onRead} />
        {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}
      </div>
      <div className={classes.content}>
        <div className={classes.rows}>
          {/* <div className={classes.row}>
            <InputLabel text="Module ID" />
            <TextInput
              classNames={!isEditable ? ["borderless"] : []}
              style={{ position: "absolute", left: 200, width: 340 }}
              value={data.module_id}
              changeHandler={value => handleChangeInput("module_id", value)}
              isDisabled={!isEditable}
              error={rootErrorMessage("module_id")}
            />
            <InputLabel style={{ position: "absolute", left: 560 }} text="Qualification ID" hasAsterisk={isEditable} />
            <TextInput
              classNames={!isEditable ? ["borderless"] : []}
              style={{ position: "absolute", left: 760, width: 340 }}
              value={data.qualification_id}
              changeHandler={value => handleChangeInput("qualification_id", value)}
              isDisabled={!isEditable}
              error={rootErrorMessage("qualification_id")}
            />
          </div> */}
          <div className={classes.row}>
            <InputLabel text="Qualification Title" hasAsterisk={isEditable} />
            <TextInput
              classNames={!isEditable ? ["borderless"] : []}
              style={{ position: "absolute", left: 200, width: 340 }}
              value={data.qualification_title}
              changeHandler={value => handleChangeInput("qualification_title", value)}
              isDisabled={!isEditable}
              error={rootErrorMessage("qualification_title")}
            />
            <InputLabel style={{ position: "absolute", left: 560 }} text="Qualification Category" hasAsterisk={isEditable} />
            <Select
              style={{ position: "absolute", left: 760 }}
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 340 }}
              options={options.qualification_category}
              value={data.qualification_category_id}
              changeHandler={value => handleChangeInput("qualification_category_id", value)}
              isDisabled={!isEditable}
              error={rootErrorMessage("qualification_category_id")}
            />
          </div>
          <div className={classes.row}>
            <InputLabel text="Z: Level of study 6-way split" hasAsterisk={isEditable} />
            <Select
              style={{ position: "absolute", left: 200 }}
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 340 }}
              options={options.z_level_of_study_six_way_split}
              value={data.z_level_of_study_six_way_split}
              changeHandler={value => handleChangeInput("z_level_of_study_six_way_split", value)}
              isDisabled={!isEditable}
              error={rootErrorMessage("z_level_of_study_six_way_split")}
            />
            <InputLabel style={{ position: "absolute", left: 560 }} text="Z: Level of study 10-way split " hasAsterisk={isEditable} />
            <Select
              style={{ position: "absolute", left: 760 }}
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 340 }}
              options={options.z_level_of_study_ten_way_split}
              value={data.z_level_of_study_ten_way_split}
              changeHandler={value => handleChangeInput("z_level_of_study_ten_way_split", value)}
              isDisabled={!isEditable}
              error={rootErrorMessage("z_level_of_study_ten_way_split")}
            />
          </div>
          {/* <div className={classes.row}>
            <InputLabel text="Course Delivery" />
            <TextInput
              classNames={!isEditable ? ["borderless"] : []}
              style={{ position: "absolute", left: 200, width: 340 }}
              value={data.course_delivery}
              changeHandler={value => handleChangeInput("course_delivery", value)}
              isDisabled={!isEditable}
            />
          </div> */}
        </div>
        <div className={classes.embeddedForms}>
          <EmbeddedForm
            isEditable={isEditable}
            title="Awarding Body Role"
            data={data.awarding_body_roles}
            inputs={awardingBodyRoleConfig.inputs}
            addIsAllowed
            addHandler={() => handleAddRow(awardingBodyRoleConfig)}
            changeHandler={(rowIndex, field, value) => handleChangeRowInput(awardingBodyRoleConfig, rowIndex, field, value)}
            deleteIsAllowed
            deleteHandler={rowIndex => handleDeleteRow(awardingBodyRoleConfig, rowIndex)}
            error={embeddedFormError("awarding_body_roles")}
          />
          <EmbeddedForm
            isEditable={isEditable}
            title="Qualification Subject"
            data={data.qualification_subjects}
            inputs={qualificationSubjectConfig.inputs}
            addIsAllowed={data.qualification_subjects.length < 5}
            addHandler={() => handleAddRow(qualificationSubjectConfig)}
            changeHandler={(rowIndex, field, value) => handleChangeRowInput(qualificationSubjectConfig, rowIndex, field, value)}
            deleteIsAllowed={data.qualification_subjects.length > 1}
            deleteHandler={rowIndex => handleDeleteRow(qualificationSubjectConfig, rowIndex)}
            error={embeddedFormError("qualification_subjects")}
          />
          <EmbeddedForm
            isEditable={isEditable}
            title="Z: Qualification Subject"
            data={data.z_qualification_subjects}
            inputs={zQualificationSubjectConfig.inputs}
            addIsAllowed={data.z_qualification_subjects.length < 5}
            addHandler={() => handleAddRow(zQualificationSubjectConfig)}
            changeHandler={(rowIndex, field, value) => handleChangeRowInput(zQualificationSubjectConfig, rowIndex, field, value)}
            deleteIsAllowed={data.z_qualification_subjects.length > 1}
            deleteHandler={rowIndex => handleDeleteRow(zQualificationSubjectConfig, rowIndex)}
            error={embeddedFormError("z_qualification_subjects")}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

QualificationForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  isNew: PropTypes.bool,
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  options: PropTypes.object,
  initialData: PropTypes.shape({
    // module_id: PropTypes.number,
    // qualification_id: PropTypes.number,
    qualification_title: PropTypes.string,
    qualification_category_id: PropTypes.number,
    z_level_of_study_six_way_split: PropTypes.number,
    z_level_of_study_ten_way_split: PropTypes.number,
    course_delivery: PropTypes.arrayOf(PropTypes.number),
    awarding_body_roles: PropTypes.arrayOf(
      PropTypes.shape({
        awarding_body_id: PropTypes.number,
        relationship_id: PropTypes.number
      })
    ),
    qualification_subjects: PropTypes.arrayOf(
      PropTypes.shape({
        qual_subject_id: PropTypes.number,
        qualification_proportion: PropTypes.string,
        qualification_subject_cah_id: PropTypes.number
      })
    ),
    z_qualification_subjects: PropTypes.arrayOf(
      PropTypes.shape({
        z_qual_subject_id: PropTypes.number,
        z_qualification_proportion: PropTypes.string
      })
    )
  }),
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

QualificationForm.defaultProps = {
  permissions: [],
  isNew: false,
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    // module_id: null,
    // qualification_id: null,
    qualification_title: null,
    qualification_category_id: null,
    z_level_of_study_six_way_split: null,
    z_level_of_study_ten_way_split: null,
    // course_delivery: null, // [1, 2],
    awarding_body_roles: [],
    qualification_subjects: [
      {
        qual_subject_id: null,
        qualification_proportion: null,
        qualification_subject_cah_id: null
      }
    ],
    z_qualification_subjects: [
      {
        z_qual_subject_id: null,
        z_qualification_proportion: null
      }
    ]
  },
  buttons: [],
  deleteHandler: () => {},
  saveHandler: () => {}
};
