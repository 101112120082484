import React, { useState, useEffect, useRef } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { all, errorMessage } from "@Root/helpers";
import { SectionWrapper, FormWrapper } from "@Root/HOCs";
import { EditButton, Spinner, SectionTitle, Editor } from "@Root/components";

const FormInstructions = ({ history, permissions, setSnackbar }) => {
  const [initialData, setInitialData] = useState("<p></p>");
  const [data, setData] = useState("<p></p>");
  const [isShown, setIsShown] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const editorRef = useRef();

  const fetchData = async () => {
    try {
      const { data } = await API.getApplicationFormInstructions();
      if (FormInstructions.isMounted) {
        setInitialData(data.html.header);
        setData(data.html.header);
      }
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const updateData = async () => {
    try {
      setIsSpinning(true);
      await API.putApplicationFormInstructions(data);
      if (FormInstructions.isMounted) setIsEditable(false);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
    if (FormInstructions.isMounted) setIsSpinning(false);
  };

  useEffect(() => {
    FormInstructions.isMounted = true;
    (async () => {
      await fetchData();
      if (FormInstructions.isMounted) setIsShown(true);
    })();
    return () => {
      FormInstructions.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper>
          <FormWrapper
            buttons={isEditable ? ["cancel", "save"] : []}
            buttonsAreDisabled={isSpinning}
            isSpinning={isSpinning}
            clickCancelButtonHandler={() => all(() => setData(initialData), () => setIsEditable(false), () => editorRef.current.setEditorState(initialData))}
            clickSaveButtonHandler={updateData}
          >
            <div className={classes.title}>
              <SectionTitle title={isEditable ? "Edit the Application Form Instructions" : "Application Form Instructions"} />
              {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}
            </div>
            <Editor ref={editorRef} value={data} changeHandler={value => setData(value)} isDisabled={!isEditable} />
          </FormWrapper>
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => ({
  permissions: authReducer.user.permissions
});

const mapDispatchToProps = dispatch => ({
  setSnackbar: data => dispatch(actions.setSnackbar(data))
});

export const AdminApplicationFormInstructions = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FormInstructions)
);
