import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { booleanOptions } from "@Root/configs";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select, TextArea, DatePicker } from "@Root/components";
export class StudiesProgrammeForm extends Component {
  constructor(props) {
    super(props);
    const { initialIsEditable, initialData } = props;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      programme_name: initialData.programme_name,
      programme_type: initialData.programme_type,
      programme_mode: initialData.programme_mode,
      subject_code: initialData.subject_code,
      programme_years: initialData.programme_years,
      course_delivery_mode_type: initialData.course_delivery_mode_type,
      course_delivery_type: initialData.course_delivery_type,
      HEFCE_code: initialData.HEFCE_code,
      HEFCE_code_Courseaim: initialData.HEFCE_code_Courseaim,
      available: initialData.available,
      description: initialData.description,
      course_delivery_start_date: initialData.course_delivery_start_date,
      designated_course: initialData.designated_course,
      expected_end_date: initialData.expected_end_date,
      qualification_identifier: initialData.qualification_identifier,
      course_delivery_reference: initialData.course_delivery_reference,
      course_delivery_title: initialData.course_delivery_title,
      course_delivery_role: initialData.course_delivery_role,
      course_delivery_venue: initialData.course_delivery_venue,
      course_session: initialData.course_session,
      module_id: initialData.course_session,
      credit_value_of_module: initialData.credit_value_of_module,
      uuid: initialData.uuid,
      program_awarding_body_id: initialData.program_awarding_body_id
    };
  }

  formIsValid = () => {
    const { programme_name, programme_type } = this.state;
    if (!programme_name) {
      this.showError("programme_name", "Required");
      return false;
    }
    if (!programme_type) {
      this.showError("programme_type", "Required");
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  setInitialState = () => {
    const { initialData } = this.props;
    this.setState({
      isEditable: false,
      error: null,
      programme_name: initialData.programme_name,
      programme_type: initialData.programme_type,
      programme_mode: initialData.programme_mode,
      subject_code: initialData.subject_code,
      programme_years: initialData.programme_years,
      course_delivery_mode_type: initialData.course_delivery_mode_type,
      course_delivery_type: initialData.course_delivery_type,
      HEFCE_code: initialData.HEFCE_code,
      HEFCE_code_Courseaim: initialData.HEFCE_code_Courseaim,
      available: initialData.available,
      description: initialData.description,
      course_delivery_start_date: initialData.course_delivery_start_date,
      designated_course: initialData.designated_course,
      expected_end_date: initialData.expected_end_date,
      qualification_identifier: initialData.qualification_identifier,
      course_delivery_reference: initialData.course_delivery_reference,
      course_delivery_title: initialData.course_delivery_title,
      course_delivery_role: initialData.course_delivery_role,
      course_delivery_venue: initialData.course_delivery_venue,
      course_session: initialData.course_session,
      module_id: initialData.course_session,
      credit_value_of_module: initialData.credit_value_of_module,
      uuid: initialData.uuid,
      program_awarding_body_id: initialData.program_awarding_body_id
    });
  };

  handleClickCancelButton = () => {
    this.setInitialState();
  };

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        !this.isUnmounted && this.setState({ isSaving: false });
      }
    );
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { state } = this;
    this.setState({ isSaving: true });
    await this.props.saveHandler(
      {
        programme_name: state.programme_name,
        programme_type: state.programme_type,
        programme_mode: state.programme_mode,
        HEFCE_code: state.HEFCE_code,
        subject_code: state.subject_code,
        HEFCE_code_Courseaim: state.HEFCE_code_Courseaim,
        programme_years: state.programme_years,
        available: state.available,
        description: state.description,
        course_delivery_mode_type: state.course_delivery_mode_type,
        course_delivery_type: state.course_delivery_type,
        course_delivery_start_date: state.course_delivery_start_date,
        designated_course: state.designated_course,
        expected_end_date: state.expected_end_date,
        qualification_identifier: state.qualification_identifier,
        course_delivery_reference: state.course_delivery_reference,
        course_delivery_title: state.course_delivery_title,
        course_delivery_role: state.course_delivery_role,
        course_delivery_venue: state.course_delivery_venue,
        course_session: state.course_session,
        module_id: state.course_session,
        program_awarding_body_id: state.program_awarding_body_id
      },
      () => {
        !this.isUnmounted && this.setState({ isEditable: false });
      }
    );
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const {
      isEditable,
      isSaving,
      programme_name,
      programme_mode,
      subject_code,
      programme_years,
      programme_type,
      HEFCE_code,
      HEFCE_code_Courseaim,
      available,
      description,
      course_delivery_mode_type,
      course_delivery_type,
      course_delivery_start_date,
      designated_course,
      expected_end_date,
      qualification_identifier,
      course_delivery_reference,
      course_delivery_title,
      course_delivery_role,
      course_delivery_venue,
      module_id,
      uuid,
      program_awarding_body_id
    } = this.state;

    const { permissions, title, options, buttons } = this.props;
    const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage } = this;
    const selectedModule = options.modules.find(module => module.value === module_id);
    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;

    console.log(options);
    return (
      <div className={classes.StudiesProgrammeForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this programme" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            <div>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}</div>
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Programme Name" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={programme_name}
                  changeHandler={programme_name => this.setState({ programme_name })}
                  placeholder=""
                  isDisabled={!isEditable}
                  error={errorMessage("programme_name")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Programme Type" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.program_types}
                  value={programme_type}
                  changeHandler={value => this.setState({ programme_type: value })}
                  isDisabled={!isEditable}
                  error={errorMessage("programme_type")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Programme Mode" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.programme_modes}
                  value={programme_mode}
                  changeHandler={value => this.setState({ programme_mode: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Subject Code" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={subject_code}
                  changeHandler={subject_code => this.setState({ subject_code })}
                  placeholder=""
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Programme Years" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.programme_years}
                  value={programme_years}
                  changeHandler={value => this.setState({ programme_years: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Available" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={booleanOptions}
                  value={available}
                  changeHandler={available => this.setState({ available })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.textareaWrapper}>
                <InputLabel text="Description" />
                <TextArea
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260, height: 90 }}
                  value={description}
                  changeHandler={value => this.setState({ description: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Course Delivery Mode Type" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.course_delivery_mode_type}
                  value={course_delivery_mode_type}
                  changeHandler={value => this.setState({ course_delivery_mode_type: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Course Delivery Reference" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={course_delivery_reference}
                  changeHandler={course_delivery_reference => this.setState({ course_delivery_reference })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Course Delivery Title" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={course_delivery_title}
                  changeHandler={course_delivery_title => this.setState({ course_delivery_title })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Programme Import ID" />
                <TextInput classNames={["borderless"]} style={{ width: 260 }} value={uuid} isDisabled />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Course Delivery Type" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.course_delivery_type}
                  value={course_delivery_type}
                  changeHandler={value => this.setState({ course_delivery_type: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Course delivery start date" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={course_delivery_start_date}
                  changeHandler={course_delivery_start_date => this.setState({ course_delivery_start_date })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Designated course" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.designated_course}
                  value={designated_course}
                  changeHandler={value => this.setState({ designated_course: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Expected end date" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={expected_end_date}
                  changeHandler={expected_end_date => this.setState({ expected_end_date })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Qualification identifier" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={qualification_identifier}
                  changeHandler={qualification_identifier => this.setState({ qualification_identifier })}
                  placeholder=""
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Course delivery role" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={course_delivery_role}
                  changeHandler={course_delivery_role => this.setState({ course_delivery_role })}
                  placeholder=""
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Course delivery venue" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.venues}
                  value={course_delivery_venue}
                  changeHandler={value => this.setState({ course_delivery_venue: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="HEFCE Code" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={HEFCE_code}
                  changeHandler={HEFCE_code => this.setState({ HEFCE_code })}
                  placeholder=""
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="HEFCE Code - COURSEAIM" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={HEFCE_code_Courseaim}
                  changeHandler={HEFCE_code_Courseaim => this.setState({ HEFCE_code_Courseaim })}
                  placeholder=""
                  isDisabled={!isEditable}
                />
              </div>{" "}
              {selectedModule && selectedModule.credit && (
                <div className={classes.inputWrapper}>
                  <InputLabel text="Credit value of module" />
                  <TextInput classNames={!isEditable ? ["borderless"] : []} style={{ width: 260 }} value={String(selectedModule.credit)} isDisabled />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <InputLabel text="Awarding body" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.program_awarding_bodies}
                  value={program_awarding_body_id}
                  changeHandler={value => this.setState({ program_awarding_body_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

StudiesProgrammeForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    programme_name: PropTypes.string,
    programme_type: PropTypes.number,
    programme_mode: PropTypes.number,
    subject_code: PropTypes.string,
    programme_years: PropTypes.number,
    course_delivery_mode_type: PropTypes.number,
    course_delivery_type: PropTypes.number,
    HEFCE_code: PropTypes.string,
    HEFCE_code_Courseaim: PropTypes.string,
    available: PropTypes.bool,
    description: PropTypes.string,
    course_delivery_start_date: PropTypes.string,
    designated_course: PropTypes.number,
    expected_end_date: PropTypes.string,
    qualification_identifier: PropTypes.string,
    course_delivery_reference: PropTypes.string,
    course_delivery_title: PropTypes.string,
    course_delivery_role: PropTypes.string,
    course_delivery_venue: PropTypes.number,
    course_session: PropTypes.string,
    module_id: PropTypes.number,
    credit_value_of_module: PropTypes.number,
    uuid: PropTypes.string,
    awarding_body: PropTypes.string
  }),
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

StudiesProgrammeForm.defaultProps = {
  permissions: [],
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    programme_name: null,
    programme_type: null,
    programme_mode: null,
    subject_code: null,
    programme_years: null,
    course_delivery_mode_type: null,
    course_delivery_type: null,
    HEFCE_code: null,
    HEFCE_code_Courseaim: null,
    available: false,
    description: null,
    course_delivery_start_date: null,
    designated_course: null,
    expected_end_date: null,
    qualification_identifier: null,
    course_delivery_reference: null,
    course_delivery_title: null,
    course_delivery_role: null,
    course_delivery_venue: null,
    course_session: null,
    module_id: null,
    credit_value_of_module: null,
    uuid: null,
    awarding_body: null
  },
  initialIsEditable: false,
  options: null,
  buttons: ["cancel", "delete", "save"],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};
