import { API } from "@Root/API";

export const createDataConfig = (
  name,
  mandatoryRules,
  columns,
  fetchDataHandlerFuncName,
  saveColumnName,
  hasRules,
  hasFilters,
  hasSorting,
  hasExport,
  hasImport,
  isType
) => {
  return {
    name,
    mandatoryRules,
    columns,
    fetchDataHandler: params => API[fetchDataHandlerFuncName](params, isType ? saveColumnName : null),
    fetchSaveColumnOptions: options => API.saveColumnOptions(saveColumnName, options),
    fetchColumnOptions: async () => await API.getColumnOptions(saveColumnName),
    hasRules,
    hasFilters,
    hasSorting,
    hasExport,
    hasImport
  };
};
