import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { ArrowButton, TextInput } from "@Root/components";

export class TablePagination extends Component {
  state = {
    inputMaxVisibleRowsQuantity: "",
    inputPage: "1"
  };

  componentDidMount() {
    this.setState({ inputMaxVisibleRowsQuantity: String(this.props.maxVisibleRowsQuantity), inputPage: String(this.props.page) });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.inputMaxVisibleRowsQuantity !== prevState.inputMaxVisibleRowsQuantity) {
      const { inputMaxVisibleRowsQuantity } = this.state;
      inputMaxVisibleRowsQuantity !== "" && inputMaxVisibleRowsQuantity !== "0" && this.props.changeMaxVisibleRowsQuantityHandler(+inputMaxVisibleRowsQuantity);
    }
    if (this.props.maxVisibleRowsQuantity !== prevProps.maxVisibleRowsQuantity) {
      this.setState({ inputMaxVisibleRowsQuantity: String(this.props.maxVisibleRowsQuantity) });
    }
    if (this.state.inputPage !== prevState.inputPage) {
      const { inputPage } = this.state;
      inputPage !== "" && inputPage !== "0" && this.props.changePageHandler(+inputPage);
    }
    if (this.props.page !== prevProps.page) {
      this.setState({ inputPage: String(this.props.page) });
    }
  }

  render() {
    const { inputMaxVisibleRowsQuantity, inputPage } = this.state;
    const { minVisibleRowsQuantity, page, changePageHandler, paginationData } = this.props;

    return (
      <div className={classes.TablePagination}>
        <div>
          Displaying {+paginationData.from}-{+paginationData.to} of {paginationData.total} {paginationData.total === 1 ? "result" : "results"}
        </div>
        <div>
          <TextInput
            style={{ width: 44, height: 26, marginRight: 10, backgroundColor: "white" }}
            value={inputMaxVisibleRowsQuantity}
            changeHandler={value =>
              value !== "0" && +value <= Math.min(paginationData.total, 100) ? this.setState({ inputMaxVisibleRowsQuantity: value }) : null
            }
            blurHandler={() => (!inputMaxVisibleRowsQuantity ? this.setState({ inputMaxVisibleRowsQuantity: String(minVisibleRowsQuantity) }) : null)}
            restriction="digits"
          />
          <span>results per page</span>
        </div>
        <div>
          <button className={classes.button} onClick={() => changePageHandler(1)}>
            First
          </button>
          <ArrowButton style={{ width: 26, height: 26 }} direction="left" clickHandler={() => (page > 1 ? changePageHandler(page - 1) : null)} />
          <div>Page</div>
          <TextInput
            style={{ width: 60, height: 26, backgroundColor: "white" }}
            value={inputPage}
            changeHandler={value => (value !== "0" && +value <= paginationData.lastPage ? this.setState({ inputPage: value }) : null)}
            blurHandler={() => (!inputPage ? this.setState({ inputPage: "1" }) : null)}
            restriction="digits"
          />
          <div>of {paginationData.lastPage}</div>
          <ArrowButton
            style={{ width: 26, height: 26 }}
            direction="right"
            clickHandler={() => (page < paginationData.lastPage ? changePageHandler(page + 1) : null)}
          />
          <button className={classes.button} onClick={() => changePageHandler(paginationData.lastPage)}>
            Last
          </button>
        </div>
      </div>
    );
  }
}

TablePagination.propTypes = {
  minVisibleRowsQuantity: PropTypes.number,
  maxVisibleRowsQuantity: PropTypes.number,
  changeMaxVisibleRowsQuantityHandler: PropTypes.func,
  page: PropTypes.number,
  changePageHandler: PropTypes.func,
  paginationData: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
    perPage: PropTypes.number,
    lastPage: PropTypes.number,
    total: PropTypes.number
  })
};
