import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withPromise, errorMessage } from "@Root/helpers";
import { API } from "@Root/API";
import * as actions from "@Root/store";
import { SectionWrapper } from "@Root/HOCs";
import { Table, Spinner, ActionsDropdown, StudiesProgrammeForm } from "@Root/components";

const Component = ({
  permissions,
  history,
  location,
  getGenderOptions,
  genderOptions,
  getStudiesProgrammeOptions,
  studiesProgrammeOptions,
  getStudiesStudyProgrammeFormOptions,
  studiesStudyProgrammeFormOptions,
  showModal,
  hideModal,
  setSnackbar
}) => {
  const [isShown, setIsShown] = useState(false);
  const [programme, setProgramme] = useState(null);

  const programmeId = location.pathname.split("/").pop();

  const actions = [
    ...(permissions.includes("create")
      ? [
          {
            name: "Create a Programme",
            handler: () => history.push(`/home/studies/programmes/new`)
          }
        ]
      : [])
  ];

  const fetchData = async () => {
    try {
      const data = await Promise.all([
        API.getProgramme(programmeId),
        !genderOptions ? getGenderOptions() : null,
        getStudiesProgrammeOptions(),
        getStudiesStudyProgrammeFormOptions()
      ]);
      setProgramme(data[0].data.data);
      Component.isMounted && setIsShown(true);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.replace("/contact-us");
    }
  };

  const putProgramme = async (payload, cb) => {
    try {
      await API.putProgramme({ program_id: programmeId, ...payload });
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteProgramme = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This programme will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteProgramme(programmeId);
          history.push("/home/studies/programmes");
        } catch (error) {
          onFinishCallback();
          setSnackbar({ text: errorMessage(error), isError: true });
        }
      },
      () => {
        hideModal();
      }
    );
  };

  const tableData = () => {
    const {
      academic_year_list,
      study_years,
      status,
      sponsors,
      managed_by,
      ordinand,
      institution,
      learning_centre,
      SLC_Loan
    } = studiesStudyProgrammeFormOptions;

    return {
      name: "studiesProgrammeStudents",
      columns: [
        {
          name: "Name",
          field: "student",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Forenames",
          field: "fore_names",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Surname",
          field: "surname",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Gender",
          field: "gender",
          searchInputType: "select",
          options: genderOptions.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Student ID",
          field: "id",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Academic Year",
          field: "academic_year",
          searchInputType: "select",
          options: academic_year_list.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Study Year",
          field: "study_years",
          searchInputType: "select",
          options: study_years.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Status",
          field: "status",
          searchInputType: "select",
          options: status.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Sponsor",
          field: "sponsor",
          searchInputType: "select",
          options: sponsors.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Program manager",
          field: "managed_by",
          searchInputType: "select",
          options: managed_by.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Ordinand",
          field: "ordinand",
          searchInputType: "select",
          options: ordinand.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Institution",
          field: "institution",
          searchInputType: "select",
          options: institution.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Learning Centre",
          field: "training_mode",
          searchInputType: "select",
          options: learning_centre.map(x => x.label),
          cellType: "text"
        },
        {
          name: "DOB",
          field: "DOB",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "SLC",
          field: "SLC",
          searchInputType: "select",
          options: SLC_Loan.map(x => x.label),
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getStudiesProgrammeStudentsTable(programmeId, params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("studiesProgrammeStudents", options),
      fetchColumnOptions: async () => await API.getColumnOptions("studiesProgrammeStudents"),
      fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        Name: row => history.push(`/home/studies/programmes/study/${row.id}/${row.program_id}`)
      }
    };
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {!!actions.length && (
        <ActionsDropdown
          actions={actions.map(action => action.name)}
          clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
          style={{ position: "absolute", right: 10, top: -80 }}
        />
      )}
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <>
            <SectionWrapper hasBackButton backButtonText="Programmes" clickBackButtonHandler={() => history.push("/home/studies/programmes")}>
              <StudiesProgrammeForm
                permissions={permissions}
                title={{ onEdit: "Edit the programme" }}
                options={studiesProgrammeOptions}
                initialData={programme}
                buttons={["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"]}
                deleteHandler={(onStartCallback, onFinishCallback) => deleteProgramme(onStartCallback, onFinishCallback)}
                saveHandler={(payload, cb) => putProgramme(payload, cb)}
              />
            </SectionWrapper>
            <div className={classes.tableWrapper}>
              <div className={classes.tableTitle}>Students</div>
              <Table
                style={{ marginTop: 70 }}
                datePeriodDropdownStyle={{ top: -70 }}
                name={tableData().name}
                columns={tableData().columns}
                fetchDataHandler={tableData().fetchDataHandler}
                fetchExportedDataHander={tableData().fetchExportedDataHander}
                fetchColumnOptions={tableData().fetchColumnOptions}
                fetchSaveColumnOptions={tableData().fetchSaveColumnOptions}
                clickLinkHandlers={tableData().clickLinkHandlers}
                errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
              />
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  genderOptions: inputDataListsReducer.genders,
  studiesProgrammeOptions: inputDataListsReducer.studiesProgrammeOptions,
  studiesStudyProgrammeFormOptions: inputDataListsReducer.studiesStudyProgrammeFormOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getGenderOptions: () => withPromise(dispatch, actions.getGenders),
    getStudiesProgrammeOptions: () => withPromise(dispatch, actions.getStudiesProgrammeOptions),
    getStudiesStudyProgrammeFormOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammeFormOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const StudiesProgramme = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
