import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";

export const SectionTitle = ({ title }) => <h2 className={classes.SectionTitle}>{title}</h2>;

SectionTitle.propTypes = {
  title: PropTypes.string
};

SectionTitle.defaultProps = {
  title: null
};
