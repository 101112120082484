import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select } from "@Root/components";

export class StudiesModuleSubjectForm extends Component {
  constructor(props) {
    super(props);
    const { initialIsEditable, initialData } = props;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      subject_id: initialData.subject_id,
      module_proportion: initialData.module_proportion
    };
  }
  formIsValid = () => {
    const { subject_id, module_proportion } = this.state;
    const { maxProportion } = this.props;
    if (!subject_id) {
      this.showError("subject_id", "Required");
      return false;
    }
    if (!module_proportion) {
      this.showError("module_proportion", "Required");
      return false;
    }
    if (+module_proportion > maxProportion) {
      this.showError("module_proportion", `Should be less than ${maxProportion}%`);
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  setInitialState = () => {
    const { initialData } = this.props;
    this.setState({
      isEditable: false,
      error: null,
      educational_module_id: initialData.educational_module_id,
      subject_id: initialData.subject_identifier,
      module_proportion: initialData.module_proportion
    });
  };

  handleClickCancelButton = () => {
    this.setInitialState();
  };

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { state } = this;
    const moduleId = this.props.location.pathname.split("/")[4];
    this.setState({ isSaving: true });
    await this.props.saveHandler(
      {
        educational_module_id: moduleId,
        subject_id: state.subject_id,
        module_proportion: state.module_proportion
      },
      () => {
        !this.isUnmounted && this.setState({ isEditable: false });
      }
    );
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { isEditable, isSaving, subject_id, module_proportion } = this.state;

    const { permissions, title, buttons, options } = this.props;
    const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage } = this;

    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;

    return (
      <div className={classes.StudiesModuleSubjectForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this module subject" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Subject identifier" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.subject_identifiers}
                  value={subject_id}
                  changeHandler={value => this.setState({ subject_id: value })}
                  isDisabled={!isEditable}
                  error={errorMessage("subject_id")}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module proportion (%)" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_proportion}
                  changeHandler={module_proportion => this.setState({ module_proportion })}
                  placeholder=""
                  isDisabled={!isEditable}
                  restriction="digits"
                  error={errorMessage("module_proportion")}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

StudiesModuleSubjectForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    educational_module_id: PropTypes.number,
    subject_id: PropTypes.number,
    module_proportion: PropTypes.string
  }),
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  maxProportion: PropTypes.number
};

StudiesModuleSubjectForm.defaultProps = {
  permissions: [],
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    educational_module_id: null,
    subject_id: null,
    module_proportion: null
  },
  initialIsEditable: false,
  options: null,
  buttons: ["cancel", "delete", "save"],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
  maxProportion: 100
};
