import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import { SectionWrapper } from "@Root/HOCs";
import { withPromise } from "@Root/helpers";
import classes from "./style.module.scss";
import * as actions from "@Root/store";
import { Spinner, StudiesDeliveryModuleForm } from "@Root/components";

const Component = ({ history, getStudiesModuleDeliveryOptions, studiesModuleDeliveryOptions, location }) => {
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      !studiesModuleDeliveryOptions && (await getStudiesModuleDeliveryOptions());
      Component.isMounted && setIsShown(true);
    } catch (error) {
      console.log(error);
      history.replace("/page-not-found");
    }
  };

  const createModuleDelivery = async payload => {
    const { data } = await API.createStudiesMuduleDelivery(payload);
    history.push(`/home/studies/modules/${data.data.educational_module_id}/module-delivery/${data.data.module_delivery_id}`);
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <SectionWrapper
            hasBackButton
            backButtonText="Back to Module"
            clickBackButtonHandler={() => history.push(`/home/studies/modules/${location.pathname.split("/")[4]}`)}
          >
            <StudiesDeliveryModuleForm
              title={{ onEdit: "Create Module Delivery" }}
              initialIsEditable={true}
              generateId={true}
              options={studiesModuleDeliveryOptions}
              buttons={["save"]}
              saveHandler={payload => createModuleDelivery(payload)}
              location={location}
            />
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  studiesModuleDeliveryOptions: inputDataListsReducer.studiesModuleDeliveryOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesModuleDeliveryOptions: () => withPromise(dispatch, actions.getStudiesModuleDeliveryOptions)
  };
};

export const StudiesNewModuleDelivery = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
