import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { all, regularExpressions } from "@Root/helpers";
import eyeIcon from "../../../assets/icons/eye.png";
import eyeWithLineIcon from "../../../assets/icons/eye-with-line.png";
import { Spinner } from "@Root/components";

export class TextInput extends Component {
  state = {
    error: null,
    passwordIsShown: false
  };

  showError = error => {
    clearTimeout(this.timeout);
    this.setState({ error });
    if (!this.props.errorIsInfinite) {
      this.timeout = setTimeout(() => this.setState({ error: null }), 3000);
    }
  };

  handleChange = value => {
    const { restriction, changeHandler } = this.props;
    if (restriction) {
      const valueIsAllowed = (value, regularExpression) => {
        return value.match(regularExpression);
      };
      (value === "" || valueIsAllowed(value, regularExpressions[restriction])) && changeHandler(value);
    } else {
      changeHandler(value);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      if (this.props.error) {
        this.showError(this.props.error);
      } else {
        if (this.props.errorIsInfinite) this.setState({ error: null });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { error, passwordIsShown } = this.state;
    const { value, blurHandler = () => {}, isDisabled, isPassword, placeholder, classNames = [], style, isSpinning, maxLength } = this.props;
    const filteredValue = value ? value : "";
    const { handleChange } = this;

    return (
      <div
        className={`${classes.TextInput} ${classNames.reduce((acc, className) => acc + ` ${classes[className]}`, "")}`}
        style={{ ...style, borderColor: error ? "#D0021B" : null }}
      >
        <input
          className={classes.input}
          style={isPassword ? { paddingRight: 30 } : null}
          type={isPassword && !passwordIsShown ? "password" : "text"}
          value={filteredValue}
          onChange={e => all(() => handleChange(e.target.value), () => this.setState({ error: null }))}
          onBlur={() => blurHandler()}
          disabled={isDisabled}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        {isSpinning && (
          <div className={classes.spinner}>
            <Spinner size="extra-small" color="dark" />
          </div>
        )}
        {isPassword && value && (
          <div className={classes.eyeIcon} onClick={() => this.setState({ passwordIsShown: !passwordIsShown })}>
            <img src={passwordIsShown ? eyeWithLineIcon : eyeIcon} alt="" />
          </div>
        )}
        {error && <div className={classes.error}>{error}*</div>}
      </div>
    );
  }
}

TextInput.propTypes = {
  value: PropTypes.string,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  isPassword: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  errorIsInfinite: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.oneOf(["borderless", "transparent"])),
  style: PropTypes.object,
  restriction: PropTypes.oneOf(["letters", "lettersAndWhiteSpaces", "digits", "email", "betweenZeroAndHundred"]),
  maxLength: PropTypes.number,
  isSpinning: PropTypes.bool
};

TextInput.defaultProps = {
  changeHandler: () => {},
  isPassword: false,
  errorIsInfinite: false,
  isSpinning: false,
  maxLength: Infinity
};
