import React from "react";
import classes from "./AdmissionsPage.module.scss";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { getCurrentLinkName } from "@Root/helpers";
import DocumentTitle from "react-document-title";
import { PageTitle, PageNavLinks } from "@Root/components";
import { AdmissionsTable } from "./AdmissionsTable/";
import { AdmissionsApplication } from "./AdmissionsApplication";

const AdmissionsPage = ({ location }) => {
  const links = [{ name: "Admissions Details", path: "admissions-details" }, { name: "Applications", path: "applications" }];

  const currentLinkName = getCurrentLinkName(links, location);

  return (
    <DocumentTitle title={`${currentLinkName} - Admissions`}>
      <div className={classes.wrapper}>
        <PageTitle title={currentLinkName} />
        <PageNavLinks style={{ marginBottom: 30 }} root={`/home/admissions`} links={links} />
        <Switch>
          {links.map((link, i) => (
            <Route exact path={`/home/admissions/${link.path}`} component={AdmissionsTable} key={i} />
          ))}
          <Route exact path="/home/admissions/applications/:applicationId" component={AdmissionsApplication} />
          <Redirect to="/home/admissions/admissions-details" />
        </Switch>
      </div>
    </DocumentTitle>
  );
};

export default withRouter(AdmissionsPage);
