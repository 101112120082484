import * as types from "../types";
import updateState from "../../helpers/updateState";

const initialState = {
  component: null,
  props: {}
};

const showModal = (state, { component, props }) => {
  return updateState(state, {
    component,
    props
  });
};

const hideModal = state => {
  return updateState(state, {
    component: null,
    props: {}
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODAL:
      return showModal(state, action);
    case types.HIDE_MODAL:
      return hideModal(state);
    default:
      return state;
  }
};
