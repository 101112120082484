import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import closeIcon from "../../../assets/icons/close.png";
import { MultiSelect, TextInput, Select } from "@Root/components";

export const InputModal = ({
  title,
  inputType,
  options,
  initialValue,
  valueIsRequired,
  placeholder,
  resolveButtonText,
  validationErrorMessage,
  validationHandler,
  clickRejectButtonHandler,
  clickResolveButtonHandler
}) => {
  const [value, setValue] = useState(initialValue);
  const [validationError, setValidationError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (validationHandler) {
      if (value) {
        (async () => {
          setIsFetching(true);
          try {
            await validationHandler(value);
            setValidationError(null);
          } catch (error) {
            setValidationError(validationErrorMessage);
          }
          setIsFetching(false);
        })();
      } else {
        setValidationError(null);
      }
    }
  }, [value]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.text}>{title}</div>
        <div className={classes.icon} onClick={clickRejectButtonHandler}>
          <img src={closeIcon} alt="" />
        </div>
      </div>
      <div className={classes.line} />
      <div className={classes.main}>
        {inputType === "text" && (
          <TextInput
            classNames={["transparent"]}
            style={{ width: 260 }}
            value={value}
            changeHandler={value => setValue(value)}
            placeholder={placeholder}
            error={validationError}
            errorIsInfinite
            isSpinning={isFetching}
          />
        )}
        {inputType === "select" && (
          <Select
            inputClassNames={["transparent"]}
            style={{ width: 260 }}
            options={options}
            value={value}
            changeHandler={value => setValue(value)}
            placeholder={placeholder}
          />
        )}
        {inputType === "multi-select" && (
          <MultiSelect
            inputClassNames={["transparent"]}
            inputStyle={{ width: 260 }}
            options={options}
            values={value}
            changeHandler={value => setValue(value)}
            placeholder={placeholder}
          />
        )}
        <button
          className={classes.button}
          onClick={() => clickResolveButtonHandler(value)}
          disabled={(valueIsRequired && !value) || (validationHandler && validationError) || isFetching}
        >
          {resolveButtonText}
        </button>
      </div>
    </div>
  );
};

InputModal.propTypes = {
  title: PropTypes.string,
  inputType: PropTypes.oneOf(["text", "select", "multi-select"]),
  valueIsRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  resolveButtonText: PropTypes.string,
  validationErrorMessage: PropTypes.string,
  validationHandler: PropTypes.func,
  clickRejectButtonHandler: PropTypes.func,
  clickResolveButtonHandler: PropTypes.func
};

InputModal.defaultProps = {
  title: "",
  inputType: null,
  options: [],
  initialValue: null,
  valueIsRequired: false,
  placeholder: "",
  resolveButtonText: "",
  validationErrorMessage: "Validation error",
  clickRejectButtonHandler: () => {},
  clickResolveButtonHandler: () => {}
};
