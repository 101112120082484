import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { emailIsValid, switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, MultiSelect, DataListAsync, TextInput, InputLabel } from "@Root/components";

export const DioceseForm = ({ permissions, initialData, titleOptions, fetchContactHandler, deleteHandler, saveHandler, errorHandler, options }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);
  const [error, setError] = useState(null);

  const bishopTitle = () => {
    const titleOption = titleOptions.find(titleOption => titleOption.value === data.bishop_title_id);
    return titleOption ? titleOption.label : null;
  };

  const bishopTitleId = label => {
    const titleOption = titleOptions.find(titleOption => titleOption.label === label);
    return titleOption ? titleOption.value : null;
  };

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    if (data.email && !emailIsValid(data.email)) {
      showError("email", "Invalid email");
      return false;
    } else {
      return true;
    }
  };

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        DioceseForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSavind(true);
    try {
      const modifiedData = switchEmptyStringsToNullsInObject(data);
      await saveHandler(modifiedData);
      DioceseForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    DioceseForm.isMounted && setIsSavind(false);
  };

  const fetchContactLabel = async value => {
    const { data } = await fetchContactHandler({ search: `id:${value}`, limit: "1" });
    return data.data[0].name;
  };

  const fetchContactOptions = async value => {
    const { data } = await fetchContactHandler({ search: `name:${value};trashed:No`, searchJoin: "and", limit: "100" });
    return data.data.map(option => ({ value: option.id, label: option.name }));
  };

  useEffect(() => {
    DioceseForm.isMounted = true;
    return () => {
      DioceseForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (data.bishop_id) {
      if (!data.bishop_title_id) {
        (async () => {
          try {
            const { data: res } = await fetchContactHandler({ search: `id:${data.bishop_id}`, limit: "1" });
            DioceseForm.isMounted && setData({ ...data, bishop_title_id: bishopTitleId(res.data[0].title) });
          } catch (error) {
            errorHandler(error);
          }
        })();
      }
    } else {
      setData({ ...data, bishop_title_id: null });
    }
  }, [data.bishop_id]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  return (
    <FormWrapper
      buttons={isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this diocese" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Diocese Details" : "Diocese Details"} />
        <div>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Diocese Name" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.diocese_name}
            changeHandler={value => handleChangeInput("diocese_name", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Diocese Index" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.diocese_index}
            changeHandler={value => handleChangeInput("diocese_index", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Address Data" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.address}
            changeHandler={value => handleChangeInput("address", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Telephone Number" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.phone}
            changeHandler={value => handleChangeInput("phone", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Email" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.email}
            changeHandler={value => handleChangeInput("email", value)}
            isDisabled={!isEditable}
            error={errorMessage("email")}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="DDO Details" />
          <div style={{ position: "absolute", left: 640 }}>
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              value={data.ddo_id}
              fetchLabelHandler={value => fetchContactLabel(value)}
              fetchOptionsHandler={value => fetchContactOptions(value)}
              changeHandler={value => handleChangeInput("ddo_id", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Bishop Identifier" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.bishop_identifier}
            changeHandler={value => handleChangeInput("bishop_identifier", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Bishop Name" />
          <div style={{ position: "absolute", left: 150 }}>
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              value={data.bishop_id}
              fetchLabelHandler={value => fetchContactLabel(value)}
              fetchOptionsHandler={value => fetchContactOptions(value)}
              changeHandler={value => handleChangeInput("bishop_id", value)}
              isDisabled={!isEditable}
            />
          </div>
          <InputLabel style={{ position: "absolute", left: 490 }} text="Bishop Title" />
          <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 640, width: 260 }} value={bishopTitle()} isDisabled />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Federation House" />
          <div style={{ position: "absolute", left: 150, width: 260 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              options={options.institutionOptions}
              values={data.institution_ids}
              changeHandler={value => handleChangeInput("institution_ids", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

DioceseForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    diocese_name: PropTypes.string,
    diocese_index: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    ddo_id: PropTypes.number,
    bishop_identifier: PropTypes.string,
    bishop_id: PropTypes.number,
    bishop_title_id: PropTypes.number,
    comment: PropTypes.string
  }),
  titleOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  fetchContactHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

DioceseForm.defaultProps = {
  permissions: [],
  initialData: {
    diocese_name: null,
    diocese_index: null,
    address: null,
    phone: null,
    email: null,
    ddo_id: null,
    bishop_identifier: null,
    bishop_id: null,
    bishop_title_id: null,
    comment: null
  },
  titleOptions: [],
  fetchContactHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
