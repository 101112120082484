import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import { SectionWrapper } from "@Root/HOCs";
import { withPromise, errorMessage } from "@Root/helpers";
import classes from "./style.module.scss";
import * as actions from "@Root/store";
import { Spinner, StudiesModuleSubjectForm } from "@Root/components";

const Component = ({ history, location, getStudiesModuleSubjectOptions, studiesModuleSubjectOptions, setSnackbar }) => {
  const moduleId = location.pathname.split("/")[4];

  const [isShown, setIsShown] = useState(false);
  const [maxProportion, setMaxProportion] = useState(null);

  const fetchData = async () => {
    try {
      const data = await Promise.all([API.getStudiesModule(moduleId), !studiesModuleSubjectOptions && getStudiesModuleSubjectOptions()]);
      const moduleProportionSum = data[0].data.data.module_subject.reduce((acc, module_subject) => acc + +module_subject.module_proportion, 0);
      setMaxProportion(100 - moduleProportionSum);
      Component.isMounted && setIsShown(true);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const postModuleSubject = async (payload, cb) => {
    try {
      const { data } = await API.createStudiesMuduleSubject(payload);
      cb();
      history.push(`/home/studies/modules/${data.data.module_id}/module-subject/${data.data.id}`);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <SectionWrapper
            hasBackButton
            backButtonText="Back to Module"
            clickBackButtonHandler={() => history.push(`/home/studies/modules/${location.pathname.split("/")[4]}`)}
          >
            <StudiesModuleSubjectForm
              title={{ onEdit: "Create Module Subject" }}
              initialIsEditable={true}
              generateId={true}
              options={studiesModuleSubjectOptions}
              buttons={["save"]}
              saveHandler={(payload, callback) => postModuleSubject(payload, callback)}
              location={location}
              maxProportion={maxProportion}
            />
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  studiesModuleSubjectOptions: inputDataListsReducer.studiesModuleSubjectOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesModuleSubjectOptions: () => withPromise(dispatch, actions.getStudiesModuleSubjectOptions),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const StudiesNewModuleSubject = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
