// export const switchNullsToEmptyArraysInObjectRecursively = object => {
//   const objectCopy = JSON.parse(JSON.stringify(object));
//   const converter = objectCopy => {
//     for (let key in objectCopy) {
//       if (objectCopy[key] === null) {
//         objectCopy[key] = [];
//       } else if (Array.isArray(objectCopy[key])) {
//         for (let embeddedObject of objectCopy[key]) {
//           converter(embeddedObject);
//         }
//       }
//     }
//   };
//   converter(objectCopy);
//   return objectCopy;
// };

export const switchEmptyArraysToNullsInObjectRecursively = object => {
  const objectCopy = JSON.parse(JSON.stringify(object));
  const converter = objectCopy => {
    for (let key in objectCopy) {
      if (Array.isArray(objectCopy[key])) {
        if (!objectCopy[key].length) {
          objectCopy[key] = null;
        } else {
          for (let embeddedObject of objectCopy[key]) {
            converter(embeddedObject);
          }
        }
      }
    }
  };
  converter(objectCopy);
  return objectCopy;
};

export const switchEmptyStringsToNullsInObject = object => {
  const objectCopy = JSON.parse(JSON.stringify(object));
  for (let key in objectCopy) {
    if (objectCopy[key] === "") objectCopy[key] = null;
  }
  return objectCopy;
};
