import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import uniqId from "uniqid";
import { toggleArrayPrimitiveValue, toggleArrayPrimitiveValues, toggleObjectKeys, datePeriods, objectsAreEqual } from "@Root/helpers";
import { TablePagination } from "./TablePagination";
import { TableRulePopup } from "./TableRulePopup";
import { TableRuleTile } from "./TableRuleTile";
import { CustomScrollbar, Modal } from "@Root/HOCs";
import { matchTypes, equalities } from "@Root/configs";
import { RoundPlusButton, ExportDropdown, DatePeriodDropdown, ColumnFilterDropdown } from "@Root/components";
import { TableData } from "./TableData";
import jsPDF from "jspdf";
import "jspdf-autotable";

export class Table extends Component {
  constructor(props) {
    super(props);
    const { minVisibleRowsQuantity } = props;

    this.state = {
      datePeriod: "All",
      dataIsFetching: false,
      data: [],
      page: 1,
      paginationData: {},
      hiddenColumnsNames: [],
      headersFilters: {},
      checkedRowsIds: [],
      rules: this.getGlobalRules(),
      openedRuleId: null,
      activeRuleId: "All",
      exportedDataUrl: null,
      maxVisibleRowsQuantity: minVisibleRowsQuantity,
      delayedMaxVisibleRowsQuantity: minVisibleRowsQuantity,
      sorting: null
    };

    this.exportedDataLink = React.createRef();
  }

  getGlobalRules = () => {
    const allRule = {
      id: "All",
      name: "All",
      description: "All",
      matchType: "All of the rules",
      rows: [
        {
          field: "trashed",
          equality: "is",
          filter: "No"
        }
      ]
    };
    const { mandatoryRules = [] } = this.props;
    return [...[allRule, ...mandatoryRules].map(rule => ({ ...rule, isMandatory: true }))];
  };

  handleSaveRule = rule => {
    const { rules, openedRuleId } = this.state;
    if (openedRuleId && openedRuleId !== "new") {
      const rulesCopy = [...rules];
      const index = rulesCopy.findIndex(rule => rule.id === openedRuleId);
      rulesCopy.splice(index, 1, { ...rule, id: openedRuleId });
      this.setState({ rules: rulesCopy, openedRuleId: null });
    } else {
      this.setState({
        rules: [
          ...rules,
          {
            ...rule,
            id: uniqId(),
            rows: [
              ...rule.rows,
              {
                field: "trashed",
                equality: "is",
                filter: "No",
                isDisabled: true
              }
            ]
          }
        ],
        openedRuleId: null
      });
    }
  };

  ruleParams = (params, id) => {
    const { rules, activeRuleId } = this.state;
    const activeRules = params || rules;
    const activeRule = activeRuleId || id;
    const { matchType, rows } = activeRules.find(rule => rule.id === activeRule);
    const rule = rows.reduce((acc, row, i) => acc + `${i === 0 ? "" : ";"}${row.field}:${row.filter}`, "");
    const rulesForFields = rows.reduce((acc, row, i) => acc + `${i === 0 ? "" : ";"}${row.field}:${equalities[row.equality]}`, "");
    return { rule, rulesForFields, ruleJoin: matchTypes[matchType] };
  };

  searchParams = () => {
    const { headersFilters } = this.state;
    if (Object.keys(headersFilters).length) {
      const search = Object.keys(headersFilters).reduce((acc, field, i) => acc + `${i === 0 ? "" : ";"}${field}:${headersFilters[field]}`, "");
      return { search, searchJoin: "and" };
    } else {
      return {};
    }
  };

  setInitialOptions = data => {
    const {
      datePeriod = "All",
      hiddenColumnsNames = [],
      rules = [],
      activeRuleId = "All",
      maxVisibleRowsQuantity = this.minVisibleRowsQuantity
    } = data.properties;
    const allRules = [...this.getGlobalRules(), ...rules];
    const activeRule = allRules.find(rule => rule.id === activeRuleId);
    const activeRuleIdFound = activeRule ? activeRule.id : "All";

    this.setState({
      datePeriod,
      hiddenColumnsNames,
      activeRuleId: activeRuleIdFound,
      maxVisibleRowsQuantity,
      rules: allRules
    });
    this.fetchData(allRules, activeRuleId, maxVisibleRowsQuantity);
  };

  sortParams = () => {
    const { sorting } = this.state;
    return sorting ? { sortBy: `${sorting.field}:${sorting.direction}` } : { sortBy: "id:desc" };
  };

  fetchOptions = async () => {
    !this.isUnmounted && this.setState({ dataIsFetching: true });
    const { fetchColumnOptions } = this.props;
    const { data } = await fetchColumnOptions();
    this.setInitialOptions(data.data);
  };

  fetchData = async (rules, activeRuleId, maxVisibleRows) => {
    const { maxVisibleRowsQuantity, datePeriod, page } = this.state;
    const { fetchDataHandler, convertDataHandler, finishFetchDataHandler, errorHandler } = this.props;
    const params = {
      limit: maxVisibleRows || maxVisibleRowsQuantity,
      start_date: datePeriods[datePeriod][0],
      end_date: datePeriods[datePeriod][1],
      page,
      ...this.ruleParams(rules, activeRuleId),
      ...this.searchParams(),
      ...this.sortParams()
    };
    !this.isUnmounted && this.setState({ dataIsFetching: true });
    try {
      const { data } = await fetchDataHandler(params);
      const convertedData = convertDataHandler(data.data);
      const { from, to, per_page: perPage, last_page: lastPage, total } = data.meta;
      const paginationData = {
        from,
        to,
        perPage,
        lastPage,
        total
      };
      !this.isUnmounted && this.setState({ data: convertedData, paginationData });
      finishFetchDataHandler();
    } catch (error) {
      errorHandler(error);
    }
    !this.isUnmounted && this.setState({ dataIsFetching: false });
  };

  fetchExportedData = async format => {
    const { datePeriod, hiddenColumnsNames, checkedRowsIds } = this.state;
    const { columns, fetchExportedDataHander, errorHandler } = this.props;
    const fields = columns
      .filter(column => !hiddenColumnsNames.includes(column.name))
      .map(column => column.field)
      .reduce((acc, field, i) => acc + `${i === 0 ? "" : ","}${field}`, "");
    const params = {
      start_date: datePeriods[datePeriod][0],
      end_date: datePeriods[datePeriod][1],
      format,
      fields,
      ...{ search: "relationship:Student", searchJoin: "and" },
      ...(checkedRowsIds.length
        ? {
            ids: checkedRowsIds.reduce((acc, id, i) => acc + `${i === 0 ? "" : ","}${id}`, "")
          }
        : {})
    };
    try {
      const { data } = await fetchExportedDataHander(params);
      this.setState({ exportedDataUrl: data }, () => {
        this.exportedDataLink.current.click();
        this.setState({ exportedDataUrl: null });
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  updateTableOptions = (field, value) => {
    const { datePeriod, hiddenColumnsNames, rules, activeRuleId, maxVisibleRowsQuantity } = this.state;
    const { fetchSaveColumnOptions } = this.props;
    const payload = {
      datePeriod,
      hiddenColumnsNames,
      rules: rules.filter(rule => !rule.isMandatory),
      activeRuleId,
      maxVisibleRowsQuantity
    };
    if (field && value) payload[field] = value;

    fetchSaveColumnOptions(payload);
  };

  handleClickClearButton = () => {
    this.setState({ datePeriod: "All", headersFilters: {}, activeRuleId: "All" });
  };

  handleClickHeaderCell = field => {
    const { sorting } = this.state;
    if (!sorting) {
      this.setState({ sorting: { field, direction: "asc" } });
    } else {
      if (sorting.field === field) {
        if (sorting.direction === "asc") {
          this.setState({ sorting: { field, direction: "desc" } });
        } else {
          this.setState({ sorting: null });
        }
      } else {
        this.setState({ sorting: { field, direction: "asc" } });
      }
    }
  };

  handleClickColumnCheckbox = (rowId, field, value) => {
    const { data } = this.state;
    const dataCopy = [...data];
    dataCopy.find(row => row.id === rowId)[field] = value;
    this.setState({ data: dataCopy });
  };

  createPdf = () => {
    const { data, hiddenColumnsNames } = this.state;
    const { columns } = this.props;
    const doc = new jsPDF({ orientation: "landscape" });
    const visibleColumns = columns.filter(column => !hiddenColumnsNames.includes(column.name));
    const headers = visibleColumns.map(column => column.name);
    const modifiedData = data.map((row, i) => {
      return visibleColumns.reduce((acc, column) => {
        acc.push(row[column.field]);
        return acc;
      }, []);
    });
    doc.autoTable({
      head: [headers],
      body: modifiedData,
      styles: { fontSize: 7 }
    });
    doc.save("table.pdf");
  };

  componentDidMount() {
    this.fetchOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !objectsAreEqual(this.state.headersFilters, prevState.headersFilters) ||
      this.state.datePeriod !== prevState.datePeriod ||
      this.state.activeRuleId !== prevState.activeRuleId ||
      this.state.maxVisibleRowsQuantity !== prevState.maxVisibleRowsQuantity
    ) {
      this.setState({ page: 1, checkedRowsIds: [] });
    }
    const activeRule = rules => rules.find(rule => rule.id === this.state.activeRuleId);
    if (
      this.state.maxVisibleRowsQuantity !== prevState.maxVisibleRowsQuantity ||
      this.state.page !== prevState.page ||
      !objectsAreEqual(this.state.headersFilters, prevState.headersFilters) ||
      this.state.datePeriod !== prevState.datePeriod ||
      this.state.activeRuleId !== prevState.activeRuleId ||
      (this.state.activeRuleId && !objectsAreEqual(activeRule(this.state.rules), activeRule(prevState.rules))) ||
      this.state.sorting !== prevState.sorting
    ) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        await this.fetchData();
        if (this.state.maxVisibleRowsQuantity !== prevState.maxVisibleRowsQuantity) {
          this.setState({ delayedMaxVisibleRowsQuantity: this.state.maxVisibleRowsQuantity });
        }
      }, 0);
    }
    if (this.state.datePeriod !== prevState.datePeriod || !objectsAreEqual(this.state.rules, prevState.rules)) {
      this.updateTableOptions();
    }
    if (this.props.shouldBeUpdated !== prevProps.shouldBeUpdated) {
      this.props.shouldBeUpdated && this.fetchData();
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    clearTimeout(this.timeout);
  }

  changeMaxVisibleRowQuantity = maxVisibleRowsQuantity => {
    this.setState({ maxVisibleRowsQuantity });
    this.updateTableOptions("maxVisibleRowsQuantity", maxVisibleRowsQuantity);
  };

  changeActiveRule = activeRuleId => {
    this.setState({ activeRuleId });
    this.updateTableOptions("activeRuleId", activeRuleId);
  };

  handleSaveColumnOptions = () => {
    const { successHandler } = this.props;
    try {
      this.updateTableOptions();
      successHandler("The options were saved successful!");
    } catch (error) {}
  };
  render() {
    const {
      datePeriod,
      dataIsFetching,
      data,
      page,
      paginationData,
      hiddenColumnsNames,
      headersFilters,
      checkedRowsIds,
      rules,
      openedRuleId,
      activeRuleId,
      exportedDataUrl,
      maxVisibleRowsQuantity,
      delayedMaxVisibleRowsQuantity,
      sorting
    } = this.state;
    const {
      style,
      datePeriodDropdownStyle,
      cleanFiltersStyle,
      columns,
      fetchDataHandler,
      clickLinkHandlers,
      checkboxes,
      minVisibleRowsQuantity,
      hasImport,
      clickImportButtonHandler,
      errorHandler,
      shouldBeUpdated,
      hasRules,
      hasExport,
      hasFilters,
      hasSorting,
      datePeriodTop = -140
    } = this.props;

    const { fetchExportedData, handleSaveRule, handleClickClearButton, handleClickHeaderCell, handleClickColumnCheckbox } = this;

    const columnsNames = columns.map(column => column.name);
    const { name, description, matchType, rows } =
      openedRuleId && openedRuleId !== "new"
        ? rules.find(rule => rule.id === openedRuleId)
        : { name: "", description: "", matchType: "All of the rules", rows: [] };

    console.log(rows);
    return (
      <div className={classes.Table} style={style}>
        <DatePeriodDropdown
          style={{ position: "absolute", right: 0, top: datePeriodTop, zIndex: 2, ...datePeriodDropdownStyle }}
          value={datePeriod}
          changeHandler={datePeriod => this.setState({ datePeriod })}
        />
        <div className={classes.cleanFilters} style={{ top: -20, ...cleanFiltersStyle }} onClick={() => handleClickClearButton()}>
          Clear
        </div>
        <div className={classes.tableBar}>
          <div className={classes.tableBarLeft}>
            {hasRules && <RoundPlusButton text="Add rule" clickHandler={() => this.setState({ openedRuleId: "new" })} />}
            {openedRuleId !== null && (
              <Modal>
                <TableRulePopup
                  isNew={openedRuleId === "new"}
                  columns={columns.map(column => ({ value: column.field, label: column.name }))}
                  name={name}
                  description={description}
                  matchType={matchType}
                  rows={rows.filter(row => !row.isDisabled)}
                  clickCloseHandler={() => this.setState({ openedRuleId: null })}
                  clickSaveHandler={rule => handleSaveRule(rule)}
                  clickDeleteHandler={() =>
                    this.setState({
                      rules: [...rules].filter(rule => rule.id !== openedRuleId),
                      activeRuleId: openedRuleId === activeRuleId ? "All" : activeRuleId,
                      openedRuleId: null
                    })
                  }
                />
              </Modal>
            )}
            <div className={classes.tilesWrapper} style={!hasRules ? { marginLeft: -10 } : null}>
              <CustomScrollbar horizontalOnly>
                <div className={classes.tiles}>
                  {rules.map(rule => (
                    <TableRuleTile
                      rule={rule}
                      columns={columns}
                      dateRange={[datePeriods[datePeriod][0], datePeriods[datePeriod][1]]}
                      fetchDataHandler={fetchDataHandler}
                      clickBarHandler={() => this.changeActiveRule(rule.id)}
                      clickEditHandler={() => this.setState({ openedRuleId: rule.id })}
                      isActive={activeRuleId === rule.id}
                      errorHandler={error => errorHandler(error)}
                      key={rule.id}
                      shouldBeUpdated={shouldBeUpdated}
                    />
                  ))}
                </div>
              </CustomScrollbar>
            </div>
          </div>
          <div className={classes.tableBarRight}>
            {hasImport && (
              <div className={classes.importButton} onClick={clickImportButtonHandler}>
                CSV Import
              </div>
            )}
            {hasExport && (
              <>
                <ExportDropdown clickHandler={format => fetchExportedData(format)} />
                <a style={{ display: "none" }} href={exportedDataUrl} ref={this.exportedDataLink} download>
                  Download
                </a>
              </>
            )}
            <ColumnFilterDropdown
              columnsNames={columnsNames}
              hiddenColumnsNames={hiddenColumnsNames}
              clickHandler={columnName => this.setState({ hiddenColumnsNames: toggleArrayPrimitiveValue(hiddenColumnsNames, columnName) })}
              maxVisibleColumnsQuantity={12}
              onSaveColumnSettings={this.handleSaveColumnOptions}
            />
          </div>
        </div>
        <div className={classes.paginationContainer}>
          {!!Object.keys(paginationData).length && (
            <TablePagination
              minVisibleRowsQuantity={minVisibleRowsQuantity}
              maxVisibleRowsQuantity={maxVisibleRowsQuantity}
              changeMaxVisibleRowsQuantityHandler={maxVisibleRowsQuantity => this.changeMaxVisibleRowQuantity(maxVisibleRowsQuantity)}
              page={page}
              changePageHandler={page => this.setState({ page })}
              paginationData={paginationData}
            />
          )}
        </div>
        <TableData
          columns={columns}
          hiddenColumnsNames={hiddenColumnsNames}
          dataIsFetching={dataIsFetching}
          data={data}
          checkedRowsIds={checkedRowsIds}
          clickCheckboxHandler={id => this.setState({ checkedRowsIds: toggleArrayPrimitiveValue(checkedRowsIds, id) })}
          clickCheckboxAllHandler={ids => this.setState({ checkedRowsIds: toggleArrayPrimitiveValues(checkedRowsIds, ids) })}
          headersFilters={headersFilters}
          changeHeaderFilterHandler={({ key, value }) => {
            this.setState({ headersFilters: toggleObjectKeys(headersFilters, { key, value }) });
          }}
          visibleRowsQuantity={delayedMaxVisibleRowsQuantity > minVisibleRowsQuantity ? delayedMaxVisibleRowsQuantity : minVisibleRowsQuantity}
          clickLinkHandlers={clickLinkHandlers}
          checkboxes={checkboxes}
          sorting={sorting}
          clickHeaderCellHandler={field => handleClickHeaderCell(field)}
          clickColumnCheckboxHandler={(rowId, field, value) => handleClickColumnCheckbox(rowId, field, value)}
          hasFilters={hasFilters}
          hasSorting={hasSorting}
        />
        <div className={classes.paginationContainer}>
          {!!Object.keys(paginationData).length && (
            <TablePagination
              minVisibleRowsQuantity={minVisibleRowsQuantity}
              maxVisibleRowsQuantity={maxVisibleRowsQuantity}
              changeMaxVisibleRowsQuantityHandler={maxVisibleRowsQuantity => this.setState({ maxVisibleRowsQuantity })}
              page={page}
              changePageHandler={page => this.setState({ page })}
              paginationData={paginationData}
            />
          )}
        </div>
      </div>
    );
  }
}

Table.propTypes = {
  style: PropTypes.object,
  datePeriodDropdownStyle: PropTypes.object,
  cleanFiltersStyle: PropTypes.object,
  name: PropTypes.string.isRequired,
  mandatoryRules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      matchType: PropTypes.string,
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          columnName: PropTypes.string,
          equality: PropTypes.string,
          filter: PropTypes.string
        })
      )
    })
  ),
  fetchDataHandler: PropTypes.func,
  finishFetchDataHandler: PropTypes.func,
  convertDataHandler: PropTypes.func,
  fetchExportedDataHander: PropTypes.func,
  columns: PropTypes.array,
  minVisibleRowsQuantity: PropTypes.number,
  // cellsAreInputs: PropTypes.bool,
  clickLinkHandlers: PropTypes.object,
  checkboxes: PropTypes.object,
  hasImport: PropTypes.bool,
  clickImportButtonHandler: PropTypes.func,
  errorHandler: PropTypes.func,
  shouldBeUpdated: PropTypes.bool,
  hasRules: PropTypes.bool,
  hasExport: PropTypes.bool,
  hasFilters: PropTypes.bool,
  hasSorting: PropTypes.bool
};

Table.defaultProps = {
  style: {},
  datePeriodDropdownStyle: {},
  cleanFiltersStyle: {},
  minVisibleRowsQuantity: 10,
  checkboxes: {},
  fetchDataHandler: () => {},
  finishFetchDataHandler: () => {},
  convertDataHandler: initialData => initialData,
  hasImport: false,
  clickImportButtonHandler: () => {},
  errorHandler: () => {},
  successHandler: () => {},
  shouldBeUpdated: false,
  hasRules: false,
  hasExport: false,
  hasFilters: false,
  hasSorting: false
};
