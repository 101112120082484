import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withPromise, errorMessage } from "@Root/helpers";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { Spinner, UserForm } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";

const User = ({ location, history, permissions, getAdminUserOptions, adminUserOptions, showModal, hideModal, currentUser, setCurrentUser, setSnackbar }) => {
  const userId = location.pathname.split("/").pop();

  const [user, setUser] = useState(undefined);
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      const data = await Promise.all([API.getAdminUserById(userId), getAdminUserOptions()]);
      if (User.isMounted) {
        setUser(data[0].data.data);
        setIsShown(true);
      }
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const putAdminUser = async (payload, cb) => {
    try {
      const { data } = await API.putAdminUser(payload);
      setUser(data.data);
      data.data.id === currentUser.id && setCurrentUser({ ...currentUser, ...data.data });
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteAdminUser = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    if (user.can_delete) {
      new Promise((resolve, reject) => {
        showModal("ConfirmationModal", {
          text: "This user will be removed?",
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve
        });
      }).then(
        async () => {
          hideModal();
          onStartCallback();
          try {
            await API.deleteAdminUser(userId);
            history.push("/home/admin/manage-system-team/system-user");
          } catch (error) {
            onFinishCallback();
            setSnackbar({ text: errorMessage(error), isError: true });
          }
        },
        () => {
          hideModal();
        }
      );
    } else {
      new Promise((resolve, reject) => {
        showModal("NotificationModal", {
          text: "This user can`t be removed! He is in use of programme.",
          clickRejectButtonHandler: reject,
          clickResolveButtonHandler: resolve
        });
      }).then(
        () => hideModal(),
        () => {
          hideModal();
        }
      );
    }
  };

  useEffect(() => {
    User.isMounted = true;
    fetchData();
    return () => {
      User.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper hasBackButton backButtonText="System Users" clickBackButtonHandler={() => history.push("/home/admin/manage-system-team/system-users")}>
          <UserForm
            title={{ onEdit: "Edit the user" }}
            permissions={permissions}
            options={adminUserOptions}
            initialData={user}
            buttons={["cancel", "delete", "save"]}
            deleteHandler={(onStartCallback, onFinishCallback) => deleteAdminUser(onStartCallback, onFinishCallback)}
            saveHandler={(payload, cb) => putAdminUser(payload, cb)}
          />
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  currentUser: authReducer.user,
  adminUserOptions: inputDataListsReducer.adminUserOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getAdminUserOptions: () => withPromise(dispatch, actions.getAdminUserOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setCurrentUser: data => dispatch(actions.setUser(data)),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const AdminUser = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(User)
);
