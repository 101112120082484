import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { Spinner } from "@Root/components";
import { ArrowIcon } from "@Root/assets";

export const SubformWrapper = ({
  title,
  isOpened,
  isOpenedHandler,
  isEditable,
  isSpinning,
  isRoot,
  addIsAllowed,
  addHandler,
  cancelHandler,
  saveHandler,
  children
}) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <div className={classes.title}>{title}</div>
        <div className={classes.buttons}>
          {isEditable && addIsAllowed && (
            <div className={classes.button} style={{ width: 80 }} onClick={addHandler}>
              Add new
            </div>
          )}
          {isRoot && (
            <div className={classes.button} style={{ width: 40 }} onClick={isOpenedHandler}>
              <img style={isOpened ? { transform: "rotate(180deg)" } : null} src={ArrowIcon} alt="" />
            </div>
          )}
        </div>
      </div>
      <div style={isOpened ? null : { display: "none" }}>
        <div className={classes.contentWrapper}>
          {React.Children.map(children, (child, i) => (
            <>
              {i !== 0 ? <div className={classes.line} /> : null}
              {child}
            </>
          ))}
          {!children.length && (
            <div className={classes.errorWrapper}>
              <div className={classes.error}>No data</div>
            </div>
          )}
          {isSpinning && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
        </div>
        {isEditable && isRoot && (
          <div className={classes.footer}>
            <button className={classes.buttonCancel} onClick={cancelHandler} disabled={isSpinning}>
              Cancel
            </button>
            <button className={classes.buttonSave} onClick={saveHandler} disabled={isSpinning}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

SubformWrapper.propTypes = {
  title: PropTypes.string,
  isOpened: PropTypes.bool,
  isOpenedHandler: PropTypes.func,
  isEditable: PropTypes.bool,
  isSpinning: PropTypes.bool,
  addIsAllowed: PropTypes.bool,
  addHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  children: PropTypes.node,
  isRoot: PropTypes.bool
};

SubformWrapper.defaultProps = {
  title: null,
  isOpened: true,
  isOpenedHandler: () => {},
  isEditable: false,
  isSpinning: false,
  addIsAllowed: false,
  addHandler: () => {},
  cancelHandler: () => {},
  saveHandler: () => {},
  children: null,
  isRoot: false
};
