import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withPromise, errorMessage } from "@Root/helpers";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { Spinner, StudiesStudyProgrammeForm } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";

const Component = ({
  history,
  location,
  getStudiesStudyProgrammeFormOptions,
  studiesStudyProgrammeFormOptions,
  getProgrammesListOptions,
  programmesListOptions,
  getAdminInstitutionOptions,
  adminInstitutionOptions,
  setSnackbar
}) => {
  const contactId = +location.pathname.split("/")[6];
  const programmeId = location.pathname.split("/")[7] !== "null" ? +location.pathname.split("/")[7] : null;
  const [isShown, setIsShown] = useState(false);
  const [student, setStudent] = useState({});

  const fetchData = async () => {
    try {
      const data = await Promise.all([
        getProgrammesListOptions(),
        getStudiesStudyProgrammeFormOptions(),
        getAdminInstitutionOptions(),
        API.getContact(contactId)
      ]);
      if (Component.isMounted) {
        setStudent({
          id: data[3].data.data.student.student_detail.student_id,
          name: data[3].data.data.contact.name,
          surname: data[3].data.data.contact.surname
        });
        setIsShown(true);
      }
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.replace("/contact-us");
    }
  };

  const saveStudyProgramme = async payload => {
    try {
      const { data } = await API.postStudiesStudyProgramme({ contact_id: contactId, ...payload });
      history.push(`/home/studies/programmes/study/${contactId}/${data.data.id}`);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <SectionWrapper hasBackButton backButtonText="Contact" clickBackButtonHandler={() => history.push(`/home/contacts/all-contacts/${contactId}`)}>
            <div className={classes.studentInfo}>
              <div>
                ID: <span>{student.id}</span>
              </div>
              <div>
                Name:{" "}
                <span>
                  {student.name} {student.surname}
                </span>
              </div>
            </div>
            <StudiesStudyProgrammeForm
              title={{ onEdit: "Add a Study Record" }}
              initialIsEditable={true}
              initialProgrammeId={programmeId}
              options={{ ...studiesStudyProgrammeFormOptions, programmesListOptions, institutions: adminInstitutionOptions }}
              buttons={["save"]}
              saveHandler={payload => saveStudyProgramme(payload)}
            />
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  studiesStudyProgrammeFormOptions: inputDataListsReducer.studiesStudyProgrammeFormOptions,
  programmesListOptions: inputDataListsReducer.programmesListOptions,
  adminInstitutionOptions: inputDataListsReducer.adminInstitutionOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesStudyProgrammeFormOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammeFormOptions),
    getProgrammesListOptions: () => withPromise(dispatch, actions.getProgrammesListOptions),
    getAdminInstitutionOptions: () => withPromise(dispatch, actions.getAdminInstitutionOptions),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const StudiesNewStudyProgramme = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
