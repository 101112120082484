import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import { SectionWrapper } from "@Root/HOCs";
import { withPromise } from "@Root/helpers";
import classes from "./style.module.scss";
import * as actions from "@Root/store";
import { Spinner, StudiesModuleCostCenterForm } from "@Root/components";

const Component = ({ history, getStudiesModuleCostCenterOptions, studiesModuleCostCenterOptions, location }) => {
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      !studiesModuleCostCenterOptions && (await getStudiesModuleCostCenterOptions());
      Component.isMounted && setIsShown(true);
    } catch (error) {
      console.log(error);
      history.replace("/page-not-found");
    }
  };

  const createModuleCostCenter = async payload => {
    const { data } = await API.createStudiesMuduleCostCenter(payload);
    history.push(
      `/home/studies/modules/${location.pathname.split("/")[4]}/module-delivery/${data.data.module_delivery_id}/module-cost-center/${data.data.cost_centre_id}`
    );
    console.log(data.data);
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <SectionWrapper
            hasBackButton
            backButtonText="Back to Module Delivery"
            clickBackButtonHandler={() =>
              history.push(`/home/studies/modules/${location.pathname.split("/")[4]}/module-delivery/${location.pathname.split("/")[6]}`)
            }
          >
            <StudiesModuleCostCenterForm
              title={{ onEdit: "Create Module Cost Center" }}
              initialIsEditable={true}
              generateId={true}
              options={studiesModuleCostCenterOptions}
              buttons={["save"]}
              saveHandler={payload => createModuleCostCenter(payload)}
              location={location}
            />
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  studiesModuleCostCenterOptions: inputDataListsReducer.studiesModuleCostCenterOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesModuleCostCenterOptions: () => withPromise(dispatch, actions.getStudiesModuleCostCenterOptions)
  };
};

export const StudiesNewModuleCostCenter = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
