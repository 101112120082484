import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { RoundPlusButton, SectionTitle, InputLabel, TextInput } from "@Root/components";
export const AdminFormTemplate = ({ permissions, title, initialData, saveHandler, editHandler, deleteHandler, keyWord, deleteByField, getValueByField }) => {
  const [data, setData] = useState([...initialData]);
  const [newType, setNewType] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [error, setError] = useState(null);

  const errorMessage = (id, value) => {
    if (!value) {
      return "Field is empty";
    }
    if (initialData.find(item => item.id !== id && item[getValueByField] === value)) {
      return `This ${keyWord.toLowerCase()} already exists`;
    }
  };

  const errorByTypeId = typeId => (error && error.typeId === typeId ? error.message : null);

  const handleChangeInput = (id, value) => {
    const dataCopy = [...data];
    dataCopy.find(type => type.id === id)[getValueByField] = value;
    setData(dataCopy);
  };

  const handleClickAddButton = async () => {
    const message = errorMessage(null, newType);
    if (message) {
      setError({
        id: null,
        message
      });
      return;
    }
    setIsSpinning(true);
    await saveHandler({ [getValueByField]: newType }, () => {
      if (AdminFormTemplate.isMounted) {
        setNewType(null);
      }
    });
    AdminFormTemplate.isMounted && setIsSpinning(false);
  };

  const handleClickEditButton = async typeId => {
    const dataCopy = [...data];
    dataCopy.find(type => type.id === typeId).isEdited = true;
    setData(dataCopy);
  };

  const handleClickSaveButton = async (id, value) => {
    const message = errorMessage(id, value);
    if (message) {
      setError({
        id,
        message
      });
      return;
    }
    setIsSpinning(true);
    await editHandler({ id, [getValueByField]: value });
    AdminFormTemplate.isMounted && setIsSpinning(false);
  };

  const handleClickDeleteButton = async item => {
    await deleteHandler(item, isSpinning => {
      AdminFormTemplate.isMounted && setIsSpinning(isSpinning);
    });
  };

  useEffect(() => {
    AdminFormTemplate.isMounted = true;
    return () => {
      AdminFormTemplate.isMounted = false;
    };
  }, []);

  useEffect(() => {
    AdminFormTemplate.isMounted && setData(JSON.parse(JSON.stringify(initialData)));
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  return (
    <FormWrapper isSpinning={isSpinning}>
      <div className={classes.header}>
        <SectionTitle title={title} />
        {permissions.includes("create") && newType === null && (
          <RoundPlusButton
            style={{ marginLeft: 10 }}
            clickHandler={() => {
              setNewType("");
            }}
          />
        )}
      </div>
      <div className={classes.content}>
        {data.map((item, i) => {
          return (
            <div className={classes.block} key={i}>
              <InputLabel style={{ minWidth: 100 }} text={`${keyWord} ${i + 1}`} />
              {item.isEdited ? (
                <>
                  <TextInput
                    classNames={["borderless"]}
                    style={{ width: 380 }}
                    value={item[getValueByField]}
                    changeHandler={value => handleChangeInput(item.id, value)}
                    error={errorByTypeId(item.id)}
                  />
                  <button className={`${classes.button} ${classes.primary}`} onClick={() => handleClickSaveButton(item.id, item[getValueByField])}>
                    Save
                  </button>
                </>
              ) : (
                <>
                  <div className={classes.text}>{item[getValueByField]}</div>
                  {permissions.includes("edit") && (
                    <button className={`${classes.button} ${classes.secondary} ${classes.edit}`} onClick={() => handleClickEditButton(item.id)}>
                      Edit
                    </button>
                  )}
                  {permissions.includes("delete") && (
                    <button className={`${classes.button} ${classes.secondary} ${classes.delete}`} onClick={() => handleClickDeleteButton(item[deleteByField])}>
                      Delete
                    </button>
                  )}
                </>
              )}
            </div>
          );
        })}
        {newType !== null && (
          <div className={classes.block}>
            <InputLabel style={{ minWidth: 100 }} text={`${keyWord} ${data.length + 1}`} />
            <TextInput
              classNames={["borderless"]}
              style={{ width: 380 }}
              value={newType}
              changeHandler={value => setNewType(value)}
              error={errorByTypeId(null)}
            />
            <button className={`${classes.button} ${classes.primary}`} onClick={handleClickAddButton}>
              Add
            </button>
          </div>
        )}
      </div>
    </FormWrapper>
  );
};

AdminFormTemplate.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  initialData: PropTypes.arrayOf(PropTypes.object),
  saveHandler: PropTypes.func,
  editHandler: PropTypes.func,
  deleteHandler: PropTypes.func
};

AdminFormTemplate.defaultProps = {
  permissions: [],
  title: "",
  deleteByField: "id",
  initialData: [],
  saveHandler: () => {},
  editHandler: () => {},
  deleteHandler: () => {}
};
