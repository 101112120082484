import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import { SettingsButton, CheckboxInput, TextInput } from "@Root/components";
import { CustomScrollbar } from "@Root/HOCs";
import { popupHeight } from "@Root/helpers";

class Dropdown extends Component {
  state = {
    popupIsShown: false,
    filter: ""
  };

  handleClickOutside = () => {
    this.setState({ popupIsShown: false });
  };

  render() {
    const { popupIsShown, filter } = this.state;
    const { columnsNames, hiddenColumnsNames, clickHandler, maxVisibleColumnsQuantity, onSaveColumnSettings } = this.props;
    const filteredColumnsNames = filter ? columnsNames.filter(columnName => columnName.toLowerCase().includes(filter.trim().toLowerCase())) : columnsNames;

    return (
      <div className={classes.ColumnFilterDropdown}>
        <SettingsButton clickHandler={() => this.setState({ popupIsShown: !popupIsShown })} />
        {popupIsShown && (
          <div className={classes.popup}>
            <div className={classes.search}>
              <TextInput
                classNames={["transparent"]}
                style={{ width: "100%" }}
                value={filter}
                changeHandler={filter => this.setState({ filter })}
                placeholder="Search column"
              />
            </div>
            <div style={{ height: popupHeight(filteredColumnsNames.length, maxVisibleColumnsQuantity, 38), position: "relative" }}>
              <CustomScrollbar verticalOnly>
                {filteredColumnsNames.map((columnName, i) => (
                  <div className={classes.column} key={i}>
                    <CheckboxInput
                      isChecked={!hiddenColumnsNames.includes(columnName)}
                      changeHandler={() => clickHandler(columnName)}
                      label={{ text: columnName, style: { color: "#4a4a4a" } }}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                ))}
              </CustomScrollbar>
            </div>
            {filteredColumnsNames.length > 0 && (
              <div className={classes.action_wrapper}>
                <button className={classes.save_button} onClick={onSaveColumnSettings}>
                  Save
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  columnsNames: PropTypes.arrayOf(PropTypes.string),
  hiddenColumnsNames: PropTypes.arrayOf(PropTypes.string),
  clickHandler: PropTypes.func,
  onSaveColumnSettings: PropTypes.func,
  maxVisibleColumnsQuantity: PropTypes.number
};

export const ColumnFilterDropdown = onClickOutside(Dropdown);
