import React, { useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { TextArea, TextInput, RadioInputGroup, InputLabel } from "@Root/components";
import { ApplicationFormTable } from "./ApplicationFormTable";

export const ApplicationForm = ({ data, programmes }) => {
  const yesNoOptions = ["No", "Yes"];
  const britishVisaStatusOptions = ["Not needed", "Granted", "Required"];
  const churchOfEnglandOptions = ["Ordinand", "Independant"];
  const fullTimeOptions = ["FT", "PT"];
  const sourceOfFundingOptions = ["Personal finance", "Supporting church", "Student ioan", "CofE funding", "Other"];
  const accomodationOptions = [
    "Renting privately",
    "Renting a college property",
    "Buying privately",
    "Living onsite in halls of residence full time (currenly only single rooms are avaliable)",
    "Living onsite in halls of residence part-time, commuting home at weekends",
    "I have no accomodation needs"
  ];
  const outcomeOfBapOptions = ["Not recommended", "Not yet known", "Recommended", "No BAP date fixed at this stage"];
  const maritalStatusOptions = ["Single", "Married", "Widowed", "Separated", "Divorced", "Other"];
  const firstHearAboutTrinityOptions = ["Former student", "Current student", "Facebook", "Twitter", "Google", "Church"];
  const attractsYouToTrinityOptions = [
    "Academic programme",
    "Vision",
    "Faculty",
    "Community",
    "Worship style",
    "Other",
    "Location",
    "Theological position of the college"
  ];
  const communicateWithYouInTheFuture = ["Post", "Email", "Phone", "Social media"];

  const studyProgrammes = () => {
    const programmesArray = [];
    Object.keys(data.study_program).forEach(value => {
      const programme = programmes.find(programme => +programme.value === +value);
      if (programme) programmesArray.push(programme.label);
    });
    return programmesArray.reduce((acc, label, i) => acc + `${i === 0 ? "" : ", "}${label}`, "");
  };

  useEffect(() => {
    console.log(55, data);
  }, [data]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>1. Personal information</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Title" />
            <RadioInputGroup
              style={{ position: "absolute", left: 158 }}
              values={["Mr", "Mrs", "Miss", "Rev", "Dr", "Other"]}
              currentValue={data.title}
              isInline
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Firstname(s)" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 100, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.firstname}
              isDisabled
            />
            <InputLabel style={{ color: "#4A4A4A", position: "absolute", left: 270 }} text="Surname" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.surname}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Address" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 100, width: 418, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.address}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="City" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 100, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.city}
              isDisabled
            />
            <InputLabel style={{ color: "#4A4A4A", position: "absolute", left: 270 }} text="Postcode" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.postcode}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="DOB" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 100, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.DOB}
              isDisabled
            />
            <InputLabel style={{ color: "#4A4A4A", position: "absolute", left: 270 }} text="Nationality" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.nationality}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Email" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 100, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.email}
              isDisabled
            />
            <InputLabel style={{ color: "#4A4A4A", position: "absolute", left: 270 }} text="Mobile tel" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.mobile_phone}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Sex" />
            <RadioInputGroup style={{ position: "absolute", left: 388 }} values={["Male", "Female"]} currentValue={data.sex} isInline isDisabled />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>2. International applicants</div>
          <div className={classes.text}>If you are a British citizen, please move straight on to section 3</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Country of birth" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.country_birth ? data.country_birth.name : ""}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Country of permanent residence" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.country_of_permanent ? data.country_of_permanent.name : ""}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="British visa status" />
            <RadioInputGroup
              style={{ position: "absolute", left: 254 }}
              values={["Not needed", "Granted", "Required"]}
              currentValue={britishVisaStatusOptions[data.british_visa_status - 1]}
              isInline
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Passport number" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.passport_number}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Is English your first language?" />
            <RadioInputGroup
              style={{ position: "absolute", left: 418 }}
              values={yesNoOptions}
              currentValue={yesNoOptions[data.is_english_first_language]}
              isInline
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="If not, have you taken an IELTS exam?" />
            <RadioInputGroup
              style={{ position: "absolute", left: 418 }}
              values={yesNoOptions}
              currentValue={yesNoOptions[data.taken_IELTS_exam]}
              isInline
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="If yes, what was your score?*" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.is_scope}
              isDisabled
            />
          </div>
          <div className={classes.note}>
            *Please note that undergraduate applications whose first language is not English are required to have a minimum overall score of 6,5 on the IELTS
            exame, and for postgraduate applications the minimum score is 7.5
          </div>
        </div>
      </div>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>3. Your programme</div>

          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Are you intending to pursue ordination in the Church of England or independent theological study?" />
            <RadioInputGroup values={churchOfEnglandOptions} currentValue={churchOfEnglandOptions[data.church_of_england - 1]} isInline isDisabled />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Which study programme are you interested in pursuing?" />
            <TextInput classNames={["borderless"]} style={{ width: 518, color: "#9B9B9B", backgroundColor: "#F5F4F3" }} value={studyProgrammes()} isDisabled />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Are you intending to to study full time or part time?" />
            <RadioInputGroup values={fullTimeOptions} currentValue={fullTimeOptions[data.full_time - 1]} isInline isDisabled />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Are you considering Context-based training (ie full time, non-residential)?" />
            <RadioInputGroup values={yesNoOptions} currentValue={yesNoOptions[data.context_based_training]} isInline isDisabled />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="What is the most likely source of funding for your study?" />
            <RadioInputGroup values={sourceOfFundingOptions} currentValue={sourceOfFundingOptions[data.source_of_funding - 1]} isInline isDisabled />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Which accomodation options are you interested in discussing?" />
            <RadioInputGroup values={accomodationOptions} currentValue={accomodationOptions[data.accomodation_options - 1]} isInline isDisabled />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>4. Education and Employment</div>
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Educational qualifications" />
            <ApplicationFormTable
              style={{ width: "100%" }}
              columnsNamesAndFields={{ Institution: "Institution", Subject: "Subject", Award: "Award", [`Result(s)`]: "Result", Date: "Date" }}
              data={data.educational_qualifications}
            />
          </div>
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Professional qualifications" />
            <ApplicationFormTable
              style={{ width: "100%" }}
              columnsNamesAndFields={{ Institution: "Institution", Subject: "Subject", Award: "Award", [`Result(s)`]: "Result", Date: "Date" }}
              data={data.professional_qualifications}
            />
          </div>
          <div className={classes.row} style={{ marginBottom: 20 }}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Recent employment history" />
            <ApplicationFormTable
              style={{ width: "100%" }}
              columnsNamesAndFields={{ Employer: "Employer", Role: "Role", "Dates from/to": "Dates from/to", "FT/PT": "FT/PT" }}
              data={data.recent_employment_history}
            />
          </div>
          <div>
            <span className={classes.text}>Please be prepared to provide copies of any certificates for academic and professional qualifications</span>
          </div>
        </div>
      </div>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>5. Ministerial details</div>
          <div>
            <span className={classes.text}>If you are not applying for ordination in the Church of England, please move straight on to section 6</span>
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Name of current church" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.name_of_current_church}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Sending diocese" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.sending_diocese}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Name of your DDO" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.name_of_your_DDO}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Date of last meeting with DDO" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.date_of_last_meeting_with_DDO}
              isDisabled
            />
          </div>

          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Email contact for your DDO" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.email_contact_for_your_DDO}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Phone contact for your DDO" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.phone_contact_fo_your_DDO}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Date of Bishops Advisory Panel" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.date_of_bishops_advisory_panel}
              isDisabled
            />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Outcome of BAP" />
            <RadioInputGroup values={outcomeOfBapOptions} currentValue={outcomeOfBapOptions[data.outcome_of_BAP - 1]} isInline isDisabled />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>6. Your story</div>
          <div className={classes.text} style={{ marginTop: 36, marginBottom: 15 }}>
            Please use this space, within 5000 characters, to tell us more about yourself – perhaps something of your spiritual journey, your interests and
            passions or your current ministry and mission involvements. If you are pursuing ordination, it would also be useful for us to hear about the kind of
            ministry you hope to develop in the future (e.g. parish, chaplaincy, theological education, etc.) If you are an independent applicant then it would
            be useful for us to know what your plans for your career after studying with us might be.
          </div>
          <div className={classes.row}>
            <TextArea
              classNames={["borderless"]}
              style={{ height: 300, width: "100%", color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.story}
              isDisabled
              placeholder="Church in Wales"
            />
          </div>
        </div>
      </div>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>7. Your circumstances</div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Marital status" />
            <RadioInputGroup values={maritalStatusOptions} currentValue={maritalStatusOptions[data.marital_status - 1]} isInline isDisabled />
          </div>
          <div className={classes.fixedRow}>
            <div className={classes.fixedRow}>
              <InputLabel style={{ color: "#4A4A4A", marginRight: 10 }} text="Spouse name" />
              <TextInput
                classNames={["borderless"]}
                style={{ position: "relative", left: 0, width: 162, color: "#9B9B9B", backgroundColor: "#F5F4F3", marginRight: 10 }}
                value={data.name_of_your_spouse}
                isDisabled
              />
            </div>
            <div className={classes.fixedRow}>
              <InputLabel style={{ color: "#4A4A4A", marginRight: 10 }} text="Spouse surname" />
              <TextInput
                classNames={["borderless"]}
                style={{ position: "relative", left: 0, width: 162, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
                value={data.surname_of_your_spouse}
                isDisabled
              />
            </div>
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Names and ages of your children" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 293, width: 225, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.names_and_ages_of_your_children}
              isDisabled
            />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Do you have any additional needs that it would be helpful for us to be aware of" />
            <RadioInputGroup values={yesNoOptions} currentValue={yesNoOptions[data.additional_needs]} isInline isDisabled />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="If yes, please tell us more about how we can accommodate you" />
            <TextArea
              classNames={["borderless"]}
              style={{ height: 90, width: "100%", color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.additional_needs_description}
              isDisabled
              placeholder="Church in Wales"
            />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Do you have a specific learning difficulty, e.g. dyslexia?" />
            <RadioInputGroup values={yesNoOptions} currentValue={yesNoOptions[data.learning_difficulty]} isInline isDisabled />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="If yes, please tell us more about how we can accommodate you" />
            <TextArea
              classNames={["borderless"]}
              style={{ height: 90, width: "100%", color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.learning_difficulty_description}
              isDisabled
              placeholder="Church in Wales"
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>8. Your connection to us</div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="How did you first hear about Trinity?" />
            <RadioInputGroup
              values={firstHearAboutTrinityOptions}
              currentValue={firstHearAboutTrinityOptions[data.first_hear_about_trinity - 1]}
              isInline
              isDisabled
            />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="What is it that attracts you to Trinity?" />
            <RadioInputGroup
              values={attractsYouToTrinityOptions}
              currentValue={attractsYouToTrinityOptions[data.attracts_you_to_trinity - 1]}
              isInline
              isDisabled
            />
          </div>
          <div className={classes.row}>
            <InputLabel style={{ color: "#4A4A4A" }} text="How would you like us to communicate with you in the future?" />
            <RadioInputGroup
              values={communicateWithYouInTheFuture}
              currentValue={communicateWithYouInTheFuture[data.communicate_with_you_in_the_future - 1]}
              isInline
              isDisabled
            />
          </div>
        </div>
      </div>
      <div className={classes.sectionsWrapper}>
        <div className={classes.section}>
          <div className={classes.title}>9. References</div>
          <div className={classes.text}>Referee 1 – your current church leader</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Name" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 138, width: 380, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.references_1_name}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Relationship to you" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 259, width: 259, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.references_1_relationship_to_you}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Email" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 138, width: 380, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.references_1_email}
              isDisabled
            />
          </div>
          <div className={classes.text}>Referee 2 – an academic referee*</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Name" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 138, width: 380, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.references_2_name}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Relationship to you" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 259, width: 259, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.references_2_relationship_to_you}
              isDisabled
            />
          </div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Email" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 138, width: 380, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.references_2_email}
              isDisabled
            />
          </div>
          <div className={classes.row}>
            <div className={classes.text} style={{ marginBottom: 15 }}>
              If you have been out of the education system for longer than 10 years it may be more relevant to give the name of a recent employer or someone who
              can tell us about your suitability for study. Use the box below to explain why you have chosen that person.
            </div>
            <TextArea
              classNames={["borderless"]}
              style={{ height: 90, width: "100%", color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.references_2_description}
              isDisabled
              placeholder="Church in Wales"
            />
          </div>
        </div>
        <div className={classes.section}>
          <div className={classes.title}>10. Declaration</div>
          <div className={classes.fixedRow}>
            <InputLabel style={{ color: "#4A4A4A" }} text="Date" />
            <TextInput
              classNames={["borderless"]}
              style={{ position: "absolute", left: 368, width: 150, color: "#9B9B9B", backgroundColor: "#F5F4F3" }}
              value={data.Date}
              isDisabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ApplicationForm.propTypes = {
  data: PropTypes.object
};
