import React, { useEffect, useState } from "react";
import classes from "./style.module.scss";
import { BlackTickIcon, CrossIcon } from "@Root/assets";
import { TextInput } from "@Root/components";
import { CustomScrollbar } from "@Root/HOCs";

export const AddExceptsModal = ({ categoryName, fields, onCancel, onSaveExceptions }) => {
  const [activeFields, setActiveField] = useState([]);
  const [value, setValue] = useState({ search: "" });
  useEffect(() => {
    if (fields) setActiveField([...fields]);
  }, [fields]);

  const onChangeFieldStatus = (oldField, status) => {
    const field = { ...oldField };
    field.disabled = status;
    setActiveField(prevState => {
      return prevState.map(item => (item.name === field.name ? field : item));
    });
  };

  const onClose = () => {
    onCancel();
    setActiveField(null);
  };

  const onSave = () => {
    onSaveExceptions(activeFields);
    setActiveField(null);
  };
  const onChangeValue = field => value => {
    setValue(prevState => ({ ...prevState, [field]: value }));
  };
  return (
    <div className={classes.modal_position}>
      <div className={classes.modal}>
        <h2 className={classes.modal_title}>Add Excepts for {categoryName}</h2>
        <p className={classes.modal_sub_title}>Excepts won't display for the user</p>
        <div className={classes.modal_search_field}>
          <label>Search: </label>
          <TextInput value={value["search"]} changeHandler={value => onChangeValue("search")(value)} />
        </div>
        <div className={classes.modal_fields}>
          <CustomScrollbar verticalOnly>
            <div className={classes.modal_fields_wrapper}>
              {activeFields &&
                activeFields.map(field => {
                  return (
                    <div
                      className={`${classes.modal_field} ${!field.disabled ? classes.modal_field_active : classes.modal_field_removed}`}
                      onClick={field.disabled ? () => onChangeFieldStatus(field, false) : null}
                      key={field.name}
                    >
                      <span>{field.name}</span>
                      {!field.disabled ? (
                        <img src={CrossIcon} className={classes.modal__category_cross} onClick={() => onChangeFieldStatus(field, true)} />
                      ) : (
                        <img src={BlackTickIcon} alt="" className={classes.modal__category_tick} />
                      )}
                    </div>
                  );
                })}
            </div>
          </CustomScrollbar>
        </div>

        <div className={classes.modal_actions}>
          <button className={classes.button_cancel} onClick={onClose}>
            Cancel
          </button>
          <button className={classes.button_save} onClick={onSave}>
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};
