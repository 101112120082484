export const matchTypes = {
  "All of the rules": "and",
  "Any of the rules": "or"
};

export const equalities = {
  is: "is",
  "<": "less",
  ">": "more",
  "<=": "less_equal",
  ">=": "more_equal",
  "is not": "is_not",
  contains: "contains",
  "is empty": "is_empty",
  "is not empty": "is_not_empty"
};

export const trashInformationMandatoryRules = [
  {
    id: "Not Trashed",
    name: "Not Trashed",
    description: "Not Trashed",
    matchType: "All of the rules",
    rows: [
      {
        field: "trashed",
        equality: "is",
        filter: "No"
      }
    ]
  },
  {
    id: "Trashed",
    name: "Trashed",
    description: "Trashed",
    matchType: "All of the rules",
    rows: [
      {
        field: "trashed",
        equality: "is",
        filter: "Yes"
      }
    ]
  }
];

export const admissionsMandatoryRules = [
  {
    id: "Not Actioned",
    name: "Not Actioned",
    description: "Not Actioned",
    matchType: "All of the rules",
    rows: [
      {
        field: "actioned",
        equality: "is",
        filter: "unactioned"
      }
    ]
  },
  {
    id: "Actioned",
    name: "Actioned",
    description: "Actioned",
    matchType: "All of the rules",
    rows: [
      {
        field: "actioned",
        equality: "is",
        filter: "actioned"
      }
    ]
  }
];
