import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { delay } from "redux-saga";
import { all, regularExpressions } from "@Root/helpers";

export class TextArea extends Component {
  state = {
    error: null
  };

  showError = async error => {
    this.setState({ error });
    await delay(3000);
    this.setState({ error: null });
  };

  handleChange = value => {
    const { restriction, changeHandler } = this.props;
    if (restriction) {
      const valueIsAllowed = (value, regularExpression) => {
        return value.match(regularExpression);
      };
      (value === "" || valueIsAllowed(value, regularExpressions[restriction])) && changeHandler(value);
    } else {
      changeHandler(value);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.props.error && this.showError(this.props.error);
    }
  }

  render() {
    const { error } = this.state;
    const { value, isDisabled, placeholder, classNames, style, maxLength } = this.props;
    const { handleChange } = this;
    const modifiedValue = value ? value : "";

    return (
      <div
        className={`${classes.wrapper} ${classNames ? classNames.reduce((acc, className) => acc + ` ${classes[className]}`, "") : ""}`}
        style={{ ...style, borderColor: error ? "#D0021B" : null }}
      >
        <textarea
          value={modifiedValue}
          onChange={e => all(() => handleChange(e.target.value), () => this.setState({ error: null }))}
          disabled={isDisabled}
          placeholder={placeholder}
          maxLength={maxLength}
        />
        {error && <div className={classes.error}>{error}*</div>}
      </div>
    );
  }
}

TextArea.propTypes = {
  value: PropTypes.string,
  changeHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.oneOf(["borderless", "transparent"])),
  style: PropTypes.object,
  restriction: PropTypes.oneOf(["letters", "lettersAndWhiteSpaces", "digits", "email"])
};
