import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { all } from "@Root/helpers";
import plusBig from "../../../assets/icons/plus-big.png";

export class FileInput extends Component {
  state = {
    error: null
  };

  input = React.createRef();

  handleChange = e => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 20971520) {
        this.showError("File is too big (Maximum file size is 20 MB)");
        return;
      }
      const fileType = file.type.split("/")[0];
      if (!this.restrictionIsPassed(fileType)) return;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.props.changeHandler(reader.result, file);
      };
    } else {
      this.props.changeHandler(null, null);
    }
  };

  handleRemove = e => {
    e.stopPropagation();
    this.input.current.value = "";
    this.props.removeHandler();
  };

  restrictionIsPassed = fileType => {
    if (this.props.restrictions && !this.props.restrictions.includes(fileType)) {
      this.showError("File type is not correct");
      return false;
    } else {
      return true;
    }
  };

  showError = async error => {
    clearTimeout(this.timeout);
    this.setState({ error });
    this.timeout = setTimeout(() => this.setState({ error: null }), 3000);
  };

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.props.error && this.showError(this.props.error);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    const { error } = this.state;
    const { inputClassNames, style, circleStyle, value, file, isDisabled, hasText } = this.props;
    const { input, handleChange, handleRemove } = this;
    const fileType = file ? file.type.split("/")[0] : null;

    return (
      <div
        className={`${classes.FileInput}  
                    ${inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, "")}`}
        style={{ ...style, borderColor: error ? "#D0021B" : null }}
        onClick={isDisabled ? e => e.preventDefault() : null}
      >
        <div
          className={`${classes.preview} ${isDisabled ? classes.disabled : ""}`}
          style={value ? { backgroundImage: `url("${value}")` } : null}
          onClick={() => all(() => input.current.click(), () => this.setState({ error: null }))}
        >
          {!value ? (
            <div className={classes.placeholder}>
              <div className={classes.circle} style={circleStyle}>
                <img src={plusBig} alt="" />
              </div>
              {hasText && !isDisabled && <div className={classes.text}>Click to upload</div>}
            </div>
          ) : (
            <>
              {!!file && fileType !== "image" && <div className={classes.name}>{file.name}</div>}
              {!isDisabled && (
                <div className={classes.remove} onClick={handleRemove}>
                  Remove
                </div>
              )}
            </>
          )}
        </div>
        <input type="file" onChange={handleChange} ref={input} />
        {error && <div className={classes.error}>{error}*</div>}
      </div>
    );
  }
}

FileInput.propTypes = {
  inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(["borderless"])),
  style: PropTypes.object,
  circleStyle: PropTypes.object,
  value: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string
  }),
  changeHandler: PropTypes.func,
  removeHandler: PropTypes.func,
  restrictions: PropTypes.arrayOf(PropTypes.oneOf(["image", "application", "text"])),
  isDisabled: PropTypes.bool,
  hasText: PropTypes.bool,
  error: PropTypes.string
};

FileInput.defaultProps = {
  inputClassNames: [],
  style: {},
  circleStyle: {},
  value: "",
  file: null,
  changeHandler: () => {},
  removeHandler: () => {},
  restrictions: null,
  isDisabled: false,
  hasText: true,
  error: null
};
