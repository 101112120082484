import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { Table, SectionTitle } from "@Root/components";
import { API } from "@Root/API";

export const ContactsApplicationsForm = ({ fetchTableDataHandler, history }) => {
  const table = {
    name: "contactsStudentApplications",
    columns: [
      {
        name: "ID",
        field: "id",
        cellType: "link",
        searchInputType: null
      },
      {
        name: "Date",
        field: "date",
        cellType: "text",
        searchInputType: "datePicker"
      },
      {
        name: "Academic Year",
        field: "academic_years",
        cellType: "text",
        searchInputType: "select",
        options: ["2019/2020"]
      },
      {
        name: "Ordinand",
        field: "ordinand",
        cellType: "text",
        searchInputType: "select",
        options: ["Y", "N"]
      },
      {
        name: "FT / PT",
        field: "ft_pt",
        cellType: "text",
        searchInputType: "select",
        options: ["FT", "PT"]
      },
      {
        name: "Res / Con",
        field: "res_con",
        cellType: "text",
        searchInputType: "select",
        options: ["Res", "Con"]
      },
      {
        name: "Programme",
        field: "programme",
        searchInputType: "textInput",
        cellType: "text"
      }
    ],
    fetchDataHandler: params => fetchTableDataHandler(params),
    fetchColumnOptions: () => API.getColumnOptions("contactsStudentApplications"),
    fetchSaveColumnOptions: options => API.saveColumnOptions("contactsStudentApplications", options),
    clickLinkHandlers: {
      ID: row => history.push(`/home/admissions/applications/${row.id}`)
    }
  };

  return (
    <FormWrapper>
      <div className={classes.titleWrapper}>
        <SectionTitle title="Applications" />
      </div>
      <Table
        style={{ marginTop: 20 }}
        datePeriodDropdownStyle={{ top: -70 }}
        name={table.name}
        columns={table.columns}
        fetchDataHandler={table.fetchDataHandler}
        fetchColumnOptions={table.fetchColumnOptions}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        clickLinkHandlers={table.clickLinkHandlers}
      />
    </FormWrapper>
  );
};

ContactsApplicationsForm.propTypes = {
  history: PropTypes.object.isRequired,
  fetchTableDataHandler: PropTypes.func
};

ContactsApplicationsForm.defaultProps = {
  fetchTableDataHandler: () => {}
};
