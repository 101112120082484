import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { BackButton } from "@Root/components";

export const SectionWrapper = ({ hasBackButton, backButtonText, clickBackButtonHandler, children }) => {
  return (
    <div className={classes.wrapper}>
      {hasBackButton && (
        <div className={classes.backButtonWrapper}>
          <BackButton text={backButtonText} clickHandler={clickBackButtonHandler} />
        </div>
      )}
      <div className={classes.main}>
        {React.Children.map(children, (child, i) => {
          return (
            <>
              {i !== 0 && <div className={classes.line} />}
              {child}
            </>
          );
        })}
      </div>
    </div>
  );
};

SectionWrapper.propTypes = {
  hasBackButton: PropTypes.bool,
  backButtonText: PropTypes.string,
  clickBackButtonHandler: PropTypes.func,
  children: PropTypes.node
};

SectionWrapper.defaultProps = {
  hasBackButton: false,
  backButtonText: "",
  clickBackButtonHandler: () => {},
  children: null
};
