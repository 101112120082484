import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select } from "@Root/components";

export class StudiesModuleDeliveryRoleForm extends Component {
  constructor(props) {
    super(props);
    const { initialIsEditable, initialData, generateId } = props;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      module_delivery_role_identifier: generateId ? Math.floor(Math.random() * (100000000000000 - 1 + 1)) + 1 : initialData.module_delivery_role_identifier,
      franchise_indicator_id: initialData.franchise_indicator_id,
      role_type_id: initialData.role_type_id,
      hesa_identifier_id: initialData.hesa_identifier_id,
      module_delivery_role_proportion: initialData.module_delivery_role_proportion,
      venue_id: initialData.venue_id
    };
  }
  formIsValid = () => {
    const { module_delivery_role_identifier, module_delivery_role_proportion } = this.state;

    if (!module_delivery_role_identifier) {
      this.showError("module_delivery_role_identifier", "Required");
      return false;
    }
    if (!module_delivery_role_proportion) {
      this.showError("module_delivery_role_proportion", "Required");
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  setInitialState = () => {
    const { initialData } = this.props;
    this.setState({
      isEditable: false,
      error: null,
      module_delivery_role_identifier: initialData.module_delivery_role_identifier,
      franchise_indicator_id: initialData.franchise_indicator_id,
      role_type_id: initialData.role_type_id,
      hesa_identifier_id: initialData.hesa_identifier_id,
      module_delivery_role_proportion: initialData.module_delivery_role_proportion,
      venue_id: initialData.venue_id
    });
  };

  handleClickCancelButton = () => {
    this.setInitialState();
  };

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { state } = this;
    const moduleDelId = this.props.location.pathname.split("/")[6];
    this.setState({ isSaving: true });
    try {
      await this.props.saveHandler({
        module_delivery_id: moduleDelId,
        module_delivery_role_identifier: state.module_delivery_role_identifier,
        franchise_indicator_id: state.franchise_indicator_id,
        role_type_id: state.role_type_id,
        hesa_identifier_id: state.hesa_identifier_id,
        module_delivery_role_proportion: state.module_delivery_role_proportion,
        venue_id: state.venue_id
      });
      !this.isUnmounted && this.setState({ isEditable: false });
    } catch (error) {
      console.log(error);
      alert("Unable to save the data");
    }
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const {
      isEditable,
      isSaving,
      module_delivery_role_identifier,
      franchise_indicator_id,
      role_type_id,
      hesa_identifier_id,
      module_delivery_role_proportion,
      venue_id
    } = this.state;

    const { permissions, title, buttons, options } = this.props;
    const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage } = this;

    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;

    return (
      <div className={classes.StudiesModuleDeliveryRoleForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this module cost center" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module delivery role identifier" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_delivery_role_identifier.toString()}
                  placeholder=""
                  isDisabled={true}
                  error={errorMessage("module_delivery_role_identifier")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Franchise indicator" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.franchise_indicators}
                  value={franchise_indicator_id}
                  changeHandler={value => this.setState({ franchise_indicator_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Role type" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.role_types}
                  value={role_type_id}
                  changeHandler={value => this.setState({ role_type_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
            </div>

            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="HESA identifier" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.hesa_identificators}
                  value={hesa_identifier_id}
                  changeHandler={value => this.setState({ hesa_identifier_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module delivery role proportion" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_delivery_role_proportion}
                  changeHandler={module_delivery_role_proportion => this.setState({ module_delivery_role_proportion })}
                  placeholder=""
                  isDisabled={!isEditable}
                  restriction="digits"
                  error={errorMessage("module_delivery_role_proportion")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Venue identifier" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.venues}
                  value={venue_id}
                  changeHandler={value => this.setState({ venue_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

StudiesModuleDeliveryRoleForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    module_delivery_role_identifier: PropTypes.number,
    franchise_indicator_id: PropTypes.number,
    role_type_id: PropTypes.number,
    hesa_identifier_id: PropTypes.number,
    module_delivery_role_proportion: PropTypes.number,
    venue_id: PropTypes.number
  }),
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

StudiesModuleDeliveryRoleForm.defaultProps = {
  permissions: [],
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    module_delivery_role_identifier: null,
    franchise_indicator_id: null,
    role_type_id: null,
    hesa_identifier_id: null,
    module_delivery_role_proportion: null,
    venue_id: null
  },
  initialIsEditable: false,
  options: null,
  buttons: ["cancel", "delete", "save"],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};
