import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import { withPromise, errorMessage } from "@Root/helpers";
import * as actions from "@Root/store";
import { SectionWrapper } from "@Root/HOCs";
import { Spinner, StudiesProgrammeForm } from "@Root/components";

const Component = ({ history, getStudiesProgrammeOptions, studiesProgrammeOptions, setSnackbar }) => {
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      await getStudiesProgrammeOptions();
      Component.isMounted && setIsShown(true);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.replace("/contact-us");
    }
  };

  const saveProgramme = async payload => {
    try {
      const { data } = await API.postStudiesProgramme(payload);
      history.push(`/home/studies/programmes/${data.data.id}`);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <SectionWrapper hasBackButton backButtonText="Programmes" clickBackButtonHandler={() => history.push("/home/studies/programmes")}>
            <StudiesProgrammeForm
              title={{ onEdit: "Create Programme Delivery" }}
              initialIsEditable={true}
              options={studiesProgrammeOptions}
              buttons={["save"]}
              saveHandler={payload => saveProgramme(payload)}
            />
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  studiesProgrammeOptions: inputDataListsReducer.studiesProgrammeOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesProgrammeOptions: () => withPromise(dispatch, actions.getStudiesProgrammeOptions),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const StudiesNewProgramme = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
