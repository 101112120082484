import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";

export const Spinner = ({ style, size = "small", color = "dark", hasBackground = false }) => (
  <div className={classes.Spinner} style={{ ...style, backgroundColor: hasBackground ? "rgba(74, 74, 74, 0.3)" : null }}>
    <div className={`${classes.circle} ${classes[size]} ${classes[color]}`} />
  </div>
);

Spinner.propTypes = {
  style: PropTypes.object,
  size: PropTypes.oneOf(["extra-small", "small", "big"]),
  color: PropTypes.oneOf(["light", "dark"]),
  hasBackground: PropTypes.bool
};
