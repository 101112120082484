import React from "react";
import classes from "./GDPRPage.module.scss";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { getCurrentLinkName } from "@Root/helpers";
import DocumentTitle from "react-document-title";
import { PageTitle, PageNavLinks } from "@Root/components";
import { GDPRTable } from "./GDPRTable";

const GDPRPage = ({ location }) => {
  const links = [{ name: "GDPR Log", path: "gdpr-log" }, { name: "Data Puring", path: "data_puring" }];
  const currentLinkName = getCurrentLinkName(links, location);

  return (
    <DocumentTitle title={`${currentLinkName} - GDPR`}>
      <div className={classes.wrapper}>
        <PageTitle title={currentLinkName} />
        <PageNavLinks style={{ marginBottom: 30 }} root={`/home/gdpr`} links={links} />
        <Switch>
          {links.map((link, i) => (
            <Route exact path={`/home/gdpr/${link.path}`} component={GDPRTable} key={i} />
          ))}
          <Redirect to="/home/gdpr/gdpr-log" />
        </Switch>
      </div>
    </DocumentTitle>
  );
};

export default withRouter(GDPRPage);
