import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import { all } from "@Root/helpers";
import triangleBlack from "../../../assets/icons/triangle-black.png";

class Dropdown extends Component {
  state = {
    popupIsShown: false
  };

  handleClickOutside = () => {
    this.setState({ popupIsShown: false });
  };

  render() {
    const { popupIsShown } = this.state;
    const { clickHandler = () => {} } = this.props;

    return (
      <div className={classes.ExportDropdown}>
        <div className={classes.opener} onClick={() => this.setState({ popupIsShown: !popupIsShown })}>
          <div className={classes.text}>Export</div>
          <div className={classes.icon}>
            <img style={popupIsShown ? { transform: "rotate(180deg)" } : null} src={triangleBlack} alt="" />
          </div>
        </div>
        {popupIsShown && (
          <div className={classes.options}>
            {["csv", "xml"].map((option, i) => (
              <div
                className={classes.option}
                onClick={() =>
                  all(
                    () => clickHandler(option),
                    () => {
                      this.setState({ popupIsShown: false });
                    }
                  )
                }
                key={i}
              >
                {`Save as ${option.toUpperCase()}`}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  clickHandler: PropTypes.func
};

export const ExportDropdown = onClickOutside(Dropdown);
