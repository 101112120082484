import React from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { localDate, withPromise, errorMessage } from "@Root/helpers";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { Table, Spinner } from "@Root/components";

class Component extends React.Component {
  state = {
    isShown: false
  };

  GDPRLogTable = () => {
    return {
      name: "GDPR Log",
      columns: [
        {
          name: "When",
          field: "when",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Who",
          field: "who",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Action",
          field: "action",
          searchInputType: "select",
          options: ["create", "view", "delete", "edit"],
          cellType: "text"
        },
        {
          name: "Model | Route",
          field: "model_with_route",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Contact ID",
          field: "contact_id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Full Name",
          field: "fullName",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Relationship",
          field: "relation",
          searchInputType: "select",
          options: [...(this.props.personRelationshipOptions || []), ...(this.props.organisationRelationshipOptions || [])].map(x => x.label),
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getGDPRLogs(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("gdpr-log", options),
      fetchColumnOptions: () => API.getColumnOptions("gdpr-log"),
      hasRules: true,
      hasSorting: true,
      hasFilters: true,
      convertDataHandler: initialData => {
        return initialData.map(row => ({ ...row, when: localDate(row.when) }));
      },
      fetchExportedDataHander: () => {}, // need to be added
      clickLinkHandlers: {
        "Contact ID": row => this.props.history.push(`/home/contacts/all-contacts/${row.contact_id}`),
        Relationship: row => this.props.history.push(`/home/contacts/all-contacts/${row.contact_id}`)
      }
    };
  };

  puringDataTable = () => {
    return {
      name: "Data Puring",
      columns: [
        {
          name: "Photo",
          field: "photo",
          searchInputType: null,
          cellType: "image"
        },
        {
          name: "Name",
          field: "name",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Institution / House",
          field: "institution_house",
          searchInputType: "select",
          options: [],
          cellType: "text"
        },
        {
          name: "Contact ID",
          field: "contact_id",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Left Date",
          field: "left_date",
          searchInputType: "select",
          options: [],
          cellType: "text"
        },
        {
          name: "Tier for Records",
          field: "tier_for_records",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Accessibility Records",
          field: "accessibility_records",
          searchInputType: "textInput",
          options: [],
          cellType: "text"
        },
        {
          name: "Other Records & Attachments",
          field: "other_records_attachments",
          searchInputType: "textInput",
          options: [],
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getGDPRPuringData(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("data_puring", options),
      fetchColumnOptions: () => API.getColumnOptions("data_puring"),
      hasRules: true,
      hasSorting: true,
      hasFilters: true,
      convertDataHandler: initialData => {
        return initialData.map(row => ({ ...row, left_date: localDate(row.left_date) }));
      },
      fetchExportedDataHander: () => {} // need to be added
    };
  };

  tableData = () => ({
    "gdpr-log": this.GDPRLogTable(),
    data_puring: this.puringDataTable()
  });

  fetchOptions = async () => {
    const {
      history,
      personRelationshipOptions,
      getPersonRelationships,
      organisationRelationshipOptions,
      getOrganisationRelationships,
      setSnackbar,
      dataPuringOptions,
      getDataPuringOptions,
      location
    } = this.props;
    const tab = location.pathname.split("/")[3];
    try {
      tab === "gdpr-log" &&
        (await Promise.all([
          !personRelationshipOptions ? getPersonRelationships() : null,
          !organisationRelationshipOptions ? getOrganisationRelationships() : null
        ]));
      tab === "data_puring" && !dataPuringOptions && (await getDataPuringOptions());
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.replace("/contact-us");
    }
  };

  async componentDidMount() {
    await this.fetchOptions();
    !this.isUnmounted && this.setState({ isShown: true });
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { isShown } = this.state;
    const { location, setSnackbar, setSuccessNotification } = this.props;
    const { tableData } = this;
    const tab = location.pathname.split("/")[3];

    return (
      <div className={classes.wrapper}>
        {isShown ? (
          <Table
            key={tableData()[tab].name}
            name={tableData()[tab].name}
            columns={tableData()[tab].columns}
            fetchDataHandler={tableData()[tab].fetchDataHandler}
            fetchColumnOptions={tableData()[tab].fetchColumnOptions}
            fetchSaveColumnOptions={tableData()[tab].fetchSaveColumnOptions}
            hasRules={tableData()[tab].hasRules}
            hasSorting={tableData()[tab].hasSorting}
            hasFilters={tableData()[tab].hasFilters}
            convertDataHandler={tableData()[tab].convertDataHandler}
            fetchExportedDataHander={tableData()[tab].fetchExportedDataHander}
            clickLinkHandlers={tableData()[tab].clickLinkHandlers}
            errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
            successHandler={success => setSuccessNotification({ text: success })}
          />
        ) : (
          <Spinner style={{ position: "absolute" }} />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ inputDataListsReducer }) => ({
  personRelationshipOptions: inputDataListsReducer.personRelationships,
  organisationRelationshipOptions: inputDataListsReducer.organisationRelationships,
  dataPuringOptions: inputDataListsReducer.dataPuringOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getPersonRelationships: () => withPromise(dispatch, actions.getPersonRelationships),
    getOrganisationRelationships: () => withPromise(dispatch, actions.getOrganisationRelationships),
    getDataPuringOptions: () => withPromise(dispatch, actions.getPuringDataOptions),
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    setSuccessNotification: data => dispatch(actions.setSuccessNotification(data))
  };
};

export const GDPRTable = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
