import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import * as actions from "@Root/store";
import { errorMessage } from "@Root/helpers";
import { Spinner, StudentTypesForm } from "@Root/components";

import { SectionWrapper } from "@Root/HOCs";

const StudentTypes = ({ history, permissions, showModal, hideModal, setSnackbar }) => {
  const [isShown, setIsShown] = useState(false);
  const [types, setTypes] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await API.getAdminStudentTypes();
      setTypes(data.data);
      setIsShown(true);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const postStudentType = async (payload, cb) => {
    try {
      const { data } = await API.postAdminStudentType(payload);
      setTypes([...types, data.data]);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteStudentType = async (name, cb) => {
    await new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This student type will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        cb(true);
        try {
          await API.deleteAdminStudentType(name);
          setTypes(types.filter(type => type.name !== name));
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        cb(false);
      },
      () => {
        hideModal();
      }
    );
  };

  useEffect(() => {
    StudentTypes.isMounted = true;
    fetchData();
    return () => {
      StudentTypes.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper>
          <StudentTypesForm
            permissions={permissions}
            title={"Student types"}
            initialData={types}
            deleteHandler={(payload, cb) => deleteStudentType(payload, cb)}
            saveHandler={(payload, cb) => postStudentType(payload, cb)}
          />
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => ({
  permissions: authReducer.user.permissions
});

const mapDispatchToProps = dispatch => {
  return {
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const AdminStudentTypes = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StudentTypes)
);
