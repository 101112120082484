import { delay } from "redux-saga";
import { all, put, select, takeEvery } from "redux-saga/effects";
import { API, axios } from "@Root/API";
import * as actions from "../actions";
import { errorMessage } from "@Root/helpers";
import { permissions } from "../../configs";
import * as types from "../types";

export function* logIn({ login, password }) {
  try {
    const { data } = yield API.postLoginPassword(login, password);
    const token = yield data.data["response-content"].access_token;
    yield (axios.defaults.headers.common["Authorization"] = `Bearer ${token}`);
    yield (localStorage.token = token);
    yield (sessionStorage.isCurrentSession = true);
    yield put(actions.setToken(token));
  } catch (err) {
    let error = Object.keys(err.response.data.errors)[0];
    error === "email" && (error = "login");
    yield put(actions.setAuthError(error));
    yield delay(3000);
    yield put(actions.setAuthError(null));
  }
}

export function* logOut({ history }) {
  try {
    yield API.deleteUserToken();
  } catch (error) {
    yield put(actions.setSnackbar({ text: errorMessage(error), isError: true }));
  } finally {
    yield localStorage.removeItem("token");
    yield sessionStorage.removeItem("isCurrentSession");
    yield put(actions.setToken(null));
    yield put(actions.setUser({}));
    yield history.push("/login");
  }
}

export function* removeToken({ history }) {
  yield localStorage.removeItem("token");
  yield sessionStorage.removeItem("isCurrentSession");
  yield put(actions.setToken(null));
  yield put(actions.setUser({}));
  yield history.push("/login");
}

export function* sendRecoveryEmail({ email, history }) {
  try {
    yield API.postRecoveryEmail(email);
    yield history.replace({ hash: "#success" });
  } catch (err) {
    let error = Object.keys(err.response.data.errors)[0];
    yield put(actions.setAuthError(error));
    yield delay(3000);
    yield put(actions.setAuthError(null));
  }
}

export function* checkRecoveryToken({ token, history }) {
  try {
    yield API.postRecoveryToken(token);
    yield put(actions.setRecoveryToken(token));
  } catch (err) {
    yield history.replace("/password-recovery");
  }
}

export function* sendNewPassword({ password, history }) {
  const token = yield select(state => state.auth.recoveryToken);
  try {
    yield API.postNewPassword(token, password);
    yield history.replace("/login");
    yield put(actions.setRecoveryToken(null));
  } catch (err) {
    yield put(actions.setAuthError("recoveryToken"));
    yield delay(3000);
    yield put(actions.setAuthError(null));
    yield history.replace("/password-recovery");
    yield put(actions.setRecoveryToken(null));
  }
}

export function* getUser({ promise }) {
  try {
    const { data } = yield API.getAdminUser();
    const user = data.data;
    const activePermissions = permissions[user.user_roles_names] || [];
    yield put(actions.setUser({ ...user, permissions: activePermissions }));
    promise.resolve();
  } catch (error) {
    promise.reject(error);
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.LOG_IN, logIn),
    takeEvery(types.LOG_OUT, logOut),
    takeEvery(types.REMOVE_TOKEN, removeToken),
    takeEvery(types.SEND_RECOVERY_EMAIL, sendRecoveryEmail),
    takeEvery(types.CHECK_RECOVERY_TOKEN, checkRecoveryToken),
    takeEvery(types.SEND_NEW_PASSWORD, sendNewPassword),
    takeEvery(types.GET_USER, getUser)
  ]);
}
