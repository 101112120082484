import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import closeIcon from "../../../assets/icons/close.png";
import { RuleRow } from "./RuleRow";
import { TextInput, RadioInputGroup } from "@Root/components";
import { matchTypes } from "@Root/configs";

export class TableRulePopup extends Component {
  state = {
    name: "",
    description: "",
    matchType: "All of the rules",
    rows: [],
    newRow: {
      field: null,
      equality: null,
      filter: ""
    },
    error: null
  };

  handleSaveButtonClick = () => {
    if (!this.ruleIsValid()) return;
    const { name, description, matchType, rows } = this.state;
    this.props.clickSaveHandler({
      name,
      description,
      matchType,
      rows
    });
  };

  ruleIsValid = () => {
    const { name } = this.state;
    if (!name) {
      this.showError("name");
      return false;
    } else {
      return true;
    }
  };

  showError = input => {
    this.setState({ error: input }, () => {
      this.setState({ error: null });
    });
  };

  updateData = () => {
    const { name = "", description = "", matchType = "All of the rules", rows = [] } = this.props;
    this.setState({
      name,
      description,
      matchType,
      rows
    });
  };

  componentDidMount() {
    this.updateData();
  }

  render() {
    const { name, description, matchType, rows, newRow, error } = this.state;
    const { isNew, columns, clickCloseHandler = () => {}, clickDeleteHandler = () => {} } = this.props;
    const { handleSaveButtonClick } = this;

    return (
      <div className={classes.TableRulePopup}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Custom scope</div>
          <div className={classes.icon} onClick={clickCloseHandler}>
            <img src={closeIcon} alt="" />
          </div>
        </div>
        <div className={classes.line} />
        <div className={classes.inputs}>
          <TextInput
            classNames={["borderless"]}
            style={{ width: 195 }}
            value={name}
            changeHandler={name => this.setState({ name })}
            placeholder="Rule name"
            error={error === "name" ? "Required" : null}
          />
          <TextInput
            classNames={["borderless"]}
            style={{ width: 195 }}
            value={description}
            changeHandler={description => this.setState({ description })}
            placeholder="Description"
            // error={error === "description" ? "Required" : null}
          />
        </div>

        <div>
          <div className={classes.matches}>Matches:</div>
          <RadioInputGroup
            values={Object.keys(matchTypes)}
            currentValue={matchType}
            changeHandler={matchType => this.setState({ matchType })}
            isInline
            firstWordIsBold
          />
        </div>

        <div className={classes.line} />

        {rows.map((row, i) => (
          <RuleRow row={row} columns={columns} clickDeleteRowHandler={() => this.setState({ rows: rows.filter((row, k) => i !== k) })} key={i} />
        ))}

        <RuleRow
          isNew
          row={newRow}
          columns={columns}
          // addingRuleHandler={addingRuleHandler}
          clickAddRowHandler={() => this.setState({ rows: [...rows, newRow], newRow: { field: null, equality: null, filter: "" } })}
          changeRuleValuesHandler={payload => this.setState({ newRow: { ...newRow, ...payload } })}
          // removingNewRuleHandler={() => this.setState({ newRule: null })}
        />

        {/* {newRule ? (
          <RuleRow
            isNew
            rule={newRule}
            tableFields={tableFields}
            addingRuleHandler={addingRuleHandler}
            adjustingNewRuleHandler={payload => this.setState({ newRule: { ...newRule, ...payload } })}
            removingNewRuleHandler={() => this.setState({ newRule: null })}
          />
        ) : (
          <div className={classes.add}>
            <span onClick={addNewRule}>Add another rule</span>
          </div>
        )} */}

        {/* {!!rows.length && (
          <div className={classes.add}>
            <span onClick={handleSaveButtonClick}>Save rule</span>
          </div>
        )} */}

        <div className={classes.footer}>
          {!!rows.length && (
            <span className={classes.save} onClick={handleSaveButtonClick}>
              Save scope
            </span>
          )}
          {!isNew && (
            <span className={classes.delete} onClick={clickDeleteHandler}>
              Delete scope
            </span>
          )}
        </div>
      </div>
    );
  }
}

TableRulePopup.propTypes = {
  isNew: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  name: PropTypes.string,
  description: PropTypes.string,
  matchType: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      equality: PropTypes.string,
      filter: PropTypes.string
    })
  ),
  clickCloseHandler: PropTypes.func,
  clickSaveHandler: PropTypes.func,
  clickDeleteHandler: PropTypes.func
};
