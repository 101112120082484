import { axios } from "@Root/API";
import objectToFormdata from "object-to-formdata";

export const postAdminUser = payload => {
  return axios.post("users", objectToFormdata(payload), {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};
