import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import usePrevious from "../../../../../hooks/usePrevious";
import { localLongDate, objectsAreEqual } from "@Root/helpers";
import { EditButton, SectionTitle } from "@Root/components";
import { Subform } from "./Subform";

export const ContactsHesaDetailForm = ({
  permissions,
  student,
  careLeaver,
  carer,
  contactPurpose,
  dependant,
  disability,
  engagement,
  financialSupportScheme,
  studentRegistration,
  leaver,
  entryQualificationAward,
  ethnicity,
  genderIdentity,
  languageProficiency,
  maritalStatus,
  nationalIdentity,
  nationality,
  parentalEducation,
  personIdentifier,
  religion,
  religiousBackground,
  serviceLeaver,
  sexualOrientation,
  socioEconomicClassification,
  standardOccupationalClassification,
  hesaDetailOptions,
  hesaDetailCourseSessionOptions,
  saveHesaDetailForm,
  fetchQualificationsByTitleHandler,
  fetchQualificationHandler,
  snapshots,
  activeSnapshotId,
  clickSnapshotHandler
}) => {
  const filters = [
    {
      name: "Key Information",
      subformNames: [
        "student",
        "contact_purposes",
        "engagements",
        "student_registrations",
        "financial_support_schemes",
        "student_financial_supports",
        "leavers",
        "marital_statuses",
        "person_identifiers",
        "dependants",
        "entry_qualification_awards",
        "language_proficiencies"
      ]
    },
    {
      name: "Equal Opportunities",
      subformNames: [
        "care_leavers",
        "carers",
        "disabilities",
        "ethnicities",
        "gender_identities",
        "national_identities",
        "nationalities",
        "parental_educations",
        "religions",
        "religious_backgrounds",
        "service_leavers",
        "sexual_orientations",
        "socio_economic_classifications",
        "standard_occupational_classifications"
      ]
    }
  ];

  const [isEditable, setIsEditable] = useState(false);
  const [activeFilterName, setActiveFilterName] = useState("Key Information");

  const [data, setData] = useState({
    student,
    careLeaver,
    carer,
    contactPurpose,
    dependant,
    disability,
    engagement,
    financialSupportScheme,
    studentRegistration,
    leaver,
    entryQualificationAward,
    ethnicity,
    genderIdentity,
    languageProficiency,
    maritalStatus,
    nationalIdentity,
    nationality,
    parentalEducation,
    personIdentifier,
    religion,
    religiousBackground,
    serviceLeaver,
    sexualOrientation,
    socioEconomicClassification,
    standardOccupationalClassification
  });

  const prevStudent = usePrevious(student);
  const prevCareLeaver = usePrevious(careLeaver);
  const prevСarer = usePrevious(carer);
  const prevContactPurpose = usePrevious(contactPurpose);
  const prevDependant = usePrevious(dependant);
  const prevDisability = usePrevious(disability);
  const prevEngagement = usePrevious(engagement);
  const prevStudentRegistration = usePrevious(studentRegistration);
  const prevFinancialSupportScheme = usePrevious(financialSupportScheme);
  const prevLeaver = usePrevious(leaver);
  const prevEntryQualificationAward = usePrevious(entryQualificationAward);
  const prevEthnicity = usePrevious(ethnicity);
  const prevGenderIdentity = usePrevious(genderIdentity);
  const prevLanguageProficiency = usePrevious(languageProficiency);
  const prevMaritalStatus = usePrevious(maritalStatus);
  const prevNationalIdentity = usePrevious(nationalIdentity);
  const prevNationality = usePrevious(nationality);
  const prevParentalEducation = usePrevious(parentalEducation);
  const prevPersonIdentifier = usePrevious(personIdentifier);
  const prevReligion = usePrevious(religion);
  const prevReligiousBackground = usePrevious(religiousBackground);
  const prevServiceLeaver = usePrevious(serviceLeaver);
  const prevSexualOrientation = usePrevious(sexualOrientation);
  const prevSocioEconomicClassification = usePrevious(socioEconomicClassification);
  const prevStandardOccupationalClassification = usePrevious(standardOccupationalClassification);

  useEffect(() => {
    const updateData = (prevFormData, formData, formName) => {
      if (!objectsAreEqual(prevFormData, formData)) {
        setData({ ...data, [formName]: formData });
      }
    };
    updateData(prevStudent, student, "student");
    updateData(prevCareLeaver, careLeaver, "careLeaver");
    updateData(prevСarer, carer, "carer");
    updateData(prevContactPurpose, contactPurpose, "contactPurpose");
    updateData(prevDependant, dependant, "dependant");
    updateData(prevDisability, disability, "disability");
    updateData(prevEngagement, engagement, "engagement");
    updateData(prevStudentRegistration, studentRegistration, "studentRegistration");
    updateData(prevFinancialSupportScheme, financialSupportScheme, "financialSupportScheme");
    updateData(prevLeaver, leaver, "leaver");
    updateData(prevEntryQualificationAward, entryQualificationAward, "entryQualificationAward");
    updateData(prevEthnicity, ethnicity, "ethnicity");
    updateData(prevGenderIdentity, genderIdentity, "genderIdentity");
    updateData(prevLanguageProficiency, languageProficiency, "languageProficiency");
    updateData(prevMaritalStatus, maritalStatus, "maritalStatus");
    updateData(prevNationalIdentity, nationalIdentity, "nationalIdentity");
    updateData(prevNationality, nationality, "nationality");
    updateData(prevParentalEducation, parentalEducation, "parentalEducation");
    updateData(prevPersonIdentifier, personIdentifier, "personIdentifier");
    updateData(prevReligion, religion, "religion");
    updateData(prevReligiousBackground, religiousBackground, "religiousBackground");
    updateData(prevServiceLeaver, serviceLeaver, "serviceLeaver");
    updateData(prevSexualOrientation, sexualOrientation, "sexualOrientation");
    updateData(prevSocioEconomicClassification, socioEconomicClassification, "socioEconomicClassification");
    updateData(prevStandardOccupationalClassification, standardOccupationalClassification, "standardOccupationalClassification");
  }, [
    student,
    careLeaver,
    carer,
    contactPurpose,
    dependant,
    disability,
    engagement,
    studentRegistration,
    financialSupportScheme,
    leaver,
    entryQualificationAward,
    ethnicity,
    genderIdentity,
    languageProficiency,
    maritalStatus,
    nationalIdentity,
    nationality,
    parentalEducation,
    personIdentifier,
    religion,
    religiousBackground,
    serviceLeaver,
    sexualOrientation,
    socioEconomicClassification,
    standardOccupationalClassification
  ]);

  const subforms = [
    {
      name: "student",
      initialData: [data.student],
      groupsConfigs: {
        level0GroupConfig: {
          title: "Student",
          quantity: {
            min: 1,
            max: 1
          },
          inputs: [
            {
              name: "Student identifier",
              field: "student_identifier",
              type: "textInput",
              validations: ["required"]
            },
            {
              name: "Birthdate",
              field: "birthdate",
              type: "datePicker",
              validations: ["required"]
            },
            {
              name: "First names",
              field: "first_names",
              type: "textInput",
              validations: ["required"]
            },
            {
              name: "Previous surname",
              field: "previous_surname",
              type: "textInput",
              validations: []
            },
            {
              name: "Sex identifier",
              field: "sex_identifier",
              type: "select",
              options: hesaDetailOptions.student.sex_identifier,
              validations: ["required"]
            },
            {
              name: "Surname",
              field: "surname",
              type: "textInput",
              validations: ["required"]
            },
            {
              name: "Surname at 16",
              field: "surname_at_16",
              type: "textInput",
              validations: []
            },
            {
              name: "Title",
              field: "title",
              type: "select",
              options: hesaDetailOptions.student.title,
              validations: ["required"]
            }
          ]
        }
      }
    },
    {
      name: "care_leavers",
      initialData: data.careLeaver,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Care Leaver",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Care leaver type",
              field: "care_leaver_type_id",
              type: "select",
              options: hesaDetailOptions.care_leaver.care_leaver_type,
              validations: ["required"]
            },
            {
              name: "Care leaver valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Care leaver valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "carers",
      initialData: data.carer,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Carer",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Carer type",
              field: "carer_type_id",
              type: "select",
              options: hesaDetailOptions.carer.carer_type,
              validations: ["required"]
            },
            {
              name: "Carer valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Carer valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "contact_purposes",
      initialData: data.contactPurpose,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Contact Purpose",
          quantity: {
            min: 0,
            max: 3
          },
          inputs: [
            {
              name: "Contact type",
              field: "contact_purpose_type_id",
              type: "select",
              options: hesaDetailOptions.contact_purpose.contact_type,
              validations: ["required"]
            }
          ]
        },
        level1GroupsConfigs: [
          {
            title: "Person address",
            field: "person_addresses",
            quantity: {
              min: 1,
              max: Infinity
            },
            inputs: [
              {
                name: "Address identifier",
                field: "address_id",
                type: "textInput",
                validations: ["required"]
              },
              {
                name: "Term time accommodation type",
                field: "accommodation_type_id",
                type: "select",
                options: hesaDetailOptions.contact_purpose.person_address.term_time_accommodation_type,
                validations: []
              },
              {
                name: "Country identifier",
                field: "country_id",
                type: "dataList",
                options: hesaDetailOptions.contact_purpose.person_address.country_identifier,
                validations: []
              },
              {
                name: "Email",
                field: "email",
                type: "textInput",
                validations: ["email"]
              },
              {
                name: "Postcode",
                field: "postcode",
                type: "textInput",
                validations: []
              },
              {
                name: "Address valid from date",
                field: "valid_from",
                type: "datePicker",
                validations: []
              },
              {
                name: "Address valid to_date",
                field: "valid_to",
                type: "datePicker",
                validations: []
              }
            ]
          }
        ]
      }
    },
    {
      name: "dependants",
      initialData: data.dependant,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Dependant",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Dependant type",
              field: "dependant_type_id",
              type: "select",
              options: hesaDetailOptions.dependant.dependant_type,
              validations: ["required"]
            },
            {
              name: "Dependant valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Dependant valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "disabilities",
      initialData: data.disability,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Disability",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Disability type",
              field: "disability_type_id",
              type: "select",
              options: hesaDetailOptions.disability.disability_type,
              validations: ["required"]
            },
            {
              name: "Disability valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Disability valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "engagements",
      initialData: data.engagement,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Engagement",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Engagement number",
              field: "engagement_number",
              type: "textInput",
              validations: ["required"]
            },
            {
              name: "Access programme",
              field: "access_programme_id",
              type: "select",
              options: hesaDetailOptions.engagement.access_programme,
              validations: ["required"]
            },
            {
              name: "Highest qualification on entry",
              field: "highest_qualification_on_entry_id",
              type: "select",
              options: hesaDetailOptions.engagement.highest_qualification_on_entry,
              validations: ["required"]
            },
            {
              name: "Intended end date of engagement",
              field: "intended_end_date_of_engagement",
              type: "datePicker",
              validations: ["required"]
            },
            {
              name: "Previous provider",
              field: "previous_provider_id",
              type: "select",
              options: hesaDetailOptions.engagement.previous_provider,
              validations: ["required"]
            },
            {
              name: "Study intention",
              field: "study_intention_id",
              type: "select",
              options: hesaDetailOptions.engagement.study_intention,
              validations: ["required"]
            },
            {
              name: "Year left last provider",
              field: "year_left_last_provider",
              type: "textInput",
              validations: ["required"],
              restriction: "digits",
              maxLength: 4
            },
            {
              name: "Z: Permanent address region on entry 4-way split",
              field: "z_permanent_address_region_on_entry_fourth_way_id",
              type: "select",
              options: hesaDetailOptions.engagement.z_permanent_address_region_on_entry_4_way_split,
              validations: ["required"]
            },
            {
              name: "Z: Permanent address region on entry",
              field: "z_permanent_address_region_on_entry_id",
              type: "select",
              options: hesaDetailOptions.engagement.z_permanent_address_region_on_entry,
              validations: ["required"]
            },
            {
              name: "Z: Engagement first enrolment date",
              field: "z_engagement_first_enrolment_date",
              type: "datePicker",
              validations: ["required"]
            }
          ]
        }
      }
    },
    {
      name: "student_registrations",
      initialData: data.studentRegistration,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Student Registration",
          quantity: {
            min: 0,
            max: engagement.length ? Infinity : 0
          },
          inputs: [
            {
              name: "Engagement number",
              field: "engagement_number",
              type: "select",
              options: engagement.map(engagement => ({ value: engagement.id, label: engagement.engagement_number })),
              validations: ["required"]
            },
            {
              name: "Student registration number",
              field: "student_registration_number",
              type: "textInput",
              validations: ["required"]
            },
            // {
            //   name: "Student identifier",
            //   field: "field3",
            //   type: "textInput",
            //   validations: ["required"]
            // },
            {
              name: "Expected end date",
              field: "expected_end_date",
              type: "datePicker",
              validations: ["required"]
            },
            {
              name: "Fee eligibility",
              field: "fee_eligibility_id",
              type: "select",
              options: hesaDetailOptions.student_registration.fee_eligibility,
              validations: ["required"]
            },
            {
              name: "Fee status",
              field: "fee_status_id",
              type: "select",
              options: hesaDetailOptions.student_registration.fee_status,
              validations: ["required"]
            },
            {
              name: "Incoming exchange",
              field: "incoming_exchange_id",
              type: "select",
              options: hesaDetailOptions.student_registration.incoming_exchange,
              validations: []
            },
            {
              name: "Student registration start date",
              field: "registration_start_date",
              type: "datePicker",
              validations: ["required"]
            },
            {
              name: "Z: Age at registration",
              field: "z_age_registration_id",
              type: "select",
              options: hesaDetailOptions.student_registration.z_age_at_registration,
              validations: ["required"]
            },
            {
              name: "Z: Student registration population",
              field: "z_registration_population_id",
              type: "select",
              options: hesaDetailOptions.student_registration.z_student_registration_population,
              validations: ["required"]
            }
          ]
        },
        level1GroupsConfigs: [
          {
            title: "Qualification awarded",
            field: "qualification_awardeds",
            quantity: {
              min: 0,
              max: Infinity
            },
            inputs: [
              {
                name: "Qualification awarded identifier",
                field: "qualification_awarded_identifier",
                type: "textInput",
                validations: ["required"],
                restriction: "digits"
              },
              {
                name: "Qualification award date",
                field: "qualification_awarded_date",
                type: "datePicker",
                validations: ["required"]
              },
              {
                name: "Qualification identifier",
                field: "qualification_id",
                type: "dataListAsync",
                fetchLabelHandler: qualificationId => fetchQualificationLabel(qualificationId),
                fetchOptionsHandler: qualificationTitle => fetchQualificationOptions(qualificationTitle),
                validations: ["required"]
              },
              {
                name: "Qualification result",
                field: "qualification_result_id",
                type: "select",
                options: hesaDetailOptions.student_registration.qualification_awarded.qualification_result,
                validations: ["required"]
              },
              {
                name: "Thesis title",
                field: "thesis_title",
                type: "textInput",
                validations: ["required"]
              }
            ]
          }
        ],
        level2GroupsConfigs: [
          {
            title: "Qualification award accreditation",
            field: "qualification_award_accreditations",
            quantity: {
              min: 0,
              max: Infinity
            },
            inputs: [
              {
                name: "Accreditation identifier",
                field: "accreditation_id",
                type: "select",
                options: hesaDetailOptions.student_registration.qualification_awarded.qualification_award_accreditation.accreditation_identifier,
                validations: ["required"]
              }
            ]
          }
        ]
      }
    },
    {
      name: "financial_support_schemes",
      initialData: data.financialSupportScheme,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Financial Support Scheme",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Financial support offer identifier",
              field: "financial_support_offer_id",
              type: "textInput",
              validations: ["required"]
            },
            {
              name: "Financial support type",
              field: "financial_support_type_id",
              type: "select",
              options: hesaDetailOptions.student_financial_support.financial_support_type,
              validations: ["required"]
            }
          ]
        },
        level1GroupsConfigs: [
          {
            title: "Financial Support Offer",
            field: "financial_support_offers",
            quantity: {
              min: 0,
              max: Infinity
            },
            inputs: [
              {
                name: "Financial support offer identifier",
                field: "financial_support_offer_id",
                type: "textInput",
                validations: ["required"]
              }
            ]
          }
        ],
        level2GroupsConfigs: [
          {
            title: "Student Financial Support",
            field: "student_financial_supports",
            quantity: {
              min: 0,
              max: Infinity
            },
            inputs: [
              {
                name: "Financial support identifier",
                field: "financial_support_id",
                type: "textInput",
                validations: ["required"]
              },
              {
                name: "Financial support amount",
                field: "financial_support_amount",
                type: "textInput",
                validations: [],
                restriction: "digits"
              },
              {
                name: "Student registration number",
                field: "student_registration_number",
                type: "select",
                options: studentRegistration.map(studentRegistration => ({
                  value: studentRegistration.id,
                  label: studentRegistration.student_registration_number
                })),
                validations: []
              },
              {
                name: "Student course session identifier",
                field: "student_course_session_id",
                type: "select",
                options: hesaDetailCourseSessionOptions,
                validations: []
              }
            ]
          }
        ]
      }
    },
    {
      name: "leavers",
      initialData: data.leaver,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Leaver",
          quantity: {
            min: 0,
            max: studentRegistration.length
          },
          inputs: [
            // {
            //   name: "Engagement number",
            //   field: "field1",
            //   type: "textInput",
            //   validations: ["required"]
            // },
            {
              name: "Student registration number",
              field: "student_registration_number",
              type: "select",
              options: studentRegistration.map(studentRegistration => ({
                value: studentRegistration.id,
                label: studentRegistration.student_registration_number
              })),
              // .filter(option => {
              //   const usedStudentRegistrationNumberIds = leaver.map( leaver => leaver.student_registration_number);
              //   // console.log(usedStudentRegistrationNumberIds);
              //   return !usedStudentRegistrationNumberIds.includes(option.value)
              // }),
              validations: ["required", "unique"]
            },
            // {
            //   name: "Student identifier",
            //   field: "field3",
            //   type: "textInput",
            //   validations: ["required"]
            // },
            {
              name: "Intended destination",
              field: "intended_destination",
              type: "textInput",
              validations: ["required"]
            },
            {
              name: "Leaver end date",
              field: "leaver_end_date",
              type: "datePicker",
              validations: ["required"]
            },
            {
              name: "Open engagement flag",
              field: "open_engagement_flag_id",
              type: "select",
              options: hesaDetailOptions.leaver.open_engagement_flag,
              validations: ["required"]
            },
            {
              name: "Reason for Student Registration ending",
              field: "reason_for_student_registration_id",
              type: "select",
              options: hesaDetailOptions.leaver.reason_for_student_registration_ending,
              validations: ["required"]
            }
          ]
        }
      }
    },
    {
      name: "entry_qualification_awards",
      initialData: data.entryQualificationAward,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Entry Qualification Award",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Entry qualification awarded identifier",
              field: "entry_qualification_award_id",
              type: "textInput",
              validations: ["required"]
            },
            {
              name: "Award date",
              field: "award_date",
              type: "datePicker",
              validations: ["required"]
            },
            {
              name: "Qualification result",
              field: "qualification_result_id",
              type: "select",
              options: hesaDetailOptions.entry_qualification_award.qualification_result,
              validations: ["required"]
            },
            {
              name: "Qualification type identifier",
              field: "qualification_type_id",
              type: "select",
              options: hesaDetailOptions.entry_qualification_award.qualification_type_identifier,
              validations: ["required"]
            }
          ]
        },
        level1GroupsConfigs: [
          {
            title: "Entry Qualification Subject",
            field: "entry_qualification_subjects",
            quantity: {
              min: 1,
              max: 5
            },
            inputs: [
              {
                name: "Subject identifier",
                field: "subject_identifier_id",
                type: "select",
                options: hesaDetailOptions.entry_qualification_award.entry_qualification_subject.subject_identifier,
                validations: ["required"]
              }
            ]
          }
        ]
      }
    },
    {
      name: "ethnicities",
      initialData: data.ethnicity,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Ethnicity",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Ethnicity type",
              field: "ethnicity_type_id",
              type: "select",
              options: hesaDetailOptions.ethnicity.ethnicity_type,
              validations: ["required"]
            },
            {
              name: "Ethnicity valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Ethnicity valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "gender_identities",
      initialData: data.genderIdentity,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Gender Identity",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Gender identity type",
              field: "gender_identity_type_id",
              type: "select",
              options: hesaDetailOptions.gender_identity.gender_identity_type,
              validations: ["required"]
            },
            {
              name: "Gender identity valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Gender identity valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "language_proficiencies",
      initialData: data.languageProficiency,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Language Proficiency",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Language identifier",
              field: "language_id",
              type: "select",
              options: hesaDetailOptions.language_proficiency.language_identifier,
              validations: ["required"]
            },
            {
              name: "Proficiency type",
              field: "proficiency_type_id",
              type: "select",
              options: hesaDetailOptions.language_proficiency.proficiency_type,
              validations: ["required"]
            },
            {
              name: "First or preferred language marker",
              field: "prelanguage",
              type: "select",
              options: hesaDetailOptions.language_proficiency.first_or_preferred_language_marker,
              validations: ["required"]
            },
            {
              name: "Level of proficiency",
              field: "proficiency_level_id",
              type: "select",
              options: hesaDetailOptions.language_proficiency.level_of_proficiency,
              validations: ["required"]
            }
          ]
        }
      }
    },
    {
      name: "marital_statuses",
      initialData: data.maritalStatus,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Marital Status",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Marital status type",
              field: "marital_status_type_id",
              type: "select",
              options: hesaDetailOptions.marital_status.marital_status_type,
              validations: ["required"]
            },
            {
              name: "Marital status valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Marital status valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "national_identities",
      initialData: data.nationalIdentity,
      groupsConfigs: {
        level0GroupConfig: {
          title: "National Identity",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "National identity type",
              field: "national_identity_type_id",
              type: "select",
              options: hesaDetailOptions.national_identity.national_identity_type,
              validations: ["required"]
            },
            {
              name: "National identity valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "National identity valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "nationalities",
      initialData: data.nationality,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Nationality",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Nationality type",
              field: "nationality_type_id",
              type: "select",
              options: hesaDetailOptions.nationality.nationality_type,
              validations: ["required"]
            },
            {
              name: "Nationality valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Nationality valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "parental_educations",
      initialData: data.parentalEducation,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Parental Education",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Parental education type",
              field: "parental_education_type_id",
              type: "select",
              options: hesaDetailOptions.parental_education.parental_education_type,
              validations: ["required"]
            },
            {
              name: "Parental education valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Parental education valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "person_identifiers",
      initialData: data.personIdentifier,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Person Identifier",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Identifier type code",
              field: "person_type_code_id",
              type: "select",
              options: hesaDetailOptions.person_identifier.identifier_type_code,
              validations: ["required"]
            },
            {
              name: "Personal identifier",
              field: "person_identifier",
              type: "textInput",
              validations: ["required"]
            }
          ]
        }
      }
    },
    {
      name: "religions",
      initialData: data.religion,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Religion",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Religion type",
              field: "religion_type_id",
              type: "select",
              options: hesaDetailOptions.religion.religion_type,
              validations: ["required"]
            },
            {
              name: "Religion valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Religion valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "religious_backgrounds",
      initialData: data.religiousBackground,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Religious Background",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Religious background type",
              field: "religious_background_type_id",
              type: "select",
              options: hesaDetailOptions.religious_background.religious_background_type,
              validations: ["required"]
            },
            {
              name: "Religious backgound valid from",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Religious background valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "service_leavers",
      initialData: data.serviceLeaver,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Service Leaver",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Service leaver type",
              field: "service_leaver_type_id",
              type: "select",
              options: hesaDetailOptions.service_leaver.service_leaver_type,
              validations: ["required"]
            },
            {
              name: "Service leaver valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Service leaver valid to",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "sexual_orientations",
      initialData: data.sexualOrientation,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Sexual Orientation",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Sexual orientation type",
              field: "sexual_orientation_type_id",
              type: "select",
              options: hesaDetailOptions.sexual_orientation.sexual_orientation_type,
              validations: ["required"]
            },
            {
              name: "Sexual orientation valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "Sexual orientation valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "socio_economic_classifications",
      initialData: data.socioEconomicClassification,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Socio Economic Classification",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Socio economic classification",
              field: "socio_economic_classification_type_id",
              type: "select",
              options: hesaDetailOptions.socio_economic_classification.socio_economic_classification,
              validations: ["required"]
            },
            {
              name: "SEC valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "SEC valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    },
    {
      name: "standard_occupational_classifications",
      initialData: data.standardOccupationalClassification,
      groupsConfigs: {
        level0GroupConfig: {
          title: "Standard Occupational Classification",
          quantity: {
            min: 0,
            max: Infinity
          },
          inputs: [
            {
              name: "Standard occupational classification 2010",
              field: "standard_occupational_classification_type_id",
              type: "select",
              options: hesaDetailOptions.standard_occupational_classification.standard_occupational_classification_2010,
              validations: ["required"]
            },
            {
              name: "SOC2010 valid from date",
              field: "valid_from",
              type: "datePicker",
              validations: []
            },
            {
              name: "SOC2010 valid to date",
              field: "valid_to",
              type: "datePicker",
              validations: []
            }
          ]
        }
      }
    }
  ];

  const fetchQualificationLabel = async qualificationId => {
    const { data } = await fetchQualificationHandler(qualificationId);
    return data.data.qualification_title;
  };

  const fetchQualificationOptions = async qualificationTitle => {
    const { data } = await fetchQualificationsByTitleHandler(qualificationTitle);
    return data.data.map(option => ({ value: option.id, label: option.qualification_title }));
  };

  useEffect(() => {
    setIsEditable(false);
  }, [activeSnapshotId]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <SectionTitle title="HESA Details" />
        <div className={classes.edit_wrapper}>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.filters}>
        {filters.map((filter, i) => (
          <div
            className={`${classes.filter} ${activeFilterName === filter.name ? classes.active : ""}`}
            onClick={() => setActiveFilterName(filter.name)}
            key={i}
          >
            {filter.name}
          </div>
        ))}
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.subforms}>
          {subforms
            .filter(subform => filters.find(filter => filter.name === activeFilterName).subformNames.includes(subform.name))
            .map(subform => (
              <Subform
                name={subform.name}
                initialData={subform.initialData}
                isEditable={isEditable}
                groupsConfigs={subform.groupsConfigs}
                saveHandler={(data, deletionsLog, callback) => saveHesaDetailForm(subform.name, data, deletionsLog, callback)}
                key={subform.name}
              />
            ))}
        </div>
        {!!snapshots.length && (
          <div className={classes.snapshots}>
            <div className={classes.title}>Snapshots:</div>
            {snapshots.map((snapshot, i) => (
              <div
                className={`${classes.snapshot} ${snapshot.id === activeSnapshotId ? classes.active : ""}`}
                onClick={() => clickSnapshotHandler(snapshot.id)}
                key={i}
              >
                {localLongDate(snapshot.created_at.date)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const arrayOfValueLabelShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  })
);

ContactsHesaDetailForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  student: PropTypes.shape({
    student_identifier: PropTypes.string,
    birthdate: PropTypes.string,
    first_names: PropTypes.string,
    previous_surname: PropTypes.string,
    sex_identifier: PropTypes.number,
    surname: PropTypes.string,
    surname_at_16: PropTypes.string,
    title: PropTypes.number
  }),
  careLeaver: PropTypes.arrayOf(
    PropTypes.shape({
      care_leaver_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  carer: PropTypes.arrayOf(
    PropTypes.shape({
      carer_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  contactPurpose: PropTypes.arrayOf(
    PropTypes.shape({
      contact_purpose_type_id: PropTypes.number,
      person_addresses: PropTypes.arrayOf(
        PropTypes.shape({
          address_id: PropTypes.string,
          accommodation_type_id: PropTypes.number,
          country_id: PropTypes.number,
          email: PropTypes.string,
          postcode: PropTypes.string,
          valid_from: PropTypes.string,
          valid_to: PropTypes.string
        })
      )
    })
  ),
  dependant: PropTypes.arrayOf(
    PropTypes.shape({
      dependant_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  disability: PropTypes.arrayOf(
    PropTypes.shape({
      dependant_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  engagement: PropTypes.arrayOf(
    PropTypes.shape({
      engagement_number: PropTypes.string,
      access_programme_id: PropTypes.number,
      highest_qualification_on_entry_id: PropTypes.number,
      intended_end_date_of_engagement: PropTypes.string,
      previous_provider_id: PropTypes.number,
      study_intention_id: PropTypes.number,
      year_left_last_provider: PropTypes.string,
      z_permanent_address_region_on_entry_fourth_way_id: PropTypes.number,
      z_permanent_address_region_on_entry_id: PropTypes.number,
      z_engagement_first_enrolment_date: PropTypes.string
    })
  ),
  studentRegistration: PropTypes.arrayOf(
    PropTypes.shape({
      engagement_number: PropTypes.number,
      student_registration_number: PropTypes.string,
      expected_end_date: PropTypes.string,
      fee_eligibility_id: PropTypes.number,
      fee_status_id: PropTypes.number,
      incoming_exchange_id: PropTypes.number,
      registration_start_date: PropTypes.string,
      z_age_registration_id: PropTypes.number,
      z_registration_population_id: PropTypes.number,
      qualification_awardeds: PropTypes.arrayOf(
        PropTypes.shape({
          qualification_awarded_identifier: PropTypes.string,
          qualification_awarded_date: PropTypes.string,
          qualification_id: PropTypes.number,
          qualification_result_id: PropTypes.number,
          thesis_title: PropTypes.string,
          qualifications_awarded: PropTypes.arrayOf(
            PropTypes.shape({
              accreditation_id: PropTypes.number
            })
          )
        })
      )
    })
  ),
  financialSupportScheme: PropTypes.arrayOf(
    PropTypes.shape({
      financial_support_offer_id: PropTypes.string,
      financial_support_type_id: PropTypes.number,
      financial_support_offers: PropTypes.arrayOf(
        PropTypes.shape({
          financial_support_offer_id: PropTypes.string,
          student_financial_supports: PropTypes.arrayOf(
            PropTypes.shape({
              financial_support_id: PropTypes.string,
              financial_support_amount: PropTypes.string,
              student_registration_number: PropTypes.string,
              student_course_session_id: PropTypes.number
            })
          )
        })
      )
    })
  ),
  leaver: PropTypes.arrayOf(
    PropTypes.shape({
      student_registration_number: PropTypes.number,
      intended_destination: PropTypes.string,
      leaver_end_date: PropTypes.string,
      open_engagement_flag_id: PropTypes.number,
      reason_for_student_registration_id: PropTypes.number
    })
  ),
  entryQualificationAward: PropTypes.arrayOf(
    PropTypes.shape({
      entry_qualification_award_id: PropTypes.string,
      award_date: PropTypes.string,
      qualification_result_id: PropTypes.number,
      qualification_type_id: PropTypes.number,
      entry_qualification_subjects: PropTypes.arrayOf(
        PropTypes.shape({
          subject_identifier_id: PropTypes.number
        })
      )
    })
  ),
  ethnicity: PropTypes.arrayOf(
    PropTypes.shape({
      ethnicity_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  genderIdentity: PropTypes.arrayOf(
    PropTypes.shape({
      gender_identity_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  languageProficiency: PropTypes.arrayOf(
    PropTypes.shape({
      language_id: PropTypes.number,
      proficiency_type_id: PropTypes.number,
      prelanguage: PropTypes.number,
      proficiency_level_id: PropTypes.number
    })
  ),
  maritalStatus: PropTypes.arrayOf(
    PropTypes.shape({
      marital_status_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  nationalIdentity: PropTypes.arrayOf(
    PropTypes.shape({
      national_identity_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  nationality: PropTypes.arrayOf(
    PropTypes.shape({
      nationality_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  parentalEducation: PropTypes.arrayOf(
    PropTypes.shape({
      parental_education_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  personIdentifier: PropTypes.arrayOf(
    PropTypes.shape({
      person_type_code_id: PropTypes.number,
      person_identifier: PropTypes.string
    })
  ),
  religion: PropTypes.arrayOf(
    PropTypes.shape({
      religion_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  religiousBackground: PropTypes.arrayOf(
    PropTypes.shape({
      religious_background_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  serviceLeaver: PropTypes.arrayOf(
    PropTypes.shape({
      service_leaver_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  sexualOrientation: PropTypes.arrayOf(
    PropTypes.shape({
      sexual_orientation_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  socioEconomicClassification: PropTypes.arrayOf(
    PropTypes.shape({
      socio_economic_classification_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  standardOccupationalClassification: PropTypes.arrayOf(
    PropTypes.shape({
      standard_occupational_classification_type_id: PropTypes.number,
      valid_from: PropTypes.string,
      valid_to: PropTypes.string
    })
  ),
  hesaDetailOptions: PropTypes.shape({
    student: PropTypes.shape({
      sex_identifier: arrayOfValueLabelShape,
      title: arrayOfValueLabelShape
    }),
    care_leaver: PropTypes.shape({
      care_leaver_type: arrayOfValueLabelShape
    }),
    carer: PropTypes.shape({
      carer_type: arrayOfValueLabelShape
    }),
    contact_purpose: PropTypes.shape({
      contact_type: arrayOfValueLabelShape,
      person_addresses: PropTypes.shape({
        term_time_accommodation_type: arrayOfValueLabelShape,
        country_identifier: arrayOfValueLabelShape
      })
    }),
    dependant: PropTypes.shape({
      dependant_type: arrayOfValueLabelShape
    }),
    disability: PropTypes.shape({
      disability: arrayOfValueLabelShape
    }),
    engagement: PropTypes.shape({
      access_programme: arrayOfValueLabelShape,
      highest_qualification_on_entry: arrayOfValueLabelShape,
      previous_provider: arrayOfValueLabelShape,
      study_intention: arrayOfValueLabelShape,
      z_permanent_address_region_on_entry_4_way_split: arrayOfValueLabelShape,
      z_permanent_address_region_on_entry: arrayOfValueLabelShape
    }),
    student_registration: PropTypes.shape({
      fee_eligibility: arrayOfValueLabelShape,
      fee_status: arrayOfValueLabelShape,
      incoming_exchange: arrayOfValueLabelShape,
      z_age_at_registration: arrayOfValueLabelShape,
      z_student_registration_population: arrayOfValueLabelShape,
      qualification_awarded: PropTypes.shape({
        qualification_result: arrayOfValueLabelShape,
        qualification_award_accreditation: PropTypes.shape({
          accreditation_identifier: arrayOfValueLabelShape
        })
      })
    }),
    leaver: PropTypes.shape({
      open_engagement_flag: arrayOfValueLabelShape,
      reason_for_student_registration_ending: arrayOfValueLabelShape
    }),
    entry_qualification_award: PropTypes.shape({
      qualification_result: arrayOfValueLabelShape,
      qualification_type_identifier: arrayOfValueLabelShape,
      entry_qualification_subject: PropTypes.shape({
        subject_identifier: arrayOfValueLabelShape
      })
    }),
    ethnicity: PropTypes.shape({
      ethnicity_type: arrayOfValueLabelShape
    }),
    gender_identity: PropTypes.shape({
      gender_identity_type: arrayOfValueLabelShape
    }),
    language_proficiency: PropTypes.shape({
      language_identifier: arrayOfValueLabelShape,
      proficiency_type: arrayOfValueLabelShape,
      first_or_preferred_language_marker: arrayOfValueLabelShape,
      level_of_proficiency: arrayOfValueLabelShape
    }),
    marital_status: PropTypes.shape({
      marital_status_type: arrayOfValueLabelShape
    }),
    national_identity: PropTypes.shape({
      national_identity_type: arrayOfValueLabelShape
    }),
    nationality: PropTypes.shape({
      nationality_type: arrayOfValueLabelShape
    }),
    parental_education: PropTypes.shape({
      parental_education_type: arrayOfValueLabelShape
    }),
    person_identifier: PropTypes.shape({
      identifier_type_code: arrayOfValueLabelShape
    }),
    religion: PropTypes.shape({
      religion_type: arrayOfValueLabelShape
    }),
    religious_background: PropTypes.shape({
      religious_background_type: arrayOfValueLabelShape
    }),
    service_leaver: PropTypes.shape({
      service_leaver_type: arrayOfValueLabelShape
    }),
    sexual_orientation: PropTypes.shape({
      sexual_orientation_type: arrayOfValueLabelShape
    }),
    socio_economic_classification: PropTypes.shape({
      socio_economic_classification: arrayOfValueLabelShape
    }),
    standard_occupational_classification: PropTypes.shape({
      standard_occupational_classification_2010: arrayOfValueLabelShape
    })
  }),
  hesaDetailCourseSessionOptions: arrayOfValueLabelShape,
  saveHesaDetailForm: PropTypes.func,
  fetchQualificationsByTitleHandler: PropTypes.func,
  fetchQualificationHandler: PropTypes.func,
  snapshots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      created_at: PropTypes.object
    })
  ),
  activeSnapshotId: PropTypes.number,
  clickSnapshotHandler: PropTypes.func
};

ContactsHesaDetailForm.defaultProps = {
  permissions: [],
  student: {
    student_identifier: null,
    birthdate: null,
    first_names: null,
    previous_surname: null,
    sex_identifier: null,
    surname: null,
    surname_at_16: null,
    title: null
  },
  careLeaver: [
    {
      care_leaver_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  carer: [
    {
      carer_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  contactPurpose: [
    {
      contact_purpose_type_id: null,
      person_addresses: [
        {
          address_id: null,
          accommodation_type_id: null,
          country_id: null,
          email: null,
          postcode: null,
          valid_from: null,
          valid_to: null
        }
      ]
    }
  ],
  dependant: [
    {
      dependant_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  disability: [
    {
      dependant_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  engagement: [
    {
      engagement_number: null,
      access_programme_id: null,
      highest_qualification_on_entry_id: null,
      intended_end_date_of_engagement: null,
      previous_provider_id: null,
      study_intention_id: null,
      year_left_last_provider: null,
      z_permanent_address_region_on_entry_fourth_way_id: null,
      z_permanent_address_region_on_entry_id: null,
      z_engagement_first_enrolment_date: null
    }
  ],
  studentRegistration: [
    {
      engagement_number: null,
      student_registration_number: null,
      expected_end_date: null,
      fee_eligibility_id: null,
      fee_status_id: null,
      incoming_exchange_id: null,
      registration_start_date: null,
      z_age_registration_id: null,
      z_registration_population_id: null,
      qualification_awardeds: [
        {
          qualification_awarded_identifier: null,
          qualification_awarded_date: null,
          qualification_id: null,
          qualification_result_id: null,
          thesis_title: null,
          qualification_award_accreditations: [
            {
              accreditation_id: null
            }
          ]
        }
      ]
    }
  ],
  financialSupportScheme: [],
  leaver: [
    {
      student_registration_number: null,
      intended_destination: null,
      leaver_end_date: null,
      open_engagement_flag_id: null,
      reason_for_student_registration_id: null
    }
  ],
  entryQualificationAward: [
    {
      entry_qualification_award_id: null,
      award_date: null,
      qualification_result_id: null,
      qualification_type_id: null,
      entry_qualification_subjects: [
        {
          subject_identifier_id: null
        }
      ]
    }
  ],
  ethnicity: [
    {
      ethnicity_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  genderIdentity: [
    {
      gender_identity_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  languageProficiency: [
    {
      language_id: null,
      proficiency_type_id: null,
      prelanguage: null,
      proficiency_level_id: null
    }
  ],
  maritalStatus: [
    {
      marital_status_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  nationalIdentity: [
    {
      national_identity_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  nationality: [
    {
      nationality_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  parentalEducation: [
    {
      parental_education_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  personIdentifier: [
    {
      person_type_code_id: null,
      person_identifier: null
    }
  ],
  religion: [
    {
      religion_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  religiousBackground: [
    {
      religious_background_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  serviceLeaver: [
    {
      service_leaver_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  sexualOrientation: [
    {
      sexual_orientation_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  socioEconomicClassification: [
    {
      socio_economic_classification_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  standardOccupationalClassification: [
    {
      standard_occupational_classification_type_id: null,
      valid_from: null,
      valid_to: null
    }
  ],
  hesaDetailOptions: null,
  hesaDetailCourseSessionOptions: [],
  saveHesaDetailForm: () => {},
  fetchQualificationsByTitleHandler: () => {},
  fetchQualificationHandler: () => {},
  snapshots: [],
  activeSnapshotId: null,
  clickSnapshotHandler: () => {}
};
