import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";

export const SectionTabs = ({ style, tabs, activeTabName, clickHandler }) => (
  <div className={classes.SectionTabs} style={style}>
    {tabs.map((tab, i) => (
      <div
        className={`${classes.tab} 
                    ${tab.name === activeTabName ? classes.active : ""}
                    ${tab.isShifted ? classes.shifted : ""}`}
        onClick={() => clickHandler(tab.name)}
        key={i}
      >
        {tab.name}
      </div>
    ))}
  </div>
);

SectionTabs.propTypes = {
  style: PropTypes.object,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      isShifted: PropTypes.bool
    })
  ),
  activeTabName: PropTypes.string,
  clickHandler: PropTypes.func
};

SectionTabs.defaultProps = {
  style: {},
  tabs: [],
  activeTabName: null,
  clickHandler: () => {}
};
