import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";

import { EditButton, SectionTitle, FileInput, CheckboxInput, MultiDataListAsync, TextArea, Select, TextInput, InputLabel } from "@Root/components";
import { SubAddressesFormForFields } from "../ContactsPersonForm/SubAddressesFormForFields";
import { generateId, emailIsValid } from "@Root/helpers";
const firstAddress = [
  {
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    city: "",
    postcode: "",
    country_id: "",
    county: "",
    address_type: "",
    other_address_type: "",
    current_address: false,
    id: generateId()
  }
];
export class ContactsOrganisationForm extends Component {
  typeOptions = [
    {
      value: "learning_venue",
      label: "Learning Venue"
    },
    {
      value: "member_institution",
      label: "Member Institution"
    },
    {
      value: "both",
      label: "Both"
    }
  ];
  appealOptions = ["Academic programme", "Faculty", "Location", "Other", "The community", "Theological position", "Vision", "Worship style"];
  mocsOptions = ["Email", "Phone", "Post", "Social media"];

  constructor(props) {
    super(props);
    const { initialIsEditable, initialData } = props;
    const currentAddressId = initialData.addresses?.find(address => address.current_address)?.id;
    const reservedAddressId = initialData.addresses ? initialData.addresses[0]?.id : firstAddress[0]?.id;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      photoFile: null,

      photo: initialData.photo,
      name: initialData.name,
      contactName: initialData.contact_name,
      email: initialData.email,
      second_email: initialData.second_email,
      relationshipId: initialData.relationship_id, // integer
      type: initialData.type, // enum "yes", "no", "both",
      telPrimary: initialData.tel_primary,
      telSecondary: initialData.tel_secondary,
      telMobile: initialData.tel_mobile,
      fax: initialData.fax,
      comment: initialData.comment,
      contacts_ids: initialData.contacts_ids,
      trashed: initialData.trashed,
      addresses: initialData.addresses || firstAddress,
      openedItemId: currentAddressId || reservedAddressId,

      poc: "",
      appeal: [],
      mocs: []
    };
  }

  formIsValid = () => {
    const { name, contactName, email, relationshipId } = this.state;
    if (!name) {
      this.showError("name", "Required");
      return false;
    } else if (!contactName) {
      this.showError("contact_name", "Required");
      return false;
    } else if (!email) {
      this.showError("email", "Required");
      return false;
    } else if (!emailIsValid(email)) {
      this.showError("email", "Invalid email");
      return false;
    } else if (!relationshipId) {
      this.showError("relationship_id", "Required");
      return false;
    } else {
      return true;
    }
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  setInitialState = () => {
    const { initialData } = this.props;
    const currentAddressId = initialData.addresses?.find(address => address.current_address)?.id;
    const reservedAddressId = initialData.addresses ? initialData.addresses[0]?.id : firstAddress[0]?.id;
    this.setState({
      isEditable: false,
      error: null,
      photoFile: null,

      photo: initialData.photo,
      name: initialData.name,
      contactName: initialData.contact_name,
      email: initialData.email,
      second_email: initialData.second_email,
      relationshipId: initialData.relationship_id, // integer
      type: initialData.type,
      telPrimary: initialData.tel_primary,
      telSecondary: initialData.tel_secondary,
      telMobile: initialData.tel_mobile,
      fax: initialData.fax,
      comment: initialData.comment,
      contacts_ids: initialData.contacts_ids,
      trashed: initialData.trashed,
      addresses: initialData.addresses || firstAddress,
      openedItemId: currentAddressId || reservedAddressId,

      poc: "",
      appeal: [],
      mocs: []
    });
  };

  fetchContactLabel = async value => {
    const { data } = await this.props.fetchContactHandler({ search: `id:${value}`, limit: "1" });
    return data.data[0].name;
  };

  fetchContactOptions = async value => {
    const { data } = await this.props.fetchContactHandler({ search: `name:${value};trashed:No`, searchJoin: "and", limit: "100" });
    return data.data.map(option => ({ value: option.id, label: option.name }));
  };

  handleClickCancelButton = () => {
    this.setInitialState();
  };

  validateAddresses = () => {
    return this.state.addresses.map(address => (typeof address.id !== "number" ? { ...address, id: null } : address));
  };

  changeOpenTab = id => {
    this.setState(prevState => ({ ...prevState, openedItemId: id }));
  };

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  };

  handlerChangeAddress = (id, field, value) => {
    this.setState(prevState => ({ ...prevState, addresses: [{ ...prevState.addresses[0], [field]: value }] }));
  };

  validateAddresses = () => {
    return this.state.addresses
      .map(address => (typeof address.id !== "number" ? { ...address, id: "" } : address))
      .map(address => (!address.address_type ? { ...address, address_type: "" } : address));
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { state } = this;
    const { initialData, errorHandler } = this.props;
    this.setState({ isSaving: true });
    const photoWasDeleted = initialData.photo !== null && this.state.photo === null;
    try {
      await this.props.saveHandler(
        {
          photo: state.photoFile || "",
          name: state.name,
          contact_name: state.contactName,
          email: state.email,
          second_email: state.second_email || "",
          relationship_id: state.relationshipId,
          type: state.type || "",
          tel_primary: state.telPrimary || "",
          tel_secondary: state.telSecondary || "",
          tel_mobile: state.telMobile || "",
          fax: state.fax || "",
          comment: state.comment || "",
          contacts_ids: state.contacts_ids || "",
          trashed: state.trashed,
          addresses: this.validateAddresses()
        },
        photoWasDeleted
      );
      !this.isUnmounted && this.setState({ isEditable: false });
    } catch (error) {
      try {
        const [errorName, errorMessages] = Object.entries(error.response.data.errors)[0];
        this.showError(errorName, errorMessages[0]);
      } catch (err) {
        errorHandler(error);
      }
    }
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.initialData.relationship_id !== this.props.initialData.relationship_id) {
      this.setState({ relationshipId: this.props.initialData.relationship_id });
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const {
      isEditable,
      isSaving,
      photoFile,
      photo,
      name,
      contactName,
      email,
      second_email,
      relationshipId,
      type,
      telPrimary,
      telSecondary,
      telMobile,
      fax,
      comment,
      contacts_ids,
      trashed,
      openedItemId,
      addresses
    } = this.state;
    const { permissions, title, contactIsSaving, countriesDataList, organisationRelationshipsDataList, buttons, contactAddressesType, isNew } = this.props;
    const { typeOptions, fetchContactLabel, fetchContactOptions, handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage } = this;

    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;
    return (
      <div className={classes.ContactsOrganisationForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this contact" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={contactIsSaving || isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            <div>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}</div>
          </div>
          <div className={classes.contentWrapper}>
            <FileInput
              inputClassNames={!isEditable ? ["borderless"] : []}
              value={photo}
              file={photoFile}
              changeHandler={(photo, photoFile) => this.setState({ photo, photoFile })}
              removeHandler={() => this.setState({ photo: null, photoFile: null })}
              isDisabled={!isEditable}
              restrictions={["image"]}
              error={errorMessage("photo")}
            />
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Name" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={name}
                  changeHandler={name => this.setState({ name })}
                  isDisabled={!isEditable}
                  error={errorMessage("name")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Contact Name" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={contactName}
                  changeHandler={contactName => this.setState({ contactName })}
                  isDisabled={!isEditable}
                  error={errorMessage("contact_name")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Email" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={email}
                  changeHandler={email => this.setState({ email })}
                  isDisabled={!isEditable}
                  error={errorMessage("email")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Second Email" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={second_email}
                  changeHandler={second_email => this.setState({ second_email })}
                  error={errorMessage("second_email")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Relationship" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={organisationRelationshipsDataList}
                  value={relationshipId}
                  changeHandler={relationshipId =>
                    this.setState({ relationshipId }, () => {
                      const { relationshipId } = this.state;
                      this.setState({ type: relationshipId === 10 ? "no" : null });
                    })
                  }
                  isDisabled={!isEditable}
                  error={errorMessage("relationship_id")}
                />
              </div>
              {isEditable && !isNew && (
                <div className={classes.inputWrapper}>
                  <InputLabel text="Trashed" />
                  <CheckboxInput style={{ height: 38 }} isChecked={trashed} changeHandler={trashed => this.setState({ trashed })} isDisabled={!isEditable} />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <InputLabel text="Type" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={typeOptions}
                  value={type}
                  changeHandler={value => this.setState({ type: value })}
                  isDisabled={!isEditable}
                  error={errorMessage("type")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Tel - Primary" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={telPrimary}
                  changeHandler={telPrimary => this.setState({ telPrimary })}
                  restriction="digits"
                  isDisabled={!isEditable}
                  error={errorMessage("tel_primary")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Tel - Secondary" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={telSecondary}
                  changeHandler={telSecondary => this.setState({ telSecondary })}
                  restriction="digits"
                  isDisabled={!isEditable}
                  error={errorMessage("tel_secondary")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Tel - Mobile" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={telMobile}
                  changeHandler={telMobile => this.setState({ telMobile })}
                  restriction="digits"
                  isDisabled={!isEditable}
                  error={errorMessage("tel_mobile")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Fax" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={fax}
                  changeHandler={fax => this.setState({ fax })}
                  isDisabled={!isEditable}
                  error={errorMessage("fax")}
                />
              </div>
              <div className={classes.noteWrapper}>
                <InputLabel text="Comment" />
                <TextArea
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={comment ? comment : ""}
                  changeHandler={comment => this.setState({ comment })}
                  isDisabled={!isEditable}
                  error={errorMessage("comment")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Contacts" />
                <MultiDataListAsync
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  values={contacts_ids}
                  fetchLabelHandler={value => fetchContactLabel(value)}
                  fetchOptionsHandler={value => fetchContactOptions(value)}
                  changeHandler={contacts_ids => this.setState({ contacts_ids })}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
            <div className={classes.columnWrapper} style={{ width: "400px" }}>
              <SubAddressesFormForFields
                countryOptions={countriesDataList}
                contactAddressesType={contactAddressesType}
                changeHandler={this.handlerChangeAddress}
                openedId={openedItemId}
                changeOpenTab={this.changeOpenTab}
                errorMessage={errorMessage}
                isEditable={isEditable}
                addresses={addresses}
                onCreateNewAddressField={this.onCreateNewAddressField}
                onRemoveAddressField={this.onRemoveAddressField}
                error={errorMessage("addresses")}
              />
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

ContactsOrganisationForm.propTypes = {
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  contactIsSaving: PropTypes.bool,
  initialData: PropTypes.shape({
    photo: PropTypes.string,
    name: PropTypes.string,
    contact_name: PropTypes.string,
    email: PropTypes.string,
    relationship_id: PropTypes.number,
    type: PropTypes.oneOf(["learning_venue", "member_institution", "both"]),
    tel_primary: PropTypes.string,
    tel_secondary: PropTypes.string,
    tel_mobile: PropTypes.string,
    fax: PropTypes.string,
    comment: PropTypes.string,
    contacts_ids: PropTypes.arrayOf(PropTypes.number),
    trashed: PropTypes.bool
  }),
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialIsEditable: PropTypes.bool,
  countriesDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  organisationRelationshipsDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  fetchContactHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

ContactsOrganisationForm.defaultProps = {
  title: {
    onRead: "",
    onEdit: ""
  },
  contactIsSaving: false,
  initialData: {
    photo: "",
    name: "",
    contact_name: "",
    email: "",
    relationship_id: null,
    type: null,
    address: "",
    tel_primary: "",
    tel_secondary: "",
    tel_mobile: "",
    fax: "",
    comment: "",
    contacts_ids: null,
    trashed: false
  },
  permissions: [],
  initialIsEditable: false,
  countriesDataList: [],
  organisationRelationshipsDataList: [],
  buttons: ["cancel", "delete", "save"],
  fetchContactHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
