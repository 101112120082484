import React, { useState, useEffect, useRef } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { all, errorMessage } from "@Root/helpers";
import { SectionWrapper, FormWrapper } from "@Root/HOCs";
import { EditButton, Spinner, SectionTitle, InputLabel, TextInput, Editor } from "@Root/components";

export const Template = ({ history, permissions, setSnackbar }) => {
  const [initialData, setInitialData] = useState({});
  const [variables, setVariables] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("<p></p>");
  const [isShown, setIsShown] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const editorRef = useRef();

  const readData = async () => {
    try {
      const { data } = await API.getAdminEmailsTemplate();
      if (Template.isMounted) {
        setInitialData({
          subject: data.data.subject,
          body: data.data.template_email
        });
        setVariables(
          Object.keys(data.data.variables_for_template).map(key => ({
            name: key,
            value: data.data.variables_for_template[key]
          }))
        );
        setSubject(data.data.subject);
        setBody(data.data.template_email);
      }
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const resetData = () => {
    setSubject(initialData.subject);
    setBody(initialData.body);
    editorRef.current.setEditorState(initialData.body);
  };

  const updateTemplate = async () => {
    try {
      setIsSpinning(true);
      await API.postAdminEmailsTemplate({
        subject,
        template_email: body
      });
      if (Template.isMounted) setIsEditable(false);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
    if (Template.isMounted) setIsSpinning(false);
  };

  useEffect(() => {
    Template.isMounted = true;
    (async () => {
      await readData();
      if (Template.isMounted) setIsShown(true);
    })();
    return () => {
      Template.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper>
          <FormWrapper
            buttons={isEditable ? ["cancel", "save"] : []}
            buttonsAreDisabled={isSpinning}
            isSpinning={isSpinning}
            clickCancelButtonHandler={() => all(() => resetData(), () => setIsEditable(false))}
            clickSaveButtonHandler={updateTemplate}
          >
            <div className={classes.title}>
              <SectionTitle title={isEditable ? "Edit Emails Template" : "Emails Template"} />
              {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}
            </div>
            <div className={classes.content}>
              <div className={classes.variables}>
                {variables.map((variable, i) => (
                  <div className={classes.variable} key={i}>
                    <div className={classes.variableName}>{variable.name}</div>
                    <div className={classes.variableValue}>{variable.value} </div>
                  </div>
                ))}
              </div>
              <div className={classes.template}>
                <InputLabel text="Subject" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ marginBottom: 20, width: 756 }}
                  value={subject}
                  changeHandler={value => setSubject(value)}
                  isDisabled={!isEditable}
                />
                <InputLabel text="Body" />
                <Editor className={classes.editor} ref={editorRef} value={body} changeHandler={value => setBody(value)} isDisabled={!isEditable} />
              </div>
            </div>
          </FormWrapper>
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => ({
  permissions: authReducer.user.permissions
});

const mapDispatchToProps = dispatch => ({
  setSnackbar: data => dispatch(actions.setSnackbar(data))
});

export const AdminEmailsTemplate = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Template)
);
