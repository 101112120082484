import React from "react";
import classes from "./AdminPage.module.scss";
import { connect } from "react-redux";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import DocumentTitle from "react-document-title";
import { PageTitle, PageNavLinks } from "@Root/components";
import { AdminTable } from "../AdminPage/AdminTable";
import { AdminNewUser } from "./AdminNewUser";
import { AdminUser } from "./AdminUser";
import { AdminRole } from "./AdminRole";
import { AdminInstitution } from "./AdminInstitution";
import { AdminSnapshot } from "./AdminSnapshot";
import { AdminQualification } from "./AdminQualification";
import { AdminVenue } from "./AdminVenue";
import { AdminStudentTypes } from "./AdminStudentTypes";
import { AdminApplicationFormInstructions } from "./AdminApplicationFormInstructions";
import { AdminEmailsTemplate } from "./AdminEmailsTemplate";
import { AdminPageTemplate } from "./AdminPageTemplate";
import { RoleManagerPage } from "./AdminUser/RoleManager";

const AdminPage = ({ location, permissions }) => {
  const links = [
    {
      title: "Manage system team",
      path: "manage-system-team",
      subLinks: [{ name: "System Users", path: "system-users" }, { name: "User Roles", path: "user-roles" }]
    },
    { title: "Snapshots", path: "snapshots", subLinks: [] },
    {
      title: "Application form",
      path: "application-form",
      subLinks: [
        { name: "Application Form Instructions", path: "application-form-instructions", isHidden: true },
        { name: "Emails", path: "emails", isHidden: true },
        { name: "Emails Template", path: "emails-template", isHidden: true }
      ]
    },
    {
      title: "Other",
      path: "other",
      subLinks: [
        { name: "Institutions", path: "institutions" },
        { name: "Qualifications", path: "qualifications" },
        { name: "Venues", path: "venues" },
        { name: "Student Types", path: "student-types", isHidden: true },
        { name: "Programme Types", path: "programme-types", isHidden: true },
        { name: "Statuses", path: "statuses", isHidden: true },
        { name: "Fee Statuses", path: "fee-statuses", isHidden: true },
        { name: "Awarding body", path: "awarding-body", isHidden: true },
        { name: "Session Year", path: "session-year", isHidden: true },
        { name: "Ordinand", path: "ordinand", isHidden: true }
      ]
    }
  ];
  const currentLinkPath = location.pathname.split("/")[3];
  const currentSubLinkPath = location.pathname.split("/")[4];
  const currentLink = links.find(link => link.path === currentLinkPath);
  const currentSubLink = currentLink?.subLinks.find(link => link.path === currentSubLinkPath);
  const hasCreatePermission = permissions.includes("create");
  return (
    <DocumentTitle title={`${currentSubLink?.name || currentLink?.title} - Admin`}>
      <div className={classes.wrapper}>
        <PageTitle title={currentSubLink?.name || currentLink?.title} />
        <PageNavLinks style={{ marginBottom: 30 }} root={`/home/admin`} links={links} subLinks currentLink={currentLink} />

        <Switch>
          {links.slice(0, 4).map(({ subLinks, path, isHidden }, i) => {
            {
              return subLinks.length > 0 && !isHidden ? (
                subLinks.map((subLink, i) => {
                  return (
                    !subLink?.isHidden && <Route exact path={`/home/admin/${path}/${subLink.path}`} component={AdminTable} key={`${path}/${subLink.path}`} />
                  );
                })
              ) : (
                <Route exact path={`/home/admin/${path}`} component={AdminTable} key={i} />
              );
            }
          })}
          {hasCreatePermission && <Route exact path="/home/admin/manage-system-team/system-users/new" component={AdminNewUser} />}
          <Route exact path="/home/admin/manage-system-team/system-users/:userId" component={AdminUser} />
          <Route exact path="/home/admin/manage-system-team/system-users/:userId/role-manager" component={RoleManagerPage} />
          <Route exact path="/home/admin/manage-system-team/user-roles/new" component={AdminRole} />
          <Route exact path="/home/admin/manage-system-team/user-roles/:roleId" component={AdminRole} />
          {!hasCreatePermission && <Redirect exact from="/home/admin/other/institutions/new" to="/home/admin/other/institutions" />}
          <Route exact path="/home/admin/other/institutions/:institutionId" component={AdminInstitution} />
          {!hasCreatePermission && <Redirect exact from="/home/admin/snapshots/new" to="/home/admin/snapshots" />}
          <Route exact path="/home/admin/snapshots/:snapshotId" component={AdminSnapshot} />
          {!hasCreatePermission && <Redirect exact from="/home/admin/other/qualifications/new" to="/home/admin/other/qualifications" />}
          <Route exact path="/home/admin/other/qualifications/:qualificationId" component={AdminQualification} />
          {!hasCreatePermission && <Redirect exact from="/home/admin/other/venues/new" to="/home/admin/other/venues" />}
          <Route exact path="/home/admin/other/venues/:venueId" component={AdminVenue} />
          <Route exact path="/home/admin/other/student-types" component={AdminStudentTypes} />
          <Route exact path="/home/admin/application-form/application-form-instructions" component={AdminApplicationFormInstructions} />
          <Route exact path="/home/admin/application-form/emails-template" component={AdminEmailsTemplate} />
          <Route exact path="/home/admin/other/:page" component={AdminPageTemplate} />
          <Route exact path="/home/admin/application-form/:page" component={AdminPageTemplate} />
          {currentLink && currentLink.subLinks.length > 0 && !currentSubLink && (
            <Redirect to={`/home/admin/${currentLink?.path}/${currentLink.subLinks[0]?.path}`} />
          )}
          <Redirect from={"/home/admin/"} to={"/home/admin/manage-system-team/system-users"} />
        </Switch>
      </div>
    </DocumentTitle>
  );
};

const mapStateToProps = ({ authReducer }) => ({
  permissions: authReducer.user.permissions
});

export default withRouter(connect(mapStateToProps)(AdminPage));
