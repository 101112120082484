import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { CustomScrollbar } from "@Root/HOCs";
import { tableHeight } from "@Root/helpers";
import { Spinner, CheckboxInput, Select, TextInput, DatePicker } from "@Root/components";
import { arrowIcon, UserIcon } from "@Root/assets";

export class TableData extends Component {
  state = {
    initialColumnWidth: 120
  };

  columnsWrapper = React.createRef();

  setInitialColumnWidth = () => {
    const { columns, hiddenColumnsNames } = this.props;
    const { columnsWrapper } = this;
    const wrapperWidth = columnsWrapper.current.clientWidth;
    const columnsQuantity = columns.filter(column => !hiddenColumnsNames.includes(column.name)).length;
    const columnWidth = (wrapperWidth - 32) / columnsQuantity;
    columnWidth > 120 && this.setState({ initialColumnWidth: columnWidth });
  };

  componentDidMount() {
    this.setInitialColumnWidth();
  }

  render() {
    const { initialColumnWidth } = this.state;
    const {
      columns,
      hiddenColumnsNames,
      data,
      dataIsFetching,
      checkedRowsIds,
      clickCheckboxHandler,
      clickCheckboxAllHandler,
      headersFilters,
      changeHeaderFilterHandler,
      visibleRowsQuantity,
      clickLinkHandlers = {},
      checkboxes = {},
      clickColumnCheckboxHandler = () => {},
      sorting = null,
      clickHeaderCellHandler = () => {},
      hasFilters = false,
      hasSorting = false
    } = this.props;
    const { columnsWrapper } = this;

    return (
      <div className={classes.TableData} style={{ height: tableHeight(visibleRowsQuantity, hasFilters) }}>
        <CustomScrollbar horizontalOnly>
          <div className={classes.columnsWrapper} ref={columnsWrapper}>
            <div className={classes.column} style={{ width: 32 }}>
              <div className={classes.headerCell}>
                <CheckboxInput
                  isChecked={!!data.length && data.every(row => checkedRowsIds.includes(row.id))}
                  changeHandler={() => clickCheckboxAllHandler(data.map(row => row.id))}
                />
              </div>
              {hasFilters && <div style={{ display: "none" }} />}
              {data.map((row, i) => (
                <div className={classes.cell} style={{ top: 46 * (i + 1 + hasFilters) }} key={i}>
                  <CheckboxInput isChecked={checkedRowsIds.includes(row.id)} changeHandler={() => clickCheckboxHandler(row.id)} />
                </div>
              ))}
            </div>
            {columns.map(column => (
              <div className={classes.column} style={hiddenColumnsNames.includes(column.name) ? { display: "none" } : null} key={column.name}>
                <div className={classes.headerCell} style={{ width: initialColumnWidth, resize: "horizontal" }}>
                  <div
                    className={classes.headerCellInner}
                    style={hasSorting ? { cursor: "pointer" } : null}
                    onClick={hasSorting ? () => clickHeaderCellHandler(column.field) : null}
                  >
                    <div>{column.name}</div>
                    {!!sorting && sorting.field === column.field && (
                      <div style={{ marginLeft: 4 }}>
                        <img style={{ transform: `rotate(${sorting.direction === "asc" ? "90" : "-90"}deg)` }} src={arrowIcon} alt="" />
                      </div>
                    )}
                  </div>
                </div>
                {hasFilters && (
                  <div className={classes.cell} style={{ overflow: "visible" }}>
                    {column.searchInputType === "textInput" && (
                      <TextInput
                        classNames={["transparent"]}
                        style={{ margin: "0 -8px", width: "calc(100% + 16px)", height: 26 }}
                        value={column.field in headersFilters ? headersFilters[column.field] : ""}
                        changeHandler={value => changeHeaderFilterHandler({ key: column.field, value })}
                      />
                    )}
                    {column.searchInputType === "select" && (
                      <Select
                        inputClassNames={["transparent"]}
                        style={{ margin: "0 -8px", width: "calc(100% + 16px)", height: 26 }}
                        inputStyle={{ height: 26 }}
                        options={column.options}
                        value={column.field in headersFilters ? headersFilters[column.field] : null}
                        changeHandler={value => changeHeaderFilterHandler({ key: column.field, value })}
                      />
                    )}
                    {column.searchInputType === "datePicker" && (
                      <DatePicker
                        classNames={["transparent"]}
                        style={{ margin: "0 -8px", width: "calc(100% + 16px)", height: 26 }}
                        value={column.field in headersFilters ? headersFilters[column.field] : null}
                        changeHandler={value => changeHeaderFilterHandler({ key: column.field, value })}
                      />
                    )}
                  </div>
                )}
                {data.map((row, k) => (
                  <div className={classes.cell} style={{ top: 46 * (k + 1 + hasFilters) }} key={k}>
                    {column.field in row ? (
                      <>
                        {column.cellType === "text" && row[column.field]}
                        {column.cellType === "link" && (
                          <span className={classes.link} onClick={() => (clickLinkHandlers[column.name] ? clickLinkHandlers[column.name](row) : null)}>
                            {row[column.field]}
                          </span>
                        )}
                        {column.cellType === "mailLink" && (
                          <a className={classes.link} href={`mailTo:${row[column.field]}`}>
                            {row[column.field]}
                          </a>
                        )}
                        {column.cellType === "image" && (
                          <div className={classes.image} style={{ backgroundImage: `url("${row[column.field] || UserIcon}")` }} />
                        )}
                        {column.cellType === "checkbox" && row[column.field] !== undefined && (
                          <CheckboxInput
                            isChecked={checkboxes[column.name].values[row[column.field]]}
                            changeHandler={value =>
                              checkboxes[column.name].changeHandler(row, () =>
                                clickColumnCheckboxHandler(
                                  row.id,
                                  column.field,
                                  Object.keys(checkboxes[column.name].values).find(key => checkboxes[column.name].values[key] === value)
                                )
                              )
                            }
                            isDisabled={checkboxes[column.name].isDisabledWhenTrue ? checkboxes[column.name].values[row[column.field]] : false}
                          />
                        )}
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </CustomScrollbar>
        {dataIsFetching && (
          <div className={classes.spinnerWrapper}>
            <Spinner size="small" color="dark" />
          </div>
        )}
        {!dataIsFetching && !data.length && <div className={classes.error}>No results found</div>}
      </div>
    );
  }
}

TableData.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      field: PropTypes.string,
      searchInputType: PropTypes.oneOf(["textInput", "select", "datePicker"]),
      options: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
          })
        ])
      ),
      cellType: PropTypes.oneOf(["text", "image", "link", "checkbox", "checkboxRemastered", "mailLink"])
    })
  ),
  hiddenColumnsNames: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object),
  dataIsFetching: PropTypes.bool,
  checkedRowsIds: PropTypes.arrayOf(PropTypes.number),
  clickCheckboxHandler: PropTypes.func,
  clickCheckboxAllHandler: PropTypes.func,
  headersFilters: PropTypes.object,
  changeHeaderFilterHandler: PropTypes.func,
  visibleRowsQuantity: PropTypes.number,
  // cellsAreInputs: PropTypes.bool,
  clickLinkHandlers: PropTypes.object,
  sorting: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.oneOf(["asc", "desc"])
  }),
  clickHeaderCellHandler: PropTypes.func,
  checkboxes: PropTypes.object,
  clickColumnCheckboxHandler: PropTypes.func,
  hasFilters: PropTypes.bool,
  hasSorting: PropTypes.bool
};
