export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const SET_TOKEN = "SET_TOKEN";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const SEND_RECOVERY_EMAIL = "SEND_RECOVERY_EMAIL";
export const CHECK_RECOVERY_TOKEN = "CHECK_RECOVERY_TOKEN";
export const SET_RECOVERY_TOKEN = "SET_RECOVERY_TOKEN";
export const SEND_NEW_PASSWORD = "SEND_NEW_PASSWORD";
export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
