import * as types from "../types";

export const getUserOptions = promise => {
  return {
    type: types.GET_USER_OPTIONS,
    promise
  };
};

export const setUserOptions = data => {
  return {
    type: types.SET_USER_OPTIONS,
    data
  };
};

export const getAdminUserOptions = promise => {
  return {
    type: types.GET_ADMIN_USER_OPTIONS,
    promise
  };
};

export const setAdminUserOptions = data => {
  return {
    type: types.SET_ADMIN_USER_OPTIONS,
    data
  };
};

export const getTitles = promise => {
  return {
    type: types.GET_TITLES,
    promise
  };
};

export const setTitles = data => {
  return {
    type: types.SET_TITLES,
    data
  };
};

export const getGenders = promise => {
  return {
    type: types.GET_GENDERS,
    promise
  };
};

export const setGenders = data => {
  return {
    type: types.SET_GENDERS,
    data
  };
};

export const getCountries = promise => {
  return {
    type: types.GET_COUNTRIES,
    promise
  };
};

export const setCountries = data => {
  return {
    type: types.SET_COUNTRIES,
    data
  };
};

export const getPersonRelationships = promise => {
  return {
    type: types.GET_PERSON_RELATIONSHIPS,
    promise
  };
};

export const setPersonRelationships = data => {
  return {
    type: types.SET_PERSON_RELATIONSHIPS,
    data
  };
};

export const getOrganisationRelationships = promise => {
  return {
    type: types.GET_ORGANISATION_RELATIONSHIPS,
    promise
  };
};

export const setOrganisationRelationships = data => {
  return {
    type: types.SET_ORGANISATION_RELATIONSHIPS,
    data
  };
};

export const getAcademicYears = promise => {
  return {
    type: types.GET_ACADEMIC_YEARS,
    promise
  };
};

export const setAcademicYears = promise => {
  return {
    type: types.SET_ACADEMIC_YEARS,
    promise
  };
};

export const getContactAddressesType = promise => {
  return {
    type: types.GET_CONTACT_ADDRESSES_TYPE,
    promise
  };
};

export const setContactAddressesType = promise => {
  return {
    type: types.SET_CONTACT_ADDRESSES_TYPE,
    promise
  };
};

export const getHesaDetailOptions = promise => {
  return {
    type: types.GET_HESA_DETAIL_OPTIONS,
    promise
  };
};

export const setHesaDetailOptions = data => {
  return {
    type: types.SET_HESA_DETAIL_OPTIONS,
    data
  };
};

export const getHesaDetailCourseSessionOptions = promise => {
  return {
    type: types.GET_HESA_DETAIL_COURSE_SESSION_OPTIONS,
    promise
  };
};

export const setHesaDetailCourseSessionOptions = data => {
  return {
    type: types.SET_HESA_DETAIL_COURSE_SESSION_OPTIONS,
    data
  };
};

export const getStudentApplicationProgrammes = promise => {
  return {
    type: types.GET_STUDENT_APPLICATION_PROGRAMMES,
    promise
  };
};

export const setStudentApplicationProgrammes = data => {
  return {
    type: types.SET_STUDENT_APPLICATION_PROGRAMMES,
    data
  };
};

export const getContactsAssociationOptions = promise => {
  return {
    type: types.GET_CONTACTS_ASSOCIATION_OPTIONS,
    promise
  };
};

export const setContactsAssociationOptions = data => {
  return {
    type: types.SET_CONTACTS_ASSOCIATION_OPTIONS,
    data
  };
};

export const getStudiesProgrammeOptions = promise => {
  return {
    type: types.GET_STUDIES_PROGRAMME_OPTIONS,
    promise
  };
};

export const setStudiesProgrammeOptions = data => {
  return {
    type: types.SET_STUDIES_PROGRAMME_OPTIONS,
    data
  };
};

export const getCreateFinancialSupportOptions = promise => {
  return {
    type: types.GET_CREATE_FINANCIAL_SUPPORT_OPTIONS,
    promise
  };
};

export const setCreateFinancialSupportOptions = data => {
  return {
    type: types.SET_CREATE_FINANCIAL_SUPPORT_OPTIONS,
    data
  };
};

export const getProgrammesListOptions = promise => {
  return {
    type: types.GET_PROGRAMMES_LIST_OPTIONS,
    promise
  };
};

export const setProgrammesListOptions = data => {
  return {
    type: types.SET_PROGRAMMES_LIST_OPTIONS,
    data
  };
};

export const getStudiesStudyProgrammeFormOptions = promise => {
  return {
    type: types.GET_STUDIES_STUDY_PROGRAMME_FORM_OPTIONS,
    promise
  };
};

export const setStudiesStudyProgrammeFormOptions = data => {
  return {
    type: types.SET_STUDIES_STUDY_PROGRAMME_FORM_OPTIONS,
    data
  };
};

export const getContactsClericOptions = promise => {
  return {
    type: types.GET_CONTACTS_CLERIC_OPTIONS,
    promise
  };
};

export const setContactsClericOptions = data => {
  return {
    type: types.SET_CONTACTS_CLERIC_OPTIONS,
    data
  };
};

export const getContactsStudentTypeOptions = promise => {
  return {
    type: types.GET_CONTACTS_STUDENT_TYPE_OPTIONS,
    promise
  };
};

export const setContactsStudentTypeOptions = data => {
  return {
    type: types.SET_CONTACTS_STUDENT_TYPE_OPTIONS,
    data
  };
};

export const getStudiesStudyProgrammePostgradOptions = promise => {
  return {
    type: types.GET_STUDIES_STUDY_PROGRAMME_POSTGRAD_OPTIONS,
    promise
  };
};

export const setStudiesStudyProgrammePostgradOptions = data => {
  return {
    type: types.SET_STUDIES_STUDY_PROGRAMME_POSTGRAD_OPTIONS,
    data
  };
};

export const getStudiesStudyProgrammeFeesOptions = promise => {
  return {
    type: types.GET_STUDIES_STUDY_PROGRAMME_FEES_OPTIONS,
    promise
  };
};

export const setStudiesStudyProgrammeFeesOptions = data => {
  return {
    type: types.SET_STUDIES_STUDY_PROGRAMME_FEES_OPTIONS,
    data
  };
};

export const getStudiesStudyProgrammeFundingAndMonitoringOptions = promise => {
  return {
    type: types.GET_STUDIES_STUDY_PROGRAMME_FUNDING_AND_MONITORING_OPTIONS,
    promise
  };
};

export const setStudiesStudyProgrammeFundingAndMonitoringOptions = data => {
  return {
    type: types.SET_STUDIES_STUDY_PROGRAMME_FUNDING_AND_MONITORING_OPTIONS,
    data
  };
};

export const getStudiesModuleOptions = promise => {
  return {
    type: types.GET_STUDIES_MODULE_OPTIONS,
    promise
  };
};

export const setStudiesModuleOptions = data => {
  return {
    type: types.SET_STUDIES_MODULE_OPTIONS,
    data
  };
};

export const getStudiesEngagementOptions = data => {
  return {
    type: types.GET_STUDIES_ENGAGEMENT_OPTIONS,
    data
  };
};

export const setStudiesEngagementOptions = data => {
  return {
    type: types.SET_STUDIES_ENGAGEMENT_OPTIONS,
    data
  };
};

export const getStudiesSuspensionsOptions = data => {
  return {
    type: types.GET_STUDIES_SUSPENSIONS_OPTIONS,
    data
  };
};

export const setStudiesSuspensionsOptions = data => {
  return {
    type: types.SET_STUDIES_SUSPENSIONS_OPTIONS,
    data
  };
};

export const getStudiesModuleSubjectOptions = promise => {
  return {
    type: types.GET_STUDIES_MODULE_SUBJECT_OPTIONS,
    promise
  };
};

export const setStudiesModuleSubjectOptions = data => {
  return {
    type: types.SET_STUDIES_MODULE_SUBJECT_OPTIONS,
    data
  };
};

export const getStudiesModuleDeliveryOptions = promise => {
  return {
    type: types.GET_STUDIES_MODULE_DELIVERY_OPTIONS,
    promise
  };
};

export const setStudiesModuleDeliveryOptions = data => {
  return {
    type: types.SET_STUDIES_MODULE_DELIVERY_OPTIONS,
    data
  };
};

export const getStudiesModuleCostCenterOptions = promise => {
  return {
    type: types.GET_STUDIES_MODULE_COST_CENTRE_OPTIONS,
    promise
  };
};

export const setStudiesModuleCostCenterOptions = data => {
  return {
    type: types.SET_STUDIES_MODULE_COST_CENTRE_OPTIONS,
    data
  };
};

export const getStudiesModuleInstanceOptions = promise => {
  return {
    type: types.GET_STUDIES_MODULE_INSTANCE_OPTIONS,
    promise
  };
};

export const setStudiesModuleInstanceOptions = data => {
  return {
    type: types.SET_STUDIES_MODULE_INSTANCE_OPTIONS,
    data
  };
};

export const getStudiesModuleDeliveryRoleOptions = promise => {
  return {
    type: types.GET_STUDIES_MODULE_DELIVERY_ROLE_OPTIONS,
    promise
  };
};

export const setStudiesModuleDeliveryRoleOptions = data => {
  return {
    type: types.SET_STUDIES_MODULE_DELIVERY_ROLE_OPTIONS,
    data
  };
};

export const getStudiesModuleDeliveryLocationOptions = promise => {
  return {
    type: types.GET_STUDIES_MODULE_DELIVERY_LOCATION_OPTIONS,
    promise
  };
};

export const setStudiesModuleDeliveryLocationOptions = data => {
  return {
    type: types.SET_STUDIES_MODULE_DELIVERY_LOCATION_OPTIONS,
    data
  };
};

export const getStudiesStudentModuleFeeOptions = promise => {
  return {
    type: types.GET_STUDIES_STUDENT_MODULE_FEE_OPTIONS,
    promise
  };
};

export const setStudiesStudentModuleFeeOptions = data => {
  return {
    type: types.SET_STUDIES_STUDENT_MODULE_FEE_OPTIONS,
    data
  };
};

export const getAdminInstitutionOptions = promise => {
  return {
    type: types.GET_ADMIN_INSTITUTION_OPTIONS,
    promise
  };
};

export const setAdminInstitutionOptions = data => {
  return {
    type: types.SET_ADMIN_INSTITUTION_OPTIONS,
    data
  };
};

export const getAdminQualificationOptions = promise => {
  return {
    type: types.GET_ADMIN_QUALIFICATION_OPTIONS,
    promise
  };
};

export const setAdminQualificationOptions = data => {
  return {
    type: types.SET_ADMIN_QUALIFICATION_OPTIONS,
    data
  };
};

export const getAdminVenueOptions = promise => {
  return {
    type: types.GET_ADMIN_VENUE_OPTIONS,
    promise
  };
};

export const setAdminVenueOptions = data => {
  return {
    type: types.SET_ADMIN_VENUE_OPTIONS,
    data
  };
};

export const getAdmissionsApplicationsOptions = promise => {
  return {
    type: types.GET_ADMISSIONS_APPLICATIONS_OPTIONS,
    promise
  };
};

export const setAdmissionsApplicationsOptions = data => {
  return {
    type: types.SET_ADMISSIONS_APPLICATIONS_OPTIONS,
    data
  };
};

export const getFinanceFundingOptions = promise => {
  return {
    type: types.GET_FINANCE_FUNDING_OPTIONS,
    promise
  };
};

export const setFinanceFundingOptions = promise => {
  return {
    type: types.SET_FINANCE_FUNDING_OPTIONS,
    promise
  };
};

export const getPuringDataOptions = promise => {
  return {
    type: types.GET_PURING_DATA_OPTIONS,
    promise
  };
};

export const setPuringDataOptions = promise => {
  return {
    type: types.SET_PURING_DATA_OPTIONS,
    promise
  };
};

export const getInstitutionOptions = promise => {
  return {
    type: types.GET_INSTITUTION_OPTIONS,
    promise
  };
};

export const setInstitutionOptions = promise => {
  return {
    type: types.SET_INSTITUTION_OPTIONS,
    promise
  };
};
