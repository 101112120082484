import React from "react";
import classes from "./FinancePage.module.scss";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { getCurrentLinkName } from "@Root/helpers";
import DocumentTitle from "react-document-title";
import { PageTitle, PageNavLinks } from "@Root/components";

import { FinanceTable } from "./FinanceTable";

const FinancePage = ({ location }) => {
  const links = [{ name: "Tuition Fees", path: "tuition-fees" }, { name: "Fundings", path: "fundings" }];
  const currentLinkName = getCurrentLinkName(links, location);

  return (
    <DocumentTitle title={`${currentLinkName} - Finance`}>
      <div className={classes.wrapper}>
        <PageTitle title={currentLinkName} />
        <PageNavLinks style={{ marginBottom: 30 }} root={`/home/finance`} links={links} />
        <Switch>
          {links.map((link, i) => (
            <Route exact path={`/home/finance/${link.path}`} component={FinanceTable} key={i} />
          ))}
          <Redirect to="/home/finance/tuition-fees" />
        </Switch>
      </div>
    </DocumentTitle>
  );
};

export default withRouter(FinancePage);
