import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar } from "react-date-range";
import moment from "moment";
import { enumerateDaysBetweenDates } from "@Root/helpers";

const DatePickerPopup = ({ value, changeHandler, minDate, maxDate }) => {
  let config = {};

  if (minDate) config.minDate = moment(minDate, "DD-MM-YYYY").toDate();
  if (maxDate) config.maxDate = moment(maxDate, "DD-MM-YYYY").toDate();
  if (minDate && maxDate)
    config.disabledDates = [...enumerateDaysBetweenDates(minDate, maxDate), moment(minDate, "DD-MM-YYYY").toDate(), moment(maxDate, "DD-MM-YYYY").toDate()];
  return (
    <div className="DatePickerPopup">
      <Calendar
        date={value ? moment(value, "DD-MM-YYYY") : new Date()}
        shownDate={new Date()}
        onChange={value => changeHandler(moment(value).format("DD-MM-YYYY"))}
        showPreview={false}
        className={"calendar-date"}
        {...config}
        disabled
      />
    </div>
  );
};

DatePickerPopup.propTypes = {
  value: PropTypes.string,
  changeHandler: PropTypes.func.isRequired
};

export default DatePickerPopup;
