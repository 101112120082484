import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import TEIDLogo from "../icons/TEIDLogo/TEIDLogo";
import { HeaderNavLinks } from "./HeaderNavLinks";
import { HeaderUser } from "./HeaderUser";

export const Header = ({ links, userName, userIcon, clickUserHandler, logOut }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <TEIDLogo style={{ marginRight: 60 }} link="/home" />
        <HeaderNavLinks links={links} />
      </div>
      <HeaderUser userName={userName} userIcon={userIcon} clickUserHandler={clickUserHandler} logOut={logOut} />
    </div>
  );
};

Header.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ),
  userName: PropTypes.string,
  userIcon: PropTypes.string,
  clickUserHandler: PropTypes.func,
  logOut: PropTypes.func
};
