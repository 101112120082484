import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { withPromise, errorMessage } from "@Root/helpers";
import { Spinner, ContactsOrganisationForm, ContactsPersonForm } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";

const Component = ({
  history,
  location,
  titleOptions,
  genderOptions,
  countryOptions,
  personRelationshipOptions,
  organisationRelationshipOptions,
  academicYears,
  getTitles,
  getGenders,
  getCountries,
  getPersonRelationships,
  getOrganisationRelationships,
  getAcademicYears,
  getContactAddressesType,
  contactAddressesType,
  setSnackbar,
  setSuccessNotification
}) => {
  const contactType = location.pathname
    .split("/")
    .pop()
    .slice(4);

  const [isShown, setIsShown] = useState(false);

  const fetchOptions = async () => {
    await Promise.all([
      !titleOptions ? getTitles() : null,
      !genderOptions ? getGenders() : null,
      !countryOptions ? getCountries() : null,
      !personRelationshipOptions ? getPersonRelationships() : null,
      !organisationRelationshipOptions ? getOrganisationRelationships() : null,
      !academicYears ? getAcademicYears() : null,
      !contactAddressesType ? getContactAddressesType() : null
    ]);
  };

  const saveContact = async payload => {
    const { data } = await API.postNewContact(contactType, payload);
    const id = data.data.id;
    history.push(`/home/contacts/all-contacts/${id}`);
  };

  useEffect(() => {
    Component.isMounted = true;
    (async () => {
      try {
        await fetchOptions();
        Component.isMounted && setIsShown(true);
      } catch (error) {
        setSnackbar({ text: error.message, isError: true });
        history.replace("/contact-us");
      }
    })();
    return () => {
      Component.isMounted = false;
    };
  }, []);
  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper hasBackButton backButtonText="All Contacts" clickBackButtonHandler={() => history.push("/home/contacts/all-contacts")}>
          {contactType === "person" ? (
            <ContactsPersonForm
              isNew
              title={{ onEdit: "Create a person" }}
              initialIsEditable={true}
              titlesDataList={titleOptions}
              gendersDataList={genderOptions}
              countriesDataList={countryOptions}
              personRelationshipsDataList={personRelationshipOptions}
              academicYears={academicYears}
              contactAddressesType={contactAddressesType}
              fetchContactHandler={params => API.getOrganisations(params)}
              buttons={["save"]}
              saveHandler={payload => saveContact(payload)}
              errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
              successHandler={success => setSuccessNotification({ text: success })}
              studentIdValidationHandler={API.getValidateStudentId}
            />
          ) : (
            <ContactsOrganisationForm
              isNew
              title={{ onEdit: "Create an organisation" }}
              initialIsEditable={true}
              countriesDataList={countryOptions}
              organisationRelationshipsDataList={organisationRelationshipOptions}
              contactAddressesType={contactAddressesType}
              buttons={["save"]}
              fetchContactHandler={params => API.getPersons(params)}
              saveHandler={payload => saveContact(payload)}
              errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
            />
          )}
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  titleOptions: inputDataListsReducer.titles,
  genderOptions: inputDataListsReducer.genders,
  countryOptions: inputDataListsReducer.countries,
  personRelationshipOptions: inputDataListsReducer.personRelationships,
  organisationRelationshipOptions: inputDataListsReducer.organisationRelationships,
  academicYears: inputDataListsReducer.academicYears,
  contactAddressesType: inputDataListsReducer.contactAddressesType
});

const mapDispatchToProps = dispatch => {
  return {
    getTitles: () => withPromise(dispatch, actions.getTitles),
    getGenders: () => withPromise(dispatch, actions.getGenders),
    getCountries: () => withPromise(dispatch, actions.getCountries),
    getPersonRelationships: () => withPromise(dispatch, actions.getPersonRelationships),
    getOrganisationRelationships: () => withPromise(dispatch, actions.getOrganisationRelationships),
    getAcademicYears: () => withPromise(dispatch, actions.getAcademicYears),
    getContactAddressesType: () => withPromise(dispatch, actions.getContactAddressesType),
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    setSuccessNotification: data => dispatch(actions.setSuccessNotification(data))
  };
};

export const ContactsNewContact = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
