import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Store/actions";
import { API } from "@Root/API";
import { withPromise, errorMessage } from "@Root/helpers";
import { SectionWrapper } from "@Root/HOCs";

import {
  Spinner,
  SectionTabs,
  ActionsDropdown,
  ContactConsentsForm,
  PostgradForm,
  ContactsAttachmentsForm,
  ContactsNotesForm,
  TrainingFacilityForm,
  DioceseForm,
  ChurchForm,
  AssociationForm,
  ClericForm,
  StaffForm,
  AcademicForm,
  ContactsApplicationsForm,
  ContactsHesaDetailForm,
  ContactsProgrammesForm,
  ContactsStudentForm,
  ContactsOrganisationForm,
  ContactsPersonForm
} from "@Root/components";

const Component = ({
  history,
  location,
  permissions,
  getUserOptions,
  userOptions,
  getTitles,
  titleOptions,
  getGenders,
  genderOptions,
  getCountries,
  countryOptions,
  getPersonRelationships,
  personRelationshipOptions,
  getOrganisationRelationships,
  organisationRelationshipOptions,
  hesaDetailOptions,
  getHesaDetailOptions,
  hesaDetailCourseSessionOptions,
  getHesaDetailCourseSessionOptions,
  contactsAssociationOptions,
  getContactsAssociationOptions,
  programmesListOptions,
  getProgrammesListOptions,
  contactsClericOptions,
  getContactsClericOptions,
  getStudiesStudyProgrammeFormOptions,
  studiesStudyProgrammeFormOptions,
  getContactsStudentTypeOptions,
  getAcademicYears,
  contactsStudentTypeOptions,
  showModal,
  hideModal,
  currentLink,
  academicYears,
  setSnackbar,
  contactAddressesType,
  getContactAddressesType,
  setSuccessNotification,
  getInstitutionOptions,
  institutionOptions,
  studiesStudyProgrammePostgradOptions,
  getStudiesStudyProgrammePostgradOptions
}) => {
  const contactId = +location.pathname.split("/").pop();
  const [isShown, setIsShown] = useState(false);
  const [contactIsSaving, setContactIsSaving] = useState(false);
  const [contactType, setContactType] = useState(null);
  const [contact, setContact] = useState(null);
  const [student, setStudent] = useState(null);
  const [academic, setAcademic] = useState(null);
  const [staff, setStaff] = useState(null);
  const [cleric, setCleric] = useState(null);
  const [association, setAssociation] = useState(undefined);
  const [church, setChurch] = useState(null);
  const [diocese, setDiocese] = useState(null);
  const [trainingFacility, setTrainingFacility] = useState(null);

  const [notes, setNotes] = useState(null);
  const [attachments, setAttachments] = useState(null);
  const [consents, setConsents] = useState(null);
  const [postgrad, setPostgrad] = useState(null);
  const [activeTabName, setActiveTabName] = useState(null);
  const [activeSnapshotId, setActiveSnapshotId] = useState(null);
  const [snapshot, setSnapshot] = useState(undefined);
  const handleAddStudyProgramme = () => {
    new Promise((resolve, reject) => {
      showModal("InputModal", {
        title: "Add a Study Record",
        inputType: "select",
        options: programmesListOptions,
        placeholder: "Select a Programme",
        resolveButtonText: "Continue",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async programmeId => {
        hideModal();
        history.push(`/home/studies/programmes/study/new/${contactId}/${programmeId}`);
      },
      () => {
        hideModal();
      }
    );
  };

  const actions = [
    ...(permissions.includes("edit") && contact && contactType
      ? [
          {
            name: "Edit the Relationship",
            handler: () => {
              new Promise((resolve, reject) => {
                const props = {
                  person: {
                    title: "Edit the Relationships",
                    inputType: "multi-select",
                    options: personRelationshipOptions,
                    initialValue: contact.relationship_ids,
                    placeholder: "Select the relationships"
                  },
                  organisation: {
                    title: "Edit the Relationship",
                    inputType: "select",
                    options: organisationRelationshipOptions,
                    initialValue: contact.relationship_id,
                    placeholder: "Select the relationship"
                  }
                };
                showModal("InputModal", {
                  title: props[contactType].title,
                  inputType: props[contactType].inputType,
                  options: props[contactType].options,
                  initialValue: props[contactType].initialValue,
                  valueIsRequired: true,
                  placeholder: props[contactType].placeholder,
                  resolveButtonText: "Save",
                  clickRejectButtonHandler: reject,
                  clickResolveButtonHandler: resolve
                });
              }).then(
                async value => {
                  hideModal();
                  setContactIsSaving(true);
                  try {
                    await saveContact({ ...contact, photo: null, [contactType === "person" ? "relationship_ids" : "relationship_id"]: value });
                  } catch (error) {
                    setSnackbar({ text: errorMessage(error), isError: true });
                  }
                  setContactIsSaving(false);
                },
                () => {
                  hideModal();
                }
              );
            }
          }
        ]
      : []),
    ...(permissions.includes("create") && student && programmesListOptions
      ? [
          {
            name: "Add a Study Record",
            handler: handleAddStudyProgramme
          }
        ]
      : [])
  ];

  const visibleForms = () => {
    const hesaDetailForm = () => {
      const data = snapshot ? snapshot : student.hesa_detail;
      return data && hesaDetailOptions && hesaDetailCourseSessionOptions
        ? [
            {
              tab: {
                name: "HESA Detail",
                isShifted: true
              },
              component: (
                <ContactsHesaDetailForm
                  permissions={permissions}
                  student={data.student}
                  careLeaver={data.care_leavers}
                  carer={data.carers}
                  contactPurpose={data.contact_purposes}
                  dependant={data.dependants}
                  disability={data.disabilities}
                  engagement={data.engagements}
                  studentRegistration={data.student_registrations}
                  financialSupportScheme={data.financial_support_schemes}
                  studentFinancialSupport={data.student_financial_supports}
                  leaver={data.leavers}
                  entryQualificationAward={data.entry_qualification_awards}
                  ethnicity={data.ethnicities}
                  genderIdentity={data.gender_identities}
                  languageProficiency={data.language_proficiencies}
                  maritalStatus={data.marital_statuses}
                  nationalIdentity={data.national_identities}
                  nationality={data.nationalities}
                  parentalEducation={data.parental_educations}
                  personIdentifier={data.person_identifiers}
                  religion={data.religions}
                  religiousBackground={data.religious_backgrounds}
                  serviceLeaver={data.service_leavers}
                  sexualOrientation={data.sexual_orientations}
                  socioEconomicClassification={data.socio_economic_classifications}
                  standardOccupationalClassification={data.standard_occupational_classifications}
                  hesaDetailOptions={hesaDetailOptions}
                  hesaDetailCourseSessionOptions={hesaDetailCourseSessionOptions}
                  fetchQualificationsByTitleHandler={qualificationTitle => API.getAdminQualificationByTitle(qualificationTitle)}
                  fetchQualificationHandler={qualificationId => API.getAdminQualification(qualificationId)}
                  saveHesaDetailForm={(formName, data, deletionsLog, callback) => saveHesaDetailForm(formName, data, deletionsLog, callback, student)}
                  snapshots={student.snapshot_list}
                  activeSnapshotId={activeSnapshotId}
                  clickSnapshotHandler={snapshotId => setActiveSnapshotId(snapshotId !== activeSnapshotId ? snapshotId : null)}
                />
              )
            }
          ]
        : [];
    };

    const studentForms = student
      ? [
          ...(student.student_detail && contactsStudentTypeOptions && hesaDetailOptions
            ? [
                {
                  tab: {
                    name: "Student"
                  },
                  component: (
                    <ContactsStudentForm
                      permissions={permissions}
                      initialData={student.student_detail}
                      studentTypeOptions={contactsStudentTypeOptions}
                      ethnicityOptions={hesaDetailOptions.ethnicity.ethnicity_type}
                      disabilityOptions={hesaDetailOptions.disability.disability_type}
                      nationalityOptions={hesaDetailOptions.nationality.nationality_type}
                      maritalStatusOptions={hesaDetailOptions.marital_status.marital_status_type}
                      deleteHandler={(onStartCallback, onFinishCallback) => deleteContactRelationship("Student", onStartCallback, onFinishCallback)}
                      saveHandler={data => saveStudentForm(data)}
                      errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
                    />
                  )
                }
              ]
            : []),
          ...(programmesListOptions && studiesStudyProgrammeFormOptions
            ? [
                {
                  tab: {
                    name: "Programmes",
                    isShifted: true
                  },
                  component: (
                    <ContactsProgrammesForm
                      permissions={permissions}
                      options={{ programmesListOptions, ...studiesStudyProgrammeFormOptions }}
                      fetchTableDataHandler={params => API.getContactsProgrammesTable(contactId, params)}
                      clickAddStudyProgrammeHandler={handleAddStudyProgramme}
                      clickProgrammeNameHandler={studyProgrammeId => history.push(`/home/studies/programmes/study/${contactId}/${studyProgrammeId}`)}
                    />
                  )
                }
              ]
            : []),
          ...hesaDetailForm(),
          {
            tab: {
              name: "Applications",
              isShifted: true
            },
            component: <ContactsApplicationsForm history={history} fetchTableDataHandler={params => API.getContactsApplicationsTable(contactId, params)} />
          }
        ]
      : [];

    const academicForms = academic
      ? [
          {
            tab: {
              name: "Academic"
            },
            component: (
              <AcademicForm
                permissions={permissions}
                options={{ institutionOptions }}
                initialData={academic}
                deleteHandler={(onStartCallback, onFinishCallback) => deleteContactRelationship("Academic", onStartCallback, onFinishCallback)}
                saveHandler={data => saveAcademicForm(data)}
                errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
              />
            )
          }
        ]
      : [];

    const staffForms = staff
      ? [
          {
            tab: {
              name: "Staff"
            },
            component: (
              <StaffForm
                permissions={permissions}
                initialData={staff}
                options={{ institutionOptions }}
                saveHandler={data => saveStaffForm(data)}
                errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
                deleteHandler={(onStartCallback, onFinishCallback) => deleteContactRelationship("Staff", onStartCallback, onFinishCallback)}
              />
            )
          }
        ]
      : [];

    const clericForms =
      cleric && contactsClericOptions
        ? [
            {
              tab: {
                name: "Cleric"
              },
              component: (
                <ClericForm
                  permissions={permissions}
                  initialData={cleric}
                  options={{ contactsClericOptions, institutionOptions }}
                  fetchDioceseByIdHandler={id => API.getDioceseById(id)}
                  fetchDioceseByNameHandler={name => API.getDioceseByName(name)}
                  deleteHandler={(onStartCallback, onFinishCallback) => deleteContactRelationship("Clergy", onStartCallback, onFinishCallback)}
                  saveHandler={data => saveClericForm(data)}
                  errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
                />
              )
            }
          ]
        : [];

    const associationForms =
      association && contactsAssociationOptions
        ? [
            {
              tab: {
                name: "Association"
              },
              component: (
                <AssociationForm
                  permissions={permissions}
                  options={{ contactsAssociationOptions, institutionOptions }}
                  initialData={association}
                  deleteHandler={(onStartCallback, onFinishCallback) => deleteContactRelationship("Association", onStartCallback, onFinishCallback)}
                  saveHandler={data => saveAssociationForm(data)}
                  errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
                />
              )
            }
          ]
        : [];

    const churchForms = church
      ? [
          {
            tab: {
              name: "Church"
            },
            component: (
              <ChurchForm
                permissions={permissions}
                initialData={church}
                options={{ institutionOptions }}
                fetchContactHandler={params => API.getContacts(params)}
                fetchDioceseByIdHandler={id => API.getDioceseById(id)}
                fetchDioceseByIndexHandler={index => API.getDioceseByIndex(index)}
                deleteHandler={(onStartCallback, onFinishCallback) => deleteContactRelationship("Church", onStartCallback, onFinishCallback)}
                saveHandler={data => saveChurchForm(data)}
                errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
              />
            )
          }
        ]
      : [];

    const dioceseForms = diocese
      ? [
          {
            tab: {
              name: "Diocese"
            },
            component: (
              <DioceseForm
                permissions={permissions}
                initialData={diocese}
                titleOptions={titleOptions}
                options={{ institutionOptions }}
                fetchContactHandler={params => API.getContacts(params)}
                deleteHandler={(onStartCallback, onFinishCallback) => deleteContactRelationship("Diocese", onStartCallback, onFinishCallback)}
                saveHandler={data => saveDioceseForm(data)}
                errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
              />
            )
          }
        ]
      : [];

    const trainingFacilityForms =
      trainingFacility && userOptions
        ? [
            {
              tab: {
                name: "Training Facility"
              },
              component: (
                <TrainingFacilityForm
                  permissions={permissions}
                  initialData={trainingFacility}
                  options={{ institutionOptions, userOptions }}
                  deleteHandler={(onStartCallback, onFinishCallback) => deleteContactRelationship("Training Facility", onStartCallback, onFinishCallback)}
                  saveHandler={payload => saveTrainingFacilityForm(payload)}
                  errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
                />
              )
            }
          ]
        : [];

    const postgradForm =
      postgrad && studiesStudyProgrammePostgradOptions
        ? [
            {
              tab: {
                name: "Postgrad"
              },
              component: (
                <PostgradForm
                  permissions={permissions}
                  options={{ studiesStudyProgrammeFormOptions, studiesStudyProgrammePostgradOptions }}
                  initialData={postgrad}
                  deleteHandler={(postgradId, onStartCallback, onFinishCallback) => deletePostgrad(postgradId, onStartCallback, onFinishCallback)}
                  saveHandler={(payload, cb) => putPostgrad(payload, cb)}
                  fetchSupervisorHandler={params => API.getSupervisors(params)}
                />
              )
            }
          ]
        : [];
    return [
      ...studentForms,
      ...academicForms,
      ...staffForms,
      ...clericForms,
      ...associationForms,
      ...churchForms,
      ...dioceseForms,
      ...trainingFacilityForms,
      {
        tab: {
          name: "Notes"
        },
        component: (
          <ContactsNotesForm
            permissions={permissions}
            notes={notes}
            saveNoteHandler={(newNote, cb) => postNewNote(newNote, cb)}
            editNoteHandler={(id, value, cb) => saveEditNote(id, value, cb)}
            onNoteDelete={(id, onStartSaving, onFinishSaving) => deleteNote(id, onStartSaving, onFinishSaving)}
          />
        )
      },
      {
        tab: {
          name: "Attachments"
        },
        component: (
          <ContactsAttachmentsForm
            permissions={permissions}
            attachments={attachments}
            saveAttachmentHandler={(attachment, cb) => postNewAttachment(attachment, cb)}
            deleteAttachmentHandler={(attachmentId, onStartSaving, onFinishSaving) => deleteAttachment(attachmentId, onStartSaving, onFinishSaving)}
            editAttachmentHandler={(attachment, cb) => putAttachment(attachment, cb)}
          />
        )
      },
      ...postgradForm,
      {
        tab: {
          name: "Consents"
        },
        component: <ContactConsentsForm permissions={permissions} initialData={consents} saveHandler={payload => saveManageConsents(payload)} />
      }
    ];
  };

  const activeForm = visibleForms().find(form => form.tab.name === activeTabName);

  const setContactAndRelationships = ({ contact, student, academic, staff, cleric, association, church, diocese, training_facility }) => {
    setContact(contact);
    student ? setStudent(student) : setStudent(null);
    academic ? setAcademic(academic) : setAcademic(null);
    staff ? setStaff(staff) : setStaff(null);
    cleric ? setCleric(cleric) : setCleric(null);
    association ? setAssociation(association) : setAssociation(null);
    church ? setChurch(church) : setChurch(null);
    diocese ? setDiocese(diocese) : setDiocese(null);
    training_facility ? setTrainingFacility(training_facility) : setTrainingFacility(null);
  };

  const fetchData = async () => {
    try {
      const { data } = await API.getContact(contactId);
      const { contact_type, student, cleric, association, training_facility, notes, attachments, consent, post_grad } = data.data;
      if (contact_type === "person") {
        await Promise.all([
          !titleOptions ? getTitles() : null,
          !genderOptions ? getGenders() : null,
          !countryOptions ? getCountries() : null,
          !academicYears ? getAcademicYears() : null,
          !personRelationshipOptions ? getPersonRelationships() : null,
          !contactAddressesType ? getContactAddressesType() : null,
          !institutionOptions ? getInstitutionOptions() : null
        ]);
      } else if (contact_type === "organisation") {
        await Promise.all([
          !countryOptions ? getCountries() : null,
          !organisationRelationshipOptions ? getOrganisationRelationships() : null,
          !titleOptions ? getTitles() : null,
          !contactAddressesType ? getContactAddressesType() : null,
          !institutionOptions ? getInstitutionOptions() : null
        ]);
      }
      if (student) {
        await Promise.all([
          getContactsStudentTypeOptions(),
          getProgrammesListOptions(),
          getStudiesStudyProgrammeFormOptions(),
          !hesaDetailOptions ? getHesaDetailOptions() : null,
          getHesaDetailCourseSessionOptions()
        ]);
      }
      if (cleric) {
        !contactsClericOptions && (await getContactsClericOptions());
      }
      if (association) {
        !contactsAssociationOptions && (await getContactsAssociationOptions());
      }
      if (training_facility) {
        await getUserOptions();
      }
      if (post_grad) {
        !studiesStudyProgrammePostgradOptions && (await getStudiesStudyProgrammePostgradOptions());
      }
      if (Component.isMounted) {
        setContactType(contact_type);
        setContactAndRelationships(data.data);
        setNotes(notes);
        setAttachments(attachments);
        setConsents(consent);
        setPostgrad(post_grad);
      }
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.replace("/contact-us");
    }
  };

  const saveContact = async (payload, photoWasDeleted = false) => {
    await Promise.all([
      API.postEditedContact(contactType, { contact_id: contactId, ...payload }),
      photoWasDeleted ? API.deleteContactPhoto(contactId) : () => {}
    ]);
    const { data } = await API.getContact(contactId);
    Component.isMounted && setContactAndRelationships(data.data);
    const { student, cleric, association, training_facility, post_grad } = data.data;
    if (student) {
      getContactsStudentTypeOptions();
      getProgrammesListOptions();
      getStudiesStudyProgrammeFormOptions();
      !hesaDetailOptions && getHesaDetailOptions();
      getHesaDetailCourseSessionOptions();
    }
    if (cleric) {
      !contactsClericOptions && getContactsClericOptions();
    }
    if (association) {
      !contactsAssociationOptions && getContactsAssociationOptions();
    }
    if (training_facility) {
      getUserOptions();
    }
    if (post_grad) {
      !studiesStudyProgrammePostgradOptions && getStudiesStudyProgrammePostgradOptions();
    }
  };

  const deleteContact = (onStartSaving, onFinishSaving) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This contact will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartSaving();
        try {
          await API.deleteContact(contactId);
          history.push("/home/contacts/all-contacts");
        } catch (error) {
          onFinishSaving();
          setSnackbar({ text: errorMessage(error), isError: true });
        }
      },
      () => {
        hideModal();
      }
    );
  };

  const deleteContactRelationship = (contactRelationship, onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: `The "${contactRelationship}" relationship will be removed?`,
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          const relationshipsDataList = {
            person: personRelationshipOptions,
            organisation: organisationRelationshipOptions
          };
          const relationshipId = relationshipsDataList[contactType].find(relationship => relationship.label === contactRelationship).value;
          await API.deleteContactRelationship(contactId, relationshipId);
          const { data } = await API.getContact(contactId);
          Component.isMounted && setContactAndRelationships(data.data);
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishCallback();
      },
      () => {
        hideModal();
      }
    );
  };

  // STUDENT
  const saveStudentForm = async data => {
    await API.putContactsStudentForm(contactId, data);
  };

  const saveHesaDetailForm = async (formName, data, deletionsLog, callback, student) => {
    console.log("2student", student);
    const hesaDetail = student?.hesa_detail;
    const modifiedData = formName === "student" ? data[0] : data;
    try {
      await API.postContactsHesaDetailForm(contactId, formName, {
        ...{ ...hesaDetail, [formName]: modifiedData },
        deletions: deletionsLog
      });
      callback();
      const { data } = await API.getContact(contactId);
      const { contact, student } = data.data;
      if (Component.isMounted) {
        setContact(contact);
        setStudent(student);
      }
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  // ACADEMIC
  const saveAcademicForm = async data => {
    await API.putContactsAcademicForm(contactId, data);
  };

  //Cleric
  const saveClericForm = async data => {
    await API.putContactsClericForm(contactId, data);
  };

  //Staff
  const saveStaffForm = async data => {
    await API.putContactsStaffForm(contactId, data);
  };

  // ASSOCIATION
  const saveAssociationForm = async data => {
    await API.putContactsAssociationForm(contactId, data);
  };

  // CHURCH
  const saveChurchForm = async data => {
    await API.putContactsChurchForm(contactId, data);
  };

  // DIOCESE
  const saveDioceseForm = async data => {
    await API.putContactsDioceseForm(contactId, data);
  };

  // TRAINING FACILITY
  const saveTrainingFacilityForm = async payload => {
    await API.putContactsTrainingFacilityForm(contactId, payload);
  };

  // NOTES
  const saveEditNote = async (id, payload, cb) => {
    try {
      const { data } = await API.putContactsNoteForm(id, payload);
      setNotes(prevState => prevState.map(note => (note.id !== id ? note : data.data)));
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };
  const postNewNote = async (newNote, cb) => {
    try {
      const { data } = await API.postNewNote(contactId, newNote);
      setNotes([...notes, data.data]);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteNote = (noteId, onStartSaving, onFinishSaving) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This note will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartSaving();
        try {
          await API.deleteNote(noteId);
          setNotes(notes.filter(note => note.id !== noteId));
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishSaving();
      },
      () => {
        hideModal();
      }
    );
  };
  // ATTACHMENTS
  const postNewAttachment = async ({ file, description }, cb) => {
    try {
      const { data } = await API.postNewAttachment(contactId, file, description);
      setAttachments([...attachments, data.data]);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const putAttachment = async ({ file, description, id }, cb) => {
    try {
      const { data } = await API.putNewAttachment(contactId, file, description, id);
      setAttachments(prevState => prevState.map(attachment => (attachment.id !== id ? attachment : data.data)));
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteAttachment = (attachmentId, onStartSaving, onFinishSaving) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This attachment will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartSaving();
        try {
          await API.deleteAttachment(attachmentId);
          setAttachments(attachments.filter(attachment => attachment.id !== attachmentId));
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishSaving();
      },
      () => {
        hideModal();
      }
    );
  };

  //MANAGE POSTGRAD
  const putPostgrad = async (payload, cb) => {
    try {
      const { data } = await API.putStudiesStudyProgrammePostgrad(payload);
      setPostgrad(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deletePostgrad = (postgradId, onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This postgrad will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteStudiesStudyProgrammePostgrad(postgradId);
          setPostgrad(undefined);
          setActiveTabName("Notes");
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishCallback();
      },
      () => {
        hideModal();
      }
    );
  };

  //MANAGE CONSENTS
  const saveManageConsents = async payload => {
    await API.putManageConsents(contactId, payload);
  };

  useEffect(() => {
    Component.isMounted = true;
    (async () => {
      await fetchData();
      setIsShown(true);
    })();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isShown) {
      const tabNames = visibleForms().map(form => form.tab.name);
      setActiveTabName(tabNames[0]);
    }
  }, [isShown]);

  useEffect(() => {
    const tabNames = visibleForms().map(form => form.tab.name);
    !tabNames.includes(activeTabName) && setActiveTabName(tabNames[0]);
  }, [student, academic, staff, cleric, association, church, diocese, trainingFacility]);

  useEffect(() => {
    if (activeSnapshotId) {
      (async () => {
        const { data } = await API.getAdminSnapshot(activeSnapshotId);
        setSnapshot(data.data.json_snapshot.hesa_detail);
      })();
    } else {
      setSnapshot(undefined);
    }
  }, [activeSnapshotId]);

  return (
    <div className={classes.wrapper}>
      {!!actions.length && (
        <ActionsDropdown
          actions={actions.map(action => action.name)}
          clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
          style={{ position: "absolute", right: 10, top: -80 }}
        />
      )}
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <>
            <SectionWrapper hasBackButton backButtonText={currentLink.name} clickBackButtonHandler={() => history.push(`/home/contacts/${currentLink.path}`)}>
              {contactType === "person" ? (
                <ContactsPersonForm
                  title={{ onEdit: "Edit the person" }}
                  contactIsSaving={contactIsSaving}
                  initialData={contact}
                  permissions={permissions}
                  titlesDataList={titleOptions}
                  gendersDataList={genderOptions}
                  countriesDataList={countryOptions}
                  academicYears={academicYears}
                  personRelationshipsDataList={personRelationshipOptions}
                  contactAddressesType={contactAddressesType}
                  fetchContactHandler={params => API.getOrganisations(params)}
                  buttons={["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"]}
                  deleteHandler={(onStartSaving, onFinishSaving) => deleteContact(onStartSaving, onFinishSaving)}
                  saveHandler={saveContact}
                  errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
                  successHandler={success => setSuccessNotification({ text: success })}
                  hideModal={hideModal}
                  showModal={showModal}
                />
              ) : (
                <ContactsOrganisationForm
                  title={{ onEdit: "Edit the organisation" }}
                  contactIsSaving={contactIsSaving}
                  initialData={contact}
                  permissions={permissions}
                  countriesDataList={countryOptions}
                  contactAddressesType={contactAddressesType}
                  organisationRelationshipsDataList={organisationRelationshipOptions}
                  buttons={["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"]}
                  fetchContactHandler={params => API.getPersons(params)}
                  deleteHandler={(onStartSaving, onFinishSaving) => deleteContact(onStartSaving, onFinishSaving)}
                  saveHandler={saveContact}
                  errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
                />
              )}
            </SectionWrapper>
            <SectionWrapper>
              <SectionTabs tabs={visibleForms().map(form => form.tab)} activeTabName={activeTabName} clickHandler={tab => setActiveTabName(tab)} />
              {activeForm && activeForm.component}
            </SectionWrapper>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  userOptions: inputDataListsReducer.users,
  titleOptions: inputDataListsReducer.titles,
  genderOptions: inputDataListsReducer.genders,
  countryOptions: inputDataListsReducer.countries,
  personRelationshipOptions: inputDataListsReducer.personRelationships,
  organisationRelationshipOptions: inputDataListsReducer.organisationRelationships,
  hesaDetailOptions: inputDataListsReducer.hesaDetailOptions,
  hesaDetailCourseSessionOptions: inputDataListsReducer.hesaDetailCourseSessionOptions,
  contactsAssociationOptions: inputDataListsReducer.contactsAssociationOptions,
  programmesListOptions: inputDataListsReducer.programmesListOptions,
  contactsClericOptions: inputDataListsReducer.contactsClericOptions,
  studiesStudyProgrammeFormOptions: inputDataListsReducer.studiesStudyProgrammeFormOptions,
  contactsStudentTypeOptions: inputDataListsReducer.contactsStudentTypeOptions,
  contactAddressesType: inputDataListsReducer.contactAddressesType,
  academicYears: inputDataListsReducer.academicYears,
  institutionOptions: inputDataListsReducer.institutionOptions,
  studiesStudyProgrammePostgradOptions: inputDataListsReducer.studiesStudyProgrammePostgradOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getUserOptions: () => withPromise(dispatch, actions.getUserOptions),
    getTitles: () => withPromise(dispatch, actions.getTitles),
    getGenders: () => withPromise(dispatch, actions.getGenders),
    getCountries: () => withPromise(dispatch, actions.getCountries),
    getPersonRelationships: () => withPromise(dispatch, actions.getPersonRelationships),
    getOrganisationRelationships: () => withPromise(dispatch, actions.getOrganisationRelationships),
    getHesaDetailOptions: () => withPromise(dispatch, actions.getHesaDetailOptions),
    getHesaDetailCourseSessionOptions: () => withPromise(dispatch, actions.getHesaDetailCourseSessionOptions),
    getContactsAssociationOptions: () => withPromise(dispatch, actions.getContactsAssociationOptions),
    getProgrammesListOptions: () => withPromise(dispatch, actions.getProgrammesListOptions),
    getContactsClericOptions: () => withPromise(dispatch, actions.getContactsClericOptions),
    getStudiesStudyProgrammeFormOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammeFormOptions),
    getContactsStudentTypeOptions: () => withPromise(dispatch, actions.getContactsStudentTypeOptions),
    getContactAddressesType: () => withPromise(dispatch, actions.getContactAddressesType),
    getAcademicYears: () => withPromise(dispatch, actions.getAcademicYears),
    getInstitutionOptions: () => withPromise(dispatch, actions.getInstitutionOptions),
    getStudiesStudyProgrammePostgradOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammePostgradOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    setSuccessNotification: data => dispatch(actions.setSuccessNotification(data))
  };
};

Component.propTypes = {
  currentLink: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string
  })
};

Component.defaultProps = {
  currentLink: {
    name: "All contacts",
    path: "all-contacts"
  }
};

export const ContactsDetails = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
