import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import isEmail from "validator/lib/isEmail";
import { switchEmptyStringsToNullsInObject, getInitialOptions, all } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, RoleManagerField, Select, TextArea, TextInput, InputLabel, FileInput } from "@Root/components";
import { useDispatch } from "react-redux";

const modifiedInitialData = initialData => {
  const initialDataCopy = JSON.parse(JSON.stringify(initialData));
  initialDataCopy.user_roles = initialDataCopy.user_roles[0];
  return initialDataCopy;
};

export const UserForm = ({ isNew, permissions, options, title, initialData, buttons, deleteHandler, saveHandler }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [file, setFile] = useState(null);
  const [data, setData] = useState(isNew ? JSON.parse(JSON.stringify(initialData)) : modifiedInitialData(initialData));
  const [isSpinning, setIsSpinning] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRoleLabel, setSelectedRoleLabel] = useState("");
  const dispatch = useDispatch();
  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    const { login, password, name, email, user_roles, status } = data;
    if (!login) {
      showError("login", "Required");
      return false;
    }
    if (isNew) {
      if (!password) {
        showError("password", "Required");
        return false;
      }
      if (password && password.length < 5) {
        showError("password", "Password length should be at least 5 characters");
        return false;
      }
    }
    if (!name) {
      showError("name", "Required");
      return false;
    }
    if (!email) {
      showError("email", "Required");
      return false;
    }
    if (email && !isEmail(email)) {
      showError("email", "Invalid email");
      return false;
    }
    if (!user_roles) {
      showError("user_roles", "Required");
      return false;
    }
    if (!status) {
      showError("status", "Required");
      return false;
    }
    return true;
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSpinning(true);
      },
      () => {
        UserForm.isMounted && setIsSpinning(false);
      }
    );
  };

  const modifiedData = () => {
    const modifiedData = switchEmptyStringsToNullsInObject(data);
    file && (modifiedData.photo = file);
    return modifiedData;
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSpinning(true);
    await saveHandler(modifiedData(), () => {
      UserForm.isMounted && setIsEditable(false);
    });
    UserForm.isMounted && setIsSpinning(false);
  };

  useEffect(() => {
    UserForm.isMounted = true;
    return () => {
      UserForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    UserForm.isMounted && setData(isNew ? JSON.parse(JSON.stringify(initialData)) : modifiedInitialData(initialData));
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  useEffect(() => {
    const roles = options.roles;
    const value = data.user_roles;
    const initialOptions = getInitialOptions(roles);
    const selectedOption = initialOptions.find(option => option.value === value);
    const selectedLabel = selectedOption ? selectedOption.label : null;
    setSelectedRoleLabel(selectedLabel);
  }, [options.roles, data.user_roles]);

  return (
    <div className={classes.wrapper}>
      <FormWrapper
        buttons={isEditable ? buttons : []}
        buttonsNames={[{ button: "delete", name: "Delete this user" }]}
        buttonsAreDisabled={isSpinning}
        isSpinning={isSpinning}
        clickCancelButtonHandler={handleClickCancelButton}
        clickDeleteButtonHandler={handleClickDeleteButton}
        clickSaveButtonHandler={handleClickSaveButton}
      >
        <div className={classes.titleWrapper}>
          <SectionTitle title={isEditable ? title.onEdit : title.onRead} />
          <div>{permissions.includes("editUser") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
        </div>
        <div className={classes.contentWrapper}>
          <FileInput
            inputClassNames={!isEditable ? ["borderless"] : []}
            value={data.photo}
            file={file}
            changeHandler={(photo, photoFile) => all(() => handleChangeInput("photo", photo), () => setFile(photoFile))}
            removeHandler={() => all(() => handleChangeInput("photo", null), () => setFile(null))}
            isDisabled={!isEditable}
            restrictions={["image"]}
          />
          <div className={classes.columnWrapper}>
            <div className={classes.inputWrapper}>
              <InputLabel text="Login" hasAsterisk={isEditable} />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.login}
                changeHandler={value => handleChangeInput("login", value)}
                isDisabled={!isEditable}
                error={errorMessage("login")}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text={isNew ? "Password" : "New Password"} hasAsterisk={isEditable && isNew} />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.password}
                changeHandler={value => handleChangeInput("password", value)}
                isDisabled={!isEditable}
                isPassword
                error={errorMessage("password")}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Contact name" hasAsterisk={isEditable} />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.name}
                changeHandler={value => handleChangeInput("name", value)}
                isDisabled={!isEditable}
                error={errorMessage("name")}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Email address" hasAsterisk={isEditable} />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.email}
                changeHandler={value => handleChangeInput("email", value)}
                isDisabled={!isEditable}
                error={errorMessage("email")}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="User role" hasAsterisk={isEditable} />
              {isNew ? (
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.roles}
                  value={data.user_roles}
                  changeHandler={value => handleChangeInput("user_roles", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("user_roles")}
                />
              ) : (
                <RoleManagerField fieldName={selectedRoleLabel} userId={data.id} />
              )}
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Status" hasAsterisk={isEditable} />
              <Select
                inputClassNames={!isEditable ? ["borderless"] : []}
                inputStyle={{ width: 260 }}
                options={options.statuses}
                value={data.status}
                changeHandler={value => handleChangeInput("status", value)}
                isDisabled={!isEditable}
                error={errorMessage("status")}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Phone number" />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.phone_number}
                changeHandler={value => handleChangeInput("phone_number", value)}
                isDisabled={!isEditable}
              />
            </div>
            <div className={classes.textareaWrapper}>
              <InputLabel text="Address" />
              <TextArea
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260, height: 90 }}
                value={data.address}
                changeHandler={value => handleChangeInput("address", value)}
                isDisabled={!isEditable}
              />
            </div>
          </div>
          <div className={classes.columnWrapper}>
            <div className={classes.textareaWrapper}>
              <InputLabel text="Venue UKPRN" />
              <TextArea
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260, height: 350 }}
                value={data.venue_UKPRN}
                changeHandler={value => handleChangeInput("venue_UKPRN", value)}
                isDisabled={!isEditable}
              />
            </div>
            <div className={classes.textareaWrapper}>
              <InputLabel text="Note" />
              <TextArea
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260, height: 90 }}
                value={data.note}
                changeHandler={value => handleChangeInput("note", value)}
                isDisabled={!isEditable}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

UserForm.propTypes = {
  isNew: PropTypes.bool,
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    photo: PropTypes.string,
    login: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    email: PropTypes.string,
    user_roles: PropTypes.arrayOf(PropTypes.number),
    status: PropTypes.string,
    phone_number: PropTypes.string,
    address: PropTypes.string,
    venue_UKPRN: PropTypes.string,
    note: PropTypes.string
  }),
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

UserForm.defaultProps = {
  isNew: false,
  title: {
    onRead: "",
    onEdit: ""
  },
  permissions: [],
  initialData: {
    photo: null,
    login: null,
    name: null,
    password: null,
    email: null,
    user_roles: null,
    status: null,
    phone_number: null,
    address: null,
    venue_UKPRN: null,
    note: null
  },
  buttons: [],
  deleteHandler: () => {},
  saveHandler: () => {}
};
