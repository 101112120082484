import moment from "moment";

export const enumerateDaysBetweenDates = function(startDate, endDate) {
  const dates = [];

  const currDate = moment(startDate, "DD-MM-YYYY").startOf("day");
  const lastDate = moment(endDate, "DD-MM-YYYY").startOf("day");

  while (currDate.add(1, "days").diff(lastDate) < 0) {
    console.log(currDate.toDate());
    dates.push(currDate.clone().toDate());
  }

  return dates;
};
