import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, MultiSelect, DataListAsync, Select, InputLabel } from "@Root/components";
export const ClericForm = ({
  permissions,
  initialData,
  options,
  fetchDioceseByIdHandler,
  fetchDioceseByNameHandler,
  deleteHandler,
  saveHandler,
  errorHandler
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        ClericForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    setIsSavind(true);
    try {
      const modifiedData = switchEmptyStringsToNullsInObject(data);
      await saveHandler(modifiedData);
      ClericForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    ClericForm.isMounted && setIsSavind(false);
  };

  const fetchDioceseLabel = async value => {
    const { data } = await fetchDioceseByIdHandler(value);
    return data.data.diocese_name;
  };

  const fetchDioceseOptions = async value => {
    const { data } = await fetchDioceseByNameHandler(value);
    return data.data.map(option => ({ value: option.id, label: option.diocese_name }));
  };

  useEffect(() => {
    ClericForm.isMounted = true;
    return () => {
      ClericForm.isMounted = false;
    };
  }, []);

  return (
    <FormWrapper
      buttons={isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this cleric" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Cleric Details" : "Cleric Details"} />
        <div className={classes.edit_wrapper}>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Type" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            options={options.contactsClericOptions}
            value={data.type}
            changeHandler={value => handleChangeInput("type", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Diocese" />
          <div style={{ position: "absolute", left: 150 }}>
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              value={data.diocese_id}
              fetchLabelHandler={value => fetchDioceseLabel(value)}
              fetchOptionsHandler={value => fetchDioceseOptions(value)}
              changeHandler={value => handleChangeInput("diocese_id", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Federation House" />
          <div style={{ position: "absolute", left: 150, width: 260 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              options={options.institutionOptions}
              values={data.institution_ids}
              changeHandler={value => handleChangeInput("institution_ids", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

ClericForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    type: PropTypes.string,
    diocese_id: PropTypes.number,
    institutions_ids: PropTypes.arrayOf(PropTypes.number)
  }),
  clergyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

ClericForm.defaultProps = {
  permissions: [],
  initialData: {
    type: null,
    diocese_id: null,
    institutions_ids: []
  },
  titleOptions: [],
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
