import React, { useState, useEffect, useRef } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withPromise, errorMessage } from "@Root/helpers";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { Spinner, ActionsDropdown, Table } from "@Root/components";
import { admissionsMandatoryRules, createDataConfig, trashInformationMandatoryRules } from "@Root/configs";

const Component = ({
  permissions,
  location,
  history,
  studiesStudyProgrammeFormOptions,
  getStudiesStudyProgrammeFormOptions,
  admissionsApplicationsOptions,
  getAdmissionsApplicationsOptions,
  showModal,
  hideModal,
  setSnackbar,
  setSuccessNotification
}) => {
  const tab = location.pathname.split("/")[3];

  const [isShown, setIsShown] = useState(false);
  const [shouldBeUpdated, setShouldBeUpdated] = useState(false);
  const [initialDataWasFetched, setInitialDataWasFetched] = useState(false);

  const hasEditPermission = permissions.includes("edit");

  const tableRef = useRef();

  const actions = () => {
    return initialDataWasFetched
      ? [
          {
            name: "Get PDF",
            handler: () => {
              tableRef.current.createPdf();
            }
          }
        ]
      : [];
  };

  const admissionsDetailsConfig = () => {
    return {
      fetchInitialDataHandler: async () => {
        await getStudiesStudyProgrammeFormOptions();
        await getAdmissionsApplicationsOptions();
      },
      table: () => {
        const { status, ordinand, academic_year_list, study_years, admission_bap_statuses, managed_by } = studiesStudyProgrammeFormOptions;
        const { applications_status } = admissionsApplicationsOptions;
        const mandatoryRules = trashInformationMandatoryRules;
        const columns = () => [
          {
            name: "Student ID",
            field: "student_id",
            searchInputType: "textInput",
            cellType: "link"
          },
          {
            name: "Name",
            field: "name",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Surname",
            field: "surname",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Study ID",
            field: "study_id",
            searchInputType: "textInput",
            cellType: "link"
          },
          {
            name: "Email",
            field: "email",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Programme",
            field: "programme",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Status",
            field: "status",
            searchInputType: "select",
            options: status.map(x => x.label),
            cellType: "text"
          },
          {
            name: "Academic Year",
            field: "academic_years",
            searchInputType: "select",
            options: academic_year_list.map(x => x.label),
            cellType: "text"
          },
          {
            name: "Ordinand",
            field: "ordinand",
            searchInputType: "select",
            options: ordinand.map(x => x.label),
            cellType: "text"
          },
          {
            name: "Diocese",
            field: "diocese",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Context",
            field: "context",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Study Year",
            field: "study_year",
            searchInputType: "select",
            options: study_years.map(x => x.label),
            cellType: "text"
          },
          {
            name: "Programme Manager",
            field: "managed_by",
            searchInputType: "select",
            options: managed_by.map(x => x.label),
            cellType: "text"
          },
          {
            name: "Enquiry Date",
            field: "enquiry",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Application Date",
            field: "application_date",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Application Status",
            field: "application_status",
            searchInputType: "select",
            options: applications_status,
            cellType: hasEditPermission ? "link" : "text"
          },
          {
            name: "Open Day Date",
            field: "open_day_date",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "BAP Date",
            field: "bap_date",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "BAP Status",
            field: "bap_status",
            searchInputType: "select",
            options: admission_bap_statuses,
            cellType: "text"
          },
          {
            name: "Interview Date",
            field: "interview_date",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Ref Sent Date",
            field: "ref_sent_date",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Ref Received Date",
            field: "ref_received_date",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "DDO Name",
            field: "DDO_name",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "DDO Email",
            field: "DDO_Email",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Sponsor Name",
            field: "sponsor",
            searchInputType: "textInput",
            cellType: "text"
          },
          {
            name: "Visa Status",
            field: "visa_status",
            searchInputType: "textInput",
            cellType: "text"
          }
        ];
        return {
          ...createDataConfig(
            "admissionsAdmissionsDetails",
            mandatoryRules,
            columns(),
            "getAdmissionsAdmissionsDetailsTable",
            "admissions-details",
            true,
            true,
            true
          ),
          clickLinkHandlers: {
            "Student ID": row => history.push(`/home/contacts/students/${row.student_id}`),
            "Study ID": row => history.push(`/home/studies/programmes/study/${row.student_id}/${row.study_id}`)
          }
        };
      }
    };
  };

  const applicationsConfig = () => ({
    fetchInitialDataHandler: async () => {
      !admissionsApplicationsOptions && (await getAdmissionsApplicationsOptions());
    },
    table: () => {
      const { applications_status, forms_status } = admissionsApplicationsOptions;
      const columns = () => [
        {
          name: "ID",
          field: "id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Applicant Name",
          field: "applicant_name",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Programme",
          field: "programme",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Date Started",
          field: "date_started",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Email address",
          field: "email",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Ordinand",
          field: "ordinand",
          searchInputType: "select",
          options: ["Y", "N"],
          cellType: "text"
        },
        {
          name: "Diocese",
          field: "diocese",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Attend",
          field: "attend",
          searchInputType: "select",
          options: ["FT", "PT"],
          cellType: "text"
        },
        {
          name: "Mode",
          field: "mode",
          searchInputType: "select",
          options: ["Residential", "Context"],
          cellType: "text"
        },
        {
          name: "Date Finished",
          field: "date_finished",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Actioned?",
          field: "actioned",
          searchInputType: "select",
          options: ["unactioned", "actioned"],
          cellType: hasEditPermission ? "checkbox" : "text"
        },
        {
          name: "Application Status",
          field: "application_status",
          searchInputType: "select",
          options: applications_status,
          cellType: hasEditPermission ? "link" : "text"
        },
        {
          name: "Form Status",
          field: "form_status",
          searchInputType: "select",
          options: forms_status,
          cellType: hasEditPermission ? "link" : "text"
        },
        {
          name: "Removed?",
          field: "removed",
          searchInputType: "select",
          options: ["untrashed", "trashed"],
          cellType: hasEditPermission ? "checkbox" : "text"
        }
      ];
      return {
        ...createDataConfig("admissionsApplicationsConfig", [], columns(), "getAdmissionsApplicationsTable", "applications", true, true, false),
        convertDataHandler: initialData => {
          return initialData.map(row => {
            return { ...row, actioned: row.date_finished ? row.actioned : undefined, form_status: row.form_status !== null ? row.form_status : "none" };
          });
        },
        clickLinkHandlers: {
          ID: row => history.push(`/home/admissions/applications/${row.id}`),
          "Email address": row => {
            window.location.href = `mailto:${row.email}`;
          },
          "Application Status": ({ id: applicationId }) => {
            new Promise((resolve, reject) => {
              showModal("InputModal", {
                title: "Change the Application Status",
                inputType: "select",
                options: applications_status,
                valueIsRequired: true,
                placeholder: "Select a Status",
                resolveButtonText: "Save",
                clickRejectButtonHandler: reject,
                clickResolveButtonHandler: resolve
              });
            }).then(
              async applicationStatusId => {
                hideModal();
                try {
                  await API.postAdmissionsApplicationsApplicationStatus(applicationId, applicationStatusId);
                  setShouldBeUpdated(true);
                } catch (error) {
                  setSnackbar({ text: errorMessage(error), isError: true });
                }
              },
              () => {
                hideModal();
              }
            );
          },
          "Form Status": ({ id: applicationId }) => {
            new Promise((resolve, reject) => {
              showModal("InputModal", {
                title: "Change the Form Status",
                inputType: "select",
                options: forms_status,
                valueIsRequired: true,
                placeholder: "Select a Status",
                resolveButtonText: "Save",
                clickRejectButtonHandler: reject,
                clickResolveButtonHandler: resolve
              });
            }).then(
              async formStatusId => {
                hideModal();
                try {
                  await API.postAdmissionsApplicationsFormStatus(applicationId, formStatusId);
                  setShouldBeUpdated(true);
                } catch (error) {
                  setSnackbar({ text: errorMessage(error), isError: true });
                }
              },
              () => {
                hideModal();
              }
            );
          }
        },
        checkboxes: {
          "Actioned?": {
            values: {
              unactioned: false,
              actioned: true
            },
            isDisabledWhenTrue: true,
            changeHandler: row => {
              new Promise((resolve, reject) => {
                showModal("InputModal", {
                  title: `This application will be imported?`,
                  inputType: "text",
                  placeholder: "Student ID",
                  resolveButtonText: "Proceed",
                  valueIsRequired: true,
                  validationHandler: API.getValidateStudentId,
                  validationErrorMessage: "This student ID is already in use",
                  clickRejectButtonHandler: reject,
                  clickResolveButtonHandler: resolve
                });
              }).then(
                async studentId => {
                  hideModal();
                  try {
                    await API.getConfirmStudentApplication(row.id, studentId);
                    setShouldBeUpdated(true);
                  } catch (error) {
                    setSnackbar({ text: errorMessage(error), isError: true });
                  }
                },
                () => {
                  hideModal();
                }
              );
            }
          },
          "Removed?": {
            values: {
              untrashed: false,
              trashed: true
            },
            changeHandler: (row, callback) => {
              const config = {
                untrashed: {
                  text: "trashed",
                  method: () => API.getAdmissionsApplicationsTrashApplication(row.id)
                },
                trashed: {
                  text: "untrashed",
                  method: () => API.getAdmissionsApplicationsUntrashApplication(row.id)
                }
              }[row.removed];
              new Promise((resolve, reject) => {
                showModal("ConfirmationModal", {
                  text: `This application will be ${config.text}?`,
                  clickRejectButtonHandler: reject,
                  clickResolveButtonHandler: resolve
                });
              }).then(
                async () => {
                  hideModal();
                  try {
                    await config.method();
                    callback();
                  } catch (error) {
                    setSnackbar({ text: errorMessage(error), isError: true });
                  }
                },
                () => {
                  hideModal();
                }
              );
            }
          }
        }
      };
    }
  });

  const configs = {
    "admissions-details": admissionsDetailsConfig,
    applications: applicationsConfig
  };

  const { table, fetchInitialDataHandler = () => {} } = configs[tab]();

  useEffect(() => {
    (async () => {
      try {
        await fetchInitialDataHandler();
        setIsShown(true);
      } catch (error) {
        setSnackbar({ text: errorMessage(error), isError: true });
        history.replace("/contact-us");
      }
    })();
  }, []);

  useEffect(() => {
    shouldBeUpdated && setShouldBeUpdated(false);
  }, [shouldBeUpdated]);

  return (
    <div className={classes.wrapper}>
      {!!actions().length && (
        <ActionsDropdown
          actions={actions().map(action => action.name)}
          clickHandler={actionName =>
            actions()
              .find(action => action.name === actionName)
              .handler()
          }
          style={{ position: "absolute", right: 10, top: -80 }}
        />
      )}
      {isShown ? (
        <Table
          ref={tableRef}
          name={table().name}
          mandatoryRules={table().mandatoryRules}
          columns={table().columns}
          fetchDataHandler={table().fetchDataHandler}
          fetchColumnOptions={table().fetchColumnOptions}
          fetchSaveColumnOptions={table().fetchSaveColumnOptions}
          finishFetchDataHandler={() => setInitialDataWasFetched(true)}
          convertDataHandler={table().convertDataHandler}
          fetchExportedDataHander={table().fetchExportedDataHander}
          clickLinkHandlers={table().clickLinkHandlers}
          hasRules={table().hasRules}
          hasFilters={table().hasFilters}
          hasSorting={table().hasSorting}
          checkboxes={table().checkboxes}
          errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
          successHandler={success => setSuccessNotification({ text: success })}
          shouldBeUpdated={shouldBeUpdated}
        />
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  studiesStudyProgrammeFormOptions: inputDataListsReducer.studiesStudyProgrammeFormOptions,
  admissionsApplicationsOptions: inputDataListsReducer.admissionsApplicationsOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesStudyProgrammeFormOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammeFormOptions),
    getAdmissionsApplicationsOptions: () => withPromise(dispatch, actions.getAdmissionsApplicationsOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    setSuccessNotification: data => dispatch(actions.setSuccessNotification(data))
  };
};

export const AdmissionsTable = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
