import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import isDecimal from "validator/lib/isDecimal";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select, DatePicker, DataListAsync } from "@Root/components";

export const PostgradForm = ({ permissions, isNew, initialData, options, cancelHandler, deleteHandler, saveHandler, fetchSupervisorHandler }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);
  const [error, setError] = useState(null);

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    const { hours_1, hours_2, hours_3 } = data;
    if (hours_1 && !isDecimal(hours_1)) {
      showError("hours_1", "Invalid value. Should be a decimal");
      return false;
    }
    if (hours_2 && !isDecimal(hours_2)) {
      showError("hours_2", "Invalid value. Should be a decimal");
      return false;
    }
    if (hours_3 && !isDecimal(hours_3)) {
      showError("hours_3", "Invalid value. Should be a decimal");
      return false;
    }
    return true;
  };

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    if (isNew) {
      cancelHandler();
    } else {
      setData(initialData);
      setIsEditable(false);
    }
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      data.post_grad_id,
      () => {
        setIsSavind(true);
      },
      () => {
        PostgradForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSavind(true);
    const modifiedData = switchEmptyStringsToNullsInObject(data);
    await saveHandler(modifiedData, () => {
      PostgradForm.isMounted && setIsEditable(false);
    });
    PostgradForm.isMounted && setIsSavind(false);
  };

  const fetchSupervisorLabel = async value => {
    const { data } = await fetchSupervisorHandler({ search: `id:${value}`, limit: "1" });
    return data.data[0].name;
  };

  const checkSupervisorsValue = () => {
    return {
      isValue: Boolean(data["supervisor_1"] || data["supervisor_2"] || data["supervisor_3"]),
      ids: [data["supervisor_1"], data["supervisor_2"], data["supervisor_3"]].filter(value => value)
    };
  };
  const fetchSupervisorOptions = async value => {
    const { data } = await fetchSupervisorHandler({
      search: `name:${value};trashed:No;${checkSupervisorsValue().isValue ? "not_ids:" : ""}${checkSupervisorsValue().ids.join(",")}`,
      searchJoin: "and",
      limit: "100"
    });
    return data.data.map(option => ({ value: option.id, label: option.name }));
  };

  const supervisorsFilter = array => {
    const { supervisor_1, supervisor_2, supervisor_3 } = data;
    return array.filter(item => item.value !== supervisor_1 && item.value !== supervisor_2 && item.value !== supervisor_3);
  };
  useEffect(() => {
    PostgradForm.isMounted = true;
    return () => {
      PostgradForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    PostgradForm.isMounted && setData(initialData);
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  return (
    <FormWrapper
      buttons={isNew ? ["cancel", "save"] : isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this postgrad" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isNew ? "Create Postgrad Details" : isEditable ? "Edit Postgrad Details" : "Postgrad Details"} />
        <div className={classes.wrapper}>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Proposal Date" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.proposal_date}
            changeHandler={value => handleChangeInput("proposal_date", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Status" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            options={options.studiesStudyProgrammePostgradOptions.status}
            value={data.postgard_status}
            changeHandler={value => handleChangeInput("postgard_status", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="TCB Response" />
          <Select
            inputClassNames={["borderless"]}
            style={{ position: "absolute", left: 150, width: 260 }}
            options={options.studiesStudyProgrammePostgradOptions.TCB_responce}
            value={data.TCB_response}
            changeHandler={value => handleChangeInput("TCB_response", value)}
            isDisabled
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="TCB Response Date" />
          <DatePicker
            classNames={["borderless"]}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.TCB_response_date}
            changeHandler={value => handleChangeInput("TCB_response_date", value)}
            isDisabled
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="UOB Date" />
          <DatePicker
            classNames={["borderless"]}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.UOB_date}
            changeHandler={value => handleChangeInput("UOB_date", value)}
            isDisabled
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="UOB Response" />
          <Select
            inputClassNames={["borderless"]}
            style={{ position: "absolute", left: 150, width: 260 }}
            options={options.studiesStudyProgrammePostgradOptions.UOB_responce}
            value={data.UOB_response}
            changeHandler={value => handleChangeInput("UOB_response", value)}
            isDisabled
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="UOB Response Date" />
          <DatePicker
            classNames={["borderless"]}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.UOB_response_date}
            changeHandler={value => handleChangeInput("UOB_response_date", value)}
            isDisabled
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Dissertation Title" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.dissertation_title}
            changeHandler={value => handleChangeInput("dissertation_title", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Dissertation Topic" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.dissertation_topic}
            changeHandler={value => handleChangeInput("dissertation_topic", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Upgrade Date" />
          <DatePicker
            classNames={["borderless"]}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.upgrade_date}
            changeHandler={value => handleChangeInput("upgrade_date", value)}
            isDisabled
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Programme Manager" />
          <div style={{ position: "absolute", left: 640 }}>
            <Select
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              options={options.studiesStudyProgrammeFormOptions.managed_by}
              value={data.managed_by_id}
              changeHandler={value => handleChangeInput("managed_by_id", value)}
              isDisabled={!isEditable}
              error={errorMessage("managed_by_id")}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Submission Date 1" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.submission_date_1}
            changeHandler={value => handleChangeInput("submission_date_1", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Submission Date 2" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.submission_date_2}
            changeHandler={value => handleChangeInput("submission_date_2", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Supervisor 1" />
          <div style={{ position: "absolute", left: 150 }}>
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              value={data.supervisor_1}
              fetchLabelHandler={async value => fetchSupervisorLabel(value)}
              fetchOptionsHandler={async value => await fetchSupervisorOptions(value)}
              changeHandler={supervisor_1 => handleChangeInput("supervisor_1", supervisor_1)}
              isDisabled={!isEditable}
            />
          </div>
          <InputLabel style={{ position: "absolute", left: 490 }} text="Hours 1" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.hours_1}
            changeHandler={value => handleChangeInput("hours_1", value)}
            isDisabled={!isEditable}
            error={errorMessage("hours_1")}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Supervisor 2" />
          <div style={{ position: "absolute", left: 150 }}>
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              value={data.supervisor_2}
              fetchLabelHandler={async value => await fetchSupervisorLabel(value)}
              fetchOptionsHandler={async value => await fetchSupervisorOptions(value)}
              changeHandler={supervisor_2 => handleChangeInput("supervisor_2", supervisor_2)}
              isDisabled={!isEditable}
            />
          </div>
          <InputLabel style={{ position: "absolute", left: 490 }} text="Hours 2" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.hours_2}
            changeHandler={value => handleChangeInput("hours_2", value)}
            isDisabled={!isEditable}
            error={errorMessage("hours_2")}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Supervisor 3" />
          <div style={{ position: "absolute", left: 150 }}>
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              value={data.supervisor_3}
              fetchLabelHandler={async value => await fetchSupervisorLabel(value)}
              fetchOptionsHandler={async value => await fetchSupervisorOptions(value)}
              changeHandler={supervisor_3 => handleChangeInput("supervisor_3", supervisor_3)}
              isDisabled={!isEditable}
            />
          </div>
          <InputLabel style={{ position: "absolute", left: 490 }} text="Hours 3" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.hours_3}
            changeHandler={value => handleChangeInput("hours_3", value)}
            isDisabled={!isEditable}
            error={errorMessage("hours_3")}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Examiner" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.examiner}
            changeHandler={value => handleChangeInput("examiner", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="Upgrader" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            value={data.upgrader}
            changeHandler={value => handleChangeInput("upgrader", value)}
            isDisabled={!isEditable}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

const arrayOfValueLabelShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })
);

PostgradForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  isNew: PropTypes.bool,
  initialData: PropTypes.shape({
    proposal_date: PropTypes.string,
    postgard_status: PropTypes.string,
    TCB_response: PropTypes.string,
    TCB_response_date: PropTypes.string,
    UOB_date: PropTypes.string,
    UOB_response: PropTypes.string,
    UOB_response_date: PropTypes.string,
    dissertation_title: PropTypes.string,
    dissertation_topic: PropTypes.string,
    upgrade_date: PropTypes.string,
    submission_date_1: PropTypes.string,
    submission_date_2: PropTypes.string,
    supervisor_1: PropTypes.string,
    hours_1: PropTypes.string,
    supervisor_2: PropTypes.string,
    hours_2: PropTypes.string,
    supervisor_3: PropTypes.string,
    hours_3: PropTypes.string,
    examiner: PropTypes.string,
    upgrader: PropTypes.string,
    managed_by_id: PropTypes.number
  }),
  options: PropTypes.shape({
    status: arrayOfValueLabelShape,
    TCB_responce: arrayOfValueLabelShape,
    UOB_responce: arrayOfValueLabelShape
  }),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

PostgradForm.defaultProps = {
  permissions: [],
  isNew: false,
  initialData: {
    proposal_date: null,
    postgard_status: null,
    TCB_response: null,
    TCB_response_date: null,
    UOB_date: null,
    UOB_response: null,
    UOB_response_date: null,
    dissertation_title: null,
    dissertation_topic: null,
    upgrade_date: null,
    submission_date_1: null,
    submission_date_2: null,
    supervisor_1: null,
    hours_1: null,
    supervisor_2: null,
    hours_2: null,
    supervisor_3: null,
    hours_3: null,
    examiner: null,
    upgrader: null,
    managed_by_id: null
  },
  options: {},
  cancelHandler: () => {},
  deleteHandler: () => {},
  fetchSupervisorHandler: () => {},
  saveHandler: () => {}
};
