import moment from "moment";

export const datePeriods = {
  All: ["1990-01-01", "2030-01-01"],
  Today: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  Yesterday: [
    moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
    moment()
      .subtract(1, "days")
      .format("YYYY-MM-DD")
  ],
  "This week": [
    moment()
      .startOf("isoWeek")
      .format("YYYY-MM-DD"),
    moment()
      .endOf("isoWeek")
      .format("YYYY-MM-DD")
  ],
  "Last week": [
    moment()
      .subtract(1, "weeks")
      .startOf("isoWeek")
      .format("YYYY-MM-DD"),
    moment()
      .subtract(1, "weeks")
      .endOf("isoWeek")
      .format("YYYY-MM-DD")
  ],
  "This month": [
    moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    moment()
      .endOf("month")
      .format("YYYY-MM-DD")
  ],
  "Last month": [
    moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD"),
    moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD")
  ],
  "This year": [
    moment()
      .startOf("year")
      .format("YYYY-MM-DD"),
    moment()
      .endOf("year")
      .format("YYYY-MM-DD")
  ],
  "Last year": [
    moment()
      .subtract(1, "year")
      .startOf("year")
      .format("YYYY-MM-DD"),
    moment()
      .subtract(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD")
  ]
};
