import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { errorMessage } from "@Root/helpers";
import { Spinner, InstitutionForm } from "@Root/components";

import { SectionWrapper } from "@Root/HOCs";

const Institution = ({ location, history, permissions, showModal, hideModal, setSnackbar }) => {
  const institutionId = location.pathname.split("/").pop();

  const [institution, setInstitution] = useState(undefined);
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await API.getAdminInstitution(institutionId);
      setInstitution(data.data);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const postInstitution = async (payload, cb) => {
    try {
      const { data } = await API.postAdminInstitution(payload);
      setInstitution(data.data);
      cb();
      history.push(`/home/admin/other/institutions/${data.data.id}`);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const putInstitution = async (payload, cb) => {
    try {
      const { data } = await API.putAdminInstitution(payload);
      setInstitution(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteInstitution = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This institution will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteAdminInstitution(institutionId);
          history.push("/home/admin/other/institutions");
        } catch (error) {
          onFinishCallback();
          setSnackbar({ text: errorMessage(error), isError: true });
        }
      },
      () => {
        hideModal();
      }
    );
  };

  useEffect(() => {
    Institution.isMounted = true;
    (async () => {
      institutionId !== "new" && (await fetchData());
      Institution.isMounted && setIsShown(true);
    })();
    return () => {
      Institution.isMounted = false;
    };
  }, []);

  const formConfig = {
    new: {
      isNew: true,
      title: { onEdit: "Create an institution" },
      buttons: ["save"],
      saveHandler: (payload, cb) => postInstitution(payload, cb)
    },
    existent: {
      title: { onEdit: "Edit the institution" },
      initialData: institution,
      buttons: ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"],
      deleteHandler: (onStartCallback, onFinishCallback) => deleteInstitution(onStartCallback, onFinishCallback),
      saveHandler: (payload, cb) => putInstitution(payload, cb)
    }
  }[institutionId === "new" ? "new" : "existent"];

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper hasBackButton backButtonText="Institutions" clickBackButtonHandler={() => history.push("/home/admin/other/institutions")}>
          <InstitutionForm
            permissions={permissions}
            isNew={formConfig.isNew}
            title={formConfig.title}
            initialData={formConfig.initialData}
            buttons={formConfig.buttons}
            deleteHandler={formConfig.deleteHandler}
            saveHandler={formConfig.saveHandler}
          />
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => ({
  permissions: authReducer.user.permissions
});

const mapDispatchToProps = dispatch => {
  return {
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const AdminInstitution = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Institution)
);
