import React from "react";
import styles from "./style.module.scss";
import { RoundPlusButton, DataList, Select, TextInput, CheckboxInput } from "@Root/components";
import CrossIcon from "../../../../../assets/icons/x.svg";
export const SubAddressesFormForFields = ({
  style,
  changeHandler,
  openedId,
  changeOpenTab,
  errorMessage,
  isEditable,
  addresses,
  onCreateNewAddressField,
  onRemoveAddressField,
  countryOptions,
  contactAddressesType,
  isCreate,
  error
}) => {
  const onToggleShowContent = id => {
    changeOpenTab(id);
  };
  const generateAddressConfig = () => {
    return {
      config: {
        inputs: [
          {
            name: "Current address",
            field: "current_address",
            type: "checkbox",
            validations: []
          },
          {
            name: "Address line 1",
            field: "address_line_1",
            type: "textInput",
            validations: []
          },
          {
            name: "Address line 2",
            field: "address_line_2",
            type: "textInput",
            validations: []
          },
          {
            name: "Address line 3",
            field: "address_line_3",
            type: "textInput",
            validations: []
          },
          {
            name: "City",
            field: "city",
            type: "textInput",
            validations: []
          },
          {
            name: "Postcode",
            field: "postcode",
            type: "textInput",
            validations: []
          },
          {
            name: "County",
            field: "county",
            type: "textInput",
            validations: []
          },
          {
            name: "Country",
            field: "country_id",
            type: "dataList",
            options: countryOptions,
            validations: []
          },
          {
            name: "Address Type",
            field: "address_type",
            type: "select",
            options: contactAddressesType,
            validations: []
          }
        ]
      }
    };
  };
  const { inputs } = generateAddressConfig().config;
  return (
    <div className={styles.accordionWrapper}>
      {addresses.map(({ id, ...rest }, index) => {
        const title = `Address ${index + 1}`;
        return (
          <div className={styles.accordion} style={style} key={id}>
            <div className={styles.title} onClick={() => !rest.current_address && onToggleShowContent(id)}>
              {title}
            </div>
            <div className={`${styles.content} ${openedId === id || rest.current_address ? `${styles.isShow}` : `${styles.isHide}`}`}>
              {inputs.map(({ name, type, options, field }) => {
                return (
                  <div
                    className={`${styles.field} ${
                      (field === "address_type" ? errorMessage(`${field}/${id}`) : errorMessage(field)) ? styles.margin_bottom : ""
                    }`}
                    key={name}
                  >
                    <span className={styles.label}>{name}</span>
                    {type === "textInput" ? (
                      <TextInput
                        style={{ height: "38px" }}
                        changeHandler={value => changeHandler(id, field, value)}
                        value={rest[field]}
                        isDisabled={!isEditable}
                      />
                    ) : type === "select" ? (
                      <Select
                        options={options}
                        changeHandler={value => changeHandler(id, field, value)}
                        value={rest[field]}
                        error={field === "address_type" ? errorMessage(`${field}/${id}`) : errorMessage(field)}
                        isDisabled={!isEditable}
                      />
                    ) : type === "checkbox" ? (
                      <CheckboxInput
                        style={{ height: 38 }}
                        isChecked={rest[field]}
                        changeHandler={value => changeHandler(id, field, value)}
                        isDisabled={!isEditable}
                      />
                    ) : (
                      type === "dataList" && (
                        <DataList
                          inputClassNames={!isEditable ? ["borderless"] : []}
                          inputStyle={{ width: "100%", border: "1px solid #d8d8d8" }}
                          options={options}
                          value={rest[field]}
                          searchPlaceholder="Start typing country"
                          changeHandler={value => changeHandler(id, field, value)}
                          maxVisibleOptionsQuantity={5}
                          error={errorMessage("country_id")}
                          isDisabled={!isEditable}
                        />
                      )
                    )}
                  </div>
                );
              })}
              {rest.address_type === "other" && (
                <div className={styles.field}>
                  <span className={styles.label}>Other address type</span>
                  <TextInput
                    style={{ height: "38px" }}
                    changeHandler={value => changeHandler(id, "other_address_type", value)}
                    value={rest["other_address_type"]}
                    maxLength={255}
                    isDisabled={!isEditable}
                  />
                </div>
              )}
            </div>
            {index === 0 && isEditable && isCreate ? (
              <RoundPlusButton style={{ position: "absolute", right: "-40px", top: "5px" }} clickHandler={onCreateNewAddressField} />
            ) : (
              index + 1 === addresses.length &&
              isEditable &&
              isCreate && (
                <img
                  src={CrossIcon}
                  style={{ position: "absolute", right: "-20px", top: "15px", cursor: "pointer" }}
                  onClick={() => onRemoveAddressField(id)}
                />
              )
            )}
          </div>
        );
      })}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
