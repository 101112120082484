import * as types from "../types";
import updateState from "../../helpers/updateState";

const initialState = {
  token: null,
  error: null,
  recoveryToken: null,
  user: {}
};

const setToken = (state, action) => {
  return updateState(state, {
    token: action.token
  });
};

const setAuthError = (state, action) => {
  return updateState(state, {
    error: action.error
  });
};

const setRecoveryToken = (state, action) => {
  return updateState(state, {
    recoveryToken: action.token
  });
};

const setUser = (state, action) => {
  return updateState(state, {
    user: action.user
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOKEN:
      return setToken(state, action);
    case types.SET_AUTH_ERROR:
      return setAuthError(state, action);
    case types.SET_RECOVERY_TOKEN:
      return setRecoveryToken(state, action);
    case types.SET_USER:
      return setUser(state, action);
    default:
      return state;
  }
};
