import moment from "moment";

export const localDate = date => {
  return moment
    .parseZone(date)
    .local()
    .format("DD-MM-YYYY HH:mm:ss");
};

export const localLongDate = date => {
  return moment
    .parseZone(date)
    .local()
    .format("Do MMMM YYYY, HH:mm");
};
