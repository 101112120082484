import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { all, emailIsValid } from "@Root/helpers";
import { SubformWrapper } from "@Root/HOCs";
import { SubformInputGroup } from "./SubformInputGroup/";

export const Subform = ({ name, initialData, isEditable, groupsConfigs, saveHandler }) => {
  const [data, setData] = useState(JSON.parse(JSON.stringify(initialData)));
  const [isOpened, setIsOpened] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [error, setError] = useState(null);
  const [deletionsLog, setDeletionsLog] = useState([]);

  const { level0GroupConfig, level1GroupsConfigs = [], level2GroupsConfigs = [] } = groupsConfigs;

  const initialFieldValue = field => {
    return field !== "valid_from" ? null : "01-01-1800";
  };

  const handleAddLevel0 = () => {
    const group0 = { id: null };
    level0GroupConfig.inputs.forEach(input => {
      group0[input.field] = initialFieldValue(input.field);
    });
    if (level1GroupsConfigs) {
      level1GroupsConfigs.forEach((level1GroupConfig, i) => {
        const group1 = { id: null };
        level1GroupConfig.inputs.forEach(input => {
          group1[input.field] = initialFieldValue(input.field);
        });
        group0[level1GroupConfig.field] = [{ ...group1 }];
        if (level2GroupsConfigs) {
          level2GroupsConfigs.forEach(level2GroupConfig => {
            const group2 = { id: null };
            level2GroupConfig.inputs.forEach(input => {
              group2[input.field] = initialFieldValue(input.field);
            });
            group0[level1GroupConfig.field][i][level2GroupConfig.field] = [{ ...group2 }];
          });
        }
      });
    }
    setData([...data, group0]);
  };

  const handleAddLevel1 = (group0Index, level1GroupConfigIndex) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const group1 = { id: null };
    level1GroupsConfigs[level1GroupConfigIndex].inputs.forEach(input => {
      group1[input.field] = initialFieldValue(input.field);
    });
    if (level2GroupsConfigs) {
      level2GroupsConfigs.forEach(level2GroupConfig => {
        const group2 = { id: null };
        level2GroupConfig.inputs.forEach(input => {
          group2[input.field] = initialFieldValue(input.field);
        });
        group1[level2GroupConfig.field] = [{ ...group2 }];
      });
    }
    dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field] = [
      ...dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field],
      group1
    ];
    setData(dataCopy);
  };

  const handleAddLevel2 = (group0Index, level1GroupConfigIndex, group1Index, level2GroupConfigIndex) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const group2 = { id: null };
    level2GroupsConfigs[level2GroupConfigIndex].inputs.forEach(input => {
      group2[input.field] = initialFieldValue(input.field);
    });
    dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field] = [
      ...dataCopy[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field],
      group2
    ];
    setData(dataCopy);
  };

  const handleChangeLevel0 = (group0Index, field, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy[group0Index][field] = value;
    setData(dataCopy);
  };

  const handleChangeLevel1 = (group0Index, group1Index, level1GroupConfigIndex, field, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field;
    dataCopy[group0Index][level1GroupConfigField][group1Index][field] = value;
    setData(dataCopy);
  };

  const handleChangeLevel2 = (group0Index, group1Index, level1GroupConfigIndex, group2Index, level2GroupConfigIndex, field, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field;
    const level2GroupConfigField = level2GroupsConfigs[level2GroupConfigIndex].field;
    dataCopy[group0Index][level1GroupConfigField][group1Index][level2GroupConfigField][group2Index][field] = value;
    setData(dataCopy);
  };

  const handleDeleteLevel0 = group0Index => {
    const id0 = data[group0Index].id;
    if (id0 !== null) {
      const deletionsLogCopy = deletionsLog.filter(log => log.level_0.id !== id0);
      deletionsLogCopy.push({
        level_0: {
          field: name,
          id: id0
        }
      });
      setDeletionsLog(deletionsLogCopy);
    }
    setData(data.filter((group, i) => i !== group0Index));
  };

  const handleDeleteLevel1 = (group0Index, group1Index, level1GroupConfigIndex) => {
    const id0 = data[group0Index].id;
    const id1 = data[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index].id;
    if (id0 !== null && id1 !== null) {
      const deletionsLogCopy = deletionsLog.filter(log => !(log.level_0.id === id0 && log.level_1.id === id1));
      deletionsLogCopy.push({
        level_0: {
          field: name,
          id: id0
        },
        level_1: {
          field: level1GroupsConfigs[level1GroupConfigIndex].field,
          id: id1
        }
      });
      setDeletionsLog(deletionsLogCopy);
    }
    const dataCopy = JSON.parse(JSON.stringify(data));
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field;
    dataCopy[group0Index][level1GroupConfigField] = dataCopy[group0Index][level1GroupConfigField].filter((group, i) => i !== group1Index);
    setData(dataCopy);
  };

  const handleDeleteLevel2 = (group0Index, group1Index, level1GroupConfigIndex, group2Index, level2GroupConfigIndex) => {
    const id0 = data[group0Index].id;
    const id1 = data[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index].id;
    const id2 =
      data[group0Index][level1GroupsConfigs[level1GroupConfigIndex].field][group1Index][level2GroupsConfigs[level2GroupConfigIndex].field][group2Index].id;
    if (id0 !== null && id1 !== null && id2 !== null) {
      const deletionsLogCopy = [...deletionsLog];
      deletionsLogCopy.push({
        level_0: {
          field: name,
          id: id0
        },
        level_1: {
          field: level1GroupsConfigs[level1GroupConfigIndex].field,
          id: id1
        },
        level_2: {
          field: level2GroupsConfigs[level2GroupConfigIndex].field,
          id: id2
        }
      });
      setDeletionsLog(deletionsLogCopy);
    }
    const dataCopy = JSON.parse(JSON.stringify(data));
    const level1GroupConfigField = level1GroupsConfigs[level1GroupConfigIndex].field;
    const level2GroupConfigField = level2GroupsConfigs[level2GroupConfigIndex].field;
    dataCopy[group0Index][level1GroupConfigField][group1Index][level2GroupConfigField] = dataCopy[group0Index][level1GroupConfigField][group1Index][
      level2GroupConfigField
    ].filter((group, i) => i !== group2Index);
    setData(dataCopy);
  };

  const formIsValid = () => {
    let message;
    let inputPath;
    outer: for (let i in data) {
      const rootGroup = data[i];
      for (let rootGroupInput in rootGroup) {
        const level0GroupEmbeddedFields = level1GroupsConfigs.map(level1GroupConfig => level1GroupConfig.field);
        const level0GroupConfigFields = level0GroupConfig.inputs.map(input => input.field);
        let input;
        if (!level0GroupEmbeddedFields.includes(rootGroupInput)) {
          if (level0GroupConfigFields.includes(rootGroupInput)) {
            const value = rootGroup[rootGroupInput];
            const validations = level0GroupConfig.inputs.find(input => input.field === rootGroupInput).validations || [];
            for (let validation of validations) {
              if (validation === "required") {
                if (!value) {
                  message = "Required";
                  input = rootGroupInput;
                  break;
                }
              } else if (validation === "email") {
                if (value && !emailIsValid(value)) {
                  message = "Invalid email";
                  input = rootGroupInput;
                  break;
                }
              } else if (validation === "unique") {
                let wasUsedTwoTimes = false;
                let lastAppearanceIndex = null;
                data.forEach((level0Group, level0GroupIndex) => {
                  if (level0Group[rootGroupInput] === value) {
                    lastAppearanceIndex = level0GroupIndex;
                    if (level0GroupIndex !== +i) wasUsedTwoTimes = true;
                  }
                });
                if (wasUsedTwoTimes && lastAppearanceIndex === +i) {
                  message = "This option is already in use";
                  input = rootGroupInput;
                  break;
                }
              }
            }
            if (input) {
              inputPath = `${i}/${input}`;
              break outer;
            }
          }
        } else {
          const level1GroupConfig = level1GroupsConfigs.find(level1GroupConfig => level1GroupConfig.field === rootGroupInput);
          const level1GroupEmbeddedFields = level2GroupsConfigs.map(level2GroupConfig => level2GroupConfig.field);
          const level1GroupConfigFields = level1GroupConfig.inputs.map(input => input.field);
          for (let k in rootGroup[rootGroupInput]) {
            const level1Group = rootGroup[rootGroupInput][k];
            for (let level1GroupInput in level1Group) {
              let input;
              if (!level1GroupEmbeddedFields.includes(level1GroupInput)) {
                if (level1GroupConfigFields.includes(level1GroupInput)) {
                  const value = level1Group[level1GroupInput];
                  const validations = level1GroupConfig.inputs.find(input => input.field === level1GroupInput).validations || [];
                  for (let validation of validations) {
                    if (validation === "required") {
                      if (!value) {
                        message = "Required";
                        input = level1GroupInput;
                        break;
                      }
                    } else if (validation === "email") {
                      if (value && !emailIsValid(value)) {
                        message = "Invalid email";
                        input = level1GroupInput;
                        break;
                      }
                    }
                  }
                  if (input) {
                    inputPath = `${i}/${k}/${input}`;
                    break outer;
                  }
                }
              } else {
                const level2GroupConfig = level2GroupsConfigs.find(level2GroupConfig => level2GroupConfig.field === level1GroupInput);
                const level2GroupConfigFields = level2GroupConfig.inputs.map(input => input.field);
                for (let l in rootGroup[rootGroupInput][k][level1GroupInput]) {
                  const level2Group = rootGroup[rootGroupInput][k][level1GroupInput][l];
                  for (let level2GroupInput in level2Group) {
                    let input;
                    if (level2GroupConfigFields.includes(level2GroupInput)) {
                      const value = level2Group[level2GroupInput];
                      const validations = level2GroupConfig.inputs.find(input => input.field === level2GroupInput).validations || [];
                      for (let validation of validations) {
                        if (validation === "required") {
                          if (!value) {
                            message = "Required";
                            input = level2GroupInput;
                            break;
                          }
                        } else if (validation === "email") {
                          if (value && !emailIsValid(value)) {
                            message = "Invalid email";
                            input = level2GroupInput;
                            break;
                          }
                        }
                      }
                      if (input) {
                        inputPath = `${i}/${k}/${l}/${input}`;
                        break outer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (inputPath) {
      setError({ inputPath, message });
      return false;
    } else {
      return true;
    }
  };

  const handleSave = async () => {
    if (!formIsValid()) return;
    setIsSpinning(true);
    await saveHandler(data, deletionsLog, () => {
      setDeletionsLog([]);
    });
    setIsSpinning(false);
  };

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  useEffect(() => {
    setData(JSON.parse(JSON.stringify(initialData)));
  }, [initialData]);

  return (
    <SubformWrapper
      title={level0GroupConfig.title}
      isOpened={isOpened}
      isOpenedHandler={() => setIsOpened(!isOpened)}
      isEditable={isEditable}
      isRoot
      isSpinning={isSpinning}
      addIsAllowed={data.length < level0GroupConfig.quantity.max ? true : false}
      addHandler={() => all(() => handleAddLevel0(), () => setIsOpened(true))}
      cancelHandler={() => all(() => setData(initialData), () => setDeletionsLog([]))}
      saveHandler={handleSave}
    >
      {data.map((rootGroup, i) => (
        <SubformInputGroup
          inputs={level0GroupConfig.inputs}
          data={rootGroup}
          changeHandler={(field, value) => handleChangeLevel0(i, field, value)}
          deleteIsAllowed={data.length > level0GroupConfig.quantity.min ? true : false}
          deleteHandler={() => handleDeleteLevel0(i)}
          isEditable={isEditable}
          key={i}
          inputPath={`${i}`}
          error={error}
        >
          {level1GroupsConfigs.map((level1GroupConfig, l) => (
            <SubformWrapper
              title={level1GroupConfig.title}
              isEditable={isEditable}
              addIsAllowed={rootGroup[level1GroupConfig.field].length < level1GroupsConfigs[l].quantity.max ? true : false}
              addHandler={() => handleAddLevel1(i, l)}
              key={l}
            >
              {rootGroup[level1GroupConfig.field].map((level1Group, k) => (
                <SubformInputGroup
                  inputs={level1GroupsConfigs[l].inputs}
                  data={level1Group}
                  changeHandler={(field, value) => handleChangeLevel1(i, k, l, field, value)}
                  deleteIsAllowed={rootGroup[level1GroupConfig.field].length > level1GroupsConfigs[l].quantity.min ? true : false}
                  deleteHandler={() => handleDeleteLevel1(i, k, l)}
                  isEditable={isEditable}
                  key={k}
                  inputPath={`${i}/${k}`}
                  error={error}
                >
                  {level2GroupsConfigs.map((level2GroupConfig, j) => (
                    <SubformWrapper
                      title={level2GroupConfig.title}
                      isEditable={isEditable}
                      addIsAllowed={rootGroup[level1GroupConfig.field][k][level2GroupConfig.field].length < level2GroupsConfigs[j].quantity.max ? true : false}
                      addHandler={() => handleAddLevel2(i, l, k, j)}
                      key={j}
                    >
                      {rootGroup[level1GroupConfig.field][k][level2GroupConfig.field].map((level2Group, q) => (
                        <SubformInputGroup
                          inputs={level2GroupsConfigs[j].inputs}
                          data={level2Group}
                          changeHandler={(field, value) => handleChangeLevel2(i, k, l, q, j, field, value)}
                          deleteIsAllowed={rootGroup[level1GroupConfig.field].length > level1GroupsConfigs[l].quantity.min ? true : false}
                          deleteHandler={() => handleDeleteLevel2(i, k, l, q, j)}
                          isEditable={isEditable}
                          key={q}
                          inputPath={`${i}/${k}/${q}`}
                          error={error}
                        />
                      ))}
                    </SubformWrapper>
                  ))}
                </SubformInputGroup>
              ))}
            </SubformWrapper>
          ))}
        </SubformInputGroup>
      ))}
    </SubformWrapper>
  );
};

const groupConfigShape = PropTypes.shape({
  title: PropTypes.string,
  field: PropTypes.string,
  quantity: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number
  }),
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      field: PropTypes.string,
      type: PropTypes.oneOf(["textInput", "select", "dataList", "dataListAsync", "datePicker"]),
      options: PropTypes.arrayOf(PropTypes.object),
      validations: PropTypes.arrayOf(PropTypes.oneOf(["required", "email", "unique"]))
    })
  )
});

Subform.propTypes = {
  name: PropTypes.string,
  initialData: PropTypes.array,
  groupsConfigs: PropTypes.shape({
    level0GroupConfig: groupConfigShape,
    level1GroupsConfigs: PropTypes.arrayOf(groupConfigShape),
    level2GroupsConfigs: PropTypes.arrayOf(groupConfigShape)
  }),
  isEditable: PropTypes.bool,
  saveHandler: PropTypes.func
};

Subform.defaultProps = {
  name: null,
  initialData: [],
  groupsConfigs: {
    level0GroupConfig: {},
    level1GroupsConfigs: [],
    level2GroupsConfigs: []
  },
  isEditable: false,
  saveHandler: () => {}
};
