import React, { Component } from "react";
import "./style.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import DocumentTitle from "react-document-title";
import { LoginForm } from "@Root/components";

class Page extends Component {
  state = {
    isKeptLoggedIn: false
  };

  keepLoggedIn = payload => {
    this.setState({ isKeptLoggedIn: payload });
    localStorage.isKeptLoggedIn = JSON.stringify(payload);
  };

  updateKeepLoggedInCheckbox = () => {
    this.setState({ isKeptLoggedIn: JSON.parse(localStorage.isKeptLoggedIn) });
  };

  componentDidMount() {
    localStorage.isKeptLoggedIn && this.updateKeepLoggedInCheckbox();
  }

  render() {
    const { keepLoggedIn } = this;
    const { isKeptLoggedIn } = this.state;
    const { logIn, error } = this.props;

    return (
      <DocumentTitle title="Login">
        <div className="LoginPage">
          <LoginForm
            isKeptLoggedIn={isKeptLoggedIn}
            error={error}
            keepLoggedInHandler={keepLoggedIn}
            passwordRecoveryHandler={() => this.props.history.push("/password-recovery")}
            logInHandler={logIn}
          />
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = ({ authReducer }) => ({ error: authReducer.error });

const mapDispatchToProps = dispatch => {
  return {
    logIn: (login, password) => dispatch(actions.logIn(login, password))
  };
};

export const LoginPage = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
