import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { Table, SectionTitle } from "@Root/components";
import { API } from "@Root/API";

export const ContactsProgrammesForm = ({ permissions, options, fetchTableDataHandler, clickAddStudyProgrammeHandler, clickProgrammeNameHandler }) => {
  const table = {
    name: "contactsStudentApplications",
    mandatoryRules: [
      {
        id: "Current Year",
        name: "Current Year",
        description: "Current Year",
        matchType: "All of the rules",
        rows: [
          {
            field: "academic_year",
            equality: "is",
            filter: "thisAcademicYear"
          }
        ]
      }
    ],
    columns: [
      {
        name: "Programme",
        field: "program_name",
        cellType: "link",
        searchInputType: "select",
        options: options.programmesListOptions.map(x => x.label)
      },
      {
        name: "Academic Year",
        field: "academic_year",
        cellType: "text",
        searchInputType: "select",
        options: options.academic_year_list.map(x => x.label)
      },
      {
        name: "Study Year",
        field: "study_year",
        cellType: "text",
        searchInputType: "select",
        options: options.study_years.map(x => x.label)
      },
      {
        name: "Programme manager",
        field: "management_by",
        cellType: "text",
        searchInputType: "select",
        options: options.managed_by.map(x => x.label)
      },
      {
        name: "Ordinand",
        field: "ordinand",
        cellType: "text",
        searchInputType: "select",
        options: options.ordinand.map(x => x.label)
      },
      {
        name: "Institution",
        field: "institution",
        cellType: "text",
        searchInputType: "select",
        options: options.institution.map(x => x.label)
      },
      {
        name: "Learning Center",
        field: "training_mode",
        cellType: "text",
        searchInputType: "select",
        options: options.learning_centre.map(x => x.label)
      },
      {
        name: "Mode of Study",
        field: "mode_of_study",
        cellType: "text",
        searchInputType: "select",
        options: options.model_of_study.map(x => x.label)
      },
      {
        name: "Credits",
        field: "credits",
        cellType: "text",
        searchInputType: "textInput"
      }
    ],
    fetchDataHandler: params => fetchTableDataHandler(params),
    fetchColumnOptions: () => API.getColumnOptions("contactsStudentApplications"),
    fetchSaveColumnOptions: options => API.saveColumnOptions("contactsStudentApplications", options),
    fetchExportedDataHander: () => {},
    clickLinkHandlers: {
      Programme: row => clickProgrammeNameHandler(row.id)
    }
  };

  return (
    <FormWrapper>
      <div className={classes.titleWrapper}>
        <SectionTitle title="Study Records" />
        {permissions.includes("create") && (
          <button className={classes.button} onClick={clickAddStudyProgrammeHandler}>
            Add a Study Record
          </button>
        )}
      </div>
      <Table
        style={{ marginTop: 92 }}
        datePeriodDropdownStyle={{ top: -70 }}
        name={table.name}
        mandatoryRules={table.mandatoryRules}
        columns={table.columns}
        fetchDataHandler={table.fetchDataHandler}
        fetchColumnOptions={table.fetchColumnOptions}
        fetchSaveColumnOptions={table.fetchSaveColumnOptions}
        fetchExportedDataHander={table.fetchExportedDataHander}
        clickLinkHandlers={table.clickLinkHandlers}
      />
    </FormWrapper>
  );
};

const arrayOfValueLabelShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })
);

ContactsProgrammesForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.shape({
    programmesListOptions: arrayOfValueLabelShape,
    academic_year_list: arrayOfValueLabelShape,
    study_years: arrayOfValueLabelShape,
    managed_by: arrayOfValueLabelShape,
    ordinand: arrayOfValueLabelShape,
    institution: arrayOfValueLabelShape,
    learning_centre: arrayOfValueLabelShape,
    model_of_study: arrayOfValueLabelShape
  }),
  fetchTableDataHandler: PropTypes.func,
  clickAddStudyProgrammeHandler: PropTypes.func,
  clickProgrammeNameHandler: PropTypes.func
};

ContactsProgrammesForm.defaultProps = {
  fetchTableDataHandler: () => {},
  clickAddStudyProgrammeHandler: () => {},
  clickProgrammeNameHandler: () => {}
};
