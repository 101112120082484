import React, { useState, useEffect, useMemo } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { SectionTitle, MultiDataListAsync, InputLabel, TextInput, EditButton, RoleManager } from "@Root/components";

export const RoleForm = ({
  isNew,
  title,
  initialData,
  fetchUserLabelHandler,
  fetchUserOptionsHandler,
  fetchInstitutionLabelHandler,
  fetchInstitutionOptionsHandler,
  buttons,
  deleteHandler,
  saveHandler,
  userPermissions,
  initialPermissions,
  fullPermissionData,
  activeRole
}) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [permissions, setPermissions] = useState(initialPermissions);
  const [isSpinning, setIsSpinning] = useState(false);
  const [error, setError] = useState(null);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    const { name } = data;
    if (!name) {
      showError("name", "Required");
      return false;
    }
    return true;
  };

  const fetchUserLabel = async userId => {
    const { data } = await fetchUserLabelHandler(userId);
    return data.data.name;
  };

  const fetchUserOptions = async userName => {
    const { data } = await fetchUserOptionsHandler(userName);
    return data.data.map(option => ({ value: option.id, label: option.name }));
  };

  const fetchInstanceLabel = async instanceId => {
    const { data } = await fetchInstitutionLabelHandler(instanceId);
    return data.data.name;
  };

  const fetchInstanceOptions = async instanceName => {
    const { data } = await fetchInstitutionOptionsHandler(instanceName);
    return data.data.map(option => ({ value: option.id, label: option.name }));
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
    setPermissions(initialPermissions);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSpinning(true);
      },
      () => {
        RoleForm.isMounted && setIsSpinning(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSpinning(true);
    const modifiedData = switchEmptyStringsToNullsInObject({ ...data, ...permissions });
    await saveHandler(modifiedData, () => {
      RoleForm.isMounted && setIsEditable(false);
    });
    RoleForm.isMounted && setIsSpinning(false);
  };

  const onChangePermissionsData = data => {
    console.log(data);
    setPermissions(data);
  };

  useEffect(() => {
    RoleForm.isMounted = true;
    return () => {
      RoleForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    RoleForm.isMounted && setData(initialData);
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  console.log("permissions", permissions);
  return (
    <FormWrapper
      buttons={isEditable ? buttons : []}
      buttonsNames={[{ button: "delete", name: "Delete this role" }]}
      buttonsAreDisabled={isSpinning}
      isSpinning={isSpinning}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.title}>
        <SectionTitle title={isEditable ? title.onEdit : title.onRead} />
        <div>{userPermissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <p className={classes.instucration}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <div className={classes.rows}>
        <div className={classes.row}>
          <InputLabel text="Name" hasAsterisk={isEditable} />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 200, width: 340 }}
            value={data.name}
            changeHandler={value => handleChangeInput("name", value)}
            isDisabled={!isEditable}
            error={errorMessage("name")}
          />
        </div>
        <div className={classes.row}>
          <InputLabel text="Users" />
          <div style={{ position: "absolute", left: 200 }}>
            <MultiDataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 340 }}
              values={data.user_ids}
              fetchLabelHandler={value => fetchUserLabel(value)}
              fetchOptionsHandler={value => fetchUserOptions(value)}
              changeHandler={values => handleChangeInput("user_ids", values)}
              isDisabled={!isEditable}
            />
          </div>
          <InputLabel style={{ position: "absolute", left: 560 }} text="Institutions" />
          <div style={{ position: "absolute", left: 760 }}>
            <MultiDataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 340 }}
              values={data.instance_ids}
              fetchLabelHandler={value => fetchInstanceLabel(value)}
              fetchOptionsHandler={value => fetchInstanceOptions(value)}
              changeHandler={values => handleChangeInput("instance_ids", values)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
      <RoleManager
        initialData={permissions}
        onSave={saveHandler}
        defaultRole={activeRole}
        isEditable={isEditable}
        onChangeData={onChangePermissionsData}
        fullPermissionData={fullPermissionData}
      />
    </FormWrapper>
  );
};

RoleForm.propTypes = {
  isNew: PropTypes.bool,
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    name: PropTypes.string,
    user_ids: PropTypes.arrayOf(PropTypes.number),
    instance_ids: PropTypes.arrayOf(PropTypes.number)
  }),
  fetchUserLabelHandler: PropTypes.func,
  fetchUserOptionsHandler: PropTypes.func,
  fetchInstitutionLabelHandler: PropTypes.func,
  fetchInstitutionOptionsHandler: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

RoleForm.defaultProps = {
  isNew: false,
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    name: null,
    user_ids: null,
    instance_ids: null
  },
  fetchUserLabelHandler: () => {},
  fetchUserOptionsHandler: () => {},
  fetchInstitutionLabelHandler: () => {},
  fetchInstitutionOptionsHandler: () => {},
  buttons: [],
  deleteHandler: () => {},
  saveHandler: () => {}
};
