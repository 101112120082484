import React, { useEffect, useState } from "react";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, CheckboxInput, InputLabel } from "@Root/components";
import PropTypes from "prop-types";
import styles from "./style.module.scss";
import classes from "../contacts/student/ContactsHesaDetailForm/style.module.scss";

export const ContactConsentsForm = ({ permissions, initialData, deleteHandler, saveHandler, errorHandler }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        ContactConsentsForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    setIsSavind(true);
    try {
      await saveHandler(data);
      ContactConsentsForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    ContactConsentsForm.isMounted && setIsSavind(false);
  };

  useEffect(() => {
    ContactConsentsForm.isMounted = true;
    return () => {
      ContactConsentsForm.isMounted = false;
    };
  }, []);

  console.log("data", initialData);
  return (
    <FormWrapper
      buttons={isEditable ? ["cancel", "save"] : []}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={styles.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Manage Consents" : "Manage Consents"} />
        <div className={classes.edit_wrapper}>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={styles.rowsWrapper}>
        <div className={styles.rowWrapper}>
          <InputLabel text="Marketing Email" />
          <CheckboxInput isDisabled={!isEditable} isChecked={data?.marketing_email} changeHandler={value => handleChangeInput("marketing_email", value)} />
        </div>{" "}
        <div className={styles.rowWrapper}>
          <InputLabel text="Marketing Post" />
          <CheckboxInput isDisabled={!isEditable} isChecked={data?.marketing_post} changeHandler={value => handleChangeInput("marketing_post", value)} />
        </div>
      </div>
    </FormWrapper>
  );
};

ContactConsentsForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    marketing_email: PropTypes.bool,
    marketing_post: PropTypes.bool
  }),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

ContactConsentsForm.defaultProps = {
  permissions: [],
  initialData: {
    marketing_email: null,
    marketing_post: null
  },
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
