import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import { SectionWrapper } from "@Root/HOCs";
import { withPromise } from "@Root/helpers";
import classes from "./style.module.scss";
import * as actions from "@Root/store";
import { Spinner, Table, ActionsDropdown, StudiesDeliveryModuleForm } from "@Root/components";
const Component = ({
  permissions,
  history,
  getStudiesModuleDeliveryOptions,
  studiesModuleDeliveryOptions,
  studiesModuleCostCenterOptions,
  getStudiesModuleCostCenterOptions,
  studiesModuleInstanceOptions,
  getStudiesModuleInstanceOptions,
  studiesModuleDeliveryRoleOptions,
  getStudiesModuleDeliveryRoleOptions,
  studiesModuleDeliveryLocationOptions,
  getStudiesModuleDeliveryLocationOptions,
  studiesStudentModuleFeeOptions,
  getStudiesStudentModuleFeeOptions,
  location,
  showModal,
  hideModal
}) => {
  const [isShown, setIsShown] = useState(false);
  const [moduleDelivery, setmoduleDelivery] = useState(null);

  const moduleId = location.pathname.split("/")[4];
  const moduleDelId = location.pathname.split("/").pop();

  const actions = [
    ...(permissions.includes("create")
      ? [
          {
            name: "Create a module cost centre",
            handler: () => history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/module-cost-center/new`)
          },
          {
            name: "Create a module instance",
            handler: () => history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/module-instance/new`)
          },
          {
            name: "Create a module delivery role",
            handler: () => history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/module-delivery-role/new`)
          },
          {
            name: "Create a module delivery location",
            handler: () => history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/module-delivery-location/new`)
          },
          {
            name: "Create a student module fee",
            handler: () => history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/student-module-fee/new`)
          }
        ]
      : [])
  ];

  const fetchData = async () => {
    try {
      const data = await Promise.all([
        API.getStudiesModuleDelivery(moduleDelId),
        !studiesModuleDeliveryOptions ? getStudiesModuleDeliveryOptions() : null,
        getStudiesModuleCostCenterOptions(),
        getStudiesModuleInstanceOptions(),
        getStudiesModuleDeliveryRoleOptions(),
        getStudiesModuleDeliveryLocationOptions(),
        getStudiesStudentModuleFeeOptions()
      ]);
      setmoduleDelivery(data[0].data.data);
      StudiesDeliveryModule.isMounted && setIsShown(true);
    } catch (error) {
      console.log(error);
      history.replace("/page-not-found");
    }
  };

  const deleteModule = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This module delivery will be removed?",
        rejectButtonText: "Cancel",
        resolveButtonText: "Proceed",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteStudiesModuleDelivery(moduleDelId);
          history.push(`/home/studies/modules/${location.pathname.split("/")[4]}`);
        } catch (error) {
          onFinishCallback();
          alert("Unable to delete the  module delivery");
        }
      },
      () => {
        hideModal();
      }
    );
  };

  const moduleCostCenterTableData = () => {
    const { hesa_cost_centres } = studiesModuleCostCenterOptions;

    return {
      name: "Module Cost Centers",
      columns: [
        {
          name: "Module cost centre",
          field: "costcn_label",
          searchInputType: "select",
          options: hesa_cost_centres.map(x => x.label),
          cellType: "link"
        },
        {
          name: "Module cost centre proportion",
          field: "hesa_cost_centre_proportion",
          searchInputType: "textInput",
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getModulesCostCentre(moduleDelId, params),
      fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        "Module cost centre": row => history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/module-cost-center/${row.cost_centre_id}`)
      }
    };
  };

  const moduleInstanceTableData = () => {
    const { accreditation_of_priors, modules_counts } = studiesModuleInstanceOptions;

    return {
      name: "Module Instances",
      columns: [
        {
          name: "Module instance identifier",
          field: "module_instance_identifier",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Module instance proportion",
          field: "module_proportion",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Module start date",
          field: "module_start_date",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Language percentage",
          field: "language_percentage",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Module marks",
          field: "module_marks",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Module outcome end date",
          field: "module_outcome_end_date",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Accreditation of prior experiential learning",
          field: "accreditation",
          searchInputType: "select",
          options: accreditation_of_priors.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Module count",
          field: "module_count",
          searchInputType: "select",
          options: modules_counts.map(x => x.label),
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getModulesInstances(moduleDelId, params),
      fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        "Module instance identifier": row =>
          history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/module-instance/${row.module_instance_id}`)
      }
    };
  };

  const moduleDeliveryRoleTableData = () => {
    const { franchise_indicators, role_types, hesa_identificators, venues } = studiesModuleDeliveryRoleOptions;

    return {
      name: "Module Delivery Roles",
      columns: [
        {
          name: "Module delivery role identifier",
          field: "module_delivery_role_identifier",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Franchise indicator",
          field: "franchiseIndicator",
          searchInputType: "select",
          options: franchise_indicators.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Role type",
          field: "roleType",
          searchInputType: "select",
          options: role_types.map(x => x.label),
          cellType: "text"
        },
        {
          name: "HESA identifier",
          field: "hessa",
          searchInputType: "select",
          options: hesa_identificators.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Module delivery role proportion",
          field: "module_delivery_role_proportion",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Venue identifier",
          field: "venue",
          searchInputType: "select",
          options: venues.map(x => x.label),
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getModulesDeliveryRole(moduleDelId, params),
      fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        "Module delivery role identifier": row =>
          history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/module-delivery-role/${row.module_delivery_role_id}`)
      }
    };
  };

  const moduleDeliveryLocationTableData = () => {
    const { venues } = studiesModuleDeliveryLocationOptions;

    return {
      name: "Modules Delivery Locations",
      columns: [
        {
          name: "Module delivery location ID",
          field: "module_delivery_location_id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Module delivery location proportion",
          field: "location_proportion",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Venue",
          field: "venue",
          searchInputType: "select",
          options: venues.map(x => x.label),
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getModulesDeliveryLocation(moduleDelId, params),
      fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        "Module delivery location ID": row =>
          history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/module-delivery-location/${row.module_delivery_location_id}`)
      }
    };
  };

  const studentModuleFeeTableData = () => {
    const { fee_collectors } = studiesStudentModuleFeeOptions;

    return {
      name: "Student Module Fees",
      columns: [
        {
          name: "Student module fee ID",
          field: "student_module_fee_id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Fe amount",
          field: "fee_amount",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Student fee colector",
          field: "student_fee_colector",
          searchInputType: "select",
          options: fee_collectors.map(x => x.label),
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getStudentModulesFee(moduleDelId, params),
      fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        "Student module fee ID": row =>
          history.push(`/home/studies/modules/${moduleId}/module-delivery/${moduleDelId}/student-module-fee/${row.student_module_fee_id}`)
      }
    };
  };

  const deliveryModuleTables = [
    moduleCostCenterTableData,
    moduleInstanceTableData,
    moduleDeliveryRoleTableData,
    moduleDeliveryLocationTableData,
    studentModuleFeeTableData
  ];

  useEffect(() => {
    StudiesDeliveryModule.isMounted = true;
    fetchData();
    return () => {
      StudiesDeliveryModule.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {!!actions.length && (
        <ActionsDropdown
          actions={actions.map(action => action.name)}
          clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
          style={{ position: "absolute", right: 10, top: -80 }}
        />
      )}
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <>
            <SectionWrapper
              hasBackButton
              backButtonText="Back to Module"
              clickBackButtonHandler={() => history.push(`/home/studies/modules/${location.pathname.split("/")[4]}`)}
            >
              <StudiesDeliveryModuleForm
                permissions={permissions}
                title={{ onEdit: "Edit Module Delivery" }}
                options={studiesModuleDeliveryOptions}
                initialData={moduleDelivery}
                buttons={["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"]}
                deleteHandler={(onStartCallback, onFinishCallback) => deleteModule(onStartCallback, onFinishCallback)}
                saveHandler={payload => API.putStudiesModuleDelivery({ module_delivery_id: moduleDelId, ...payload })}
                location={location}
              />
            </SectionWrapper>
            {deliveryModuleTables.map((moduleDeliverySubTable, key) => {
              return (
                <div key={key} className={classes.tableWrapper}>
                  <div className={classes.tableTitle}>{moduleDeliverySubTable().name}</div>
                  <Table
                    style={{ marginTop: 70 }}
                    datePeriodDropdownStyle={{ top: -70 }}
                    name={moduleDeliverySubTable().name}
                    columns={moduleDeliverySubTable().columns}
                    fetchDataHandler={moduleDeliverySubTable().fetchDataHandler}
                    fetchExportedDataHander={moduleDeliverySubTable().fetchExportedDataHander}
                    clickLinkHandlers={moduleDeliverySubTable().clickLinkHandlers}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  studiesModuleDeliveryOptions: inputDataListsReducer.studiesModuleDeliveryOptions,
  studiesModuleCostCenterOptions: inputDataListsReducer.studiesModuleCostCenterOptions,
  studiesModuleInstanceOptions: inputDataListsReducer.studiesModuleInstanceOptions,
  studiesModuleDeliveryRoleOptions: inputDataListsReducer.studiesModuleDeliveryRoleOptions,
  studiesModuleDeliveryLocationOptions: inputDataListsReducer.studiesModuleDeliveryLocationOptions,
  studiesStudentModuleFeeOptions: inputDataListsReducer.studiesStudentModuleFeeOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesModuleDeliveryOptions: () => withPromise(dispatch, actions.getStudiesModuleDeliveryOptions),
    getStudiesModuleCostCenterOptions: () => withPromise(dispatch, actions.getStudiesModuleCostCenterOptions),
    getStudiesModuleInstanceOptions: () => withPromise(dispatch, actions.getStudiesModuleInstanceOptions),
    getStudiesModuleDeliveryRoleOptions: () => withPromise(dispatch, actions.getStudiesModuleDeliveryRoleOptions),
    getStudiesModuleDeliveryLocationOptions: () => withPromise(dispatch, actions.getStudiesModuleDeliveryLocationOptions),
    getStudiesStudentModuleFeeOptions: () => withPromise(dispatch, actions.getStudiesStudentModuleFeeOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal())
  };
};

export const StudiesDeliveryModule = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
