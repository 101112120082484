import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import { SectionWrapper } from "@Root/HOCs";
import { withPromise } from "@Root/helpers";
import classes from "./style.module.scss";
import * as actions from "@Root/store";
import { Spinner, StudiesModuleDeliveryRoleForm } from "@Root/components";

const Component = ({ history, getStudiesModuleDeliveryRoleOptions, studiesModuleDeliveryRoleOptions, location }) => {
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      !studiesModuleDeliveryRoleOptions && (await getStudiesModuleDeliveryRoleOptions());
      Component.isMounted && setIsShown(true);
    } catch (error) {
      console.log(error);
      history.replace("/page-not-found");
    }
  };

  const createModuleDeliveryRole = async payload => {
    const { data } = await API.createStudiesMuduleDeliveryRole(payload);
    history.push(
      `/home/studies/modules/${location.pathname.split("/")[4]}/module-delivery/${data.data.module_delivery_id}/module-delivery-role/${
        data.data.module_delivery_role_id
      }`
    );
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <SectionWrapper
            hasBackButton
            backButtonText="Back to Module Delivery"
            clickBackButtonHandler={() =>
              history.push(`/home/studies/modules/${location.pathname.split("/")[4]}/module-delivery/${location.pathname.split("/")[6]}`)
            }
          >
            <StudiesModuleDeliveryRoleForm
              title={{ onEdit: "Create Module Delivery Role" }}
              initialIsEditable={true}
              generateId={true}
              options={studiesModuleDeliveryRoleOptions}
              buttons={["save"]}
              saveHandler={payload => createModuleDeliveryRole(payload)}
              location={location}
            />
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  studiesModuleDeliveryRoleOptions: inputDataListsReducer.studiesModuleDeliveryRoleOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesModuleDeliveryRoleOptions: () => withPromise(dispatch, actions.getStudiesModuleDeliveryRoleOptions)
  };
};

export const StudiesNewModuleDeliveryRole = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
