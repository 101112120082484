import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import { delay } from "redux-saga";
import { popupHeight, all } from "@Root/helpers";
import { CustomScrollbar, InputError } from "@Root/HOCs";
import { TextInput } from "../TextInput";
import { CrossIcon, PlusIcon } from "@Root/assets";
import { Spinner } from "@Root/components";
import { ArrowIcon } from "@Root/assets";

export class List extends Component {
  state = {
    popupIsShown: false,
    filter: "",
    options: [],
    selectedOptions: [],
    initialOptionIsFetching: false,
    optionsAreFetching: false,
    error: null
  };

  handleClickOutside = () => {
    this.setState({ popupIsShown: false });
  };

  showError = async error => {
    this.setState({ error });
    await delay(3000);
    !this.isUnmounted && this.setState({ error: null });
  };

  fetchOptions = () => {
    this.timeout = setTimeout(async () => {
      this.setState({ optionsAreFetching: true });
      try {
        const options = (await this.props.fetchOptionsHandler(this.state.filter)) || [];
        !this.isUnmounted && this.state.filter && this.setState({ options });
      } catch (error) {
        this.props.errorHandler(error);
      }
      !this.isUnmounted && this.setState({ optionsAreFetching: false });
    }, 1000);
  };

  handleAdd = option => {
    const { selectedOptions } = this.state;
    if (selectedOptions.find(selectedOption => selectedOption.value === option.value)) return;
    this.setState({ selectedOptions: [...selectedOptions, option] });
  };

  handleRemove = option => {
    const { selectedOptions } = this.state;
    this.setState({ selectedOptions: selectedOptions.filter(selectedOption => selectedOption.value !== option.value) });
  };

  async componentDidMount() {
    const { values, fetchLabelHandler } = this.props;
    if (values) {
      this.setState({ initialOptionIsFetching: true });
      try {
        const labels = await Promise.all(values.map(value => fetchLabelHandler(value)));
        !this.isUnmounted && this.setState({ selectedOptions: values.map((value, i) => ({ value, label: labels[i] })) });
      } catch (error) {
        this.props.errorHandler(error);
      }
      !this.isUnmounted && this.setState({ initialOptionIsFetching: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { error, changeHandler } = this.props;
    const { filter, selectedOptions } = this.state;
    if (error !== prevProps.error) {
      error && this.showError(error);
    }
    if (filter !== prevState.filter) {
      clearTimeout(this.timeout);
      filter ? this.fetchOptions() : this.setState({ options: [], optionsAreFetching: false });
    }
    if (selectedOptions !== prevState.selectedOptions) {
      const newValues = selectedOptions.length ? selectedOptions.map(option => option.value) : null;
      changeHandler(newValues);
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
    clearTimeout(this.timeout);
  }

  render() {
    const { popupIsShown, filter, options, selectedOptions, initialOptionIsFetching, optionsAreFetching, error } = this.state;
    const { values, searchPlaceholder, changeHandler, inputClassNames, inputStyle, maxVisibleOptionsQuantity, isDisabled } = this.props;
    const { handleAdd, handleRemove } = this;

    const labels = selectedOptions.map(option => option.label).join(", ");
    const wasSelected = option => !!selectedOptions.find(selectedOption => selectedOption.value === option.value);

    return (
      <div className={classes.wrapper}>
        <InputError error={error}>
          <div
            className={`${classes.input} ${isDisabled ? classes.disabled : ""} 
                        ${inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, "")}`}
            style={inputStyle}
            onClick={() => all(() => !isDisabled && this.setState({ popupIsShown: !popupIsShown }), () => this.setState({ error: null }))}
          >
            {values ? <div className={classes.value}>{labels}</div> : <div className={`${classes.value} ${classes.empty}`} />}
            {initialOptionIsFetching && (
              <div className={classes.spinner}>
                <Spinner size="extra-small" color="dark" />
              </div>
            )}
            {!isDisabled && (
              <>
                {!initialOptionIsFetching && values && (
                  <div
                    className={classes.xIcon}
                    onClick={event => all(() => event.stopPropagation(), () => changeHandler(null), () => this.setState({ selectedOptions: [] }))}
                  >
                    <img src={CrossIcon} alt="" />
                  </div>
                )}
                <div className={classes.icon}>
                  <img style={popupIsShown ? { transform: "rotate(180deg)" } : null} src={ArrowIcon} alt="" />
                </div>
              </>
            )}
          </div>
        </InputError>
        {popupIsShown && (
          <div className={classes.popup}>
            <div style={{ height: popupHeight(selectedOptions.length, maxVisibleOptionsQuantity, 38) }}>
              <CustomScrollbar verticalOnly>
                {selectedOptions.map((option, i) => (
                  <div className={`${classes.option} ${classes.hover}`} onClick={() => handleRemove(option)} key={i}>
                    {option.label}
                    <div className={classes.xIcon}>
                      <img src={CrossIcon} alt="" />
                    </div>
                  </div>
                ))}
              </CustomScrollbar>
            </div>

            <div className={classes.search}>
              <TextInput classNames={["transparent"]} value={filter} changeHandler={filter => this.setState({ filter })} placeholder={searchPlaceholder} />
              {optionsAreFetching && (
                <div className={classes.spinner}>
                  <Spinner size="extra-small" color="dark" />
                </div>
              )}
            </div>
            <div style={{ height: popupHeight(options.length, maxVisibleOptionsQuantity, 38) }}>
              <CustomScrollbar verticalOnly>
                {options.map((option, i) => (
                  <div className={`${classes.option} ${!wasSelected(option) ? classes.hover : ""}`} onClick={() => handleAdd(option)} key={i}>
                    {option.label}
                    {!wasSelected(option) && (
                      <div className={classes.xIcon}>
                        <img src={PlusIcon} alt="" />
                      </div>
                    )}
                  </div>
                ))}
              </CustomScrollbar>
            </div>
          </div>
        )}
      </div>
    );
  }
}

List.propTypes = {
  inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(["borderless", "transparent"])),
  inputStyle: PropTypes.object,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object])),
  fetchLabelHandler: PropTypes.func,
  fetchOptionsHandler: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  changeHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  maxVisibleOptionsQuantity: PropTypes.number,
  error: PropTypes.string,
  errorHandler: PropTypes.func
};

List.defaultProps = {
  inputClassNames: [],
  inputStyle: {},
  values: null,
  fetchLabelHandler: () => {},
  fetchOptionsHandler: () => {},
  searchPlaceholder: "Type to search",
  changeHandler: () => {},
  isDisabled: false,
  maxVisibleOptionsQuantity: 3,
  error: null,
  errorHandler: error => {
    console.log(error);
  }
};

export const MultiDataListAsync = onClickOutside(List);
