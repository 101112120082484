import * as types from "../types";

export const logIn = (login, password) => {
  return {
    type: types.LOG_IN,
    login,
    password
  };
};

export const logOut = history => {
  return {
    type: types.LOG_OUT,
    history
  };
};

export const removeToken = history => {
  return {
    type: types.REMOVE_TOKEN,
    history
  };
};

export const setToken = token => {
  return {
    type: types.SET_TOKEN,
    token
  };
};

export const setAuthError = error => {
  return {
    type: types.SET_AUTH_ERROR,
    error
  };
};

export const sendRecoveryEmail = (email, history) => {
  return {
    type: types.SEND_RECOVERY_EMAIL,
    email,
    history
  };
};

export const checkRecoveryToken = (token, history) => {
  return {
    type: types.CHECK_RECOVERY_TOKEN,
    token,
    history
  };
};

export const setRecoveryToken = token => {
  return {
    type: types.SET_RECOVERY_TOKEN,
    token
  };
};

export const sendNewPassword = (password, history) => {
  return {
    type: types.SEND_NEW_PASSWORD,
    password,
    history
  };
};

export const getUser = promise => {
  return {
    type: types.GET_USER,
    promise
  };
};

export const setUser = user => {
  return {
    type: types.SET_USER,
    user
  };
};
