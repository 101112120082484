import React from "react";
import classes from "./style.module.scss";
import { ArrowIcon, CrossIcon, PlusIcon } from "@Root/assets";

export class AccordionStudentsRoleManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      onOpenExceptions: props.onOpenExceptionsForStudentsRoles,
      onChangeFieldStatus: props.onChangeFieldStatusForStudentsRoles,
      isEditable: props.isEditable
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ data: nextProps.data, isEditable: nextProps.isEditable });
  }

  render() {
    return (
      <div {...{ className: classes.wrapper }}>
        <ul {...{ className: classes.accordion_list }}>
          {this.state.data.map((data, key) => {
            return (
              <li {...{ className: `${classes.accordion_list__item}`, key }}>
                <AccordionItem
                  data={data}
                  onDataChange={this.props.onDataChange}
                  onOpenExceptions={this.state.onOpenExceptions}
                  onChangeFieldStatus={this.state.onChangeFieldStatus}
                  isEditable={this.state.isEditable}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      item: props.data,
      tabs: props.data.tabs,
      title: props.data.name,
      access: props.data.access,
      isEditable: props.isEditable
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ item: nextProps.data, tabs: nextProps.data.tabs, title: nextProps.data.name, isEditable: nextProps.isEditable });
  }

  render() {
    const {
      state: { opened, title, access, tabs, item },
      props: { isEditable }
    } = this;
    return (
      <>
        <div
          {...{
            className: `${classes.accordion_item}, ${opened && `${classes.accordion_item__opened}`}`
          }}
        >
          <div
            {...{
              className: `${classes.accordion_item__line}`,
              onClick: () => {
                this.setState({ opened: !opened });
              }
            }}
          >
            <div className={classes.accordion_item__main}>
              <h3 className={classes.accordion_item__title}>{title}</h3>
              <img className={classes.accordion_item__icon} src={ArrowIcon} alt={"arrow"} />
            </div>
            <div className={classes.accordion_item__status}>{access} access</div>
          </div>
          <div className={classes.accordion_item__inner}>
            <div className={classes.accordion_item__content}>
              <div className={classes.accordion_item__excepts}>
                {tabs.map(({ fields, name }) => (
                  <div className={classes.accordion_item__excepts_except} key={name}>
                    <div className={classes.accordion_item__excepts_except__title}>{name}</div>
                    <div className={classes.accordion_item__excepts_except__actions}>
                      <span>Excepts:</span>
                      {isEditable && (
                        <div
                          className={classes.accordion_item__excepts_except_add_button}
                          onClick={fields.length > 0 ? () => this.props.onOpenExceptions(item, { name, fields }) : null}
                        >
                          <img src={PlusIcon} alt="" />
                          <span>Add</span>
                        </div>
                      )}
                      <div className={classes.accordion_item__excepts_block}>
                        {fields.filter(field => field.disabled).length > 0 ? (
                          fields
                            .filter(field => field.disabled)
                            .map(field => {
                              return (
                                <div
                                  className={`${classes.accordion_item__excepts_block__item} ${classes.accordion_item__excepts_block__item__selected}`}
                                  key={field.name}
                                >
                                  <span>{field.name}</span>
                                  {isEditable && (
                                    <img
                                      src={CrossIcon}
                                      alt=""
                                      onClick={() =>
                                        this.props.onChangeFieldStatus(
                                          item,
                                          {
                                            name,
                                            fields
                                          },
                                          field
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              );
                            })
                        ) : (
                          <span className={classes.accordion_item__excepts_without_label}>No excepts yet</span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
