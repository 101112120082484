import React from "react";
import classes from "./style.module.scss";
import { RoleManager } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";
import { useHistory, useParams } from "react-router";
import { permissionsConfig } from "@Root/configs";

const generateAccordion = () => {
  return permissionsConfig;
};

const data = [
  { options: generateAccordion(), role: { value: "admin", label: "admin" } },
  { options: generateAccordion(), role: { value: "super admin", label: "super admin" } }
];

export const RoleManagerPage = () => {
  const history = useHistory();
  const params = useParams();

  const saveHandler = data => {
    console.log(data);
  };
  return (
    <div className={classes.page}>
      <SectionWrapper
        hasBackButton
        backButtonText="User"
        clickBackButtonHandler={() => history.push(`/home/admin/manage-system-team/system-users/${params.userId}`)}
      >
        <RoleManager
          initialData={data[0].options}
          onSave={saveHandler}
          title={"Manage Role"}
          defaultRole={data[1].role}
          showRolesSelect
          showSaveButton
          isEditable
          showInstruction
          fullPermissionData={data}
        />
      </SectionWrapper>
    </div>
  );
};
