import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { RoundPlusButton, Spinner, SectionTitle, TextInput, InputLabel } from "@Root/components";

export const TypesForm = ({ permissions, title, initialData, deleteHandler, saveHandler }) => {
  const [addType, setAddType] = useState(false);
  const [data, setData] = useState(initialData);
  const [newType, setNewType] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [error, setError] = useState(null);

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    if (!newType) {
      showError("newType", "Field is empty");
      return false;
    }
    return true;
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSpinning(true);
    await saveHandler({ name: newType }, () => {
      if (TypesForm.isMounted) {
        setAddType(false);
        setNewType(null);
      }
    });
    TypesForm.isMounted && setIsSpinning(false);
  };

  const handleClickDeleteButton = async type => {
    await deleteHandler(type, isSpinning => {
      TypesForm.isMounted && setIsSpinning(isSpinning);
    });
  };

  useEffect(() => {
    TypesForm.isMounted = true;
    return () => {
      TypesForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    TypesForm.isMounted && setData(initialData);
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <SectionTitle title={title} />
        {permissions.includes("create") && !addType && (
          <RoundPlusButton
            style={{ marginLeft: 10 }}
            clickHandler={() => {
              setAddType(true);
            }}
          />
        )}
      </div>
      <div className={classes.contentWrapper}>
        {data.map((type, index) => {
          return (
            <div key={index} className={classes.inputWrapper}>
              <InputLabel text={`Type ${index + 1}`} />
              <TextInput classNames={["borderless"]} value={type.name} isDisabled={true} maxLength={255} />
              {permissions.includes("delete") && (
                <div className={classes.delete} onClick={() => handleClickDeleteButton(type.name)}>
                  Delete
                </div>
              )}
            </div>
          );
        })}
        {addType && (
          <div className={classes.inputWrapper}>
            <InputLabel text={`Type ${data.length + 1}`} />
            <TextInput value={newType} isDisabled={false} changeHandler={value => setNewType(value)} error={errorMessage("newType")} maxLength={255} />
            <button className={classes.add} onClick={handleClickSaveButton}>
              Add
            </button>
          </div>
        )}
      </div>
      {isSpinning && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

TypesForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  initialData: PropTypes.arrayOf(PropTypes.object),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

TypesForm.defaultProps = {
  permissions: [],
  title: "",
  initialData: [],
  deleteHandler: () => {},
  saveHandler: () => {}
};
