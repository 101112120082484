import { combineReducers } from "redux";
import { reducer as authReducer } from "./auth.reducer";
import { reducer as modalReducer } from "./modal.reducer";
import { reducer as snackbarReducer } from "./snackbar.reducer";
import { reducer as inputDataListsReducer } from "./inputDataLists.reducer";

export const rootReducer = combineReducers({
  authReducer,
  modalReducer,
  snackbarReducer,
  inputDataListsReducer
});
