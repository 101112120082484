import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { withPromise } from "@Root/helpers";
import { Spinner, Header } from "@Root/components";
import ContactsPage from "./ContactsPage/ContactsPage";
import StudiesPage from "./StudiesPage/StudiesPage";
import AdmissionsPage from "./AdmissionsPage/AdmissionsPage";
import FinancePage from "./FinancePage/FinancePage";
import GDPRPage from "./GDPRPage/GDPRPage";
import AdminPage from "./AdminPage/AdminPage";
import { ModalWrapper } from "@Root/HOCs";

const Page = ({ props, component, setSnackbar, logOut, history, user, getUser }) => {
  const links = [
    { name: "Contacts", path: "contacts" },
    { name: "Studies", path: "studies" },
    { name: "Admissions", path: "admissions" },
    { name: "Finance", path: "finance" },
    { name: "GDPR", path: "gdpr" },
    { name: "Admin", path: "admin" }
  ];

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await getUser();
        setIsShown(true);
      } catch (error) {
        setSnackbar({ text: error.message, isError: true });
      }
    })();
  }, []);

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <>
          <Header
            links={links}
            userName={user.name}
            userIcon={user.photo}
            clickUserHandler={user.id ? () => history.push(`/home/admin/system-users/${user.id}`) : null}
            logOut={() => logOut(history)}
          />
          <Switch>
            <Redirect exact from="/home" to="/home/contacts" />
            <Route path="/home/contacts" component={ContactsPage} />
            <Route path="/home/studies" render={() => <StudiesPage />} />
            <Route path="/home/admissions" render={() => <AdmissionsPage />} />
            <Route path="/home/finance" render={() => <FinancePage />} />
            <Route path="/home/gdpr" render={() => <GDPRPage />} />
            <Route path="/home/admin" render={() => <AdminPage />} />
          </Switch>
        </>
      ) : (
        <Spinner style={{ position: "absolute" }} size="big" />
      )}
      {component && <ModalWrapper component={component} props={props} />}
    </div>
  );
};

const mapStateToProps = ({ modalReducer, authReducer }) => ({ component: modalReducer.component, props: modalReducer.props, user: authReducer.user });

const mapDispatchToProps = dispatch => {
  return {
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    getUser: () => withPromise(dispatch, actions.getUser),
    logOut: history => dispatch(actions.logOut(history))
  };
};

export const HomePage = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
