import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, DataListAsync, MultiSelect } from "@Root/components";

export const ChurchForm = ({
  permissions,
  initialData,
  fetchContactHandler,
  fetchDioceseByIdHandler,
  fetchDioceseByIndexHandler,
  deleteHandler,
  saveHandler,
  errorHandler,
  options
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        ChurchForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    setIsSavind(true);
    try {
      const modifiedData = switchEmptyStringsToNullsInObject(data);
      await saveHandler(modifiedData);
      ChurchForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    ChurchForm.isMounted && setIsSavind(false);
  };

  const fetchContactLabel = async value => {
    const { data } = await fetchContactHandler({ search: `id:${value}`, limit: "1" });
    return data.data[0].name;
  };

  const fetchContactOptions = async value => {
    const { data } = await fetchContactHandler({ search: `name:${value};trashed:No`, searchJoin: "and", limit: "100" });
    return data.data.map(option => ({ value: option.id, label: option.name }));
  };

  const fetchDioceseLabel = async value => {
    const { data } = await fetchDioceseByIdHandler(value);
    return data.data.diocese_index;
  };

  const fetchDioceseOptions = async value => {
    const { data } = await fetchDioceseByIndexHandler(value);
    return data.data.map(option => ({ value: option.id, label: option.diocese_index }));
  };

  useEffect(() => {
    ChurchForm.isMounted = true;
    return () => {
      ChurchForm.isMounted = false;
    };
  }, []);

  return (
    <FormWrapper
      buttons={isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this church" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Church Details" : "Church Details"} />
        <div>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Contact Officer" />
          <div style={{ position: "absolute", left: 150 }}>
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              value={data.contact_officer_id}
              fetchLabelHandler={value => fetchContactLabel(value)}
              fetchOptionsHandler={value => fetchContactOptions(value)}
              changeHandler={value => handleChangeInput("contact_officer_id", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Vicar / Pastor" />
          <div style={{ position: "absolute", left: 150 }}>
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              value={data.vicar_id}
              fetchLabelHandler={value => fetchContactLabel(value)}
              fetchOptionsHandler={value => fetchContactOptions(value)}
              changeHandler={value => handleChangeInput("vicar_id", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Denomination" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.denomination}
            changeHandler={value => handleChangeInput("denomination", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Diocese" />
          <div style={{ position: "absolute", left: 150 }}>
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              value={data.diocese_id}
              fetchLabelHandler={value => fetchDioceseLabel(value)}
              fetchOptionsHandler={value => fetchDioceseOptions(value)}
              changeHandler={value => handleChangeInput("diocese_id", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Federation House" />
          <div style={{ position: "absolute", left: 150, width: 260 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              options={options.institutionOptions}
              values={data.institution_ids}
              changeHandler={value => handleChangeInput("institution_ids", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

ChurchForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    contact_officer_id: PropTypes.number,
    vicar_id: PropTypes.number,
    denomination: PropTypes.string,
    diocese_id: PropTypes.number
  }),
  fetchContactHandler: PropTypes.func,
  fetchDioceseByIdHandler: PropTypes.func,
  fetchDioceseByIndexHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

ChurchForm.defaultProps = {
  permissions: [],
  initialData: {
    contact_officer: null,
    vicar: null,
    denomination: null,
    diocese_id: null
  },
  fetchContactHandler: () => {},
  fetchDioceseByIdHandler: () => {},
  fetchDioceseByIndexHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
