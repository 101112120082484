import { axios } from "@Root/API";

export const postEditedContact = (contactType, data) => {
  const { addresses, relationship_ids, ...rest } = data;
  const formData = new FormData();
  Object.keys(rest).forEach(key => formData.append(key, rest[key]));
  addresses.forEach((address, index) => {
    for (let key in address) {
      formData.append(`addresses[${index}][${key}]`, address[key]);
    }
  });
  if (contactType !== "organisation") {
    relationship_ids.forEach(relationship_id => {
      formData.append(`relationship_ids[]`, relationship_id);
    });
  }

  const url = {
    person: "persons",
    organisation: "organisations"
  };
  return axios.post(`${url[contactType]}/edit`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};
