export const GET_USER_OPTIONS = "GET_USER_OPTIONS";
export const SET_USER_OPTIONS = "SET_USER_OPTIONS";
export const GET_ADMIN_USER_OPTIONS = "GET_ADMIN_USER_OPTIONS";
export const SET_ADMIN_USER_OPTIONS = "SET_ADMIN_USER_OPTIONS";
export const GET_TITLES = "GET_TITLES";
export const SET_TITLES = "SET_TITLES";
export const GET_GENDERS = "GET_GENDERS";
export const SET_GENDERS = "SET_GENDERS";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const GET_PERSON_RELATIONSHIPS = "GET_PERSON_RELATIONSHIPS";
export const SET_PERSON_RELATIONSHIPS = "SET_PERSON_RELATIONSHIPS";
export const GET_ORGANISATION_RELATIONSHIPS = "GET_ORGANISATION_RELATIONSHIPS";
export const SET_ORGANISATION_RELATIONSHIPS = "SET_ORGANISATION_RELATIONSHIPS";
export const GET_HESA_DETAIL_OPTIONS = "GET_HESA_DETAIL_OPTIONS";
export const SET_HESA_DETAIL_OPTIONS = "SET_HESA_DETAIL_OPTIONS";
export const GET_HESA_DETAIL_COURSE_SESSION_OPTIONS = "GET_HESA_DETAIL_COURSE_SESSION_OPTIONS";
export const SET_HESA_DETAIL_COURSE_SESSION_OPTIONS = "SET_HESA_DETAIL_COURSE_SESSION_OPTIONS";
export const GET_STUDENT_APPLICATION_PROGRAMMES = "GET_STUDENT_APPLICATION_PROGRAMMES";
export const SET_STUDENT_APPLICATION_PROGRAMMES = "SET_STUDENT_APPLICATION_PROGRAMMES";
export const GET_CONTACTS_ASSOCIATION_OPTIONS = "GET_CONTACTS_ASSOCIATION_OPTIONS";
export const SET_CONTACTS_ASSOCIATION_OPTIONS = "SET_CONTACTS_ASSOCIATION_OPTIONS";
export const GET_STUDIES_PROGRAMME_OPTIONS = "GET_STUDIES_PROGRAMME_OPTIONS";
export const SET_STUDIES_PROGRAMME_OPTIONS = "SET_STUDIES_PROGRAMME_OPTIONS";
export const GET_STUDIES_MODULE_OPTIONS = "GET_STUDIES_MODULE_OPTIONS";
export const SET_STUDIES_MODULE_OPTIONS = "SET_STUDIES_MODULE_OPTIONS";
export const GET_STUDIES_ENGAGEMENT_OPTIONS = "GET_STUDIES_ENGAGEMENT_OPTIONS";
export const SET_STUDIES_ENGAGEMENT_OPTIONS = "SET_STUDIES_ENGAGEMENT_OPTIONS";
export const GET_STUDIES_SUSPENSIONS_OPTIONS = "GET_STUDIES_SUSPENSIONS_OPTIONS";
export const SET_STUDIES_SUSPENSIONS_OPTIONS = "SET_STUDIES_SUSPENSIONS_OPTIONS";
export const GET_STUDIES_MODULE_SUBJECT_OPTIONS = "GET_STUDIES_MODULE_SUBJECT_OPTIONS";
export const SET_STUDIES_MODULE_SUBJECT_OPTIONS = "SET_STUDIES_MODULE_SUBJECT_OPTIONS";
export const GET_STUDIES_MODULE_DELIVERY_OPTIONS = "GET_STUDIES_MODULE_DELIVERY_OPTIONS";
export const SET_STUDIES_MODULE_DELIVERY_OPTIONS = "SET_STUDIES_MODULE_DELIVERY_OPTIONS";
export const GET_STUDIES_MODULE_COST_CENTRE_OPTIONS = "GET_STUDIES_MODULE_COST_CENTRE_OPTIONS";
export const SET_STUDIES_MODULE_COST_CENTRE_OPTIONS = "SET_STUDIES_MODULE_COST_CENTRE_OPTIONS";
export const GET_STUDIES_MODULE_INSTANCE_OPTIONS = "GET_STUDIES_MODULE_INSTANCE_OPTIONS";
export const SET_STUDIES_MODULE_INSTANCE_OPTIONS = "SET_STUDIES_MODULE_INSTANCE_OPTIONS";
export const GET_STUDIES_MODULE_DELIVERY_ROLE_OPTIONS = "GT_STUDIES_MODULE_DELIVERY_ROLE_OPTIONS";
export const SET_STUDIES_MODULE_DELIVERY_ROLE_OPTIONS = "SET_STUDIES_MODULE_DELIVERY_ROLE_OPTIONS";
export const GET_STUDIES_MODULE_DELIVERY_LOCATION_OPTIONS = "GET_STUDIES_MODULE_DELIVERY_LOCATION_OPTIONS";
export const SET_STUDIES_MODULE_DELIVERY_LOCATION_OPTIONS = "SET_STUDIES_MODULE_DELIVERY_LOCATION_OPTIONS";
export const GET_FINANCE_FUNDING_OPTIONS = "GET_FINANCE_FUNDING_OPTIONS";
export const SET_FINANCE_FUNDING_OPTIONS = "SET_FINANCE_FUNDING_OPTIONS";
export const GET_STUDIES_STUDENT_MODULE_FEE_OPTIONS = "GET_STUDIES_STUDENT_MODULE_FEE_OPTIONS";
export const SET_STUDIES_STUDENT_MODULE_FEE_OPTIONS = "SET_STUDIES_STUDENT_MODULE_FEE_OPTIONS";
export const GET_CREATE_FINANCIAL_SUPPORT_OPTIONS = "GET_CREATE_FINANCIAL_SUPPORT_OPTIONS";
export const SET_CREATE_FINANCIAL_SUPPORT_OPTIONS = "SET_CREATE_FINANCIAL_SUPPORT_OPTIONS";
export const GET_PROGRAMMES_LIST_OPTIONS = "GET_PROGRAMMES_LIST_OPTIONS";
export const SET_PROGRAMMES_LIST_OPTIONS = "SET_PROGRAMMES_LIST_OPTIONS";
export const GET_STUDIES_STUDY_PROGRAMME_FORM_OPTIONS = "GET_STUDIES_STUDY_PROGRAMME_FORM_OPTIONS";
export const SET_STUDIES_STUDY_PROGRAMME_FORM_OPTIONS = "SET_STUDIES_STUDY_PROGRAMME_FORM_OPTIONS";
export const GET_CONTACTS_CLERIC_OPTIONS = "GET_CONTACTS_CLERIC_OPTIONS";
export const SET_CONTACTS_CLERIC_OPTIONS = "SET_CONTACTS_CLERIC_OPTIONS";
export const GET_CONTACTS_STUDENT_TYPE_OPTIONS = "GET_CONTACTS_STUDENT_TYPE_OPTIONS";
export const SET_CONTACTS_STUDENT_TYPE_OPTIONS = "SET_CONTACTS_STUDENT_TYPE_OPTIONS";
export const GET_STUDIES_STUDY_PROGRAMME_POSTGRAD_OPTIONS = "GET_STUDIES_STUDY_PROGRAMME_POSTGRAD_OPTIONS";
export const SET_STUDIES_STUDY_PROGRAMME_POSTGRAD_OPTIONS = "SET_STUDIES_STUDY_PROGRAMME_POSTGRAD_OPTIONS";
export const GET_STUDIES_STUDY_PROGRAMME_FEES_OPTIONS = "GET_STUDIES_STUDY_PROGRAMME_FEES_OPTIONS";
export const SET_STUDIES_STUDY_PROGRAMME_FEES_OPTIONS = "SET_STUDIES_STUDY_PROGRAMME_FEES_OPTIONS";
export const GET_STUDIES_STUDY_PROGRAMME_FUNDING_AND_MONITORING_OPTIONS = "GET_STUDIES_STUDY_PROGRAMME_FUNDING_AND_MONITORING_OPTIONS";
export const SET_STUDIES_STUDY_PROGRAMME_FUNDING_AND_MONITORING_OPTIONS = "SET_STUDIES_STUDY_PROGRAMME_FUNDING_AND_MONITORING_OPTIONS";
export const GET_ADMIN_INSTITUTION_OPTIONS = "GET_ADMIN_INSTITUTION_OPTIONS";
export const SET_ADMIN_INSTITUTION_OPTIONS = "SET_ADMIN_INSTITUTION_OPTIONS";
export const GET_ADMIN_QUALIFICATION_OPTIONS = "GET_ADMIN_QUALIFICATION_OPTIONS";
export const SET_ADMIN_QUALIFICATION_OPTIONS = "SET_ADMIN_QUALIFICATION_OPTIONS";
export const GET_ADMIN_VENUE_OPTIONS = "GET_ADMIN_VENUE_OPTIONS";
export const SET_ADMIN_VENUE_OPTIONS = "SET_ADMIN_VENUE_OPTIONS";
export const GET_ADMISSIONS_APPLICATIONS_OPTIONS = "GET_ADMISSIONS_APPLICATIONS_OPTIONS";
export const SET_ADMISSIONS_APPLICATIONS_OPTIONS = "SET_ADMISSIONS_APPLICATIONS_OPTIONS";
export const GET_PURING_DATA_OPTIONS = "GET_PURING_DATA_OPTIONS";
export const SET_PURING_DATA_OPTIONS = "SET_PURING_DATA_OPTIONS";
export const GET_ACADEMIC_YEARS = "GET_ACADEMIC_YEARS";
export const SET_ACADEMIC_YEARS = "SET_ACADEMIC_YEARS";
export const GET_CONTACT_ADDRESSES_TYPE = "GET_CONTACT_ADDRESSES_TYPE";
export const SET_CONTACT_ADDRESSES_TYPE = "SET_CONTACT_ADDRESSES_TYPE";
export const GET_INSTITUTION_OPTIONS = "GET_INSTITUTION_OPTIONS";
export const SET_INSTITUTION_OPTIONS = "SET_INSTITUTION_OPTIONS";
