import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import isEmail from "validator/lib/isEmail";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, TextArea } from "@Root/components";

export const InstitutionForm = ({ permissions, isNew, title, initialData, buttons, deleteHandler, saveHandler }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [isSpinning, setIsSpinning] = useState(false);
  const [error, setError] = useState(null);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    const { name, contact_email } = data;
    if (!name) {
      showError("name", "Required");
      return false;
    }
    if (contact_email && !isEmail(contact_email)) {
      showError("contact_email", "Invalid email");
      return false;
    }
    return true;
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSpinning(true);
      },
      () => {
        InstitutionForm.isMounted && setIsSpinning(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSpinning(true);
    const modifiedData = switchEmptyStringsToNullsInObject(data);
    await saveHandler(modifiedData, () => {
      InstitutionForm.isMounted && setIsEditable(false);
    });
    InstitutionForm.isMounted && setIsSpinning(false);
  };

  useEffect(() => {
    InstitutionForm.isMounted = true;
    return () => {
      InstitutionForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    InstitutionForm.isMounted && setData(initialData);
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  return (
    <div className={classes.wrapper}>
      <FormWrapper
        buttons={isEditable ? buttons : []}
        buttonsNames={[{ button: "delete", name: "Delete this instance" }]}
        buttonsAreDisabled={isSpinning}
        isSpinning={isSpinning}
        clickCancelButtonHandler={handleClickCancelButton}
        clickDeleteButtonHandler={handleClickDeleteButton}
        clickSaveButtonHandler={handleClickSaveButton}
      >
        <div className={classes.titleWrapper}>
          <SectionTitle title={isEditable ? title.onEdit : title.onRead} />
          {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.columnWrapper}>
            <div className={classes.inputWrapper}>
              <InputLabel text="Name" hasAsterisk={isEditable} />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.name}
                changeHandler={value => handleChangeInput("name", value)}
                isDisabled={!isEditable}
                error={errorMessage("name")}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Address" />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.address}
                changeHandler={value => handleChangeInput("address", value)}
                isDisabled={!isEditable}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Contact Name" />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.contact_name}
                changeHandler={value => handleChangeInput("contact_name", value)}
                isDisabled={!isEditable}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Contact Phone" />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.contact_phone}
                changeHandler={value => handleChangeInput("contact_phone", value)}
                isDisabled={!isEditable}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Contact Email" />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.contact_email}
                changeHandler={value => handleChangeInput("contact_email", value)}
                isDisabled={!isEditable}
                error={errorMessage("contact_email")}
              />
            </div>
          </div>
          <div className={classes.columnWrapper}>
            <div className={classes.inputWrapper}>
              <InputLabel text="Venue UKPRN" />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.venue_ukprn}
                changeHandler={value => handleChangeInput("venue_ukprn", value)}
                isDisabled={!isEditable}
              />
            </div>
            <div className={classes.textareaWrapper}>
              <InputLabel text="Notes" />
              <TextArea
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260, height: 90 }}
                value={data.notes}
                changeHandler={value => handleChangeInput("notes", value)}
                isDisabled={!isEditable}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

InstitutionForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  isNew: PropTypes.bool,
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    contact_name: PropTypes.string,
    contact_phone: PropTypes.string,
    contact_email: PropTypes.string,
    venue_ukprn: PropTypes.string,
    notes: PropTypes.string
  }),
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

InstitutionForm.defaultProps = {
  permissions: [],
  isNew: false,
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    name: null,
    address: null,
    contact_name: null,
    contact_phone: null,
    contact_email: null,
    venue_ukprn: null,
    notes: null
  },
  buttons: [],
  deleteHandler: () => {},
  saveHandler: () => {}
};
