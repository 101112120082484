import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import { SectionWrapper } from "@Root/HOCs";
import { withPromise } from "@Root/helpers";
import classes from "./style.module.scss";
import * as actions from "@Root/store";
import { Spinner, StudiesModuleDeliveryLocationForm } from "@Root/components";

const Component = ({ history, getStudiesModuleDeliveryLocationOptions, studiesModuleDeliveryLocationOptions, location }) => {
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      !studiesModuleDeliveryLocationOptions && (await getStudiesModuleDeliveryLocationOptions());
      Component.isMounted && setIsShown(true);
    } catch (error) {
      console.log(error);
      history.replace("/page-not-found");
    }
  };

  const createModuleDeliveryLocation = async payload => {
    const { data } = await API.createStudiesMuduleDeliveryLocation(payload);
    history.push(
      `/home/studies/modules/${location.pathname.split("/")[4]}/module-delivery/${data.data.module_delivery_id}/module-delivery-location/${
        data.data.module_delivery_location_id
      }`
    );
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <SectionWrapper
            hasBackButton
            backButtonText="Back to Module Delivery"
            clickBackButtonHandler={() =>
              history.push(`/home/studies/modules/${location.pathname.split("/")[4]}/module-delivery/${location.pathname.split("/")[6]}`)
            }
          >
            <StudiesModuleDeliveryLocationForm
              title={{ onEdit: "Create Module Delivery Location" }}
              initialIsEditable={true}
              options={studiesModuleDeliveryLocationOptions}
              buttons={["save"]}
              saveHandler={payload => createModuleDeliveryLocation(payload)}
              location={location}
            />
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  studiesModuleDeliveryLocationOptions: inputDataListsReducer.studiesModuleDeliveryLocationOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesModuleDeliveryLocationOptions: () => withPromise(dispatch, actions.getStudiesModuleDeliveryLocationOptions)
  };
};

export const StudiesNewModuleDeliveryLocation = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
