import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import isInt from "validator/lib/isInt";
import isDecimal from "validator/lib/isDecimal";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { booleanOptions } from "@Root/configs";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select, DatePicker, MultiSelect, TextArea } from "@Root/components";

export const AssociationForm = ({ permissions, options, initialData, deleteHandler, saveHandler, errorHandler }) => {
  const associationStatusOptions = [{ value: "member", label: "Member" }, { value: "friend", label: "Friend" }];
  const doNotPostOptions = [{ value: true, label: "True" }, { value: false, label: "False" }];

  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState({
    ...initialData,
    old_associate_id: initialData.old_associate_id !== null ? String(initialData.old_associate_id) : null
  });
  const [isSaving, setIsSavind] = useState(false);
  const [error, setError] = useState(null);

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    if (!!data.old_associate_id && !isInt(data.old_associate_id)) {
      showError("old_associate_id", "Invalid value. Should be an integer");
      return false;
    } else if (!!data.so_amount && !isDecimal(data.so_amount)) {
      showError("so_amount", "Invalid value. Should be a decimal");
      return false;
    } else {
      return true;
    }
  };

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        AssociationForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSavind(true);
    try {
      const modifiedData = switchEmptyStringsToNullsInObject(data);
      await saveHandler(modifiedData);
      AssociationForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    AssociationForm.isMounted && setIsSavind(false);
  };

  useEffect(() => {
    AssociationForm.isMounted = true;
    return () => {
      AssociationForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  return (
    <FormWrapper
      buttons={isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this association" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Association Details" : "Association Details"} />
        <div>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Assoc Status" />
          <Select
            style={{ position: "absolute", left: 120 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 180 }}
            options={associationStatusOptions}
            value={data.associate_status}
            changeHandler={value => handleChangeInput("associate_status", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 315 }} text="Date Joined Assoc" />
          <DatePicker
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 445, width: 180 }}
            value={data.date_joined_associate}
            changeHandler={value => handleChangeInput("date_joined_associate", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 645 }} text="Date Left Assoc" />
          <div style={{ position: "absolute", left: 775 }}>
            <DatePicker
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 180 }}
              value={data.date_left_associate}
              changeHandler={value => handleChangeInput("date_left_associate", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Old Assoc ID" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 120, width: 180 }}
            value={data.old_associate_id}
            changeHandler={value => handleChangeInput("old_associate_id", value)}
            isDisabled={!isEditable}
            error={errorMessage("old_associate_id")}
          />
          <InputLabel style={{ position: "absolute", left: 315 }} text="Left College" />
          <div style={{ position: "absolute", left: 445 }}>
            <DatePicker
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 180 }}
              value={data.left_college}
              changeHandler={value => handleChangeInput("left_college", value)}
              isDisabled={!isEditable}
            />
          </div>
          <InputLabel style={{ position: "absolute", left: 645 }} text="Contact Preference" />
          <Select
            style={{ position: "absolute", left: 775 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 180 }}
            options={options.contactsAssociationOptions.contactPreferences}
            value={data.contact_preference_id}
            changeHandler={value => handleChangeInput("contact_preference_id", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Federation House" />
          <div style={{ position: "absolute", left: 120, width: 180 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 180 }}
              options={options.institutionOptions}
              values={data.institution_ids}
              changeHandler={value => handleChangeInput("institution_ids", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>Standing Order</div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="S/O Date" />
          <div style={{ position: "absolute", left: 120 }}>
            <DatePicker
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 180 }}
              value={data.so_date}
              changeHandler={value => handleChangeInput("so_date", value)}
              isDisabled={!isEditable}
            />
          </div>
          <InputLabel style={{ position: "absolute", left: 315 }} text="Last S/O Payment" />
          <div style={{ position: "absolute", left: 445 }}>
            <DatePicker
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 180 }}
              value={data.last_so_payment}
              changeHandler={value => handleChangeInput("last_so_payment", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="S/O Amount" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 120, width: 180 }}
            value={data.so_amount}
            changeHandler={value => handleChangeInput("so_amount", value)}
            isDisabled={!isEditable}
            error={errorMessage("so_amount")}
          />
          <InputLabel style={{ position: "absolute", left: 315 }} text="S/O Frequency" />
          <Select
            style={{ position: "absolute", left: 445 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 180 }}
            options={options.contactsAssociationOptions.frequencies}
            value={data.frequency_id}
            changeHandler={value => handleChangeInput("frequency_id", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>Cash Receipts</div>
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text="Please record Date/Amount/Purpose (one item per line)" />
          <TextArea
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 445, width: 485, height: 90 }}
            value={data.cash_receipts}
            changeHandler={value => handleChangeInput("cash_receipts", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>Other Detail</div>
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text="Gift Aid" />
          <Select
            style={{ position: "absolute", left: 120 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.gift_aid}
            changeHandler={value => handleChangeInput("gift_aid", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 315 }} text="Subs Paid Till" />
          <div style={{ position: "absolute", left: 445 }}>
            <DatePicker
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 180 }}
              value={data.subs_paid_till}
              changeHandler={value => handleChangeInput("subs_paid_till", value)}
              isDisabled={!isEditable}
            />
          </div>
          <InputLabel style={{ position: "absolute", left: 645 }} text="Brief Notes" />
          <TextArea
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 775, width: 180, height: 90 }}
            value={data.brief_notes}
            changeHandler={value => handleChangeInput("brief_notes", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>Key Flags</div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Do not Post" />
          <Select
            style={{ position: "absolute", left: 120 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 180 }}
            options={doNotPostOptions}
            value={data.dont_post}
            changeHandler={value => handleChangeInput("dont_post", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 315 }} text="Principal's Contact" />
          <Select
            style={{ position: "absolute", left: 445 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.principals_contact}
            changeHandler={value => handleChangeInput("principals_contact", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 645 }} text="Deceased" />
          <Select
            style={{ position: "absolute", left: 775 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.deceased}
            changeHandler={value => handleChangeInput("deceased", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Bishop" />
          <Select
            style={{ position: "absolute", left: 120 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.bishop}
            changeHandler={value => handleChangeInput("bishop", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 315 }} text="Overseas" />
          <Select
            style={{ position: "absolute", left: 445 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 180 }}
            options={booleanOptions}
            value={data.overseas}
            changeHandler={value => handleChangeInput("overseas", value)}
            isDisabled={!isEditable}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

const arrayOfValueLabelShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  })
);

AssociationForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.shape({
    contactPreferences: arrayOfValueLabelShape,
    frequencies: arrayOfValueLabelShape
  }),
  initialData: PropTypes.shape({
    associate_status: PropTypes.string,
    date_joined_associate: PropTypes.string,
    date_left_associate: PropTypes.string,
    old_associate_id: PropTypes.number,
    left_college: PropTypes.string,
    contact_preference_id: PropTypes.number,
    so_date: PropTypes.string,
    last_so_payment: PropTypes.string,
    so_amount: PropTypes.string,
    frequency_id: PropTypes.number,
    cash_receipts: PropTypes.string,
    gift_aid: PropTypes.bool,
    subs_paid_till: PropTypes.string,
    brief_notes: PropTypes.string,
    dont_post: PropTypes.bool,
    principals_contact: PropTypes.bool,
    deceased: PropTypes.bool,
    bishop: PropTypes.bool,
    overseas: PropTypes.bool,
    institution_ids: PropTypes.arrayOf(PropTypes.number)
  }),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

AssociationForm.defaultProps = {
  permissions: [],
  options: {
    contactPreferences: [],
    frequencies: []
  },
  initialData: {
    associate_status: null,
    date_joined_associate: null,
    date_left_associate: null,
    old_associate_id: null,
    left_college: null,
    contact_preference_id: null,
    so_date: null,
    last_so_payment: null,
    so_amount: null,
    frequency_id: null,
    cash_receipts: null,
    gift_aid: null,
    subs_paid_till: null,
    brief_notes: null,
    dont_post: null,
    principals_contact: null,
    deceased: null,
    bishop: null,
    overseas: null,
    institution_ids: []
  },
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
