import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { connect } from "react-redux";
import { localLongDate, withPromise, errorMessage } from "@Root/helpers";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { Spinner, Table, ActionsDropdown } from "@Root/components";
import { useLocation } from "react-router";

const Component = ({
  history,
  permissions,
  adminUserOptions,
  getAdminUserOptions,
  adminQualificationOptions,
  getAdminQualificationOptions,
  setSnackbar,
  getAdminVenueOptions,
  adminVenueOptions,
  setSuccessNotification
}) => {
  const location = useLocation();
  const tab = location.pathname.split("/")[4] || location.pathname.split("/")[3];

  const [isShown, setIsShown] = useState(false);

  const systemUsersConfig = () => ({
    fetchInitialDataHandler: async () => {
      getAdminUserOptions();
    },
    actions: [
      ...(permissions.includes("createRole")
        ? [
            {
              name: "Create User",
              handler: () => history.push(`/home/admin/manage-system-team/system-users/new`)
            }
          ]
        : [])
    ],
    table: () => ({
      name: "adminSystemUsers",
      columns: [
        {
          name: "Username",
          field: "user_name",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Contact Record",
          field: "contact_record",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Email",
          field: "email",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Creation Date",
          field: "created_at",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Last visit",
          field: "last_visit",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Status",
          field: "status",
          searchInputType: "select",
          options: adminUserOptions?.statuses.map(x => x.label),
          cellType: "text"
        },
        {
          name: "User Roles",
          field: "roles",
          searchInputType: "select",
          options: adminUserOptions?.roles.map(x => x.label),
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getAdminSystemUsersTable(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("system-users", options),
      fetchColumnOptions: () => API.getColumnOptions("system-users"),
      hasRules: true,
      convertDataHandler: initialData => {
        return initialData.map(row => ({ ...row, created_at: localLongDate(row.created_at), last_visit: localLongDate(row.last_visit) }));
      },
      // fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        Username: row => history.push(`/home/admin/manage-system-team/system-users/${row.id}`)
      }
    })
  });

  const userRolesConfig = () => ({
    actions: [
      ...(permissions.includes("createRole")
        ? [
            {
              name: "Create Role",
              handler: () => history.push(`/home/admin/manage-system-team/user-roles/new`)
            }
          ]
        : [])
    ],
    table: () => ({
      name: "adminUserRoles",
      columns: [
        {
          name: "Role ID",
          field: "id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Name",
          field: "name",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Created at",
          field: "created_at",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Updated at",
          field: "updated_at",
          searchInputType: "textInput",
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getAdminUserRolesTable(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("user-roles", options),
      fetchColumnOptions: () => API.getColumnOptions("user-roles"),
      hasRules: true,
      convertDataHandler: initialData => {
        return initialData.map(row => ({ ...row, created_at: localLongDate(row.created_at.date), updated_at: localLongDate(row.updated_at.date) }));
      },
      clickLinkHandlers: {
        "Role ID": row => history.push(`/home/admin/manage-system-team/user-roles/${row.id}`),
        Name: row => history.push(`/home/admin/manage-system-team/user-roles/${row.id}`)
      }
    })
  });

  const institutionsConfig = () => ({
    actions: [
      ...(permissions.includes("create")
        ? [
            {
              name: "Add an Institutions",
              handler: () => history.push(`/home/admin/other/institutions/new`)
            }
          ]
        : [])
    ],
    table: () => ({
      name: "AdminInstitutions",
      columns: [
        {
          name: "Institution ID",
          field: "id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Name",
          field: "name",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Address",
          field: "address",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Contact Name",
          field: "contact_name",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Contact Phone",
          field: "contact_phone",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Contact Email",
          field: "contact_email",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Created at",
          field: "created_at",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Updated at",
          field: "updated_at",
          searchInputType: "textInput",
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getAdminInstitutions(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("institutions", options),
      fetchColumnOptions: () => API.getColumnOptions("institutions"),
      hasRules: true,
      convertDataHandler: initialData => {
        return initialData.map(row => ({ ...row, created_at: localLongDate(row.created_at), updated_at: localLongDate(row.updated_at) }));
      },
      // fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        "Institution ID": row => history.push(`/home/admin/other/institutions/${row.id}`),
        Name: row => history.push(`/home/admin/other/institutions/${row.id}`)
      }
    })
  });

  const snapshotsConfig = () => ({
    actions: [
      ...(permissions.includes("create")
        ? [
            {
              name: "Add a Snapshot",
              handler: () => history.push(`/home/admin/snapshots/new`)
            }
          ]
        : [])
    ],
    table: () => ({
      name: "adminSnapshots",
      columns: [
        {
          name: "Snapshot ID",
          field: "id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Created at",
          field: "created_at",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Contact ID",
          field: "contact_id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Contact Name",
          field: "contact_name",
          searchInputType: "textInput",
          cellType: "link"
        }
      ],
      fetchDataHandler: params => API.getAdminSnapshots(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("snapshots", options),
      fetchColumnOptions: () => API.getColumnOptions("snapshots"),
      hasRules: true,
      convertDataHandler: initialData => {
        return initialData.map(row => ({ ...row, created_at: localLongDate(row.created_at.date) }));
      },
      // fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        "Snapshot ID": row => history.push(`/home/admin/snapshots/${row.id}`),
        "Contact ID": row => history.push(`/home/contacts/all-contacts/${row.contact_id}`),
        "Contact Name": row => history.push(`/home/contacts/all-contacts/${row.contact_id}`)
      }
    })
  });

  const qualificationsConfig = () => ({
    fetchInitialDataHandler: async () => {
      !adminQualificationOptions && (await getAdminQualificationOptions());
    },
    actions: [
      ...(permissions.includes("create")
        ? [
            {
              name: "Add a Qualification",
              handler: () => history.push(`/home/admin/other/qualifications/new`)
            }
          ]
        : [])
    ],
    table: () => ({
      name: "adminQualifications",
      columns: [
        {
          name: "Qualification ID",
          field: "id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Qualification Title",
          field: "qualification_title",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Qualification Category",
          field: "qualification_category_id",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Z: Level of study 6-way split",
          field: "z_level_of_study_six_way_split",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Z: Level of study 10-way split",
          field: "z_level_of_study_ten_way_split",
          searchInputType: "textInput",
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getAdminQualifications(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("qualifications", options),
      fetchColumnOptions: () => API.getColumnOptions("qualifications"),
      hasRules: true,
      convertDataHandler: initialData => {
        return initialData.map(row => ({
          ...row,
          qualification_category_id: adminQualificationOptions.qualification_category.find(x => x.value === row.qualification_category_id).label,
          z_level_of_study_six_way_split: adminQualificationOptions.z_level_of_study_six_way_split.find(x => x.value === row.z_level_of_study_six_way_split)
            .label,
          z_level_of_study_ten_way_split: adminQualificationOptions.z_level_of_study_ten_way_split.find(x => x.value === row.z_level_of_study_ten_way_split)
            .label
        }));
      },
      clickLinkHandlers: {
        "Qualification ID": row => history.push(`/home/admin/other/qualifications/${row.id}`)
      }
    })
  });

  const venuesConfig = () => ({
    fetchInitialDataHandler: async () => {
      !adminVenueOptions && (await getAdminVenueOptions());
    },
    actions: [
      ...(permissions.includes("create")
        ? [
            {
              name: "Add a Venue",
              handler: () => history.push(`/home/admin/other/venues/new`)
            }
          ]
        : [])
    ],
    table: () => ({
      name: "adminVenue",
      columns: [
        {
          name: "Venue ID",
          field: "venue_id",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Venue Name",
          field: "venue_name",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Venue Type",
          field: "venue_type",
          searchInputType: "select",
          options: adminVenueOptions.venues_types.map(x => x.label),
          cellType: "text"
        },
        {
          name: "Postcode",
          field: "postcode",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Venue UKPRN",
          field: "venue_ukprn",
          searchInputType: "textInput",
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getAdminVanues(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("venues", options),
      fetchColumnOptions: () => API.getColumnOptions("venues"),
      hasRules: true,
      convertDataHandler: initialData => {
        return initialData.map(row => ({
          ...row,
          venue_type: adminVenueOptions.venues_types.find(x => x.value === row.venue_type_id).label
        }));
      },
      clickLinkHandlers: {
        "Venue ID": row => history.push(`/home/admin/other/venues/${row.venue_id}`),
        "Venue Name": row => history.push(`/home/admin/other/venues/${row.venue_id}`)
      }
    })
  });

  const configs = {
    "system-users": systemUsersConfig,
    "user-roles": userRolesConfig,
    institutions: institutionsConfig,
    snapshots: snapshotsConfig,
    qualifications: qualificationsConfig,
    venues: venuesConfig
  };

  const { actions = [], table, fetchInitialDataHandler = () => {} } = configs[tab]();

  useEffect(() => {
    Table.isMounted = true;
    (async () => {
      try {
        await fetchInitialDataHandler();
        Table.isMounted && setIsShown(true);
      } catch (error) {
        setSnackbar({ text: errorMessage(error), isError: true });
        history.replace("/contact-us");
      }
    })();
    return () => {
      Table.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {!!actions.length && (
        <ActionsDropdown
          actions={actions.map(action => action.name)}
          clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
          style={{ position: "absolute", right: 10, top: -80 }}
        />
      )}
      {isShown ? (
        <Table
          name={table().name}
          columns={table().columns}
          fetchDataHandler={table().fetchDataHandler}
          fetchSaveColumnOptions={table().fetchSaveColumnOptions}
          fetchColumnOptions={table().fetchColumnOptions}
          convertDataHandler={table().convertDataHandler}
          fetchExportedDataHander={table().fetchExportedDataHander}
          clickLinkHandlers={table().clickLinkHandlers}
          hasRules={table().hasRules}
          errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
          successHandler={success => setSuccessNotification(success)}
          datePeriodTop={table().name !== "adminSnapshots" ? -210 : -140}
        />
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  adminUserOptions: inputDataListsReducer.adminUserOptions,
  adminQualificationOptions: inputDataListsReducer.adminQualificationOptions,
  adminVenueOptions: inputDataListsReducer.adminVenueOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getAdminUserOptions: () => withPromise(dispatch, actions.getAdminUserOptions),
    getAdminQualificationOptions: () => withPromise(dispatch, actions.getAdminQualificationOptions),
    getAdminVenueOptions: () => withPromise(dispatch, actions.getAdminVenueOptions),
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    setSuccessNotification: data => dispatch(actions.setSuccessNotification(data))
  };
};

export const AdminTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
