import React from "react";
import "./style.scss";
import DocumentTitle from "react-document-title";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { PasswordRecoveryForm, PasswordRecoverySuccessForm } from "@Root/components";

const Page = ({ error, history, location, sendRecoveryEmail }) => {
  return (
    <DocumentTitle title="Password recovery">
      <div className="PasswordRecoveryPage">
        {location.hash !== "#success" ? (
          <PasswordRecoveryForm error={error} history={history} recoveryEmailHandler={sendRecoveryEmail} />
        ) : (
          <PasswordRecoverySuccessForm />
        )}
      </div>
    </DocumentTitle>
  );
};

const mapStateToProps = ({ authReducer }) => ({ error: authReducer.error });

const mapDispatchToProps = dispatch => {
  return {
    sendRecoveryEmail: (email, history) => dispatch(actions.sendRecoveryEmail(email, history))
  };
};

export const PasswordRecoveryPage = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Page)
);
