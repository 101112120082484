import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import triangle from "../../../assets/icons/triangle.png";
import { CustomScrollbar } from "@Root/HOCs";
import { CheckboxInput } from "../CheckboxInput/";
import { popupHeight } from "@Root/helpers";
import { toggleArrayPrimitiveValue, all } from "@Root/helpers";
import { ArrowIcon } from "@Root/assets";

export const MultiSelect = ({
  inputClassNames,
  inputStyle,
  optionsStyle,
  options: passedOptions,
  values: passedValues,
  changeHandler,
  isDisabled,
  placeholder,
  maxVisibleOptionsQuantity,
  error: passedError
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [error, setError] = useState(null);

  const values = passedValues !== null ? passedValues : [];

  const options = passedOptions.map(passedOption => (typeof passedOption === "object" ? passedOption : { label: passedOption, value: passedOption }));

  const selectedOptions = values.map(value => {
    const option = options.find(option => option.value === value);
    return { label: option ? option.label : value, value };
  });

  const handleChange = value => {
    const newValues = toggleArrayPrimitiveValue(values, value);
    changeHandler(newValues.length ? newValues : null);
  };

  useEffect(() => {
    if (passedError) {
      clearTimeout(MultiSelect.timeout);
      setError(passedError);
      MultiSelect.timeout = setTimeout(() => setError(null), 3000);
    }
  }, [passedError]);

  useEffect(() => {
    return () => {
      clearTimeout(MultiSelect.timeout);
    };
  }, []);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsOpened(false);
      }}
    >
      <div className={classes.wrapper}>
        <div
          className={`${classes.input} ${isDisabled ? classes.disabled : ""} 
                    ${inputClassNames.reduce((acc, className) => acc + ` ${classes[className]}`, "")}`}
          style={{ ...inputStyle, borderColor: error ? "#D0021B" : null }}
          onClick={() => all(() => !isDisabled && setIsOpened(!isOpened), () => setError(null))}
        >
          {selectedOptions.length ? (
            <div className={classes.values}>
              {selectedOptions.reduce((acc, selectedOption, i) => acc + (i === 0 ? selectedOption.label : `, ${selectedOption.label}`), "")}
            </div>
          ) : (
            <div className={`${classes.values} ${classes.empty}`}>{placeholder}</div>
          )}
          {!isDisabled && (
            <div className={classes.icon}>
              <img style={isOpened ? { transform: "rotate(180deg)" } : null} src={ArrowIcon} alt="" />
            </div>
          )}
        </div>
        {isOpened && (
          <div className={classes.options} style={{ ...optionsStyle, height: popupHeight(options.length, maxVisibleOptionsQuantity, 38) }}>
            <CustomScrollbar verticalOnly>
              {options.map((option, i) => (
                <div className={classes.option} key={i}>
                  <CheckboxInput
                    isChecked={values.includes(option.value)}
                    changeHandler={() => handleChange(option.value)}
                    label={{ text: option.label, style: { color: "#292929" } }}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              ))}
            </CustomScrollbar>
          </div>
        )}
        {error && <div className={classes.errorMessage}>{error}*</div>}
      </div>
    </OutsideClickHandler>
  );
};

MultiSelect.propTypes = {
  inputClassNames: PropTypes.arrayOf(PropTypes.oneOf(["borderless", "transparent"])),
  inputStyle: PropTypes.object,
  optionsStyle: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
      })
    ])
  ),
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])),
  changeHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  maxVisibleOptionsQuantity: PropTypes.number,
  error: PropTypes.string
};

MultiSelect.defaultProps = {
  inputClassNames: [],
  inputStyle: {},
  optionsStyle: {},
  options: [],
  values: null,
  changeHandler: () => {},
  isDisabled: false,
  placeholder: "",
  maxVisibleOptionsQuantity: 5,
  error: null
};
