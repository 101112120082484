import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withPromise, errorMessage } from "@Root/helpers";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { Spinner, QualificationForm } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";

const Qualification = ({ location, history, permissions, adminQualificationOptions, getAdminQualificationOptions, showModal, hideModal, setSnackbar }) => {
  const qualificationId = location.pathname.split("/").pop();

  const [qualification, setQualification] = useState(undefined);
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      if (qualificationId === "new") {
        !adminQualificationOptions && (await getAdminQualificationOptions());
      } else {
        const data = await Promise.all([API.getAdminQualification(qualificationId), !adminQualificationOptions && getAdminQualificationOptions()]);
        Qualification.isMounted && setQualification(data[0].data.data);
      }
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const postQualification = async (payload, cb) => {
    try {
      const { data } = await API.postAdminQualification(payload);
      setQualification(data.data);
      cb();
      history.push(`/home/admin/other/qualifications/${data.data.id}`);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const putQualification = async (payload, cb) => {
    try {
      const { data } = await API.putAdminQualification(payload);
      setQualification(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteQualification = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This qualification will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteAdminQualification(qualificationId);
          history.push("/home/admin/other/qualifications");
        } catch (error) {
          onFinishCallback();
          setSnackbar({ text: errorMessage(error), isError: true });
        }
      },
      () => {
        hideModal();
      }
    );
  };

  useEffect(() => {
    Qualification.isMounted = true;
    (async () => {
      await fetchData();
      Qualification.isMounted && setIsShown(true);
    })();
    return () => {
      Qualification.isMounted = false;
    };
  }, []);

  const formConfig = {
    new: {
      isNew: true,
      title: { onEdit: "Create a Qualification" },
      buttons: ["save"],
      saveHandler: (payload, cb) => postQualification(payload, cb)
    },
    existent: {
      title: { onEdit: "Edit the Qualification" },
      initialData: qualification,
      buttons: ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"],
      deleteHandler: (onStartCallback, onFinishCallback) => deleteQualification(onStartCallback, onFinishCallback),
      saveHandler: (payload, cb) => putQualification(payload, cb)
    }
  }[qualificationId === "new" ? "new" : "existent"];

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper hasBackButton backButtonText="Qualifications" clickBackButtonHandler={() => history.push("/home/admin/other/qualifications")}>
          <QualificationForm
            permissions={permissions}
            isNew={formConfig.isNew}
            title={formConfig.title}
            options={adminQualificationOptions}
            initialData={formConfig.initialData}
            buttons={formConfig.buttons}
            deleteHandler={formConfig.deleteHandler}
            saveHandler={formConfig.saveHandler}
          />
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  adminQualificationOptions: inputDataListsReducer.adminQualificationOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getAdminQualificationOptions: () => withPromise(dispatch, actions.getAdminQualificationOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const AdminQualification = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Qualification)
);
