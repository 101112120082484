import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import Ringer from "../../icons/Ringer/Ringer";

export const HeaderUser = ({ userName, userIcon, clickUserHandler, logOut }) => {
  return (
    <div className="HeaderUser">
      <div className="divider" />
      <Ringer />
      <div>
        <div className="name" onClick={clickUserHandler}>
          {userName}
        </div>
        <div className="log-out" onClick={() => logOut()}>
          log out
        </div>
      </div>
      <div className="icon" style={{ backgroundImage: `url("${userIcon}")` }} onClick={clickUserHandler} />
    </div>
  );
};

HeaderUser.propTypes = {
  userName: PropTypes.string,
  userIcon: PropTypes.string,
  logOut: PropTypes.func
};

HeaderUser.defaultProps = {
  userName: "",
  userIcon: "",
  logOut: () => {}
};
