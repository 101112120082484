import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { switchEmptyArraysToNullsInObjectRecursively } from "@Root/helpers";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, MultiSelect } from "@Root/components";

export const TrainingFacilityForm = ({ permissions, initialData, options, deleteHandler, saveHandler, errorHandler }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);

  console.log(options);
  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        TrainingFacilityForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    setIsSavind(true);
    try {
      const modifiedData = switchEmptyArraysToNullsInObjectRecursively(data);
      await saveHandler(modifiedData);
      TrainingFacilityForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    TrainingFacilityForm.isMounted && setIsSavind(false);
  };

  useEffect(() => {
    TrainingFacilityForm.isMounted = true;
    return () => {
      TrainingFacilityForm.isMounted = false;
    };
  }, []);

  return (
    <FormWrapper
      buttons={isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this training facility" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Training Facility Details" : "Training Facility Details"} />
        {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Owners" />
          <div style={{ position: "absolute", left: 150 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              options={options.userOptions}
              values={data.user_ids}
              changeHandler={value => handleChangeInput("user_ids", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Federation House" />
          <div style={{ position: "absolute", left: 150, width: 260 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              options={options.institutionOptions}
              values={data.institution_ids}
              changeHandler={value => handleChangeInput("institution_ids", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

TrainingFacilityForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    user_ids: PropTypes.arrayOf(PropTypes.number),
    institution_ids: PropTypes.arrayOf(PropTypes.number)
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

TrainingFacilityForm.defaultProps = {
  permissions: [],
  initialData: {
    user_ids: [],
    institution_ids: []
  },
  options: [],
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
