import React from "react";
import classes from "./TEIDLogo.module.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const TEIDLogo = ({ style, link }) => (
  <div className={classes.TEIDLogo} style={style}>
    {link ? <Link to={link}>TEI Logo Here</Link> : "TEI Logo Here"}
  </div>
);

TEIDLogo.propTypes = {
  style: PropTypes.object,
  link: PropTypes.string
};

TEIDLogo.defaultProps = {
  style: {}
};

export default TEIDLogo;
