import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { Spinner, VenueForm } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";
import { withPromise, errorMessage } from "@Root/helpers";

const Venue = ({ location, history, permissions, showModal, hideModal, setSnackbar, adminVenueOptions, getAdminVenueOptions }) => {
  const venueId = location.pathname.split("/").pop();

  const [venue, setVenue] = useState(undefined);
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      if (venueId === "new") {
        !adminVenueOptions && (await getAdminVenueOptions());
      } else {
        const data = await Promise.all([API.getAdminVenue(venueId), !adminVenueOptions && (await getAdminVenueOptions())]);
        Venue.isMounted && setVenue(data[0].data.data);
      }
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const postVenue = async (payload, cb) => {
    try {
      const { data } = await API.postAdminVenue(payload);
      setVenue(data.data);
      cb();
      history.push(`/home/admin/other/venues/${data.data.venue_id}`);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const putVenue = async (payload, cb) => {
    try {
      const { data } = await API.putAdminVenue(payload);
      setVenue(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteVenue = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This venue will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteAdminVenue(venueId);
          history.push("/home/admin/other/venues");
        } catch (error) {
          onFinishCallback();
          setSnackbar({ text: errorMessage(error), isError: true });
        }
      },
      () => {
        hideModal();
      }
    );
  };

  useEffect(() => {
    Venue.isMounted = true;
    (async () => {
      await fetchData();
      Venue.isMounted && setIsShown(true);
    })();
    return () => {
      Venue.isMounted = false;
    };
  }, []);

  const formConfig = {
    new: {
      isNew: true,
      options: adminVenueOptions,
      title: { onEdit: "Create a Venue" },
      buttons: ["save"],
      saveHandler: (payload, cb) => postVenue(payload, cb)
    },
    existent: {
      title: { onEdit: "Edit the Venue" },
      options: adminVenueOptions,
      initialData: venue,
      buttons: ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"],
      deleteHandler: (onStartCallback, onFinishCallback) => deleteVenue(onStartCallback, onFinishCallback),
      saveHandler: (payload, cb) => putVenue(payload, cb)
    }
  }[venueId === "new" ? "new" : "existent"];

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper hasBackButton backButtonText="Venues" clickBackButtonHandler={() => history.push("/home/admin/other/venues")}>
          <VenueForm
            permissions={permissions}
            isNew={formConfig.isNew}
            options={formConfig.options}
            title={formConfig.title}
            initialData={formConfig.initialData}
            buttons={formConfig.buttons}
            deleteHandler={formConfig.deleteHandler}
            saveHandler={formConfig.saveHandler}
          />
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  adminVenueOptions: inputDataListsReducer.adminVenueOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getAdminVenueOptions: () => withPromise(dispatch, actions.getAdminVenueOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const AdminVenue = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Venue)
);
