import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import { SectionWrapper } from "@Root/HOCs";
import { withPromise } from "@Root/helpers";
import classes from "./style.module.scss";
import * as actions from "@Root/store";
import { Spinner, StudiesModuleDeliveryLocationForm } from "@Root/components";

const Component = ({ permissions, history, getStudiesModuleDeliveryLocationOptions, studiesModuleDeliveryLocationOptions, location, showModal, hideModal }) => {
  const [isShown, setIsShown] = useState(false);
  const [moduleDeliveryLocation, setModuleDeliveryLocation] = useState(null);

  const moduleDelLocId = location.pathname.split("/").pop();

  const fetchData = async () => {
    try {
      const data = await Promise.all([
        API.getStudiesModuleDeliveryLocation(moduleDelLocId),
        !studiesModuleDeliveryLocationOptions ? getStudiesModuleDeliveryLocationOptions() : null
      ]);
      setModuleDeliveryLocation(data[0].data.data);
      Component.isMounted && setIsShown(true);
    } catch (error) {
      console.log(error);
      history.replace("/page-not-found");
    }
  };

  const deleteModule = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This  module delivery location will be removed?",
        rejectButtonText: "Cancel",
        resolveButtonText: "Proceed",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteStudiesModuleDeliveryLocation(moduleDelLocId);
          history.push(`/home/studies/modules/${location.pathname.split("/")[4]}/module-delivery/${location.pathname.split("/")[6]}`);
        } catch (error) {
          onFinishCallback();
          alert("Unable to delete the module delivery location");
        }
      },
      () => {
        hideModal();
      }
    );
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <SectionWrapper
            hasBackButton
            backButtonText="Back to Module Delivery"
            clickBackButtonHandler={() =>
              history.push(`/home/studies/modules/${location.pathname.split("/")[4]}/module-delivery/${location.pathname.split("/")[6]}`)
            }
          >
            <StudiesModuleDeliveryLocationForm
              permissions={permissions}
              title={{ onEdit: "Edit Module Delivery Location" }}
              options={studiesModuleDeliveryLocationOptions}
              initialData={moduleDeliveryLocation}
              buttons={["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"]}
              deleteHandler={(onStartCallback, onFinishCallback) => deleteModule(onStartCallback, onFinishCallback)}
              saveHandler={payload => API.putStudiesDeliveryLocation({ module_delivery_location_id: moduleDelLocId, ...payload })}
              location={location}
            />
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  studiesModuleDeliveryLocationOptions: inputDataListsReducer.studiesModuleDeliveryLocationOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesModuleDeliveryLocationOptions: () => withPromise(dispatch, actions.getStudiesModuleDeliveryLocationOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal())
  };
};

export const StudiesModuleDeliveryLocation = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
