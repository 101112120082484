import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { ConfirmationModal, InputModal, UploadingModal, ImportModal, NotificationModal, RoleManagerModal } from "@Root/components";

export const ModalWrapper = ({ component, props }) => {
  const components = {
    ConfirmationModal: <ConfirmationModal {...props} />,
    InputModal: <InputModal {...props} />,
    UploadingModal: <UploadingModal {...props} />,
    ImportModal: <ImportModal {...props} />,
    NotificationModal: <NotificationModal {...props} />
  };
  return <div className={classes.wrapper}>{component in components ? components[component] : null}</div>;
};

ModalWrapper.propTypes = {
  component: PropTypes.oneOf(["ConfirmationModal", "InputModal", "UploadingModal", "ImportModal", "NotificationModal"]),
  props: PropTypes.object
};

ModalWrapper.defaultProps = {
  component: null,
  props: {}
};
