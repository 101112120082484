import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withPromise, errorMessage, all } from "@Root/helpers";
import { API } from "@Root/API";
import * as actions from "@Root/store";
import {
  Spinner,
  SectionTabs,
  FundingAndMonitoringForm,
  AdmissionsForm,
  FeesForm,
  PostgradForm,
  StudiesStudyProgrammeForm,
  ContactsNotesForm,
  ContactsAttachmentsForm,
  ActionsDropdown
} from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";

const Component = ({
  permissions,
  history,
  location,
  studiesStudyProgrammeFormOptions,
  getStudiesStudyProgrammeFormOptions,
  programmesListOptions,
  getProgrammesListOptions,
  studiesStudyProgrammePostgradOptions,
  getStudiesStudyProgrammePostgradOptions,
  studiesStudyProgrammeFeesOptions,
  getStudiesStudyProgrammeFeesOptions,
  studiesStudyProgrammeFundingAndMonitoringOptions,
  getStudiesStudyProgrammeFundingAndMonitoringOptions,
  getAdminInstitutionOptions,
  adminInstitutionOptions,
  showModal,
  hideModal,
  getAdmissionsApplicationsOptions,
  admissionsApplicationsOptions,
  setSnackbar
}) => {
  const contactId = +location.pathname.split("/")[5];
  const studyProgrammeId = +location.pathname.split("/")[6];

  const [isShown, setIsShown] = useState(false);
  const [student, setStudent] = useState({});
  const [activeTabName, setActiveTabName] = useState("Notes");
  const [studyProgramme, setStudyProgramme] = useState(undefined);
  const [notes, setNotes] = useState(undefined);
  const [attachments, setAttachments] = useState(undefined);
  const [fees, setFees] = useState(undefined);
  const [hasNewFees, setHasNewFees] = useState(false);
  const [newPostgradIsAllowed, setNewPostgradIsAllowed] = useState(false);
  const [postgrad, setPostgrad] = useState(undefined);
  const [hasNewPostgrad, setHasNewPostgrad] = useState(false);
  const [admissions, setAdmissions] = useState(undefined);
  const [hasNewAdmissions, setHasNewAdmissions] = useState(false);
  const [fundingAndMonitoring, setFundingAndMonitoring] = useState(undefined);
  const [hasNewFundingAndMonitoring, setHasNewFundingAndMonitoring] = useState(false);

  const actions = [
    ...(permissions.includes("create") && newPostgradIsAllowed && !postgrad && !hasNewPostgrad
      ? [
          {
            name: "Add Postgrad Details",
            handler: () => {
              setHasNewPostgrad(true);
              setActiveTabName("Postgrad");
            }
          }
        ]
      : []),
    ...(permissions.includes("create") && !hasNewFees && !fees
      ? [
          {
            name: "Add Fees Details",
            handler: () => {
              setHasNewFees(true);
              setActiveTabName("Fees");
            }
          }
        ]
      : []),
    ...(permissions.includes("create") && !hasNewAdmissions && !admissions
      ? [
          {
            name: "Add Admissions Details",
            handler: () => {
              setHasNewAdmissions(true);
              setActiveTabName("Admissions");
            }
          }
        ]
      : []),
    ...(permissions.includes("create") && !hasNewFundingAndMonitoring && !fundingAndMonitoring
      ? [
          {
            name: "Add Funding and Monitoring Details",
            handler: () => {
              setHasNewFundingAndMonitoring(true);
              setActiveTabName("Funding and Monitoring");
            }
          }
        ]
      : [])
  ];

  const forms = () => {
    return [
      {
        tab: {
          name: "Notes"
        },
        component: (
          <ContactsNotesForm
            permissions={permissions}
            notes={notes}
            saveNoteHandler={(newNote, cb) => postNewNote(newNote, cb)}
            editNoteHandler={(id, value, cb) => saveEditNote(id, value, cb)}
            onNoteDelete={(id, onStartSaving, onFinishSaving) => deleteNote(id, onStartSaving, onFinishSaving)}
          />
        )
      },
      {
        tab: {
          name: "Attachments"
        },
        component: (
          <ContactsAttachmentsForm
            permissions={permissions}
            attachments={attachments}
            saveAttachmentHandler={(attachment, cb) => postAttachment(attachment, cb)}
            deleteAttachmentHandler={(attachmentId, onStartSaving, onFinishSaving) => deleteAttachment(attachmentId, onStartSaving, onFinishSaving)}
            editAttachmentHandler={(attachment, cb) => putAttachment(attachment, cb)}
          />
        )
      },
      ...(hasNewPostgrad
        ? [
            {
              tab: {
                name: "Postgrad"
              },
              component: (
                <PostgradForm
                  isNew
                  options={studiesStudyProgrammePostgradOptions}
                  cancelHandler={() => all(() => setHasNewPostgrad(false), () => setActiveTabName("Notes"))}
                  saveHandler={(payload, cb) => postPostgrad(payload, cb)}
                  fetchSupervisorHandler={params => API.getSupervisors(params)}
                />
              )
            }
          ]
        : []),
      ...(postgrad
        ? [
            {
              tab: {
                name: "Postgrad"
              },
              component: (
                <PostgradForm
                  permissions={permissions}
                  options={{ studiesStudyProgrammeFormOptions, studiesStudyProgrammePostgradOptions }}
                  initialData={postgrad}
                  deleteHandler={(postgradId, onStartCallback, onFinishCallback) => deletePostgrad(postgradId, onStartCallback, onFinishCallback)}
                  saveHandler={(payload, cb) => putPostgrad(payload, cb)}
                  fetchSupervisorHandler={params => API.getSupervisors(params)}
                />
              )
            }
          ]
        : []),
      ...(hasNewFees
        ? [
            {
              tab: {
                name: "Fees"
              },
              component: (
                <FeesForm
                  isNew
                  options={studiesStudyProgrammeFeesOptions}
                  cancelHandler={() => all(() => setHasNewFees(false), () => setActiveTabName("Notes"))}
                  saveHandler={(payload, cb) => postFees(payload, cb)}
                />
              )
            }
          ]
        : []),
      ...(fees
        ? [
            {
              tab: {
                name: "Fees"
              },
              component: (
                <FeesForm
                  permissions={permissions}
                  options={studiesStudyProgrammeFeesOptions}
                  initialData={fees}
                  deleteHandler={(feesId, onStartCallback, onFinishCallback) => deleteFees(feesId, onStartCallback, onFinishCallback)}
                  saveHandler={(payload, cb) => putFees(payload, cb)}
                />
              )
            }
          ]
        : []),
      ...(hasNewAdmissions
        ? [
            {
              tab: {
                name: "Admissions"
              },
              component: (
                <AdmissionsForm
                  isNew
                  cancelHandler={() => all(() => setHasNewAdmissions(false), () => setActiveTabName("Notes"))}
                  saveHandler={(payload, cb) => postAdmissions(payload, cb)}
                  options={{ studiesStudyProgrammeFormOptions, admissionsApplicationsOptions }}
                />
              )
            }
          ]
        : []),
      ...(admissions
        ? [
            {
              tab: {
                name: "Admissions"
              },
              component: (
                <AdmissionsForm
                  options={{ studiesStudyProgrammeFormOptions, admissionsApplicationsOptions }}
                  permissions={permissions}
                  initialData={admissions}
                  deleteHandler={(admissionsId, onStartCallback, onFinishCallback) => deleteAdmissions(admissionsId, onStartCallback, onFinishCallback)}
                  saveHandler={(payload, cb) => putAdmissions(payload, cb)}
                />
              )
            }
          ]
        : []),
      ...(hasNewFundingAndMonitoring
        ? [
            {
              tab: {
                name: "Funding and Monitoring"
              },
              component: (
                <FundingAndMonitoringForm
                  isNew
                  options={studiesStudyProgrammeFundingAndMonitoringOptions}
                  cancelHandler={() => all(() => setHasNewFundingAndMonitoring(false), () => setActiveTabName("Notes"))}
                  saveHandler={(payload, cb) => postFundingAndMonitoring(payload, cb)}
                />
              )
            }
          ]
        : []),
      ...(fundingAndMonitoring
        ? [
            {
              tab: {
                name: "Funding and Monitoring"
              },
              component: (
                <FundingAndMonitoringForm
                  permissions={permissions}
                  options={studiesStudyProgrammeFundingAndMonitoringOptions}
                  initialData={fundingAndMonitoring}
                  deleteHandler={(fundingAndMonitoringId, onStartCallback, onFinishCallback) =>
                    deleteFundingAndMonitoring(fundingAndMonitoringId, onStartCallback, onFinishCallback)
                  }
                  saveHandler={(payload, cb) => putFundingAndMonitoring(payload, cb)}
                />
              )
            }
          ]
        : [])
    ];
  };

  const activeForm = forms().find(form => form.tab.name === activeTabName);

  const fetchData = async () => {
    try {
      const data = await Promise.all([
        API.getStudiesStudyProgramme(studyProgrammeId),
        API.getContact(contactId),
        getStudiesStudyProgrammeFormOptions(),
        getProgrammesListOptions(),
        !studiesStudyProgrammePostgradOptions ? getStudiesStudyProgrammePostgradOptions() : null,
        !studiesStudyProgrammeFeesOptions ? getStudiesStudyProgrammeFeesOptions() : null,
        !studiesStudyProgrammeFundingAndMonitoringOptions ? getStudiesStudyProgrammeFundingAndMonitoringOptions() : null,
        !admissionsApplicationsOptions && getAdmissionsApplicationsOptions(),
        getAdminInstitutionOptions()
      ]);
      console.log("data", data);
      const { course_student_session, notes, attachments, can_attach_post_grad, post_grad, fees, admission_detail, funding_and_monitoring } = data[0].data.data;
      if (Component.isMounted) {
        setStudyProgramme(course_student_session);
        setStudent({
          id: data[1].data.data.student.student_detail.student_id,
          name: data[1].data.data.contact.name,
          surname: data[1].data.data.contact.surname
        });
        setNotes(notes);
        setAttachments(attachments);
        setFees(fees);
        setNewPostgradIsAllowed(can_attach_post_grad);
        setPostgrad(post_grad);
        setAdmissions(admission_detail);
        setFundingAndMonitoring(funding_and_monitoring);
      }
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.replace("/contact-us");
    }
  };

  const putStudyProgramme = async (payload, cb) => {
    try {
      await API.putStudiesStudyProgramme(payload);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteStudyProgramme = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This study record will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteStudiesStudyProgramme(studyProgrammeId);
          history.push("/home/studies/programmes");
        } catch (error) {
          onFinishCallback();
          setSnackbar({ text: errorMessage(error), isError: true });
        }
      },
      () => {
        hideModal();
      }
    );
  };

  // NOTES
  const saveEditNote = async (id, payload, cb) => {
    try {
      const { data } = await API.putStudiesStudyProgrammeNote(id, studyProgrammeId, payload);
      setNotes(prevState => prevState.map(note => (note.id !== id ? note : data)));
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const postNewNote = async (newNote, cb) => {
    try {
      const { data } = await API.postStudiesStudyProgrammeNote(contactId, studyProgrammeId, newNote);
      setNotes([...notes, data.data]);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteNote = (noteId, onStartSaving, onFinishSaving) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This note will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        onStartSaving();
        hideModal();
        try {
          await API.deleteNote(noteId);
          setNotes(notes.filter(note => note.id !== noteId));
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishSaving();
      },
      () => {
        hideModal();
      }
    );
  };

  //Atachment
  const postAttachment = async ({ file, description }, cb) => {
    try {
      const { data } = await API.postStudiesStudyProgrammeAttachment(studyProgrammeId, file, description);
      setAttachments([...attachments, data.data]);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const putAttachment = async ({ file, description, id }, cb) => {
    try {
      const { data } = await API.putStudiesStudyProgrammeAttachment(studyProgrammeId, file, description, id);
      setAttachments(prevState => prevState.map(attachment => (attachment.id !== id ? attachment : data.data)));
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteAttachment = (attachmentId, onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This attachment will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteAttachment(attachmentId);
          setAttachments(attachments.filter(attachment => attachment.id !== attachmentId));
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishCallback();
      },
      () => {
        hideModal();
      }
    );
  };

  //Fees
  const postFees = async (payload, cb) => {
    try {
      const { data } = await API.postStudiesStudyProgrammeFees(contactId, studyProgrammeId, payload);
      setHasNewFees(false);
      setFees(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const putFees = async (payload, cb) => {
    try {
      const { data } = await API.putStudiesStudyProgrammeFees(payload);
      setFees(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteFees = (feesId, onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "These fees will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteStudiesStudyProgrammeFees(feesId);
          setFees(undefined);
          setActiveTabName("Notes");
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishCallback();
      },
      () => {
        hideModal();
      }
    );
  };

  //Postgrad
  const postPostgrad = async (payload, cb) => {
    console.log("payload", payload);
    try {
      const { data } = await API.postStudiesStudyProgrammePostgrad(studyProgrammeId, payload);
      setHasNewPostgrad(false);
      setPostgrad(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const putPostgrad = async (payload, cb) => {
    try {
      const { data } = await API.putStudiesStudyProgrammePostgrad(payload);
      setPostgrad(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deletePostgrad = (postgradId, onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This postgrad will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteStudiesStudyProgrammePostgrad(postgradId);
          setPostgrad(undefined);
          setActiveTabName("Notes");
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishCallback();
      },
      () => {
        hideModal();
      }
    );
  };

  //Admissions
  const postAdmissions = async (payload, cb) => {
    try {
      const { data } = await API.postStudiesStudyProgrammeAdmissions(studyProgrammeId, payload);
      setHasNewAdmissions(false);
      setAdmissions(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const putAdmissions = async (payload, cb) => {
    try {
      const { data } = await API.putStudiesStudyProgrammeAdmissions(payload);
      setAdmissions(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteAdmissions = (admissionsId, onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "These admissions will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteStudiesStudyProgrammeAdmissions(admissionsId);
          setAdmissions(undefined);
          setActiveTabName("Notes");
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishCallback();
      },
      () => {
        hideModal();
      }
    );
  };

  //FundingAndMonitoring
  const postFundingAndMonitoring = async (payload, cb) => {
    try {
      const { data } = await API.postStudiesStudyProgrammeFundingAndMonitoring(studyProgrammeId, payload);
      setHasNewFundingAndMonitoring(false);
      setFundingAndMonitoring(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const putFundingAndMonitoring = async (payload, cb) => {
    try {
      const { data } = await API.putStudiesStudyProgrammeFundingAndMonitoring(payload);
      setFundingAndMonitoring(data.data);
      cb();
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  const deleteFundingAndMonitoring = (fundingAndMonitoringId, onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This funding and monitoring will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteStudiesStudyProgrammeFundingAndMonitoring(fundingAndMonitoringId);
          setFundingAndMonitoring(undefined);
          setActiveTabName("Notes");
        } catch (error) {
          setSnackbar({ text: errorMessage(error), isError: true });
        }
        onFinishCallback();
      },
      () => {
        hideModal();
      }
    );
  };

  useEffect(() => {
    Component.isMounted = true;
    (async () => {
      await fetchData();
      Component.isMounted && setIsShown(true);
    })();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {!!actions.length && (
        <ActionsDropdown
          actions={actions.map(action => action.name)}
          clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
          style={{ position: "absolute", right: 10, top: -80 }}
        />
      )}
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <>
            <SectionWrapper hasBackButton backButtonText="Contact" clickBackButtonHandler={() => history.push(`/home/contacts/all-contacts/${contactId}`)}>
              <div className={classes.studentInfo}>
                <div>
                  ID: <span>{student.id}</span>
                </div>
                <div>
                  Name:{" "}
                  <span>
                    {student.name} {student.surname}
                  </span>
                </div>
              </div>
              <StudiesStudyProgrammeForm
                title={{ onEdit: "Edit this Study Record" }}
                options={{ ...studiesStudyProgrammeFormOptions, programmesListOptions, institutions: adminInstitutionOptions }}
                initialData={studyProgramme}
                buttons={["cancel", "delete", "save"]}
                deleteHandler={(onStartCallback, onFinishCallback) => deleteStudyProgramme(onStartCallback, onFinishCallback)}
                saveHandler={(payload, cb) => putStudyProgramme(payload, cb)}
              />
            </SectionWrapper>
            <SectionWrapper>
              <SectionTabs tabs={forms().map(form => form.tab)} activeTabName={activeTabName} clickHandler={tab => setActiveTabName(tab)} />
              {activeForm && activeForm.component}
            </SectionWrapper>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  studiesStudyProgrammeFormOptions: inputDataListsReducer.studiesStudyProgrammeFormOptions,
  programmesListOptions: inputDataListsReducer.programmesListOptions,
  studiesStudyProgrammePostgradOptions: inputDataListsReducer.studiesStudyProgrammePostgradOptions,
  studiesStudyProgrammeFeesOptions: inputDataListsReducer.studiesStudyProgrammeFeesOptions,
  studiesStudyProgrammeFundingAndMonitoringOptions: inputDataListsReducer.studiesStudyProgrammeFundingAndMonitoringOptions,
  adminInstitutionOptions: inputDataListsReducer.adminInstitutionOptions,
  admissionsApplicationsOptions: inputDataListsReducer.admissionsApplicationsOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesStudyProgrammeFormOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammeFormOptions),
    getProgrammesListOptions: () => withPromise(dispatch, actions.getProgrammesListOptions),
    getStudiesStudyProgrammePostgradOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammePostgradOptions),
    getStudiesStudyProgrammeFeesOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammeFeesOptions),
    getStudiesStudyProgrammeFundingAndMonitoringOptions: () => withPromise(dispatch, actions.getStudiesStudyProgrammeFundingAndMonitoringOptions),
    getAdminInstitutionOptions: () => withPromise(dispatch, actions.getAdminInstitutionOptions),
    getAdmissionsApplicationsOptions: () => withPromise(dispatch, actions.getAdmissionsApplicationsOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const StudiesStudyProgramme = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
