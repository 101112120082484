import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select, DatePicker } from "@Root/components";

export class StudiesModuleInstanceForm extends Component {
  constructor(props) {
    super(props);
    const { initialIsEditable, initialData, generateId } = props;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      module_instance_identifier: generateId ? Math.floor(Math.random() * (100000000000000 - 1 + 1)) + 1 : initialData.module_instance_identifier,
      student_course_session_id: initialData.student_course_session_id,
      accreditation_of_prior_id: initialData.accreditation_of_prior_id,
      module_count_id: initialData.module_count_id,
      module_proportion: initialData.module_proportion,
      module_start_date: initialData.module_start_date,
      z_module_start_date: initialData.z_module_start_date,
      language_percentage: initialData.language_percentage,
      module_marks: initialData.module_marks,
      module_outcome_id: initialData.module_outcome_id,
      module_outcome_end_date: initialData.module_outcome_end_date,
      module_result_id: initialData.module_result_id
    };
  }
  formIsValid = () => {
    const {
      module_instance_identifier,
      module_proportion,
      module_start_date,
      z_module_start_date,
      language_percentage,
      module_marks,
      module_outcome_end_date
    } = this.state;
    if (!module_instance_identifier) {
      this.showError("module_instance_identifier", "Required");
      return false;
    }
    if (!module_proportion) {
      this.showError("module_proportion", "Required");
      return false;
    }
    if (!module_start_date) {
      this.showError("module_start_date", "Required");
      return false;
    }
    if (!z_module_start_date) {
      this.showError("z_module_start_date", "Required");
      return false;
    }
    if (!language_percentage) {
      this.showError("language_percentage", "Required");
      return false;
    }
    if (!module_marks) {
      this.showError("module_marks", "Required");
      return false;
    }
    if (!module_outcome_end_date) {
      this.showError("module_outcome_end_date", "Required");
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  setInitialState = () => {
    const { initialData } = this.props;
    this.setState({
      isEditable: false,
      error: null,
      module_instance_identifier: initialData.module_instance_identifier,
      student_course_session_id: initialData.student_course_session_id,
      accreditation_of_prior_id: initialData.accreditation_of_prior_id,
      module_count_id: initialData.module_count_id,
      module_proportion: initialData.module_proportion,
      module_start_date: initialData.module_start_date,
      z_module_start_date: initialData.z_module_start_date,
      language_percentage: initialData.language_percentage,
      module_marks: initialData.module_marks,
      module_outcome_id: initialData.module_outcome_id,
      module_outcome_end_date: initialData.module_outcome_end_date,
      module_result_id: initialData.module_result_id
    });
  };

  handleClickCancelButton = () => {
    this.setInitialState();
  };

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { state } = this;
    const moduleDelId = this.props.location.pathname.split("/")[6];
    this.setState({ isSaving: true });
    try {
      await this.props.saveHandler({
        module_instance_identifier: state.module_instance_identifier,
        student_course_session_id: state.student_course_session_id,
        accreditation_of_prior_id: state.accreditation_of_prior_id,
        module_count_id: state.module_count_id,
        module_delivery_id: moduleDelId,
        module_proportion: state.module_proportion,
        module_start_date: state.module_start_date,
        z_module_start_date: state.z_module_start_date,
        language_percentage: state.language_percentage,
        module_marks: state.module_marks,
        module_outcome_id: state.module_outcome_id,
        module_outcome_end_date: state.module_outcome_end_date,
        module_result_id: state.module_result_id
      });
      !this.isUnmounted && this.setState({ isEditable: false });
    } catch (error) {
      console.log(error);
      alert("Unable to save the data");
    }
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const {
      isEditable,
      isSaving,
      module_instance_identifier,
      student_course_session_id,
      accreditation_of_prior_id,
      module_count_id,
      module_proportion,
      module_start_date,
      z_module_start_date,
      language_percentage,
      module_marks,
      module_outcome_id,
      module_outcome_end_date,
      module_result_id
    } = this.state;

    const { permissions, title, buttons, options } = this.props;
    const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage } = this;

    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;

    return (
      <div className={classes.StudiesModuleInstanceForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this module instance" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module instance identifier" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_instance_identifier.toString()}
                  placeholder=""
                  isDisabled={true}
                  error={errorMessage("module_instance_identifier")}
                />
              </div>

              <div className={classes.inputWrapper}>
                <InputLabel text="Student course session identifier " />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.student_course_session_identificators}
                  value={student_course_session_id}
                  changeHandler={value => this.setState({ student_course_session_id: value })}
                  isDisabled={!isEditable}
                  error={errorMessage("student_course_session_id")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Accreditation of prior experiential learning" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.accreditation_of_priors}
                  value={accreditation_of_prior_id}
                  changeHandler={value => this.setState({ accreditation_of_prior_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module count" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.modules_counts}
                  value={module_count_id}
                  changeHandler={value => this.setState({ module_count_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module proportion" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_proportion}
                  changeHandler={module_proportion => this.setState({ module_proportion })}
                  placeholder=""
                  isDisabled={!isEditable}
                  restriction="digits"
                  error={errorMessage("module_proportion")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module start date" hasAsterisk={isEditable} />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_start_date}
                  changeHandler={module_start_date => this.setState({ module_start_date })}
                  isDisabled={!isEditable}
                  error={errorMessage("module_start_date")}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Z module start date" hasAsterisk={isEditable} />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={z_module_start_date}
                  changeHandler={z_module_start_date => this.setState({ z_module_start_date })}
                  isDisabled={!isEditable}
                  error={errorMessage("z_module_start_date")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Language percentage" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={language_percentage}
                  changeHandler={language_percentage => this.setState({ language_percentage })}
                  placeholder=""
                  isDisabled={!isEditable}
                  restriction="digits"
                  error={errorMessage("language_percentage")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module marks" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_marks}
                  changeHandler={module_marks => this.setState({ module_marks })}
                  placeholder=""
                  isDisabled={!isEditable}
                  restriction="betweenZeroAndHundred"
                  error={errorMessage("module_marks")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module outcome" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.modules_outcomes}
                  value={module_outcome_id}
                  changeHandler={value => this.setState({ module_outcome_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module outcome end date" hasAsterisk={isEditable} />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_outcome_end_date}
                  changeHandler={module_outcome_end_date => this.setState({ module_outcome_end_date })}
                  isDisabled={!isEditable}
                  error={errorMessage("module_outcome_end_date")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module result" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.modules_results}
                  value={module_result_id}
                  changeHandler={value => this.setState({ module_result_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

StudiesModuleInstanceForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    module_instance_identifier: PropTypes.number,
    student_course_session_id: PropTypes.number,
    accreditation_of_prior_id: PropTypes.number,
    module_count_id: PropTypes.number,
    module_proportion: PropTypes.number,
    module_start_date: PropTypes.string,
    z_module_start_date: PropTypes.string,
    language_percentage: PropTypes.number,
    module_marks: PropTypes.number,
    module_outcome_id: PropTypes.number,
    module_outcome_end_date: PropTypes.string,
    module_result_id: PropTypes.number
  }),
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

StudiesModuleInstanceForm.defaultProps = {
  permissions: [],
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    module_instance_identifier: null,
    student_course_session_id: null,
    accreditation_of_prior_id: null,
    module_count_id: null,
    module_proportion: null,
    module_start_date: null,
    z_module_start_date: null,
    language_percentage: null,
    module_marks: null,
    module_outcome_id: null,
    module_outcome_end_date: null,
    module_result_id: null
  },
  initialIsEditable: false,
  options: null,
  buttons: ["cancel", "delete", "save"],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};
