import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { delay } from "redux-saga";
import { TickImage } from "@Root/assets/icons";

export const SuccessNotification = ({ text, changeHandler }) => {
  const wait = 500;

  const [bottom, setBottom] = useState(-60);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    SuccessNotification.isMounted = true;
    (async () => {
      await delay(1);
      SuccessNotification.isMounted && setBottom(40);
      await delay(3000);
      SuccessNotification.isMounted && setOpacity(0);
      await delay(wait);
      changeHandler(null);
    })();
    return () => {
      SuccessNotification.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper} style={{ bottom, opacity, transition: `all ${wait}ms ease-out` }}>
      <img src={TickImage} alt="" className={classes.image} />
      {text}
    </div>
  );
};

SuccessNotification.propTypes = {
  text: PropTypes.string,
  changeHandler: PropTypes.func,
  isError: PropTypes.bool
};

SuccessNotification.defaultProps = {
  text: null,
  changeHandler: () => {},
  isError: false
};
