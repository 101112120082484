import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import optionsIcon from "../../../assets/icons/options.png";
import { matchTypes, equalities } from "../../../configs/rules";

export const TableRuleTile = ({
  rule,
  dateRange,
  fetchDataHandler,
  clickBarHandler = () => {},
  clickEditHandler = () => {},
  isActive,
  errorHandler = () => {},
  shouldBeUpdated = false
}) => {
  const [quantity, setQuantity] = useState(null);

  const fetchData = async () => {
    try {
      const { matchType, rows } = rule;
      const ruleParams = rows.reduce((acc, row, i) => acc + `${i === 0 ? "" : ";"}${row.field}:${row.filter}`, "");
      const rulesForFields = rows.reduce((acc, row, i) => acc + `${i === 0 ? "" : ";"}${row.field}:${equalities[row.equality]}`, "");
      const params = { start_date: dateRange[0], end_date: dateRange[1], rule: ruleParams, rulesForFields, ruleJoin: matchTypes[matchType] };
      const { data } = await fetchDataHandler(params);
      TableRuleTile.isMounted && setQuantity(data.meta.total);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    TableRuleTile.isMounted = true;
    return () => {
      TableRuleTile.isMounted = false;
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [rule.matchType, JSON.stringify(rule.rows), JSON.stringify(dateRange)]);

  useEffect(() => {
    shouldBeUpdated && fetchData();
  }, [shouldBeUpdated]);

  return (
    <div className={classes.TableRuleTile}>
      <div className={`${classes.bar} ${isActive ? classes.active : ""}`}>
        <div className={classes.text} onClick={clickBarHandler}>
          <span className={classes.name} style={rule.isMandatory ? { fontWeight: "bold" } : null}>
            {rule.name}
          </span>
          &nbsp;
          <span>{quantity !== null ? `(${quantity})` : ""}</span>
        </div>
        {!rule.isMandatory && (
          <div className={classes.opener} onClick={() => clickEditHandler()}>
            <img src={optionsIcon} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

TableRuleTile.propTypes = {
  rule: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    matchType: PropTypes.string,
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        columnName: PropTypes.string,
        equality: PropTypes.string,
        filter: PropTypes.string
      })
    ),
    isMandatory: PropTypes.bool
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      field: PropTypes.string,
      searchInputType: PropTypes.oneOf(["textInput", "select", "datePicker"]),
      options: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
          })
        ])
      ),
      cellType: PropTypes.oneOf(["text", "image", "link", "checkbox", "checkboxRemastered", "mailLink"])
    })
  ),
  dateRange: PropTypes.arrayOf(PropTypes.string),
  fetchDataHandler: PropTypes.func,
  clickBarHandler: PropTypes.func,
  clickEditHandler: PropTypes.func,
  isActive: PropTypes.bool,
  errorHandler: PropTypes.func,
  shouldBeUpdated: PropTypes.bool
};
