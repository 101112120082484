import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { SectionTitle, EditButton, CheckboxInputGroup, Select, InputLabel } from "@Root/components";
import { FormWrapper } from "@Root/HOCs";

const appealOptions = ["Academic programme", "Faculty", "Location", "Other", "The community", "Theological position", "Vision", "Worship style"];
const mocsOptions = ["Email", "Phone", "Post", "Social media"];

export const ContactsMarketingDataForm = ({ isNew, initialData, deleteHandler, saveHandler, errorHandler }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        ContactsMarketingDataForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    setIsSavind(true);
    try {
      await saveHandler(data);
      ContactsMarketingDataForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    ContactsMarketingDataForm.isMounted && setIsSavind(false);
  };

  useEffect(() => {
    ContactsMarketingDataForm.isMounted = true;
    return () => {
      ContactsMarketingDataForm.isMounted = false;
    };
  }, []);

  return (
    <FormWrapper
      buttons={isEditable ? [...(!isNew ? ["cancel", "delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete Marketing Data" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Marketing Data" : "Marketing Data"} />
        {!isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="POC" />
          <Select
            style={{ position: "absolute", left: 150 }}
            inputStyle={{ width: 260 }}
            options={["Bishop", "Church"]}
            value={data.poc}
            changeHandler={value => handleChangeInput("poc", value)}
            placeholder="Not Known"
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.checkboxGroupWrapper}>
          <InputLabel style={{ width: 150 }} text="MOCs" />
          <CheckboxInputGroup
            style={{ width: 260, border: "1px solid #d8d8d8", borderRadius: 2, padding: "0px 10px" }}
            options={mocsOptions}
            values={data.mocs}
            changeHandler={values => handleChangeInput("mocs", values)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.checkboxGroupWrapper}>
          <InputLabel style={{ width: 150 }} text="Appeal" />
          <CheckboxInputGroup
            style={{ width: 260, border: "1px solid #d8d8d8", borderRadius: 2, padding: "0px 10px" }}
            options={appealOptions}
            values={data.appeal}
            changeHandler={values => handleChangeInput("appeal", values)}
            isDisabled={!isEditable}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

ContactsMarketingDataForm.propTypes = {
  isNew: PropTypes.bool,
  initialData: PropTypes.shape({
    poc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mocs: PropTypes.array,
    appeal: PropTypes.array
  }),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

ContactsMarketingDataForm.defaultProps = {
  isNew: false,
  initialData: {
    poc: null,
    mocs: [],
    appeal: []
  },
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
