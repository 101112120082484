import React from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { Table } from "@Root/components";
import { withPromise, errorMessage } from "@Root/helpers";

class Component extends React.Component {
  tableColumnsTuitionsFees = () => {
    return [
      {
        name: "ID",
        field: "SID",
        searchInputType: "textInput",
        cellType: "link"
      },
      {
        name: "Name",
        field: "name",
        searchInputType: "textInput",
        cellType: "link"
      },
      {
        name: "Surname",
        field: "surname",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Given Name",
        field: "given_name",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Email",
        field: "email",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Programme Name",
        field: "programme",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Status",
        field: "status",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Academic Year",
        field: "academic_year",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Study Year",
        field: "study_year",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Sponsor",
        field: "sponsor",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Accom",
        field: "accomondation",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Managed By",
        field: "managed_by",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Ministerial",
        field: "ministerial",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Institution",
        field: "institution",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Training Mode",
        field: "training_mode",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: " PoS Mode",
        field: "pos_mode",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Diocese",
        field: "diocese",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Tuition",
        field: "tuition",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Assessment",
        field: "assessment",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Validation",
        field: "validation",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Ordination",
        field: "ordination",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Study Room",
        field: "study_room",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Study Fee",
        field: "study_fee",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Supp. 1",
        field: "supp_1",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Supp. 2",
        field: "supp_2",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Bursary",
        field: "bursary",
        searchInputType: "textInput",
        cellType: "text"
      }
    ];
  };

  tableColumnsFunding = () => {
    return [
      {
        name: "Student Name",
        field: "student_name",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Programme",
        field: "programme",
        searchInputType: "textInput",
        cellType: "link"
      },
      {
        name: "Academic Year",
        field: "academic_year",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Learning Venue",
        field: "learning_venue",
        searchInputType: "select",
        options: [],
        cellType: "text"
      },
      {
        name: "Fees Due",
        field: "fees_due",
        searchInputType: "textInput",
        cellType: "text"
      },
      {
        name: "Fees Paid",
        field: "fess_paid",
        searchInputType: "select",
        options: [],
        cellType: "text"
      },
      {
        name: "Funding Body",
        field: "funding_body",
        searchInputType: "select",
        options: [],
        cellType: "text"
      },
      {
        name: "Funding Completion",
        field: "funding_completion",
        searchInputType: "textInput",
        cellType: "text"
      }
    ];
  };
  tableData = {
    "tuition-fees": {
      name: "Tuitions Fees",
      columns: this.tableColumnsTuitionsFees(),
      hasRules: true,
      hasSorting: true,
      hasFilters: true,
      fetchDataHandler: params => API.getStudentsWithFinancialSupport(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("tuition-fees", options),
      fetchColumnOptions: () => API.getColumnOptions("tuition-fees"),
      clickLinkHandlers: {
        ID: row => this.props.history.push(`/home/contacts/all-contacts/${row.SID}`),
        Name: row => this.props.history.push(`/home/contacts/all-contacts/${row.SID}`)
      }
    },
    fundings: {
      name: "Fundings",
      columns: this.tableColumnsFunding(),
      hasRules: true,
      hasSorting: true,
      hasFilters: true,
      fetchDataHandler: params => API.getStudentsWithFinancialSupport(params),
      fetchSaveColumnOptions: options => API.saveColumnOptions("fundings", options),
      fetchColumnOptions: () => API.getColumnOptions("fundings")
    }
  };

  render() {
    const { location, setSnackbar, setSuccessNotification } = this.props;
    const { tableData } = this;
    const tab = location.pathname.split("/")[3];
    const { name, columns, fetchDataHandler, clickLinkHandlers, hasRules, hasSorting, hasFilters, fetchColumnOptions, fetchSaveColumnOptions } = tableData[tab];
    return (
      <div className={classes.wrapper}>
        <Table
          key={name}
          name={name}
          columns={columns}
          hasRules={hasRules}
          hasSorting={hasSorting}
          hasFilters={hasFilters}
          fetchDataHandler={fetchDataHandler}
          fetchColumnOptions={fetchColumnOptions}
          fetchSaveColumnOptions={fetchSaveColumnOptions}
          fetchExportedDataHander={() => {}}
          clickLinkHandlers={clickLinkHandlers}
          errorHandler={error => setSnackbar({ text: errorMessage(error), isError: true })}
          successHandler={success => setSuccessNotification({ text: success })}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    setSuccessNotification: data => dispatch(actions.setSuccessNotification(data)),
    getFinanceFundingOptions: () => withPromise(dispatch, actions.getFinanceFundingOptions)
  };
};

const mapStateToProps = ({ inputDataListsReducer }) => {
  return {
    financeFundingOptions: inputDataListsReducer.financeFundingOptions
  };
};
export const FinanceTable = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
