import React from "react";
import classes from "./style.module.scss";
import { NavLink } from "react-router-dom";

export const RoleManagerField = ({ fieldName, userId }) => {
  return (
    <div className={classes.field}>
      <div className={classes.field_value}>{fieldName}</div>
      <NavLink className={classes.manager_button} to={`/home/admin/manage-system-team/system-users/${userId}/role-manager`}>
        Manage
      </NavLink>
    </div>
  );
};
