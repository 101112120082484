import React, { useState, useEffect, useMemo } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { errorMessage } from "@Root/helpers";
import { Spinner, ActionsDropdown, RoleForm } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";
import { permissionsConfig } from "@Root/configs";

const generateAccordion = () => {
  return permissionsConfig;
};

const data = [
  { options: generateAccordion(), role: { value: "admin", label: "admin" } },
  { options: generateAccordion(), role: { value: "super-admin", label: "super admin" } }
];

const Role = ({ location, history, permissions, showModal, hideModal, setSnackbar }) => {
  const roleId = location.pathname.split("/").pop();

  const [role, setRole] = useState(undefined);
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      const { data } = await API.getAdminRole(roleId);
      setRole(data.data);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const postRole = async (payload, cb) => {
    console.log(payload);
    // try {
    //   const { data } = await API.postAdminRole(payload);
    //   setRole(data.data);
    //   cb();
    //   history.push(`/home/admin/manage-system-team/user-roles/${data.data.id}`);
    // } catch (error) {
    //   setSnackbar({ text: errorMessage(error), isError: true });
    // }
  };

  const putRole = async (payload, cb) => {
    console.log("payload", payload);
    // try {
    //   const { data } = await API.putAdminRole(payload);
    //   setRole(data.data);
    //   cb();
    // } catch (error) {
    //   setSnackbar({ text: errorMessage(error), isError: true });
    // }
  };

  const deleteRole = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This role will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteAdminRole(roleId);
          history.push("/home/admin/manage-system-team/user-roles");
        } catch (error) {
          onFinishCallback();
          setSnackbar({ text: errorMessage(error), isError: true });
        }
      },
      () => {
        hideModal();
      }
    );
  };

  useEffect(() => {
    Role.isMounted = true;
    (async () => {
      roleId !== "new" && (await fetchData());
      Role.isMounted && setIsShown(true);
    })();
    return () => {
      Role.isMounted = false;
    };
  }, []);

  const actions = [];

  const formConfig = {
    new: {
      isNew: true,
      title: { onEdit: "Create a role" },
      buttons: ["save"],
      saveHandler: (payload, cb) => postRole(payload, cb)
    },
    existent: {
      title: { onEdit: "Edit the role" },
      initialData: role,
      buttons: ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"],
      deleteHandler: (onStartCallback, onFinishCallback) => deleteRole(onStartCallback, onFinishCallback),
      saveHandler: (payload, cb) => putRole(payload, cb)
    }
  }[roleId === "new" ? "new" : "existent"];

  const currentPermission = useMemo(
    () => (formConfig.initialData ? data?.find(permission => permission.role.value === formConfig.initialData.name) : data[0]),
    [permissions, data]
  );

  return (
    <div className={classes.wrapper}>
      {roleId !== "new" && !!actions.length && (
        <ActionsDropdown
          actions={actions.map(action => action.name)}
          clickHandler={actionName => actions.find(action => action.name === actionName).handler()}
          style={{ position: "absolute", right: 10, top: -80 }}
        />
      )}
      {isShown ? (
        <SectionWrapper hasBackButton backButtonText="User Roles" clickBackButtonHandler={() => history.push("/home/admin/manage-system-team/user-roles")}>
          <RoleForm
            userPermissions={permissions}
            isNew={formConfig.isNew}
            title={formConfig.title}
            initialData={formConfig.initialData}
            fetchUserLabelHandler={userId => API.getAdminUserById(userId)}
            fetchUserOptionsHandler={userName => API.getAdminUserByName(userName)}
            fetchInstitutionLabelHandler={institutionId => API.getAdminInstitution(institutionId)}
            fetchInstitutionOptionsHandler={institutionName => API.getAdminInstitutionByName(institutionName)}
            buttons={formConfig.buttons}
            deleteHandler={formConfig.deleteHandler}
            saveHandler={formConfig.saveHandler}
            initialPermissions={currentPermission.options}
            fullPermissionData={data}
            activeRole={currentPermission.role}
          />
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => ({
  permissions: authReducer.user.permissions
});

const mapDispatchToProps = dispatch => {
  return {
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal()),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const AdminRole = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Role)
);
