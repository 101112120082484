import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withPromise, errorMessage } from "@Root/helpers";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { Spinner, UserForm } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";

const NewUser = ({ history, getAdminUserOptions, adminUserOptions, setSnackbar }) => {
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      await getAdminUserOptions();
      NewUser.isMounted && setIsShown(true);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.push("/contact-us");
    }
  };

  const postAdminUser = async payload => {
    try {
      const { data } = await API.postAdminUser(payload);
      history.push(`/home/admin/manage-system-team/system-users/${data.data.id}`);
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  useEffect(() => {
    NewUser.isMounted = true;
    fetchData();
    return () => {
      NewUser.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper hasBackButton backButtonText="System Users" clickBackButtonHandler={() => history.push("/home/admin/manage-system-team/system-users")}>
          <UserForm isNew title={{ onEdit: "Create a user" }} options={adminUserOptions} buttons={["save"]} saveHandler={payload => postAdminUser(payload)} />
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  adminUserOptions: inputDataListsReducer.adminUserOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getAdminUserOptions: () => withPromise(dispatch, actions.getAdminUserOptions),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const AdminNewUser = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewUser)
);
