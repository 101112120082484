import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import {
  SectionTitle,
  EditButton,
  FileInput,
  InputLabel,
  TextInput,
  Select,
  DatePicker,
  TextArea,
  MultiSelect,
  MultiDataListAsync,
  CheckboxInput
} from "@Root/components";

import { booleanOptions } from "@Root/configs";
import { SubAddressesFormForFields } from "./SubAddressesFormForFields";
import { checkAddressValidFields, generateId, emailIsValid } from "@Root/helpers";

const addressTemplateField = {
  address_line_1: "",
  address_line_2: "",
  address_line_3: "",
  city: "",
  postcode: "",
  country_id: "",
  county: "",
  address_type: null,
  current_address: false,
  other_address_type: ""
};

const firstAddress = [
  {
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    city: "",
    postcode: "",
    country_id: "",
    county: "",
    address_type: null,
    other_address_type: "",
    current_address: false,
    id: generateId()
  }
];
export class ContactsPersonForm extends Component {
  appealOptions = ["Academic programme", "Faculty", "Location", "Other", "The community", "Theological position", "Vision", "Worship style"];
  mocsOptions = ["Email", "Phone", "Post", "Social media"];

  constructor(props) {
    super(props);
    const { initialIsEditable, initialData } = props;
    const currentAddressId = initialData.addresses?.find(address => address.current_address)?.id;
    const reservedAddressId = initialData.addresses ? initialData.addresses[0]?.id : firstAddress[0]?.id;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      photoFile: null,

      photo: initialData.photo,
      surname: initialData.surname,
      name: initialData.name,
      middle_names: initialData.middle_names,
      email: initialData.email,
      second_email: initialData.second_email,
      relationshipIds: initialData.relationship_ids, // array of integers
      student_id: initialData.student_id,
      dateOfBirthday: initialData.date_of_birthday,
      genderId: initialData.gender_id, // integer
      titleId: initialData.title_id, // integer
      isResidentInCarter: initialData.is_resident_in_carter, // bool
      isNotEmailed: initialData.is_not_emailed, // bool
      addresses: initialData.addresses?.length > 0 ? initialData.addresses : firstAddress,
      telPrimary: initialData.tel_primary,
      telSecondary: initialData.tel_secondary,
      telMobile: initialData.tel_mobile,
      comment: initialData.comment,
      organisation_ids: initialData.organisation_ids,
      trashed: initialData.trashed,
      spouse_name: initialData.spouse_name,
      spouse_surname: initialData.spouse_surname,
      dietary_requirements: initialData.dietary_requirements,
      year_joined_federation: initialData.year_joined_federation,
      year_left_federation: initialData.year_left_federation,
      updated_at: initialData.updated_at,
      pruning_review_date: initialData.pruning_review_date,
      financial_arrangements: initialData.financial_arrangements,
      university_card_barcode: initialData.university_card_barcode,
      publicly_fundable: Number(initialData.publicly_fundable),
      age: initialData.age,
      emergency_contact_details: initialData.emergency_contact_details,
      university_card_date: initialData.university_card_date,
      previous_name: initialData.previous_name,
      previous_surname: initialData.previous_surname,
      known_first_name: initialData.known_first_name,
      known_surname: initialData.known_surname,

      openedItemId: currentAddressId || reservedAddressId,
      poc: "",
      appeal: [],
      mocs: [],
      studentIdValidationError: null,
      studentIdValidationIsFetching: false
    };
  }

  formIsValid = () => {
    const { name, surname, email, relationshipIds, student_id, studentIdValidationError, studentIdValidationIsFetching, addresses } = this.state;
    const { isNew } = this.props;
    if (!name) {
      this.showError("name", "Required");
      return false;
    } else if (!surname) {
      this.showError("surname", "Required");
      return false;
    } else if (!email) {
      this.showError("email", "Required");
      return false;
    } else if (!emailIsValid(email)) {
      this.showError("email", "Invalid email");
      return false;
    } else if (!relationshipIds) {
      this.showError("relationship_ids", "Required");
      return false;
    } else if (studentIdValidationError || studentIdValidationIsFetching) {
      return false;
    } else if (isNew && this.relationshipsIncludeStudent() && !student_id) {
      this.showError("student_id", "Required");
      return false;
    } else if (checkAddressValidFields(addresses)) {
      this.showError("addresses", "You can`t save empty addresses");
    } else {
      return true;
    }
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      setTimeout(() => {
        this.setState({ error: null });
      }, 3000);
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  setInitialState = () => {
    const { initialData } = this.props;
    const currentAddressId = initialData.addresses?.find(address => address.current_address)?.id;
    const reservedAddressId = initialData.addresses ? initialData.addresses[0]?.id : firstAddress[0]?.id;
    this.setState({
      isEditable: false,
      error: null,
      photoFile: null,

      photo: initialData.photo,
      surname: initialData.surname,
      name: initialData.name,
      middle_names: initialData.middle_names,
      email: initialData.email,
      second_email: initialData.second_email,
      relationshipIds: initialData.relationship_ids,
      dateOfBirthday: initialData.date_of_birthday,
      genderId: initialData.gender_id, // integer
      titleId: initialData.title_id, // integer
      isResidentInCarter: initialData.is_resident_in_carter, // bool
      isNotEmailed: initialData.is_not_emailed, // bool
      addresses: initialData.addresses?.length > 0 ? initialData.addresses : firstAddress,
      telPrimary: initialData.tel_primary,
      telSecondary: initialData.tel_secondary,
      telMobile: initialData.tel_mobile,
      comment: initialData.comment,
      organisation_ids: initialData.organisation_ids,
      trashed: initialData.trashed,
      spouse_name: initialData.spouse_name,
      spouse_surname: initialData.spouse_surname,
      dietary_requirements: initialData.dietary_requirements,
      year_joined_federation: initialData.year_joined_federation,
      year_left_federation: initialData.year_left_federation,
      updated_at: initialData.updated_at,
      pruning_review_date: initialData.pruning_review_date,
      financial_arrangements: initialData.financial_arrangements,
      university_card_barcode: initialData.university_card_barcode,
      publicly_fundable: Number(initialData.publicly_fundable),
      age: initialData.age,
      emergency_contact_details: initialData.emergency_contact_details,
      university_card_date: initialData.university_card_date,
      previous_name: initialData.previous_name,
      previous_surname: initialData.previous_surname,
      know_first_name: initialData.know_first_name,
      know_surname: initialData.know_surname,

      openedItemId: currentAddressId || reservedAddressId,
      poc: "",
      appeal: [],
      mocs: []
    });
  };

  fetchContactLabel = async value => {
    const { data } = await this.props.fetchContactHandler({ search: `id:${value}`, limit: "1" });
    return data.data[0].name;
  };

  fetchContactOptions = async value => {
    const { data } = await this.props.fetchContactHandler({ search: `name:${value}`, limit: "100" });
    return data.data.map(option => ({ value: option.id, label: option.name }));
  };

  relationshipsIncludeStudent = () => {
    const { relationshipIds } = this.state;
    const { personRelationshipsDataList: relationships } = this.props;
    if (relationshipIds === null) return;
    for (let id of relationshipIds) {
      if (relationships.find(relationship => relationship.value === id).label === "Student") {
        return true;
      }
    }
  };

  handleClickCancelButton = () => {
    this.setInitialState();
  };

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  };

  handlerChangeAddress = (id, field, value) => {
    this.setState(prevState => {
      let addressCopy;
      const currentAddress = prevState.addresses.find(address => address.id === id);
      if (field === "address_type" && value !== null && value !== "Other") {
        const checkDuplicatedValue = this.state.addresses.filter(address => address.id !== id).some(address => address.address_type === value);
        if (checkDuplicatedValue) {
          addressCopy = { ...currentAddress };
          this.showError(
            `address_type/${id}`,
            "The Address Type has already been selected.  It can be selected only once.  If required for this address, remove it from the other address where it is used."
          );
        } else addressCopy = { ...currentAddress, [field]: value };
      } else addressCopy = { ...currentAddress, [field]: value };

      return {
        ...prevState,
        addresses:
          field !== "current_address"
            ? prevState.addresses.map(address => (address.id === addressCopy.id ? addressCopy : address))
            : prevState.addresses.map(address => {
                if (address.id === id) return { ...address, current_address: true };
                else if (address.current_address) return { ...address, current_address: false };
                return address;
              })
      };
    });
  };

  onCreateNewAddressField = () => {
    this.setState(prevState => ({ ...prevState, addresses: [...prevState.addresses, { ...addressTemplateField, id: generateId() }] }));
  };
  onRemoveAddressField = id => {
    this.setState(prevState => ({ ...prevState, addresses: prevState.addresses.filter(address => address.id !== id) }));
  };

  validateAddresses = () => {
    return this.state.addresses
      .map(address => (typeof address.id !== "number" ? { ...address, id: "" } : address))
      .map(address => (!address.address_type ? { ...address, address_type: "" } : address));
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { state } = this;
    const { initialData, errorHandler, successHandler } = this.props;
    this.setState({ isSaving: true });
    const photoWasDeleted = initialData.photo !== null && this.state.photo === null;
    try {
      await this.props.saveHandler(
        {
          photo: state.photoFile || "",
          surname: state.surname,
          name: state.name,
          middle_names: state.middle_names || "",
          email: state.email,
          second_email: state.second_email || "",
          relationship_ids: state.relationshipIds,
          student_id: state.student_id || "",
          date_of_birthday: state.dateOfBirthday || "",
          gender_id: state.genderId || "",
          title_id: state.titleId || "",
          is_resident_in_carter: state.isResidentInCarter ?? "",
          is_not_emailed: state.isNotEmailed ?? "",
          addresses: this.validateAddresses(),
          tel_primary: state.telPrimary || "",
          tel_secondary: state.telSecondary || "",
          tel_mobile: state.telMobile || "",
          spouse_name: state.spouse_name || "",
          spouse_surname: state.spouse_surname || "",
          comment: state.comment || "",
          organisation_ids: state.organisation_ids,
          trashed: state.trashed,
          dietary_requirements: state.dietary_requirements || "",
          year_joined_federation: state.year_joined_federation || "",
          year_left_federation: state.year_left_federation || "",
          pruning_review_date: state.pruning_review_date || "",
          financial_arrangements: state.financial_arrangements || "",
          university_card_barcode: state.university_card_barcode || "",
          publicly_fundable: state.publicly_fundable || 0,
          age: state.age,
          emergency_contact_details: state.emergency_contact_details || "",
          university_card_date: state.university_card_date || "",
          previous_name: state.previous_name || "",
          previous_surname: state.previous_surname || "",
          known_first_name: state.known_first_name || "",
          known_surname: state.known_surname || ""
        },
        photoWasDeleted
      );
      successHandler("The user information was saved successful!");
      !this.isUnmounted && this.setState({ isEditable: false });
    } catch (error) {
      try {
        const [errorName, errorMessages] = Object.entries(error.response.data.errors)[0];
        this.showError(errorName, errorMessages[0]);
      } catch (err) {
        errorHandler(error);
      }
    }
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  changeOpenTab = id => {
    this.setState(prevState => ({ ...prevState, openedItemId: prevState.openedItemId === id ? null : id }));
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.initialData !== this.props.initialData) {
      this.setInitialState();
    }
    if (prevState.relationshipIds !== this.state.relationshipIds) {
      if (!this.relationshipsIncludeStudent()) {
        this.setState({ student_id: "" });
      }
      if (this.relationshipsIncludeStudent() && this.state.relationshipIds.length === 1) {
        this.setState({ trashed: false });
      }
    }
    if (prevState.student_id !== this.state.student_id) {
      if (this.state.student_id) {
        this.setState({ studentIdValidationIsFetching: true });
        try {
          await this.props.studentIdValidationHandler(this.state.student_id);
          this.setState({ studentIdValidationError: null });
        } catch (error) {
          this.setState({ studentIdValidationError: "This student ID is already in use" });
        }
        this.setState({ studentIdValidationIsFetching: false });
      } else {
        this.setState({ studentIdValidationError: null });
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  showNotificationModal = async (text, fieldName, value = null) => {
    const { hideModal, showModal } = this.props;
    await new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: text,
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      () => {
        hideModal();
        if (value !== null) {
          this.setState({ [fieldName]: value });
        }
      },
      () => {
        hideModal();
        if (value !== null) {
          this.setState({ [fieldName]: !value });
        }
      }
    );
  };
  render() {
    const {
      isEditable,
      isSaving,
      photoFile,
      photo,
      surname,
      name,
      second_email,
      middle_names,
      email,
      relationshipIds,
      student_id,
      dateOfBirthday,
      genderId,
      titleId,
      isResidentInCarter,
      isNotEmailed,
      addresses,
      telPrimary,
      telSecondary,
      telMobile,
      comment,
      organisation_ids,
      trashed,
      studentIdValidationIsFetching,
      studentIdValidationError,
      spouse_name,
      spouse_surname,
      dietary_requirements,
      year_joined_federation,
      year_left_federation,
      updated_at,
      pruning_review_date,
      financial_arrangements,
      university_card_barcode,
      publicly_fundable,
      age,
      emergency_contact_details,
      university_card_date,
      openedItemId,
      previous_name,
      previous_surname,
      known_first_name,
      known_surname
    } = this.state;
    const {
      isNew,
      permissions,
      title,
      contactIsSaving,
      titlesDataList,
      gendersDataList,
      personRelationshipsDataList,
      buttons,
      academicYears,
      contactAddressesType,
      countriesDataList
    } = this.props;
    const {
      relationshipsIncludeStudent,
      handleClickCancelButton,
      handleClickDeleteButton,
      handleClickSaveButton,
      errorMessage,
      fetchContactLabel,
      fetchContactOptions
    } = this;
    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;
    return (
      <div className={classes.ContactsPersonForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this contact" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={contactIsSaving || isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            <div className={classes.wrapper}>
              {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}
            </div>
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.latestUpdateWrapper} style={{ justifyContent: isNew ? "flex-end" : "space-between" }}>
                {!isNew && (
                  <div>
                    <div>Latest update:</div> <div>{updated_at.split(" ")[0]}</div>
                    <div>{updated_at.split(" ")[1]}</div>
                  </div>
                )}
                <FileInput
                  style={{ width: "260px" }}
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  value={photo}
                  file={photoFile}
                  changeHandler={(photo, photoFile) => this.setState({ photo, photoFile })}
                  removeHandler={() => this.setState({ photo: null, photoFile: null })}
                  isDisabled={!isEditable}
                  restrictions={["image"]}
                  error={errorMessage("photo")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Name" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 125 }}
                  value={name}
                  changeHandler={name => this.setState({ name })}
                  placeholder="First name"
                  isDisabled={!isEditable}
                  error={errorMessage("name")}
                />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 125 }}
                  value={surname}
                  changeHandler={surname => this.setState({ surname })}
                  placeholder="Surname"
                  isDisabled={!isEditable}
                  error={errorMessage("surname")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Middle Names" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={middle_names}
                  changeHandler={middle_names => this.setState({ middle_names })}
                  isDisabled={!isEditable}
                  error={errorMessage("middle_names")}
                />
              </div>
              <div className={classes.textareaWrapper}>
                <InputLabel text="Spouse Name" />
                <TextArea
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={spouse_name ? spouse_name : ""}
                  changeHandler={spouse_name => this.setState({ spouse_name })}
                  error={errorMessage("spouse_name")}
                  isDisabled={!isEditable}
                  maxLength={255}
                />
              </div>
              <div className={classes.textareaWrapper}>
                <InputLabel text="Spouse Surname" />
                <TextArea
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={spouse_surname ? spouse_surname : ""}
                  changeHandler={spouse_surname => this.setState({ spouse_surname })}
                  error={errorMessage("spouse_surname")}
                  isDisabled={!isEditable}
                  maxLength={255}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Previous name" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 125 }}
                  value={previous_name}
                  changeHandler={previous_name => this.setState({ previous_name })}
                  placeholder="Previous first name"
                  isDisabled={!isEditable}
                  error={errorMessage("previous_name")}
                />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 125 }}
                  value={previous_surname}
                  changeHandler={previous_surname => this.setState({ previous_surname })}
                  placeholder="Previous surname"
                  isDisabled={!isEditable}
                  error={errorMessage("previous_surname")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Known as name" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 125 }}
                  value={known_first_name}
                  changeHandler={known_first_name => this.setState({ known_first_name })}
                  placeholder="Know as first name"
                  isDisabled={!isEditable}
                  error={errorMessage("known_first_name")}
                />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 125 }}
                  value={known_surname}
                  changeHandler={known_surname => this.setState({ known_surname })}
                  placeholder="Know as surname"
                  isDisabled={!isEditable}
                  error={errorMessage("known_surname")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Gender" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 125 }}
                  options={gendersDataList}
                  value={genderId}
                  changeHandler={genderId => this.setState({ genderId })}
                  maxVisibleOptionsQuantity={5}
                  error={errorMessage("gender_id")}
                  isDisabled={!isEditable}
                />
                <InputLabel text="Title" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 80 }}
                  options={titlesDataList}
                  value={titleId}
                  changeHandler={titleId => this.setState({ titleId })}
                  maxVisibleOptionsQuantity={5}
                  error={errorMessage("title_id")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Resident in сarter" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 125 }}
                  options={booleanOptions}
                  value={isResidentInCarter}
                  changeHandler={isResidentInCarter => this.setState({ isResidentInCarter })}
                  error={errorMessage("is_resident_in_carter")}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Relationships" hasAsterisk={isEditable} />
                <MultiSelect
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={personRelationshipsDataList}
                  values={relationshipIds ? relationshipIds : []}
                  changeHandler={relationshipIds => this.setState({ relationshipIds })}
                  error={errorMessage("relationship_ids")}
                  isDisabled={!isEditable}
                />
              </div>
              {relationshipsIncludeStudent() && (
                <div className={classes.inputWrapper}>
                  <InputLabel text="Student ID" hasAsterisk={isEditable} />
                  <TextInput
                    classNames={!isEditable ? ["borderless"] : []}
                    isDisabled={!isEditable}
                    style={{ width: 260 }}
                    value={student_id}
                    changeHandler={student_id => this.setState({ student_id })}
                    error={studentIdValidationError ? studentIdValidationError : errorMessage("student_id")}
                    errorIsInfinite={!!studentIdValidationError}
                    isSpinning={studentIdValidationIsFetching}
                  />
                </div>
              )}
              {isEditable && !isNew && (
                <div className={classes.inputWrapper}>
                  <InputLabel text="Trashed" />
                  <CheckboxInput
                    style={{ height: 38 }}
                    isChecked={trashed}
                    changeHandler={trashed =>
                      trashed
                        ? this.showNotificationModal("If you trash this student all his study records will be also trashed", "trashed", trashed)
                        : this.setState({ trashed })
                    }
                    isDisabled={!isEditable}
                  />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <InputLabel text="Date of birthday" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={dateOfBirthday}
                  changeHandler={dateOfBirthday => this.setState({ dateOfBirthday })}
                  error={errorMessage("date_of_birthday")}
                  isDisabled={!isEditable}
                />
              </div>{" "}
              {!isNew && (
                <div className={classes.inputWrapper}>
                  <InputLabel text="Age" />
                  <TextInput
                    isDisabled={true}
                    style={{ width: 260 }}
                    value={age ? `${age} years old` : "No data available"}
                    changeHandler={age => this.setState({ age })}
                    error={errorMessage("age")}
                    classNames={!isEditable ? ["borderless"] : []}
                  />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <InputLabel text="Tel - Primary" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={telPrimary}
                  changeHandler={telPrimary => this.setState({ telPrimary })}
                  error={errorMessage("tel_primary")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Tel - Secondary" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={telSecondary}
                  changeHandler={telSecondary => this.setState({ telSecondary })}
                  error={errorMessage("tel_secondary")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Tel - Mobile" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={telMobile}
                  changeHandler={telMobile => this.setState({ telMobile })}
                  error={errorMessage("tel_mobile")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Email" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={email}
                  changeHandler={email => this.setState({ email })}
                  error={errorMessage("email")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Second Email" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={second_email}
                  changeHandler={second_email => this.setState({ second_email })}
                  error={errorMessage("second_email")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Do not email" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 125 }}
                  options={booleanOptions}
                  value={isNotEmailed}
                  changeHandler={isNotEmailed => {
                    console.log(isNotEmailed);
                    this.setState({ isNotEmailed });
                  }}
                  error={errorMessage("is_not_emailed")}
                  isDisabled={!isEditable}
                />
              </div>{" "}
              <div className={classes.noteWrapper}>
                <span>Note:&nbsp;</span>
                <span>The "Do not Email" flag should NOT be changed from True to False without Contact's confirmation</span>
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Emergency contact details" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={emergency_contact_details}
                  changeHandler={emergency_contact_details => this.setState({ emergency_contact_details })}
                  error={errorMessage("emergency_contact_details")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Organisations" />
                <MultiDataListAsync
                  inputClassNames={["borderless"]}
                  inputStyle={{ width: 260 }}
                  values={organisation_ids}
                  fetchLabelHandler={value => fetchContactLabel(value)}
                  fetchOptionsHandler={value => fetchContactOptions(value)}
                  changeHandler={organisation_ids => this.setState({ organisation_ids })}
                  isDisabled
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.textareaWrapper}>
                <InputLabel text="Dietary Requirements" />
                <TextArea
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={dietary_requirements ? dietary_requirements : ""}
                  changeHandler={dietary_requirements => this.setState({ dietary_requirements })}
                  error={errorMessage("dietary_requirements")}
                  isDisabled={!isEditable}
                  maxLength={255}
                />
              </div>
              <SubAddressesFormForFields
                changeHandler={this.handlerChangeAddress}
                openedId={openedItemId}
                changeOpenTab={this.changeOpenTab}
                errorMessage={errorMessage}
                isEditable={isEditable}
                addresses={addresses}
                onCreateNewAddressField={this.onCreateNewAddressField}
                onRemoveAddressField={this.onRemoveAddressField}
                contactAddressesType={contactAddressesType}
                countryOptions={countriesDataList}
                error={errorMessage("addresses")}
                isCreate
              />
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.textareaWrapper}>
                <InputLabel text="Comment" />
                <TextArea
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={comment ? comment : ""}
                  changeHandler={comment => this.setState({ comment })}
                  error={errorMessage("comment")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Year joined Federation" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={year_joined_federation}
                  changeHandler={year_joined_federation => this.setState({ year_joined_federation })}
                  error={errorMessage("year_joined_federation")}
                  isDisabled={!isEditable}
                  minDate={year_joined_federation}
                  maxDate={year_left_federation}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Year left Federation" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={year_left_federation}
                  changeHandler={year_left_federation => this.setState({ year_left_federation })}
                  error={errorMessage("year_left_federation")}
                  isDisabled={!isEditable}
                  minDate={year_joined_federation}
                  maxDate={year_left_federation}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Pruning review date" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={pruning_review_date}
                  changeHandler={pruning_review_date => this.setState({ pruning_review_date })}
                  error={errorMessage("pruning_review_date")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Financial arrangements" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={financial_arrangements}
                  changeHandler={financial_arrangements => this.setState({ financial_arrangements })}
                  error={errorMessage("financial_arrangements")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="University card barcode" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={university_card_barcode}
                  changeHandler={university_card_barcode => this.setState({ university_card_barcode })}
                  error={errorMessage("university_card_barcode")}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Publicly Fundable" />
                <CheckboxInput
                  style={{ height: 38 }}
                  isChecked={Boolean(publicly_fundable)}
                  changeHandler={publicly_fundable => this.setState({ publicly_fundable: Number(publicly_fundable) })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text={`University card date applied for`} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={academicYears || []}
                  value={university_card_date}
                  changeHandler={university_card_date => this.setState({ university_card_date })}
                  error={errorMessage("university_card_date")}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

ContactsPersonForm.propTypes = {
  isNew: PropTypes.bool,
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  contactIsSaving: PropTypes.bool,
  initialData: PropTypes.shape({
    photo: PropTypes.string,
    surname: PropTypes.string,
    name: PropTypes.string,
    given_name: PropTypes.string,
    email: PropTypes.string,
    relationship_ids: PropTypes.arrayOf(PropTypes.number),
    date_of_birthday: PropTypes.string,
    gender_id: PropTypes.number,
    title_id: PropTypes.number,
    is_resident_in_carter: PropTypes.bool,
    is_not_emailed: PropTypes.bool,
    tel_primary: PropTypes.string,
    tel_secondary: PropTypes.string,
    tel_mobile: PropTypes.string,
    year_joined_federation: PropTypes.string,
    year_left_federation: PropTypes.string,
    comment: PropTypes.string,
    organisation_ids: PropTypes.arrayOf(PropTypes.number),
    trashed: PropTypes.bool,
    pruning_review_date: PropTypes.string,
    financial_arrangements: PropTypes.string,
    university_card_barcode: PropTypes.string,
    publicly_fundable: PropTypes.bool
  }),
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialIsEditable: PropTypes.bool,
  titlesDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  gendersDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  countriesDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  academicYears: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  personRelationshipsDataList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ),
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func,
  studentIdValidationHandler: PropTypes.func
};

ContactsPersonForm.defaultProps = {
  isNew: false,
  title: {
    onRead: "",
    onEdit: ""
  },
  contactIsSaving: false,
  initialData: {
    photo: "",
    surname: "",
    name: "",
    given_names: "",
    email: "",
    relationship_ids: null,
    date_of_birthday: "",
    gender_id: null,
    title_id: null,
    is_resident_in_carter: null,
    is_not_emailed: null,
    tel_primary: "",
    tel_secondary: "",
    tel_mobile: "",
    spouse_name: "",
    spouse_surname: "",
    year_joined_federation: "",
    year_left_federation: "",
    updated_at: "",
    comment: "",
    organisation_ids: null,
    trashed: false,
    pruning_review_date: "",
    financial_arrangements: "",
    university_card_barcode: "",
    publicly_fundable: 0
  },
  permissions: [],
  initialIsEditable: false,
  titlesDataList: [],
  gendersDataList: [],
  countriesDataList: [],
  personRelationshipsDataList: [],
  academicYears: [],
  buttons: ["cancel", "delete", "save"],
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {},
  successHandler: () => {},
  studentIdValidationHandler: () => {}
};
