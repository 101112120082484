import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { Spinner } from "@Root/components";

export const FormWrapper = ({
  children,
  buttons,
  buttonsNames,
  buttonsAreDisabled,
  isSpinning,
  clickCancelButtonHandler,
  clickDeleteButtonHandler,
  clickSaveButtonHandler
}) => {
  const buttonName = button => {
    const buttonName = buttonsNames.find(buttonName => buttonName.button === button);
    return buttonName ? buttonName.name : button;
  };

  return (
    <div className={classes.FormWrapper}>
      <div className={classes.main}>
        {children}
        {isSpinning && (
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        )}
      </div>
      {!!buttons && (
        <div className={classes.footer}>
          {buttons.includes("save") && (
            <button className={classes.save} onClick={clickSaveButtonHandler} disabled={buttonsAreDisabled}>
              {buttonName("save")}
            </button>
          )}
          {buttons.includes("delete") && (
            <button className={classes.delete} onClick={clickDeleteButtonHandler} disabled={buttonsAreDisabled}>
              {buttonName("delete")}
            </button>
          )}
          {buttons.includes("cancel") && (
            <button className={classes.cancel} onClick={clickCancelButtonHandler} disabled={buttonsAreDisabled}>
              {buttonName("cancel")}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  buttonsNames: PropTypes.arrayOf(PropTypes.shape({ button: PropTypes.oneOf(["cancel", "delete", "save"]), name: PropTypes.string })),
  isSpinning: PropTypes.bool,
  buttonsAreDisabled: PropTypes.bool,
  clickCancelButtonHandler: PropTypes.func,
  clickDeleteButtonHandler: PropTypes.func,
  clickSaveButtonHandler: PropTypes.func
};

FormWrapper.defaultProps = {
  children: null,
  buttons: null,
  buttonsNames: [],
  isSpinning: false,
  buttonsAreDisabled: false,
  clickCancelButtonHandler: () => {},
  clickDeleteButtonHandler: () => {},
  clickSaveButtonHandler: () => {}
};
