import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

export const PageNavLinks = ({ root, links, style, subLinks, currentLink }) => {
  return !subLinks ? (
    <div className="PageNavLinks" style={{ ...style }}>
      {links.map((link, i) => (
        <NavLink to={`${root}/${link.path}`} activeClassName="active" key={i}>
          {link.name}
        </NavLink>
      ))}
    </div>
  ) : (
    <div>
      <div className="PageNavLinks" style={{ ...style, marginBottom: currentLink?.subLinks.length > 0 ? 10 : 30 }}>
        {links.map(({ title, path }, i) => (
          <NavLink to={`${root}/${path}`} activeClassName="active" key={i}>
            {title}
          </NavLink>
        ))}
      </div>
      {currentLink?.subLinks.length > 0 && (
        <div className="PageNavSubLinks" style={style}>
          {currentLink?.subLinks.map(({ name, path }, i) => {
            return (
              <NavLink to={`${root}/${currentLink.path}/${path}`} activeClassName="active" key={i}>
                {name}
              </NavLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

PageNavLinks.propTypes = {
  root: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string
    })
  ),
  subLinks: PropTypes.bool,
  style: PropTypes.object
};
