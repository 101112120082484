import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { booleanOptions } from "@Root/configs";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, MultiSelect, TextInput, Select, InputLabel } from "@Root/components";

export const AcademicForm = ({ permissions, initialData, deleteHandler, saveHandler, errorHandler, options }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        AcademicForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    setIsSavind(true);
    try {
      const modifiedData = switchEmptyStringsToNullsInObject(data);
      await saveHandler(modifiedData);
      AcademicForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    AcademicForm.isMounted && setIsSavind(false);
  };

  useEffect(() => {
    AcademicForm.isMounted = true;
    return () => {
      AcademicForm.isMounted = false;
    };
  }, []);

  return (
    <FormWrapper
      buttons={isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this academic" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Academic Details" : "Academic Details"} />
        <div>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Examiner" />
          <Select
            style={{ position: "absolute", left: 150 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.examiner}
            changeHandler={value => handleChangeInput("examiner", value)}
            isDisabled={!isEditable}
            // error={errorMessage("examiner")}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Internal" />
          <Select
            style={{ position: "absolute", left: 150 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.internal}
            changeHandler={value => handleChangeInput("internal", value)}
            isDisabled={!isEditable}
            // error={errorMessage("internal")}
          />
          <InputLabel style={{ position: "absolute", left: 490 }} text="External" />
          <Select
            style={{ position: "absolute", left: 640 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.external}
            changeHandler={value => handleChangeInput("external", value)}
            isDisabled={!isEditable}
            // error={errorMessage("external")}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Unavailable" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.unavailable}
            changeHandler={value => handleChangeInput("unavailable", value)}
            isDisabled={!isEditable}
            // error={errorMessage("unavailable")}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Institution" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.institution}
            changeHandler={value => handleChangeInput("institution", value)}
            isDisabled={!isEditable}
            // error={errorMessage("institution")}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Department" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.department}
            changeHandler={value => handleChangeInput("department", value)}
            isDisabled={!isEditable}
            // error={errorMessage("department")}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Position" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            value={data.position}
            changeHandler={value => handleChangeInput("position", value)}
            isDisabled={!isEditable}
            // error={errorMessage("position")}
          />
        </div>
      </div>
      <div className={classes.rowWrapper}>
        <InputLabel text="Federation House" />
        <div style={{ position: "absolute", left: 150, width: 260 }}>
          <MultiSelect
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={options.institutionOptions}
            values={data.institution_ids}
            changeHandler={value => handleChangeInput("institution_ids", value)}
            isDisabled={!isEditable}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

AcademicForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    examiner: PropTypes.bool,
    internal: PropTypes.bool,
    external: PropTypes.bool,
    unavailable: PropTypes.string,
    institution_ids: PropTypes.arrayOf(PropTypes.number),
    department: PropTypes.string,
    position: PropTypes.string
  }),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

AcademicForm.defaultProps = {
  permissions: [],
  initialData: {
    examiner: null,
    internal: null,
    external: null,
    unavailable: null,
    department: null,
    position: null,
    institutions: null,
    institution_ids: []
  },
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
