import React, { Component } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import GoogleMap, { Marker } from "react-maps-google";
import DocumentTitle from "react-document-title";
import * as actions from "@Root/store";
import { API } from "@Root/API";
import { errorMessage } from "@Root/helpers";
import { SectionTitle, InputLabel, TextInput, TextArea } from "@Root/components";
import { envelopIcon, facebookIcon, phoneIcon, twitterIcon } from "@Root/assets";

class Page extends Component {
  state = {
    error: null,
    name: "",
    phone: "",
    message: ""
  };

  handleClickSubmitButton = async () => {
    if (!this.formIsValid()) return;
    const { name, phone, message } = this.state;
    try {
      await API.sendContactUsForm({
        name,
        phone_number: phone,
        message
      });
      this.props.setSnackbar({ text: "This message was successfully sent" });
      this.setState({ name: null, phone: null, message: null });
    } catch (error) {
      this.props.setSnackbar({ text: errorMessage(error), isError: true });
    }
  };

  formIsValid = () => {
    const { name, phone, message } = this.state;
    if (!name) {
      this.showError("name", "Required");
      return false;
    }
    if (!phone) {
      this.showError("phone", "Required");
      return false;
    }
    if (!message) {
      this.showError("message", "Required");
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  render() {
    const { name, phone, message } = this.state;
    const { handleClickSubmitButton, errorMessage } = this;
    return (
      <DocumentTitle title="Contact us">
        <div className={classes.wrapper}>
          <div className={classes.contactUs}>
            <div className={classes.contactUsTitle}>
              <SectionTitle title={"Get in touch with us"} />
            </div>

            <div className={classes.contactUsContent}>
              <div className={classes.contactUsWrapper}>
                <div style={{ display: "flex", width: 290, justifyContent: "space-between", marginBottom: 20 }}>
                  <span>Were we are</span>
                  <span style={{ color: "#6C6C6C" }}>Stoke hill, Bristol BS9 1JP</span>
                </div>
                <div style={{ width: 500, height: 230 }}>
                  <GoogleMap apiKey="AIzaSyC7EbCoJC1eDuWnJeUcK8k6S02Y92mPsIU">
                    <Marker position={{ lat: 40.7174343, lng: -73.9930319 }} />
                    <Marker position={{ lat: 40.8174343, lng: -72.9930319 }} />
                    <Marker position={{ lat: 40.9174343, lng: -71.9930319 }} />
                  </GoogleMap>
                </div>
                <div className={classes.info}>
                  <div className={classes.contactInfo}>
                    <div>
                      <img src={envelopIcon} alt="" />
                      <a href="mailto:someone@example.com">info@trinitycollegebristol.ac.uk</a>
                    </div>
                    <div>
                      <img src={phoneIcon} alt="" />
                      <a href="tel:+44 (0) 117 968 2803"> +44 (0) 117 968 2803</a>
                    </div>
                  </div>
                  <div className={classes.smInfo}>
                    <div>We are in socials</div>
                    <div>
                      <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                        <img src={facebookIcon} alt="" />
                      </a>
                      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <img src={twitterIcon} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.contactUsWrapper}>
                <div className={classes.inputsWrapper}>
                  <div className={classes.inputWrapper}>
                    <InputLabel text="Name" hasAsterisk={true} />
                    <TextInput
                      style={{ width: 325 }}
                      value={name}
                      changeHandler={name => this.setState({ name })}
                      placeholder=""
                      error={errorMessage("name")}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputLabel text="Phone" hasAsterisk={true} />
                    <TextInput
                      style={{ width: 325 }}
                      value={phone}
                      changeHandler={phone => this.setState({ phone })}
                      placeholder=""
                      error={errorMessage("phone")}
                    />
                  </div>
                  <div style={{ display: "flex", width: 400, justifyContent: "space-between", height: 90 }}>
                    <InputLabel text="Message" hasAsterisk={true} />
                    <TextArea
                      style={{ width: 325, height: 90 }}
                      value={message}
                      changeHandler={message => this.setState({ message })}
                      error={errorMessage("message")}
                    />
                  </div>

                  <div style={{ position: "relative", left: 245 }}>
                    <button onClick={handleClickSubmitButton}>Submit</button>
                  </div>
                  <script src=""> </script>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const ContactUsPage = withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Page)
);
