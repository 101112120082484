import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { TextInput, Select } from "@Root/components";
import { equalities } from "@Root/configs";

export class RuleRow extends Component {
  state = {
    error: null
  };

  handleButtonClick = () => {
    const { isNew, clickAddRowHandler = () => {}, clickDeleteRowHandler = () => {} } = this.props;
    if (isNew) {
      if (!this.rowIsValid()) return;
      // addingRuleHandler(row);
      clickAddRowHandler();
      // removingNewRuleHandler();
    } else {
      // removingRuleHandler(index);
      clickDeleteRowHandler();
    }
  };

  rowIsValid = () => {
    const { field, equality, filter } = this.props.row;
    if (!field) {
      this.showError("field");
      return false;
    } else if (!equality) {
      this.showError("equality");
      return false;
    } else if (!filter && equality !== "is empty" && equality !== "is not empty") {
      this.showError("filter");
      return false;
    } else {
      return true;
    }
  };

  showError = input => {
    this.setState({ error: input }, () => {
      this.setState({ error: null });
    });
  };

  render() {
    const { error } = this.state;
    const { isNew, columns, row, changeRuleValuesHandler = () => {} } = this.props;
    const { handleButtonClick } = this;

    return (
      <div className={classes.RuleRow}>
        <Select
          inputClassNames={["borderless"]}
          options={columns}
          value={row.field}
          changeHandler={value => changeRuleValuesHandler({ field: value })}
          isDisabled={!isNew}
          inputStyle={{ width: "130px" }}
          error={error === "field" ? "Required" : null}
        />
        <Select
          inputClassNames={["borderless"]}
          options={Object.keys(equalities)}
          value={row.equality}
          changeHandler={value => changeRuleValuesHandler({ equality: value })}
          isDisabled={!isNew}
          inputStyle={{ width: "75px" }}
          optionsStyle={{ width: "90px" }}
          error={error === "equality" ? "Required" : null}
        />
        <TextInput
          classNames={["borderless"]}
          style={{ width: 130 }}
          value={row.filter}
          changeHandler={value => changeRuleValuesHandler({ filter: value })}
          isDisabled={!isNew}
          error={error === "filter" ? "Required" : null}
        />
        <button onClick={handleButtonClick}>{isNew ? "Add" : "Delete"}</button>
      </div>
    );
  }
}

RuleRow.propTypes = {
  isNew: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  row: PropTypes.shape({
    field: PropTypes.string,
    equality: PropTypes.string,
    filter: PropTypes.string
  }).isRequired,

  clickAddRowHandler: PropTypes.func,
  clickDeleteRowHandler: PropTypes.func,

  // addingRuleHandler: PropTypes.func,
  changeRuleValuesHandler: PropTypes.func
  // removingRuleHandler: PropTypes.func,
  // removingNewRuleHandler: PropTypes.func
};
