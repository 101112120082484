import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, Select, InputLabel } from "@Root/components";
export const FundingAndMonitoringForm = ({ permissions, isNew, options, initialData, cancelHandler, deleteHandler, saveHandler }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [isSpinning, setIsSpinning] = useState(false);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    if (isNew) {
      cancelHandler();
    } else {
      setData(initialData);
      setIsEditable(false);
    }
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      data.funding_and_monitoring_id,
      () => {
        setIsSpinning(true);
      },
      () => {
        FundingAndMonitoringForm.isMounted && setIsSpinning(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    setIsSpinning(true);
    await saveHandler(data, () => {
      FundingAndMonitoringForm.isMounted && setIsEditable(false);
    });
    FundingAndMonitoringForm.isMounted && setIsSpinning(false);
  };

  useEffect(() => {
    FundingAndMonitoringForm.isMounted = true;
    return () => {
      FundingAndMonitoringForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    FundingAndMonitoringForm.isMounted && setData(initialData);
  }, [initialData]);

  return (
    <FormWrapper
      buttons={isNew ? ["cancel", "save"] : isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this funding and monitoring" }]}
      buttonsAreDisabled={isSpinning}
      isSpinning={isSpinning}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle
          title={isNew ? "Create Funding and Monitoring Details" : isEditable ? "Edit Funding and Monitoring Details" : "Funding and Monitoring Details"}
        />
        <div className={classes.wrapper}>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Allocated Place" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            options={options.allocatedPlace}
            value={data.allocated_place_id}
            changeHandler={value => handleChangeInput("allocated_place_id", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 450 }} text="Equivalent or Lower Qualification" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            options={options.equivalentQualification}
            value={data.equivalent_qualification_id}
            changeHandler={value => handleChangeInput("equivalent_qualification_id", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Funding Completion" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            options={options.fundingCompletion}
            value={data.funding_completion_id}
            changeHandler={value => handleChangeInput("funding_completion_id", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 450 }} text="Regulating Body" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 640, width: 260 }}
            options={options.regulatingBody}
            value={data.regulating_body_id}
            changeHandler={value => handleChangeInput("regulating_body_id", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Sandwich Fee" />
          <Select
            inputClassNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 150, width: 260 }}
            options={options.sandwichFee}
            value={data.sandwich_fee_id}
            changeHandler={value => handleChangeInput("sandwich_fee_id", value)}
            isDisabled={!isEditable}
          />
        </div>
      </div>
    </FormWrapper>
  );
};

const arrayOfValueLabelShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  })
);

FundingAndMonitoringForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  isNew: PropTypes.bool,
  options: PropTypes.shape({
    allocatedPlace: arrayOfValueLabelShape,
    equivalentQualification: arrayOfValueLabelShape,
    fundingCompletion: arrayOfValueLabelShape,
    regulatingBody: arrayOfValueLabelShape,
    sandwichFee: arrayOfValueLabelShape
  }),
  initialData: PropTypes.shape({
    allocated_place_id: PropTypes.number,
    equivalent_qualification_id: PropTypes.number,
    funding_completion_id: PropTypes.number,
    regulating_body_id: PropTypes.number,
    sandwich_fee_id: PropTypes.number
  }),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

FundingAndMonitoringForm.defaultProps = {
  permissions: [],
  isNew: false,
  options: {
    allocatedPlace: [],
    equivalentQualification: [],
    fundingCompletion: [],
    regulatingBody: [],
    sandwichFee: []
  },
  initialData: {
    allocated_place_id: null,
    equivalent_qualification_id: null,
    funding_completion_id: null,
    regulating_body_id: null,
    sandwich_fee_id: null
  },
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};
