export const collegesConfig = () => {
  const hostname = window.location.hostname;
  const collegeAbbreviation = hostname.split(".")[0];
  const colleges = {
    trinitycollegebristol: "Trinity College Briston",
    stmellitus: "St Mellitus College Trust",
    ctf: "Cambridge Theological Federation",
    teid: "Test college"
  };
  return colleges[collegeAbbreviation];
};
