import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";
import { booleanOptions, collegesConfig } from "@Root/configs";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select, MultiSelect, DatePicker, TextArea } from "@Root/components";

export const ContactsStudentForm = ({
  permissions,
  initialData,
  studentTypeOptions,
  ethnicityOptions,
  disabilityOptions,
  nationalityOptions,
  maritalStatusOptions,
  deleteHandler,
  saveHandler,
  errorHandler
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState(initialData);
  const [isSaving, setIsSavind] = useState(false);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        ContactsStudentForm.isMounted && setIsSavind(false);
      }
    );
  };

  useEffect(() => {
    setData(initialData);
  }, [initialData]);
  const handleClickSaveButton = async () => {
    setIsSavind(true);
    const modifiedData = switchEmptyStringsToNullsInObject(data);
    try {
      await saveHandler(modifiedData);
      ContactsStudentForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    ContactsStudentForm.isMounted && setIsSavind(false);
  };

  useEffect(() => {
    ContactsStudentForm.isMounted = true;
    return () => {
      ContactsStudentForm.isMounted = false;
    };
  }, []);

  return (
    <FormWrapper
      buttons={isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this student" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Student Details" : "Student Details"} />
        <div className={classes.wrapper}>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Student ID" />
          <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={String(data.student_id)} isDisabled />
          {data.contact_id && (
            <>
              <InputLabel style={{ position: "absolute", left: 610 }} text="ID (from import) " />
              <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 765, width: 260 }} value={data.contact_id} isDisabled />
            </>
          )}
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Community Member ?" />
          <Select
            style={{ position: "absolute", left: 275 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.is_community_member}
            changeHandler={value => handleChangeInput("is_community_member", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Student Types" />
          <div style={{ position: "absolute", left: 275 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              options={studentTypeOptions}
              values={data.student_type_ids}
              changeHandler={values => handleChangeInput("student_type_ids", values)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="UOA/UOD Code/BannerID" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 260 }}
            value={data.banner_id}
            changeHandler={value => handleChangeInput("banner_id", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="SLC Number" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 260 }}
            value={data.slc_number}
            changeHandler={value => handleChangeInput("slc_number", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Eligible for Support?" />
          <div className={classes.text} style={{ position: "absolute", left: 275 }}>
            <span style={{ color: "#4A4A4A" }}>Note:</span> Recorded in Study Records - see Programmes tab
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="HEAPES?" />
          <div className={classes.text} style={{ position: "absolute", left: 275 }}>
            <span style={{ color: "#4A4A4A" }}>Note:</span> Recorded in Study Records - see Programmes tab
          </div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text={`Studied at ${collegesConfig()}`} />
          <Select
            style={{ position: "absolute", left: 275 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.studied_at}
            changeHandler={value => handleChangeInput("studied_at", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text={`Federation House`} />
          <TextInput
            classNames={["borderless"]}
            style={{ position: "absolute", left: 275, width: 260 }}
            value={data.institutions}
            changeHandler={value => handleChangeInput("institutions", value)}
            isDisabled={true}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>From Interview</div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Ethnicity HESA Codes" />
          <Select
            style={{ position: "absolute", left: 275 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={ethnicityOptions}
            value={data.ethnicity_type_id}
            changeHandler={value => handleChangeInput("ethnicity_type_id", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Disability HESA Codes" />
          <Select
            style={{ position: "absolute", left: 275 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={disabilityOptions}
            value={data.disability_type_id}
            changeHandler={value => handleChangeInput("disability_type_id", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>From Application</div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="International" />
          <Select
            style={{ position: "absolute", left: 275 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.is_international}
            changeHandler={value => handleChangeInput("is_international", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Nationality" />
          <Select
            style={{ position: "absolute", left: 275 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={nationalityOptions}
            value={data.nationality_type_id}
            changeHandler={value => handleChangeInput("nationality_type_id", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Disability" />
          <Select
            style={{ position: "absolute", left: 275 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.has_disability}
            changeHandler={value => handleChangeInput("has_disability", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text="Disability Detail" />
          <TextArea
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 750, height: 90 }}
            value={data.disability_details}
            changeHandler={value => handleChangeInput("disability_details", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Learning Support" />
          <Select
            style={{ position: "absolute", left: 275 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={booleanOptions}
            value={data.has_learning_support}
            changeHandler={value => handleChangeInput("has_learning_support", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text="Learning Support Details" />
          <TextArea
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 750, height: 90 }}
            value={data.learning_support_details}
            changeHandler={value => handleChangeInput("learning_support_details", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text="Academic Qualifications" />
          <TextArea
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 750, height: 90 }}
            value={data.academic_qualifications}
            changeHandler={value => handleChangeInput("academic_qualifications", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text="Other Qualifications" />
          <TextArea
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 750, height: 90 }}
            value={data.other_qualifications}
            changeHandler={value => handleChangeInput("other_qualifications", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text="Work Experience" />
          <TextArea
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 750, height: 90 }}
            value={data.work_experience}
            changeHandler={value => handleChangeInput("work_experience", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Marital Status" />
          <Select
            style={{ position: "absolute", left: 275 }}
            inputClassNames={!isEditable ? ["borderless"] : []}
            inputStyle={{ width: 260 }}
            options={maritalStatusOptions}
            value={data.marital_status_type_id}
            changeHandler={value => handleChangeInput("marital_status_type_id", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Spouse Name" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 260 }}
            value={data.spouse_name}
            changeHandler={value => handleChangeInput("spouse_name", value)}
            isDisabled={!isEditable}
          />
          <InputLabel style={{ position: "absolute", left: 610 }} text="Spouse Surname" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 765, width: 260 }}
            value={data.spouse_surname}
            changeHandler={value => handleChangeInput("spouse_surname", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text="Notes about children" />
          <TextArea
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 750, height: 90 }}
            value={data.children_notes}
            changeHandler={value => handleChangeInput("children_notes", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.textAreaRowWrapper}>
          <InputLabel text="Brief Notes (use Notes Tab For Detailed)" />
          <TextArea
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 750, height: 90 }}
            value={data.brief_notes}
            changeHandler={value => handleChangeInput("brief_notes", value)}
            isDisabled={!isEditable}
          />
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Passport Expiry" />
          <div style={{ position: "absolute", left: 275 }}>
            <DatePicker
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 260 }}
              value={data.passport_expiry}
              changeHandler={value => handleChangeInput("passport_expiry", value)}
              isDisabled={!isEditable}
            />
          </div>
          <InputLabel style={{ position: "absolute", left: 610 }} text="Visa Expiry" />
          <div style={{ position: "absolute", left: 765 }}>
            <DatePicker
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 260 }}
              value={data.visa_expiry}
              changeHandler={value => handleChangeInput("visa_expiry", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.rowWrapper} style={{ marginBottom: "30px", marginTop: "30px" }}>
          <InputLabel style={{ position: "absolute", left: 610 }} text="Visa Notes" />
          <div style={{ position: "absolute", left: 765 }}>
            <TextArea
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 260, height: 90 }}
              maxLength={255}
              value={data.visa_notes}
              changeHandler={value => handleChangeInput("visa_notes", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
        <div className={classes.line} />
        <div className={classes.rowWrapper}>
          <div className={classes.subtitle}>HESA Detail</div>
        </div>
        <div className={classes.rowWrapper}>
          <InputLabel text="Confirmed HUSID" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 275, width: 260 }}
            value={data.confirmed_hus_id !== null ? String(data.confirmed_hus_id) : ""}
            changeHandler={value => handleChangeInput("confirmed_hus_id", value !== "" ? Number(value) : null)}
            isDisabled={!isEditable}
            restriction="digits"
          />
          <InputLabel style={{ position: "absolute", left: 610 }} text="Calculated HUSID" />
          <TextInput
            classNames={!isEditable ? ["borderless"] : []}
            style={{ position: "absolute", left: 765, width: 260 }}
            value={data.calculated_hus_id !== null ? String(data.calculated_hus_id) : ""}
            changeHandler={value => handleChangeInput("calculated_hus_id", value !== "" ? Number(value) : null)}
            isDisabled={!isEditable}
            restriction="digits"
          />
        </div>
        {data.domicile && (
          <div className={classes.rowWrapper}>
            <InputLabel text="DOMICILE" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.domicile} isDisabled />
          </div>
        )}
        {data.previnst && (
          <div className={classes.rowWrapper}>
            <InputLabel text="PREVINST" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.previnst} isDisabled />
          </div>
        )}
        {data.disall && (
          <div className={classes.rowWrapper}>
            <InputLabel text="DISALL" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.disall} isDisabled />
          </div>
        )}
        {data.qualent3 && (
          <div className={classes.rowWrapper}>
            <InputLabel text="QUALENT3" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.qualent3} isDisabled />
          </div>
        )}
        {data.qualent3 && (
          <div className={classes.rowWrapper}>
            <div className={classes.text}>
              <span style={{ color: "#4A4A4A" }}>Note:</span> If QUALENT3 is at level 3 then QUALSBJ and QUALTYPE are required for each level 3 qualification
            </div>
          </div>
        )}
        {(data.qualsbj_1 || data.qualtype_1) && (
          <div className={classes.rowWrapper}>
            {data.qualsbj_1 && (
              <>
                <InputLabel text="QUALSBJ(1)" />
                <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.qualsbj_1} isDisabled />
              </>
            )}
            {data.qualtype_1 && (
              <>
                <InputLabel style={{ position: "absolute", left: 610 }} text="QUALTYPE(1)" />
                <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 765, width: 260 }} value={data.qualtype_1} isDisabled />
              </>
            )}
          </div>
        )}
        {(data.qualsbj_2 || data.qualtype_2) && (
          <div className={classes.rowWrapper}>
            {data.qualsbj_2 && (
              <>
                <InputLabel text="QUALSBJ(2)" />
                <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.qualsbj_2} isDisabled />
              </>
            )}
            {data.qualtype_2 && (
              <>
                <InputLabel style={{ position: "absolute", left: 610 }} text="QUALTYPE(2)" />
                <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 765, width: 260 }} value={data.qualtype_2} isDisabled />
              </>
            )}
          </div>
        )}
        {(data.qualsbj_3 || data.qualtype_3) && (
          <div className={classes.rowWrapper}>
            {data.qualsbj_3 && (
              <>
                <InputLabel text="QUALSBJ(3)" />
                <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.qualsbj_3} isDisabled />
              </>
            )}
            {data.qualtype_3 && (
              <>
                <InputLabel style={{ position: "absolute", left: 610 }} text="QUALTYPE(3)" />
                <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 765, width: 260 }} value={data.qualtype_3} isDisabled />
              </>
            )}
          </div>
        )}
        {data.was_sselig && (
          <div className={classes.rowWrapper}>
            <InputLabel text="WASSSELIG" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.was_sselig} isDisabled />
          </div>
        )}
        {data.was_heapes && (
          <div className={classes.rowWrapper}>
            <InputLabel text="WASHEAPES" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.was_heapes} isDisabled />
          </div>
        )}
        {data.dsa && (
          <div className={classes.rowWrapper}>
            <InputLabel text="DSA" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.dsa} isDisabled />
          </div>
        )}
        {data.elig4supp && (
          <div className={classes.rowWrapper}>
            <InputLabel text="ELIG4SUPP" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.elig4supp} isDisabled />
          </div>
        )}
        {data.consent && (
          <div className={classes.rowWrapper}>
            <InputLabel text="CONSENT" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.consent} isDisabled />
          </div>
        )}
        {data.trashed && (
          <div className={classes.rowWrapper}>
            <InputLabel text="TRASHED" />
            <TextInput classNames={["borderless"]} style={{ position: "absolute", left: 275, width: 260 }} value={data.trashed} isDisabled />
          </div>
        )}
      </div>
    </FormWrapper>
  );
};

const arrayOfValueLabelShape = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string
  })
);

ContactsStudentForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    student_id: PropTypes.string,
    is_community_member: PropTypes.bool,
    student_type_ids: PropTypes.arrayOf(PropTypes.number),
    institution_ids: PropTypes.arrayOf(PropTypes.number),
    banner_id: PropTypes.string,
    slc_number: PropTypes.string,
    ethnicity_type_id: PropTypes.number,
    disability_type_id: PropTypes.number,
    is_international: PropTypes.bool,
    nationality_type_id: PropTypes.number,
    has_disability: PropTypes.bool,
    disability_details: PropTypes.string,
    has_learning_support: PropTypes.bool,
    learning_support_details: PropTypes.string,
    academic_qualifications: PropTypes.string,
    other_qualifications: PropTypes.string,
    work_experience: PropTypes.string,
    marital_status_type_id: PropTypes.number,
    spouse_name: PropTypes.string,
    spouse_surname: PropTypes.string,
    children_notes: PropTypes.string,
    brief_notes: PropTypes.string,
    passport_expiry: PropTypes.string,
    visa_expiry: PropTypes.string,
    visa_notes: PropTypes.string,
    confirmed_hus_id: PropTypes.number,
    calculated_hus_id: PropTypes.number,
    studied_at: PropTypes.string,
    institutions: PropTypes.string,
    qual_ent_3_id: PropTypes.number,
    qual_sbj_1_id: PropTypes.number,
    qual_type_1_id: PropTypes.number,
    qual_sbj_2_id: PropTypes.number,
    qual_type_2_id: PropTypes.number,
    qual_sbj_3_id: PropTypes.number,
    qual_type_3_id: PropTypes.number
  }),
  studentTypeOptions: arrayOfValueLabelShape,
  ethnicityOptions: arrayOfValueLabelShape,
  disabilityOptions: arrayOfValueLabelShape,
  nationalityOptions: arrayOfValueLabelShape,
  maritalStatusOptions: arrayOfValueLabelShape,
  studiedAtOptions: arrayOfValueLabelShape,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  errorHandler: PropTypes.func
};

ContactsStudentForm.defaultProps = {
  permissions: [],
  initialData: {
    student_id: null,
    is_community_member: null,
    student_type_ids: null,
    banner_id: null,
    slc_number: null,
    ethnicity_type_id: null,
    disability_type_id: null,
    is_international: null,
    nationality_type_id: null,
    has_disability: null,
    disability_details: null,
    has_learning_support: null,
    learning_support_details: null,
    academic_qualifications: null,
    other_qualifications: null,
    work_experience: null,
    marital_status_type_id: null,
    spouse_name: null,
    spouse_surname: null,
    children_notes: null,
    brief_notes: null,
    passport_expiry: null,
    visa_expiry: null,
    visa_notes: null,
    confirmed_hus_id: null,
    calculated_hus_id: null,
    studied_at: null,
    qual_ent_3_id: null,
    qual_sbj_1_id: null,
    qual_type_1_id: null,
    qual_sbj_2_id: null,
    qual_type_2_id: null,
    qual_sbj_3_id: null,
    qual_type_3_id: null
  },
  studentTypeOptions: [],
  ethnicityOptions: [],
  disabilityOptions: [],
  nationalityOptions: [],
  maritalStatusOptions: [],
  studiedAtOptions: [],
  deleteHandler: () => {},
  saveHandler: () => {},
  errorHandler: () => {}
};
