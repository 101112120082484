import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withPromise } from "@Root/helpers";
import { API } from "@Root/API";
import * as actions from "@Root/store";
import { Spinner, ActionsDropdown, Table, StudiesModuleForm } from "@Root/components";
import { SectionWrapper } from "@Root/HOCs";

const Component = ({
  permissions,
  history,
  location,
  getStudiesModuleOptions,
  studiesModuleOptions,
  getStudiesModuleDeliveryOptions,
  studiesModuleDeliveryOptions,
  showModal,
  hideModal
}) => {
  const [isShown, setIsShown] = useState(false);
  const [module, setModule] = useState(null);

  const moduleId = location.pathname.split("/").pop();

  const actions = () => [
    ...(permissions.includes("create")
      ? [
          {
            name: "Create a module delivery",
            handler: () => history.push(`/home/studies/modules/${moduleId}/module-delivery/new`)
          },
          ...(module.module_subject.length < 3 && module.module_subject.reduce((acc, module_subject) => acc + +module_subject.module_proportion, 0) < 100
            ? [
                {
                  name: "Create a module subject",
                  handler: () => history.push(`/home/studies/modules/${moduleId}/module-subject/new`)
                }
              ]
            : [])
        ]
      : [])
  ];

  const fetchData = async () => {
    try {
      const data = await Promise.all([
        API.getStudiesModule(moduleId),
        !studiesModuleOptions ? getStudiesModuleOptions() : null,
        getStudiesModuleDeliveryOptions()
      ]);
      setModule(data[0].data.data);
      StudiesModule.isMounted && setIsShown(true);
    } catch (error) {
      console.log(error);
      history.replace("/page-not-found");
    }
  };

  const deleteModule = (onStartCallback = () => {}, onFinishCallback = () => {}) => {
    new Promise((resolve, reject) => {
      showModal("ConfirmationModal", {
        text: "This module will be removed?",
        clickRejectButtonHandler: reject,
        clickResolveButtonHandler: resolve
      });
    }).then(
      async () => {
        hideModal();
        onStartCallback();
        try {
          await API.deleteStudiesModule(moduleId);
          history.push("/home/studies/modules");
        } catch (error) {
          onFinishCallback();
          alert("Unable to delete the module");
        }
      },
      () => {
        hideModal();
      }
    );
  };

  const moduleSubjectTableData = () => {
    return {
      name: "studiesModulesSubject",
      columns: [
        {
          name: "Module Subject",
          field: "subject",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Educational Module",
          field: "educational_module",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Module proportion",
          field: "module_proportion",
          searchInputType: "textInput",
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getModulesSubject(moduleId, params),
      fetchExportedDataHander: () => {},
      clickLinkHandlers: {
        "Module Subject": row => history.push(`/home/studies/modules/${moduleId}/module-subject/${row.id}`)
      }
    };
  };

  const moduleDeliveryTableData = () => {
    const { language_identifiers } = studiesModuleDeliveryOptions;

    return {
      name: "studiesModulesDelivery",
      columns: [
        {
          name: "Module delivery identifier",
          field: "module_delivery_identifier",
          searchInputType: "textInput",
          cellType: "link"
        },
        {
          name: "Module start date",
          field: "module_start_date",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Module end date",
          field: "module_end_date",
          searchInputType: "textInput",
          cellType: "text"
        },
        {
          name: "Language Identifier",
          field: "language_identifier",
          searchInputType: "select",
          options: language_identifiers.map(x => x.label),
          cellType: "text"
        }
      ],
      fetchDataHandler: params => API.getModulesDelivery(moduleId, params),
      fetchExportedDataHander: () => {},
      fetchSaveColumnOptions: options => API.saveColumnOptions("modules", options),
      fetchColumnOptions: () => API.getColumnOptions("modules"),
      clickLinkHandlers: {
        "Module delivery identifier": row => history.push(`/home/studies/modules/${moduleId}/module-delivery/${row.module_delivery_id}`)
      }
    };
  };

  useEffect(() => {
    StudiesModule.isMounted = true;
    fetchData();
    return () => {
      StudiesModule.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {isShown && !!actions().length && (
        <ActionsDropdown
          actions={actions().map(action => action.name)}
          clickHandler={actionName =>
            actions()
              .find(action => action.name === actionName)
              .handler()
          }
          style={{ position: "absolute", right: 10, top: -80 }}
        />
      )}
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <>
            <SectionWrapper hasBackButton backButtonText="Back to Modules" clickBackButtonHandler={() => history.push("/home/studies/modules")}>
              <StudiesModuleForm
                permissions={permissions}
                title={{ onEdit: "Edit the module" }}
                options={studiesModuleOptions}
                initialData={module}
                buttons={["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"]}
                deleteHandler={(onStartCallback, onFinishCallback) => deleteModule(onStartCallback, onFinishCallback)}
                saveHandler={payload => API.putStudiesModule({ module_id: moduleId, ...payload })}
              />
            </SectionWrapper>
            <div className={classes.tableWrapper}>
              <div className={classes.tableTitle}>Module Deliveries</div>
              <Table
                style={{ marginTop: 70 }}
                datePeriodDropdownStyle={{ top: -70 }}
                name={moduleDeliveryTableData().name}
                columns={moduleDeliveryTableData().columns}
                fetchColumnOptions={moduleDeliveryTableData().fetchColumnOptions}
                fetchSaveColumnOptions={moduleDeliveryTableData().fetchSaveColumnOptions}
                fetchDataHandler={moduleDeliveryTableData().fetchDataHandler}
                fetchExportedDataHander={moduleDeliveryTableData().fetchExportedDataHander}
                clickLinkHandlers={moduleDeliveryTableData().clickLinkHandlers}
              />
            </div>
            <div className={classes.tableWrapper}>
              <div className={classes.tableTitle}>Module Subjects</div>
              <Table
                style={{ marginTop: 70 }}
                datePeriodDropdownStyle={{ top: -70 }}
                name={moduleSubjectTableData().name}
                columns={moduleSubjectTableData().columns}
                fetchDataHandler={moduleSubjectTableData().fetchDataHandler}
                fetchExportedDataHander={moduleSubjectTableData().fetchExportedDataHander}
                clickLinkHandlers={moduleSubjectTableData().clickLinkHandlers}
              />
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ authReducer, inputDataListsReducer }) => ({
  permissions: authReducer.user.permissions,
  studiesModuleOptions: inputDataListsReducer.studiesModuleOptions,
  studiesModuleDeliveryOptions: inputDataListsReducer.studiesModuleDeliveryOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesModuleOptions: () => withPromise(dispatch, actions.getStudiesModuleOptions),
    getStudiesModuleDeliveryOptions: () => withPromise(dispatch, actions.getStudiesModuleDeliveryOptions),
    showModal: (component, props) => dispatch(actions.showModal(component, props)),
    hideModal: () => dispatch(actions.hideModal())
  };
};

export const StudiesModule = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
