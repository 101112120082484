import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import moment from "moment";
import { all } from "@Helpers/all";
import { FormWrapper } from "@Root/HOCs";
import { RoundPlusButton, SectionTitle, TextArea, FileInput } from "@Root/components";

export const ContactsAttachmentsForm = ({ permissions, attachments, saveAttachmentHandler, deleteAttachmentHandler, editAttachmentHandler }) => {
  const [inputsAreShown, setInputsAreShown] = useState(false);
  const [newAttachmentValue, setNewAttachmentValue] = useState(null);
  const [newAttachmentFile, setNewAttachmentFile] = useState(null);
  const [editAttachmentId, setEditAttachmentId] = useState(null);
  const [editAttachmentFile, setEditAttachmentFile] = useState(null);
  const [editAttachmentValue, setEditAttachmentValue] = useState(null);
  const [editAttachmentDescription, setEditAttachmentDescription] = useState(null);
  const [newAttachmentDescription, setNewAttachmentDescription] = useState("");
  const [error, setError] = useState(null);
  const [isSaving, setIsSavind] = useState(false);

  const nullifyNewAttachment = () => {
    setInputsAreShown(false);
    setNewAttachmentValue(null);
    setNewAttachmentFile(null);
    setNewAttachmentDescription("");
  };

  const handleClickCancelButton = () => {
    nullifyNewAttachment();
  };

  const handleClickSaveButton = async () => {
    if (!newAttachmentFile) {
      setError("File is not chosen");
      return;
    }
    setIsSavind(true);
    await saveAttachmentHandler(
      {
        file: newAttachmentFile,
        description: newAttachmentDescription
      },
      () => {
        ContactsAttachmentsForm.isMounted && nullifyNewAttachment();
      }
    );
    ContactsAttachmentsForm.isMounted && setIsSavind(false);
  };

  const handleClickEditButton = async () => {
    setIsSavind(true);
    await editAttachmentHandler({ id: editAttachmentId, file: editAttachmentFile || "", description: editAttachmentDescription }, () => {
      ContactsAttachmentsForm.isMounted && onCancelEdit();
    });
    ContactsAttachmentsForm.isMounted && setIsSavind(false);
  };

  const handleClickDeleteButton = attachmentId => {
    deleteAttachmentHandler(attachmentId, () => setIsSavind(true), () => ContactsAttachmentsForm.isMounted && setIsSavind(false));
  };

  useEffect(() => {
    ContactsAttachmentsForm.isMounted = true;
    return () => {
      ContactsAttachmentsForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  const onEditAttachment = (id, file_url, description) => {
    setEditAttachmentId(id);
    setEditAttachmentValue(file_url);
    setEditAttachmentDescription(description);
  };

  const onCancelEdit = () => {
    setEditAttachmentId(null);
    setEditAttachmentValue(null);
    setEditAttachmentFile(null);
    setEditAttachmentDescription(null);
  };
  const renderAttachment = (author_name, created_at, file_url, file_name, description, id, index, is_owner, avatar) => {
    return (
      <div key={index} className={classes.attachment}>
        <div className={classes.main}>
          <div className={classes.left}>
            <div className={classes.photo} style={{ backgroundImage: `url(${avatar})` }} />
          </div>
          <div className={classes.right}>
            <div className={classes.author}>
              <div>
                {author_name}, {moment.utc(created_at).fromNow()}
              </div>
              {(permissions.includes("editAttachment") || is_owner) && (
                <div className={classes.edit_button} onClick={() => onEditAttachment(id, file_url, description)}>
                  Edit
                </div>
              )}
            </div>
            <a className={classes.link} href={file_url} target="_blank" rel="noopener noreferrer">
              {file_name}
            </a>
            <div className={classes.description}>{description}</div>
          </div>
        </div>
        {(permissions.includes("deleteAttachment") || is_owner) && (
          <div className={classes.buttons}>
            <button className={classes.delete} onClick={() => handleClickDeleteButton(id)}>
              Delete
            </button>
          </div>
        )}
      </div>
    );
  };

  console.log(attachments);
  const renderEditAttachment = (cancel, save, description, setDescription, setValue, setFile, file, value, buttonSaveName) => {
    return (
      <div className={classes.newAttachment}>
        <div className={classes.inputs}>
          <FileInput
            style={{ width: 84, height: 84 }}
            circleStyle={{ width: 30, height: 30 }}
            value={value}
            file={file}
            changeHandler={(value, file) => all(() => setValue(value), () => setFile(file))}
            removeHandler={() => all(() => setValue(null), () => setFile(null))}
            hasText={false}
            error={error}
          />
          <TextArea style={{ width: 796, height: 84 }} value={description} changeHandler={value => setDescription(value)} />
        </div>
        <div className={classes.buttons}>
          <button className={classes.buttonSecondary} onClick={cancel} disabled={isSaving}>
            Cancel
          </button>
          <button className={classes.buttonMain} onClick={save} disabled={isSaving}>
            {buttonSaveName}
          </button>
        </div>
      </div>
    );
  };
  return (
    <FormWrapper isSpinning={isSaving}>
      <div className={classes.wrapper}>
        <div className={classes.title}>
          <SectionTitle title="Attachments (Maximum file size is 20 MB)" />
          {permissions.includes("createAttachment") && !inputsAreShown && <RoundPlusButton clickHandler={() => setInputsAreShown(true)} />}
        </div>
        {inputsAreShown &&
          renderEditAttachment(
            handleClickCancelButton,
            handleClickSaveButton,
            newAttachmentDescription,
            setNewAttachmentDescription,
            setNewAttachmentValue,
            setNewAttachmentFile,
            newAttachmentFile,
            newAttachmentValue,
            "Add"
          )}
        <div className={classes.attachments}>
          {attachments.map(({ author_name, created_at, file_url, file_name, description, id, is_owner, user_avatar }, i) =>
            editAttachmentId !== id
              ? renderAttachment(author_name, created_at, file_url, file_name, description, id, i, is_owner, user_avatar)
              : renderEditAttachment(
                  onCancelEdit,
                  handleClickEditButton,
                  editAttachmentDescription,
                  setEditAttachmentDescription,
                  setEditAttachmentValue,
                  setEditAttachmentFile,
                  editAttachmentFile,
                  editAttachmentValue,
                  "Save"
                )
          )}
        </div>
        {!attachments.length && !inputsAreShown && <div className={classes.message}>There are currently no attachments for this contact</div>}
      </div>
    </FormWrapper>
  );
};

ContactsAttachmentsForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      file_name: PropTypes.string,
      file_url: PropTypes.string,
      author_name: PropTypes.string,
      created_at: PropTypes.shape({
        date: PropTypes.string
      })
    })
  ),
  saveAttachmentHandler: PropTypes.func,
  deleteAttachmentHandler: PropTypes.func
};

ContactsAttachmentsForm.defaultProps = {
  permissions: [],
  attachments: [],
  saveAttachmentHandler: () => {},
  deleteAttachmentHandler: () => {}
};
