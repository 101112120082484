export const permissions = {
  viewer: [],
  manager: ["create", "edit", "createNote"],
  admin: [
    "create",
    "import",
    "createUser",
    "editApplicationFormInstructions",
    "createPerson",
    "createNote",
    "edit",
    "delete",
    "createAttachment",
    "editUser",
    "importCSV",
    "createRole"
  ],
  "super-admin": [
    "create",
    "edit",
    "delete",
    "createPerson",
    "editApplicationFormInstructions",
    "editNote",
    "deleteNote",
    "createNote",
    "createAttachment",
    "editAttachment",
    "deleteAttachment",
    "editUser",
    "importCSV",
    "createUser",
    "createRole"
  ]
};

const userRoles = [
  {
    name: "Students",
    tabs: [
      {
        name: "Contact information1",
        fields: [{ name: "Programs", disabled: true }, { name: "Programs1", disabled: true }, { name: "Tel Primary", disabled: false }]
      },
      {
        name: "Contact information2",
        fields: [{ name: "Tel Primary", disabled: false }, { name: "Tel secondary", disabled: false }, { name: "Tel mobile", disabled: false }]
      },
      {
        name: "Student details",
        fields: [{ name: "Tel Primary 2", disabled: false }, { name: "Tel secondary 3", disabled: false }, { name: "Tel mobile 4", disabled: false }]
      }
    ],
    access: "Full"
  },
  {
    name: "Academics",
    tabs: [
      { name: "Contact information1", fields: [{ name: "Programs", disabled: false }, { name: "Programs1", disabled: false }] },
      {
        name: "Contact information2",
        fields: [{ name: "Tel Primary", disabled: false }, { name: "Tel secondary", disabled: false }, { name: "Tel mobile", disabled: false }]
      },
      {
        name: "Student details",
        fields: [{ name: "Tel Primary 2", disabled: false }, { name: "Tel secondary 3", disabled: false }, { name: "Tel mobile 4", disabled: false }]
      }
    ],
    access: "Limited"
  }
];

const tabs = [
  {
    name: "Contacts",
    userRoles,
    selected: true,
    subTabs: [
      {
        name: "All contacts",
        selected: true,
        fields: [
          { name: "photo" },
          { name: "name" },
          { name: "title" },
          { name: "given name" },
          { name: "mobile" },
          { name: "gender" },
          { name: "relationship" },
          { name: "trashed" }
        ]
      },
      {
        name: "All contacts 2",
        additional: {
          csv_import: false,
          csv_export: true,
          xml_export: true
        },
        fields: [
          { name: "photo" },
          { name: "name" },
          { name: "title" },
          { name: "given name" },
          { name: "mobile" },
          { name: "gender" },
          { name: "relationship" },
          { name: "trashed" }
        ]
      },
      {
        name: "All contacts 3",
        fields: [
          { name: "photo" },
          { name: "name" },
          { name: "title" },
          { name: "given name" },
          { name: "mobile" },
          { name: "gender" },
          { name: "relationship" },
          { name: "trashed" }
        ]
      },
      {
        name: "All contacts 4",
        additional: {},
        fields: [
          { name: "photo" },
          { name: "name" },
          { name: "title" },
          { name: "given name" },
          { name: "mobile" },
          { name: "gender" },
          { name: "relationship" },
          { name: "trashed" }
        ]
      }
    ]
  },
  {
    name: "Studies",
    userRoles,
    subTabs: [
      {
        name: "Records of studies",
        additional: {},
        selected: true,
        fields: [{ name: "name" }, "student id", { name: "programme" }, "academic year", { name: "status" }, { name: "study year" }, { name: "context" }]
      }
    ]
  },
  {
    name: "Admissions",
    userRoles,
    subTabs: [
      {
        name: "Admissions details",
        selected: true,
        fields: [
          { name: "student id" },
          { name: "name" },
          { name: "surname" },
          { name: "study id" },
          { name: "email" },
          { name: "programme" },
          { name: "status" },
          { name: "academic year" }
        ]
      }
    ]
  },
  {
    name: "Finance",
    userRoles,
    subTabs: [
      {
        name: "Tuition Fees",
        selected: true,
        fields: [
          { name: "id" },
          { name: "name" },
          { name: "surname" },
          { name: "given name" },
          { name: "email" },
          { name: "programme name" },
          { name: "status" },
          { name: "academic year" }
        ]
      }
    ]
  },
  {
    name: "GDPR",
    userRoles,
    subTabs: [
      {
        name: "GDPR log",
        selected: true,
        fields: [
          { name: "when" },
          { name: "who" },
          { name: "action" },
          { name: "given name" },
          { name: "model" },
          { name: "contact id" },
          { name: "full name" },
          { name: "relation ship" }
        ]
      }
    ]
  },
  {
    name: "Admin",
    userRoles,
    subTabs: [
      {
        name: "System users",
        selected: true,
        fields: [
          { name: "username" },
          { name: "contact record" },
          { name: "email" },
          { name: "creation date" },
          { name: "last visit" },
          { name: "status" },
          { name: "user roles" },
          { name: "trashed" }
        ]
      }
    ]
  }
];

export const permissionsConfig = {
  actions: [
    {
      title: "Can view own data",
      tabs
    },
    {
      title: "Can view other user data",
      tabs
    },
    {
      title: "Can edit own data",
      tabs
    },
    {
      title: "Can edit other user data",
      tabs
    },
    {
      title: "Can delete own data",
      tabs
    },
    {
      title: "Can delete other user data",
      tabs
    },
    {
      title: "Create rules",
      tabs
    },
    {
      title: "Edit rules",
      tabs
    }
  ],
  additional: {
    students_csv_import: false,
    students_csv_export: true,
    students_xml_export: true,
    push_programmes: true
  }
};
