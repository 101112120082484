import React from "react";
import classes from "./ContactsPage.module.scss";
import { connect } from "react-redux";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { getCurrentLink } from "@Root/helpers";
import DocumentTitle from "react-document-title";
import { PageTitle, PageNavLinks } from "@Root/components";
import { ContactsTable } from "./ContactsTable/";
import { ContactsNewContact } from "./ContactsNewContact";
import { ContactsDetails } from "./ContactsDetails";

const ContactsPage = ({ location, permissions }) => {
  const links = [
    { name: "All Contacts", path: "all-contacts" },
    { name: "Students", path: "students" },
    { name: "Academics", path: "academics" },
    { name: "Staff", path: "staff" },
    { name: "Clergy", path: "clergy" },
    { name: "Associations", path: "associations" },
    { name: "Churches", path: "churches" },
    { name: "Dioceses", path: "dioceses" },
    { name: "Training Facilities", path: "training-facilities" },
    { name: "Other", path: "other" }
  ];

  const currentLink = getCurrentLink(links, location);
  const currentLinkName = currentLink.name || "";

  const hasCreatePermission = permissions.includes("create");

  return (
    <DocumentTitle title={`${currentLinkName} - Contacts`}>
      <div className={classes.wrapper}>
        <PageTitle title={currentLinkName} />
        <PageNavLinks style={{ marginBottom: 30 }} root={`/home/contacts`} links={links} />
        <Switch>
          {links.map((link, i) => (
            <Route exact path={`/home/contacts/${link.path}`} component={ContactsTable} key={i} />
          ))}
          {hasCreatePermission && <Route exact path="/home/contacts/:tab/new-person" component={ContactsNewContact} />}
          {hasCreatePermission && <Route exact path="/home/contacts/:tab/new-organisation" component={ContactsNewContact} />}
          <Route exact path="/home/contacts/:tab/:contactId" render={() => <ContactsDetails currentLink={currentLink} />} />
          <Redirect to="/home/contacts/all-contacts" />
        </Switch>
      </div>
    </DocumentTitle>
  );
};

const mapStateToProps = ({ authReducer }) => ({
  permissions: authReducer.user.permissions
});

export default withRouter(connect(mapStateToProps)(ContactsPage));
