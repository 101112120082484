import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, MultiSelect } from "@Root/components";
import { switchEmptyStringsToNullsInObject } from "@Root/helpers";

export const StaffForm = ({ permissions, deleteHandler, initialData, saveHandler, errorHandler, options }) => {
  const [data, setData] = useState(initialData);
  const [isEditable, setIsEditable] = useState(false);
  const [isSaving, setIsSavind] = useState(false);

  const handleClickCancelButton = () => {
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSavind(true);
      },
      () => {
        StaffForm.isMounted && setIsSavind(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    setIsSavind(true);
    const modifiedData = switchEmptyStringsToNullsInObject(data);
    try {
      await saveHandler(modifiedData);
      StaffForm.isMounted && setIsEditable(false);
    } catch (error) {
      errorHandler(error);
    }
    StaffForm.isMounted && setIsSavind(false);
  };

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  useEffect(() => {
    StaffForm.isMounted = true;
    return () => {
      StaffForm.isMounted = false;
    };
  }, []);

  return (
    <FormWrapper
      buttons={isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : []), "save"] : []}
      buttonsNames={[{ button: "delete", name: "Delete this staff member" }]}
      buttonsAreDisabled={isSaving}
      isSpinning={isSaving}
      clickCancelButtonHandler={handleClickCancelButton}
      clickDeleteButtonHandler={handleClickDeleteButton}
      clickSaveButtonHandler={handleClickSaveButton}
    >
      <div className={classes.titleWrapper}>
        <SectionTitle title={isEditable ? "Edit Staff Details" : "Staff Details"} />
        <div>{permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}</div>
      </div>
      <div className={classes.rowsWrapper}>
        <div className={classes.rowWrapper}>
          <InputLabel text="Federation House" />
          <div style={{ position: "absolute", left: 150, width: 260 }}>
            <MultiSelect
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 260 }}
              options={options.institutionOptions}
              values={data.institution_ids}
              changeHandler={value => handleChangeInput("institution_ids", value)}
              isDisabled={!isEditable}
            />
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

StaffForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  institution_ids: PropTypes.arrayOf(PropTypes.number),
  deleteHandler: PropTypes.func
};

StaffForm.defaultProps = {
  permissions: [],
  initialData: {
    institution_ids: []
  },
  deleteHandler: () => {}
};
