import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { booleanOptions } from "@Root/configs";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select, DatePicker } from "@Root/components";

export class StudiesStudyProgrammeForm extends Component {
  constructor(props) {
    super(props);
    const { initialIsEditable, initialProgrammeId, initialData } = props;
    const data = initialProgrammeId ? { ...initialData, program_id: initialProgrammeId } : initialData;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      data
    };
  }

  handleChangeInput = (field, value) => {
    this.setState({ data: { ...this.state.data, [field]: value } });
  };

  formIsValid = () => {
    const {
      program_id,
      academic_year,
      study_years,
      managed_by_id,
      ordinand_id,
      learning_centre_id,
      credits,
      status_id,
      institution_id,
      model_of_study_id
    } = this.state.data;
    if (!program_id) {
      this.showError("program_id", "Required");
      return false;
    }
    if (!academic_year) {
      this.showError("academic_year", "Required");
      return false;
    }
    if (!study_years) {
      this.showError("study_years", "Required");
      return false;
    }
    if (!managed_by_id) {
      this.showError("managed_by_id", "Required");
      return false;
    }
    if (!ordinand_id) {
      this.showError("ordinand_id", "Required");
      return false;
    }
    if (!learning_centre_id) {
      this.showError("learning_centre_id", "Required");
      return false;
    }
    if (!credits) {
      this.showError("credits", "Required");
      return false;
    }
    if (!status_id) {
      this.showError("status_id", "Required");
      return false;
    }
    if (!institution_id) {
      this.showError("institution_id", "Required");
      return false;
    }
    if (!model_of_study_id) {
      this.showError("model_of_study_id", "Required");
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  handleClickCancelButton = () => {
    this.setState({
      isEditable: false,
      error: null,
      data: this.props.initialData
    });
  };

  handleClickDeleteButton = async () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { data } = this.state;
    this.setState({ isSaving: true });
    await this.props.saveHandler(data, () => {
      !this.isUnmounted && this.setState({ isEditable: false });
    });
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { isEditable, isSaving, data } = this.state;
    const { buttons, title, options } = this.props;
    const { handleChangeInput, handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage } = this;
    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;
    const selectedProg = options.programmesListOptions.find(prog => prog.value === data.program_id);

    return (
      <div className={classes.StudiesProgrammeForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this Study Record" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            <div>{!isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}</div>
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Programme Name" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.programmesListOptions}
                  value={data.program_id}
                  changeHandler={value => handleChangeInput("program_id", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("program_id")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Academic Year" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.academic_year_list}
                  value={data.academic_year}
                  changeHandler={value => handleChangeInput("academic_year", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("academic_year")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Study Year" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.study_years}
                  value={data.study_years}
                  changeHandler={value => handleChangeInput("study_years", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("study_years")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Context" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.context}
                  value={data.context_id}
                  changeHandler={value => handleChangeInput("context_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Programme Manager" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.managed_by}
                  value={data.managed_by_id}
                  changeHandler={value => handleChangeInput("managed_by_id", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("managed_by_id")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Ordinand" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.ordinand}
                  value={data.ordinand_id}
                  changeHandler={value => handleChangeInput("ordinand_id", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("ordinand_id")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Learning Center" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.learning_centre}
                  value={data.learning_centre_id}
                  changeHandler={value => handleChangeInput("learning_centre_id", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("learning_centre_id")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Auditing?" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={booleanOptions}
                  value={data.auditing}
                  changeHandler={value => handleChangeInput("auditing", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Credits" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={data.credits}
                  changeHandler={value => handleChangeInput("credits", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("credits")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Eligible for Support in 2019?" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={booleanOptions}
                  value={data.eligible_for_support}
                  changeHandler={value => handleChangeInput("eligible_for_support", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="SLC Loan in 2019?" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={booleanOptions}
                  value={data.SLC_Loan_id}
                  changeHandler={value => handleChangeInput("SLC_Loan_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="HESA Qual Code" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.HESA_Qual_code}
                  value={data.HESA_Qual_code_id}
                  changeHandler={value => handleChangeInput("HESA_Qual_code_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="HESA Qual Award" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.HESA_Qual_awards}
                  value={data.HESA_Qual_award_id}
                  changeHandler={value => handleChangeInput("HESA_Qual_award_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Student Course Session Start Date" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={data.course_session_start_date}
                  changeHandler={value => handleChangeInput("course_session_start_date", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Student Course Session End Date" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={data.course_session_end_date}
                  changeHandler={value => handleChangeInput("course_session_end_date", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Z: Student Course Session Start Date" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={data.z_course_session_start_date}
                  changeHandler={value => handleChangeInput("z_course_session_start_date", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Z: Student Course Session End Date" />
                <DatePicker
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={data.z_course_session_end_date}
                  changeHandler={value => handleChangeInput("z_course_session_end_date", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Reason for Student Course Session Ending " />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  options={options.reason_for_student_course_session_ending}
                  value={data.reason_course_session_id}
                  changeHandler={value => handleChangeInput("reason_course_session_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper} style={{ height: 76 }}>
                <InputLabel text="Dates" style={{ position: "absolute", top: 40 }} />
                <div style={{ display: "flex", justifyContent: "space-between", width: 970 }}>
                  <div>
                    <InputLabel text="Start" style={{ fontWeight: "bold" }} />
                    <DatePicker
                      classNames={!isEditable ? ["borderless"] : []}
                      style={{ width: 260 }}
                      value={data.start_date}
                      changeHandler={value => handleChangeInput("start_date", value)}
                      isDisabled={!isEditable}
                    />
                  </div>
                  <div>
                    <InputLabel text="Leaving" style={{ fontWeight: "bold" }} />
                    <DatePicker
                      classNames={!isEditable ? ["borderless"] : []}
                      style={{ width: 260 }}
                      value={data.leaving_date}
                      changeHandler={value => handleChangeInput("leaving_date", value)}
                      isDisabled={!isEditable}
                    />
                  </div>
                  <div>
                    <InputLabel text="Finishing" style={{ fontWeight: "bold" }} />
                    <DatePicker
                      classNames={!isEditable ? ["borderless"] : []}
                      style={{ width: 260 }}
                      value={data.finishing_date}
                      changeHandler={value => handleChangeInput("finishing_date", value)}
                      isDisabled={!isEditable}
                    />
                  </div>
                </div>
              </div>
              {data.imported && (
                <>
                  <div className={classes.inputWrapper}>
                    <InputLabel text="ID" />
                    <TextInput
                      classNames={!isEditable ? ["borderless"] : []}
                      style={{ width: 260 }}
                      value={data.ID}
                      changeHandler={value => handleChangeInput("ID", value)}
                      isDisabled={true}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputLabel text="Trashed" />
                    <TextInput
                      classNames={!isEditable ? ["borderless"] : []}
                      style={{ width: 260 }}
                      value={data.ID}
                      changeHandler={value => handleChangeInput("ID", value)}
                      isDisabled={true}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputLabel text="statusold" />
                    <TextInput
                      classNames={!isEditable ? ["borderless"] : []}
                      style={{ width: 260 }}
                      value={data.statusold}
                      changeHandler={value => handleChangeInput("statusold", value)}
                      isDisabled={true}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputLabel text="sselig" />
                    <TextInput
                      classNames={!isEditable ? ["borderless"] : []}
                      style={{ width: 260 }}
                      value={data.sselig}
                      changeHandler={value => handleChangeInput("sselig", value)}
                      isDisabled={true}
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputLabel text="future_prog" />
                    <TextInput
                      classNames={!isEditable ? ["borderless"] : []}
                      style={{ width: 260 }}
                      value={data.future_prog}
                      changeHandler={value => handleChangeInput("future_prog", value)}
                      isDisabled={true}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Status" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.status}
                  value={data.status_id}
                  changeHandler={value => handleChangeInput("status_id", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("status")}
                />
              </div>{" "}
              <div className={classes.inputWrapper}>
                <InputLabel text="Fee Status" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.fee_status}
                  value={data.fee_status_id}
                  changeHandler={value => handleChangeInput("fee_status_id", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("fee_status_id")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Programme  Year" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.program_years_list}
                  value={data.program_years_id}
                  changeHandler={value => handleChangeInput("program_years_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Accommodation" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.accommodation}
                  value={data.accommodation_id}
                  changeHandler={value => handleChangeInput("accommodation_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Sponsor" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.sponsors}
                  value={data.sponsor_id}
                  changeHandler={value => handleChangeInput("sponsor_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Federation House" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.institutions}
                  value={data.institution_id}
                  changeHandler={value => handleChangeInput("institution_id", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("institution_id")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Mode of Study" hasAsterisk={isEditable} />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.model_of_study}
                  value={data.model_of_study_id}
                  changeHandler={value => handleChangeInput("model_of_study_id", value)}
                  isDisabled={!isEditable}
                  error={errorMessage("model_of_study_id")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Diocese" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.diocese}
                  value={data.diocese_id}
                  changeHandler={value => handleChangeInput("diocese_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Pastoral Tutor" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.managed_by}
                  value={data.pastoral_tutor}
                  changeHandler={value => handleChangeInput("pastoral_tutor", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="In Heapes 2019?" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={booleanOptions}
                  value={data.In_HEAPES}
                  changeHandler={value => handleChangeInput("In_HEAPES", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Financial Support" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.financial_support}
                  value={data.financial_support_id}
                  changeHandler={value => handleChangeInput("financial_support_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="HESA Qual Class Code" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.HESA_Qual_Class_code}
                  value={data.HESA_Qual_Class_code_id}
                  changeHandler={value => handleChangeInput("HESA_Qual_Class_code_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              {selectedProg && selectedProg.type === "Post Graduate" && (
                <div className={classes.inputWrapper}>
                  <InputLabel text="Intended Thesis Title" />
                  <TextInput
                    classNames={!isEditable ? ["borderless"] : []}
                    style={{ width: 260 }}
                    value={data.intended_thesis_title}
                    changeHandler={value => handleChangeInput("intended_thesis_title", value)}
                    isDisabled={!isEditable}
                  />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <InputLabel text="Student Registration Number" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.student_registrations}
                  value={data.student_registration_id}
                  changeHandler={value => handleChangeInput("student_registration_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
              {selectedProg && selectedProg.type === "Post Graduate" && (
                <div className={classes.inputWrapper}>
                  <InputLabel text="PhD Submission Date" />
                  <DatePicker
                    classNames={!isEditable ? ["borderless"] : []}
                    style={{ width: 260 }}
                    value={data.phd_submission_date}
                    changeHandler={value => handleChangeInput("phd_submission_date", value)}
                    isDisabled={!isEditable}
                  />
                </div>
              )}
              <div className={classes.inputWrapper}>
                <InputLabel text="Predicted Full Time Equivalence" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={data.predicted_full_time}
                  changeHandler={value => handleChangeInput("predicted_full_time", value)}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Student Load" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={data.student_load}
                  changeHandler={value => handleChangeInput("student_load", value)}
                  isDisabled={!isEditable}
                />
              </div>{" "}
              <div className={classes.inputWrapper}>
                <InputLabel text="Session Year" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  options={options.session_years}
                  value={data.session_year_id}
                  changeHandler={value => handleChangeInput("session_year_id", value)}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

StudiesStudyProgrammeForm.propTypes = {
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialProgrammeId: PropTypes.number,
  initialData: PropTypes.shape({
    program_id: PropTypes.number,
    academic_year: PropTypes.number,
    study_years: PropTypes.number,
    context_id: PropTypes.number,
    managed_by_id: PropTypes.number,
    ordinand_id: PropTypes.number,
    learning_centre_id: PropTypes.number,
    auditing: PropTypes.bool,
    credits: PropTypes.string,
    eligible_for_support: PropTypes.bool,
    SLC_Loan_id: PropTypes.bool,
    HESA_Qual_code_id: PropTypes.number,
    HESA_Qual_award_id: PropTypes.number,
    course_session_start_date: PropTypes.string,
    course_session_end_date: PropTypes.string,
    z_course_session_start_date: PropTypes.string,
    z_course_session_end_date: PropTypes.string,
    reason_course_session_id: PropTypes.number,
    status_id: PropTypes.number,
    program_years_id: PropTypes.number,
    accommodation_id: PropTypes.number,
    sponsor_id: PropTypes.number,
    institution_id: PropTypes.number,
    model_of_study_id: PropTypes.number,
    diocese_id: PropTypes.number,
    pastoral_tutor: PropTypes.number,
    In_HEAPES: PropTypes.bool,
    financial_support_id: PropTypes.number,
    HESA_Qual_Class_code_id: PropTypes.number,
    intended_thesis_title: PropTypes.string,
    student_registration_id: PropTypes.number,
    phd_submission_date: PropTypes.string,
    predicted_full_time: PropTypes.string,
    student_load: PropTypes.string,
    start_date: PropTypes.string,
    leaving_date: PropTypes.string,
    finishing_date: PropTypes.string,
    fee_status_id: PropTypes.number,
    session_year_id: PropTypes.number
  }),
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

StudiesStudyProgrammeForm.defaultProps = {
  title: {
    onRead: "",
    onEdit: ""
  },
  initialProgrammeId: null,
  initialData: {
    imported: false,
    program_id: null,
    academic_year: null,
    study_years: null,
    context_id: null,
    managed_by_id: null,
    ordinand_id: null,
    learning_centre_id: null,
    auditing: null,
    credits: null,
    eligible_for_support: null,
    SLC_Loaт: null,
    HESA_Qual_code_id: null,
    HESA_Qual_award_id: null,
    course_session_start_date: null,
    course_session_end_date: null,
    z_course_session_start_date: null,
    z_course_session_end_date: null,
    reason_course_session_id: null,
    status_id: null,
    program_years_id: null,
    accommodation_id: null,
    sponsor_id: null,
    institution_id: null,
    model_of_study_id: null,
    diocese_id: null,
    pastoral_tutor: null,
    In_HEAPES: null,
    financial_support_id: null,
    HESA_Qual_Class_code_id: null,
    intended_thesis_title: null,
    student_registration_id: null,
    phd_submission_date: null,
    predicted_full_time: null,
    student_load: null,
    start_date: null,
    leaving_date: null,
    finishing_date: null,
    ID: null,
    trashed: null,
    statusold: null,
    sselig: null,
    future_prog: null,
    fee_status_id: null,
    session_year_id: null
  },
  initialIsEditable: false,
  options: null,
  buttons: ["cancel", "delete", "save"],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};
