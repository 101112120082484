import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "@Root/API";
import * as actions from "@Root/store";
import { errorMessage } from "@Root/helpers";
import { AdminFormTemplate, Spinner } from "@Root/components";

import { SectionWrapper } from "@Root/HOCs";
import { useParams } from "react-router";

export const AdminPageTemplate = () => {
  const [isShown, setIsShown] = useState(false);
  const [data, setData] = useState([]);
  const [keyWord, setKeyWord] = useState("");
  const [title, setTitle] = useState("");
  const [deleteByField, setDeleteByField] = useState("");
  const [getValueByField, setGetValueByField] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const permissions = useSelector(state => state.authReducer.user.permissions);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const param = params.page;
  const getAPIObject = (payload, param) => {
    const object = {
      "awarding-body": {
        get: async () => await API.getAdminAwardingBody(),
        post: async () => await API.postAdminAwardingBody(payload),
        put: async () => await API.putAdminAwardingBody(payload),
        delete: async () => await API.deleteAdminAwardingBody(payload)
      },
      emails: {
        get: async () => await API.getAdminEmails(),
        post: async () => await API.postAdminEmail(payload),
        put: async () => await API.putAdminEmail(payload),
        delete: async () => await API.deleteAdminEmail(payload)
      },
      statuses: {
        get: async () => await API.getAdminTeiStatuses(),
        post: async () => await API.postAdminTeiStatus(payload),
        put: async () => await API.putAdminTeiStatus(payload),
        delete: async () => await API.deleteAdminTeiStatus(payload)
      },
      "programme-types": {
        get: async () => await API.getAdminProgrammeTypes(),
        post: async () => await API.postAdminProgrammeType(payload),
        put: async () => await API.putAdminProgrammeType(payload),
        delete: async () => await API.deleteAdminProgrammeType(payload)
      },
      "fee-statuses": {
        get: async () => await API.getAdminFeesStatuses(),
        post: async () => await API.postAdminFeesStatuses(payload),
        put: async () => await API.putAdminFeesStatuses(payload),
        delete: async () => await API.deleteAdminFeesStatuses(payload)
      },
      "session-year": {
        get: async () => await API.getAdminSessionYear(),
        post: async () => await API.postAdminSessionYear(payload),
        put: async () => await API.putAdminSessionYear(payload),
        delete: async () => await API.deleteAdminSessionYear(payload)
      },
      ordinand: {
        get: async () => await API.getAdminOrdinand(),
        post: async () => await API.postAdminOrdinand(payload),
        put: async () => await API.putAdminOrdinand(payload),
        delete: async () => await API.deleteAdminOrdinand(payload)
      }
    };

    return object[param];
  };
  const getKeyWord = param => {
    const object = {
      "awarding-body": () => "Body",
      emails: () => "Email",
      statuses: () => "Status",
      "programme-types": () => "Type",
      "fee-statuses": () => "Status",
      "session-year": () => "Session Year",
      ordinand: () => "Ordinand"
    };

    setKeyWord(object[param]);
  };
  const getTitle = param => {
    const object = {
      "awarding-body": () => "Awarding Body",
      emails: () => "Emails",
      statuses: () => "Statuses",
      "fee-statuses": () => "Fee statuses",
      "programme-types": () => "Programme type",
      "session-year": () => "Session Year",
      ordinand: () => "Ordinand"
    };
    setTitle(object[param]);
  };

  const getSubTitle = param => {
    const object = {
      "awarding-body": () => "Body",
      emails: () => "Email",
      statuses: () => "Status",
      "fee-statuses": () => "Status",
      "programme-types": () => "Type",
      "session-year": () => "Session Year",
      ordinand: () => "Ordinand"
    };
    setSubTitle(object[param]);
  };

  const getDeleteByField = param => {
    const object = {
      "awarding-body": () => "id",
      emails: () => "id",
      statuses: () => "id",
      "fee-statuses": () => "id",
      "programme-types": () => "name",
      "session-year": () => "id",
      ordinand: () => "id"
    };

    setDeleteByField(object[param]);
  };
  const getValueForField = param => {
    const object = {
      "awarding-body": () => "name",
      emails: () => "email",
      statuses: () => "name",
      "fee-statuses": () => "name",
      "programme-types": () => "name",
      "session-year": () => "name",
      ordinand: () => "name"
    };

    setGetValueByField(object[param]);
  };

  const fetchData = async param => {
    setIsShown(false);
    try {
      const { data } = await getAPIObject(null, param).get();
      setData(data.data);
      setIsShown(true);
    } catch (error) {
      dispatch(actions.setSnackbar({ text: errorMessage(error), isError: true }));
      history.push("/contact-us");
    }
  };

  const postData = async (payload, callback) => {
    try {
      const { data } = await getAPIObject(payload, param).post();
      setData(prevState => [...prevState, data.data]);
      callback();
    } catch (error) {
      dispatch(actions.setSnackbar({ text: errorMessage(error), isError: true }));
    }
  };

  const putData = async payload => {
    try {
      const { data } = await getAPIObject(payload, param).put();
      setData(prevState => {
        const dataCopy = [...prevState];
        const index = prevState.findIndex(item => item.id === data.data.id);
        dataCopy.splice(index, 1, data.data);
        return dataCopy;
      });
    } catch (error) {
      dispatch(actions.setSnackbar({ text: errorMessage(error), isError: true }));
    }
  };

  const deleteData = async (value, callback) => {
    const ItemIsDeletable = data.find(item => item.id === value)?.can_delete;
    const ItemIsRemovable = data.find(item => item.id === value)?.can_remove;
    const neverWillDelete = ItemIsRemovable === false;
    if (ItemIsDeletable || getValueByField === "email") {
      await new Promise((resolve, reject) => {
        dispatch(
          actions.showModal("ConfirmationModal", {
            text: `This ${subTitle.toLowerCase()} will be removed?`,
            clickRejectButtonHandler: reject,
            clickResolveButtonHandler: resolve
          })
        );
      }).then(
        async () => {
          dispatch(actions.hideModal());
          callback(true);
          try {
            await getAPIObject(value, param).delete();
            setData(prevState => prevState.filter(item => item[deleteByField] !== value));
          } catch (error) {
            dispatch(actions.setSnackbar({ text: errorMessage(error), isError: true }));
          }
          callback(false);
        },
        () => {
          dispatch(actions.hideModal());
        }
      );
    } else {
      await new Promise((resolve, reject) => {
        dispatch(
          actions.showModal("NotificationModal", {
            text: `This ${subTitle.toLowerCase()} can't be deleted. ${!neverWillDelete ? "It`s in use." : ""}`,
            clickRejectButtonHandler: reject,
            clickResolveButtonHandler: resolve
          })
        );
      }).then(
        () => {
          dispatch(actions.hideModal());
        },
        () => {
          dispatch(actions.hideModal());
        }
      );
    }
  };

  useEffect(() => {
    AdminPageTemplate.isMounted = true;
    fetchData(param);
    getKeyWord(param);
    getTitle(param);
    getDeleteByField(param);
    getValueForField(param);
    getSubTitle(param);
    return () => {
      AdminPageTemplate.isMounted = false;
    };
  }, [param]);

  return (
    <div className={classes.wrapper}>
      {isShown ? (
        <SectionWrapper>
          <AdminFormTemplate
            deleteByField={deleteByField}
            keyWord={keyWord}
            permissions={permissions}
            title={title}
            initialData={data}
            getValueByField={getValueByField}
            saveHandler={(payload, callback) => postData(payload, callback)}
            editHandler={payload => putData(payload)}
            deleteHandler={(payload, callback) => deleteData(payload, callback)}
          />
        </SectionWrapper>
      ) : (
        <Spinner style={{ position: "absolute" }} />
      )}
    </div>
  );
};
