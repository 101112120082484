import React from "react";
import classes from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DocumentTitle from "react-document-title";
import * as actions from "@Root/store";
import { withPromise, errorMessage } from "@Root/helpers";
import { API } from "@Root/API";
import { Spinner, ApplicationForm, BackButton } from "@Root/components";

class Component extends React.Component {
  state = {
    isShown: false,
    data: {}
  };

  async componentDidMount() {
    const { programmes, getProgrammes } = this.props;
    const { match, history, setSnackbar } = this.props;
    try {
      const data = await Promise.all([API.getStudentApplication(match.params.applicationId), programmes === null ? getProgrammes() : null]);
      this.setState({ isShown: true, data: data[0].data.data });
    } catch (error) {
      setSnackbar({ text: errorMessage(error), isError: true });
      history.replace("/contact-us");
    }
  }

  render() {
    const { isShown, data } = this.state;
    const { history, programmes } = this.props;

    return (
      <DocumentTitle title="Application">
        <div className={classes.AdmissionsApplication}>
          {isShown ? (
            <div className={classes.pageWrapper}>
              <div className={classes.backButtonWrapper}>
                <BackButton text="All Applications" clickHandler={() => history.goBack()} />
              </div>
              <div className={classes.line} />
              <ApplicationForm data={data} programmes={programmes} />
            </div>
          ) : (
            <Spinner style={{ height: "calc(100vh - 280px)" }} />
          )}
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = ({ inputDataListsReducer }) => ({
  programmes: inputDataListsReducer.studentApplicationProgrammes
});

const mapDispatchToProps = dispatch => {
  return {
    getProgrammes: () => withPromise(dispatch, actions.getStudentApplicationProgrammes),
    setSnackbar: data => dispatch(actions.setSnackbar(data))
  };
};

export const AdmissionsApplication = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
