import * as types from "../types";

export const setSnackbar = data => {
  return {
    type: types.SET_SNACKBAR,
    data
  };
};

export const setSuccessNotification = data => {
  console.log(data);
  return {
    type: types.SET_SUCCESS_NOTIFICATION,
    data
  };
};
