import * as types from "../types";
import updateState from "../../helpers/updateState";

const initialState = {
  users: null,
  titles: null,
  genders: null,
  countries: null,
  personRelationships: null,
  organisationRelationships: null,
  hesaDetailOptions: null,
  hesaDetailCourseSessionOptions: null,
  studentApplicationProgrammes: null,
  contactsAssociationOptions: null,
  studiesProgrammeOptions: null,
  createFinancialSupportOptions: null,
  programmesListOptions: null,
  contactsClericOptions: null,
  contactsStudentTypeOptions: null,
  studiesStudyProgrammePostgradOptions: null,
  studiesStudyProgrammeFeesOptions: null,
  studiesStudyProgrammeFundingAndMonitoringOptions: null,
  adminUserOptions: null,
  studiesModuleOptions: null,
  studiesModuleSubjectOptions: null,
  studiesModuleDeliveryOptions: null,
  studiesModuleInstanceOptions: null,
  studiesEngagementOptions: null,
  studiesSuspensionsOptions: null,
  adminInstitutionOptions: null,
  studiesModuleCostCenterOptions: null,
  studiesModuleDeliveryLocationOptions: null,
  studiesStudentModuleFeeOptions: null,
  studiesModuleDeliveryRoleOptions: null,
  adminQualificationOptions: null,
  adminVenueOptions: null,
  admissionsApplicationsOptions: null,
  financeFundingOptions: null,
  dataPuringOptions: null,
  academicYears: null,
  contactAddressesType: null,
  institutionOptions: null
};

const setUserOptions = (state, { data }) => {
  return updateState(state, {
    users: data
  });
};

const setTitles = (state, { data }) => {
  return updateState(state, {
    titles: data
  });
};

const setGenders = (state, { data }) => {
  return updateState(state, {
    genders: data
  });
};

const setCountries = (state, { data }) => {
  return updateState(state, {
    countries: data
  });
};

const setPersonRelationships = (state, { data }) => {
  return updateState(state, {
    personRelationships: data
  });
};

const setOrganisationRelationships = (state, { data }) => {
  return updateState(state, {
    organisationRelationships: data
  });
};

const setHesaDetailOptions = (state, { data }) => {
  return updateState(state, {
    hesaDetailOptions: data
  });
};

const setHesaDetailCourseSessionOptions = (state, { data }) => {
  return updateState(state, {
    hesaDetailCourseSessionOptions: data
  });
};

const setStudentApplicationProgrammes = (state, { data }) => {
  return updateState(state, {
    studentApplicationProgrammes: data
  });
};

const setContactsAssociationOptions = (state, { data }) => {
  return updateState(state, {
    contactsAssociationOptions: data
  });
};

const setCreateProgrammeData = (state, { data }) => {
  return updateState(state, {
    studiesProgrammeOptions: data
  });
};

const setCreateFinancialSupportOptions = (state, { data }) => {
  return updateState(state, {
    createFinancialSupportOptions: data
  });
};

const setProgrammesListOptions = (state, { data }) => {
  return updateState(state, {
    programmesListOptions: data
  });
};

const setStudiesStudyProgrammeFormOptions = (state, { data }) => {
  return updateState(state, {
    studiesStudyProgrammeFormOptions: data
  });
};

const setContactsClericOptions = (state, { data }) => {
  return updateState(state, {
    contactsClericOptions: data
  });
};

const setContactsStudentTypeOptions = (state, { data }) => {
  return updateState(state, {
    contactsStudentTypeOptions: data
  });
};

const setStudiesStudyProgrammePostgradOptions = (state, { data }) => {
  return updateState(state, {
    studiesStudyProgrammePostgradOptions: data
  });
};

const setStudiesStudyProgrammeFeesOptions = (state, { data }) => {
  return updateState(state, {
    studiesStudyProgrammeFeesOptions: data
  });
};

const setStudiesStudyProgrammeFundingAndMonitoringOptions = (state, { data }) => {
  return updateState(state, {
    studiesStudyProgrammeFundingAndMonitoringOptions: data
  });
};

const setAdminUserOptions = (state, { data }) => {
  return updateState(state, {
    adminUserOptions: data
  });
};

const setStudiesModuleOptions = (state, { data }) => {
  return updateState(state, {
    studiesModuleOptions: data
  });
};

const setStudiesModuleSubjectOptions = (state, { data }) => {
  return updateState(state, {
    studiesModuleSubjectOptions: data
  });
};

const setStudiesModuleDeliveryOptions = (state, { data }) => {
  return updateState(state, {
    studiesModuleDeliveryOptions: data
  });
};

const setStudiesModuleCostCenterOptions = (state, { data }) => {
  return updateState(state, {
    studiesModuleCostCenterOptions: data
  });
};

const setStudiesModuleInstanceOptions = (state, { data }) => {
  return updateState(state, {
    studiesModuleInstanceOptions: data
  });
};

const setStudiesEngagementOptions = (state, { data }) => {
  return updateState(state, {
    studiesEngagementOptions: data
  });
};

const setStudiesSuspensionsOptions = (state, { data }) => {
  return updateState(state, {
    studiesSuspensionsOptions: data
  });
};

const setStudiesModuleDeliveryRoleOptions = (state, { data }) => {
  return updateState(state, {
    studiesModuleDeliveryRoleOptions: data
  });
};

const setStudiesModuleDeliveryLocationOptions = (state, { data }) => {
  return updateState(state, {
    studiesModuleDeliveryLocationOptions: data
  });
};

const setStudiesStudentModuleFeeOptions = (state, { data }) => {
  return updateState(state, {
    studiesStudentModuleFeeOptions: data
  });
};

const setAdminInstitutionOptions = (state, { data }) => {
  return updateState(state, {
    adminInstitutionOptions: data
  });
};

const setAdminQualificationOptions = (state, { data }) => {
  return updateState(state, {
    adminQualificationOptions: data
  });
};

const setAdminVenueOptions = (state, { data }) => {
  return updateState(state, {
    adminVenueOptions: data
  });
};

const setAdmissionsApplicationsOptions = (state, { data }) => {
  return updateState(state, {
    admissionsApplicationsOptions: data
  });
};

const setFinanceFundingOptions = (state, { data }) => {
  return updateState(state, {
    financeFundingOptions: data
  });
};

const setPuringDataOptions = (state, { data }) => {
  return updateState(state, {
    puringDataOptions: data
  });
};
const setAcademicYears = (state, { promise }) => {
  return updateState(state, {
    academicYears: promise
  });
};
const setContactAddressesType = (state, { promise }) => {
  return updateState(state, {
    contactAddressesType: promise
  });
};

const setInstitutionOptions = (state, { promise }) => {
  return updateState(state, {
    institutionOptions: promise
  });
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_OPTIONS:
      return setUserOptions(state, action);
    case types.SET_TITLES:
      return setTitles(state, action);
    case types.SET_GENDERS:
      return setGenders(state, action);
    case types.SET_COUNTRIES:
      return setCountries(state, action);
    case types.SET_PERSON_RELATIONSHIPS:
      return setPersonRelationships(state, action);
    case types.SET_ORGANISATION_RELATIONSHIPS:
      return setOrganisationRelationships(state, action);
    case types.SET_HESA_DETAIL_OPTIONS:
      return setHesaDetailOptions(state, action);
    case types.SET_HESA_DETAIL_COURSE_SESSION_OPTIONS:
      return setHesaDetailCourseSessionOptions(state, action);
    case types.SET_STUDENT_APPLICATION_PROGRAMMES:
      return setStudentApplicationProgrammes(state, action);
    case types.SET_CONTACTS_ASSOCIATION_OPTIONS:
      return setContactsAssociationOptions(state, action);
    case types.SET_STUDIES_PROGRAMME_OPTIONS:
      return setCreateProgrammeData(state, action);
    case types.SET_CREATE_FINANCIAL_SUPPORT_OPTIONS:
      return setCreateFinancialSupportOptions(state, action);
    case types.SET_PROGRAMMES_LIST_OPTIONS:
      return setProgrammesListOptions(state, action);
    case types.SET_STUDIES_STUDY_PROGRAMME_FORM_OPTIONS:
      return setStudiesStudyProgrammeFormOptions(state, action);
    case types.SET_CONTACTS_CLERIC_OPTIONS:
      return setContactsClericOptions(state, action);
    case types.SET_CONTACTS_STUDENT_TYPE_OPTIONS:
      return setContactsStudentTypeOptions(state, action);
    case types.SET_STUDIES_STUDY_PROGRAMME_POSTGRAD_OPTIONS:
      return setStudiesStudyProgrammePostgradOptions(state, action);
    case types.SET_STUDIES_STUDY_PROGRAMME_FEES_OPTIONS:
      return setStudiesStudyProgrammeFeesOptions(state, action);
    case types.SET_STUDIES_STUDY_PROGRAMME_FUNDING_AND_MONITORING_OPTIONS:
      return setStudiesStudyProgrammeFundingAndMonitoringOptions(state, action);
    case types.SET_ADMIN_USER_OPTIONS:
      return setAdminUserOptions(state, action);
    case types.SET_STUDIES_MODULE_OPTIONS:
      return setStudiesModuleOptions(state, action);
    case types.SET_STUDIES_ENGAGEMENT_OPTIONS:
      return setStudiesEngagementOptions(state, action);
    case types.SET_STUDIES_SUSPENSIONS_OPTIONS:
      return setStudiesSuspensionsOptions(state, action);
    case types.SET_STUDIES_MODULE_SUBJECT_OPTIONS:
      return setStudiesModuleSubjectOptions(state, action);
    case types.SET_STUDIES_MODULE_DELIVERY_OPTIONS:
      return setStudiesModuleDeliveryOptions(state, action);
    case types.SET_STUDIES_MODULE_COST_CENTRE_OPTIONS:
      return setStudiesModuleCostCenterOptions(state, action);
    case types.SET_STUDIES_MODULE_INSTANCE_OPTIONS:
      return setStudiesModuleInstanceOptions(state, action);
    case types.SET_STUDIES_MODULE_DELIVERY_ROLE_OPTIONS:
      return setStudiesModuleDeliveryRoleOptions(state, action);
    case types.SET_STUDIES_MODULE_DELIVERY_LOCATION_OPTIONS:
      return setStudiesModuleDeliveryLocationOptions(state, action);
    case types.SET_STUDIES_STUDENT_MODULE_FEE_OPTIONS:
      return setStudiesStudentModuleFeeOptions(state, action);
    case types.SET_ADMIN_INSTITUTION_OPTIONS:
      return setAdminInstitutionOptions(state, action);
    case types.SET_ADMIN_QUALIFICATION_OPTIONS:
      return setAdminQualificationOptions(state, action);
    case types.SET_ADMIN_VENUE_OPTIONS:
      return setAdminVenueOptions(state, action);
    case types.SET_ADMISSIONS_APPLICATIONS_OPTIONS:
      return setAdmissionsApplicationsOptions(state, action);
    case types.SET_FINANCE_FUNDING_OPTIONS:
      return setFinanceFundingOptions(state, action);
    case types.SET_PURING_DATA_OPTIONS:
      return setPuringDataOptions(state, action);
    case types.SET_ACADEMIC_YEARS:
      return setAcademicYears(state, action);
    case types.SET_CONTACT_ADDRESSES_TYPE:
      return setContactAddressesType(state, action);
    case types.SET_INSTITUTION_OPTIONS:
      return setInstitutionOptions(state, action);
    default:
      return state;
  }
};
