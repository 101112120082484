import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import moment from "moment";
import { FormWrapper } from "@Root/HOCs";
import { RoundPlusButton, SectionTitle, TextArea, TextInput } from "@Root/components";

export const ContactsNotesForm = ({ permissions, notes, saveNoteHandler, editNoteHandler, onNoteDelete }) => {
  const [newNote, setNewNote] = useState(null);
  const [editedNoteId, setEditedNoteId] = useState(null);
  const [error, setError] = useState(null);
  const [isSaving, setIsSavind] = useState(false);
  const [editValue, setEditValue] = useState("");
  const handleClickSaveButton = async () => {
    if (newNote === "") {
      setError("Required");
      return;
    }
    setIsSavind(true);
    await saveNoteHandler(newNote, () => {
      ContactsNotesForm.isMounted && setNewNote(null);
    });
    ContactsNotesForm.isMounted && setIsSavind(false);
  };

  const handleClickSaveEditButton = async () => {
    setIsSavind(true);
    await editNoteHandler(editedNoteId, editValue, () => {
      ContactsNotesForm.isMounted && cancelEdit();
    });
    ContactsNotesForm.isMounted && setIsSavind(false);
  };

  useEffect(() => {
    ContactsNotesForm.isMounted = true;
    return () => {
      ContactsNotesForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  const onEditNote = (id, value) => {
    setEditedNoteId(id);
    setEditValue(value);
  };

  const onChangeEditValue = value => {
    setEditValue(value);
  };

  const cancelEdit = () => {
    setEditValue("");
    setEditedNoteId(null);
    setIsSavind(false);
  };

  const onDelete = id => {
    onNoteDelete(id, () => setIsSavind(true), () => ContactsNotesForm.isMounted && setIsSavind(false));
  };
  const renderCreateNote = value => {
    return (
      <div className={classes.textareaWrapper}>
        <TextArea style={{ width: 900, height: 84 }} value={value} changeHandler={value => setNewNote(value)} error={error} />
        <div className={classes.buttonsWrapper}>
          <button className={classes.buttonSecondary} onClick={() => setNewNote(null)} disabled={isSaving}>
            Cancel
          </button>
          <button className={classes.buttonMain} onClick={handleClickSaveButton} disabled={isSaving}>
            Add
          </button>
        </div>
      </div>
    );
  };

  const renderEditNote = initialValue => {
    return (
      <div>
        <TextInput value={initialValue} changeHandler={value => onChangeEditValue(value)} />
        <div className={classes.buttonsWrapper}>
          <button className={classes.buttonSecondary} onClick={cancelEdit} disabled={isSaving}>
            Cancel
          </button>
          <button className={classes.buttonMain} onClick={handleClickSaveEditButton} disabled={isSaving}>
            Save
          </button>
        </div>
      </div>
    );
  };

  return (
    <FormWrapper isSpinning={isSaving}>
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <SectionTitle title="Notes" />
          {permissions.includes("createNote") && newNote === null && <RoundPlusButton clickHandler={() => setNewNote("")} />}
        </div>
        {newNote !== null && renderCreateNote(newNote)}
        <div className={classes.notesWrapper}>
          {notes.map((note, i) => (
            <div key={i} className={classes.noteWrapper}>
              <div className={classes.left}>
                <div className={classes.photo} style={{ backgroundImage: `url(${note.user_avatar})` }} />
              </div>
              <div className={classes.right}>
                <div className={classes.author}>
                  <div>
                    {note?.author_name}, {moment(new Date(note.created_at), "DD/MM/YYYY").fromNow()}
                  </div>
                  <div className={classes.actions}>
                    {(permissions.includes("editNote") || note.is_owner) && editedNoteId !== note.id && (
                      <div onClick={() => onEditNote(note.id, note.note_text)} className={classes.edit}>
                        Edit
                      </div>
                    )}
                    {(permissions.includes("deleteNote") || note.is_owner) && editedNoteId !== note.id && (
                      <div onClick={() => onDelete(note.id)} className={classes.delete}>
                        Delete
                      </div>
                    )}
                  </div>
                </div>
                {note.id !== editedNoteId ? <div className={classes.text}>{note.note_text}</div> : renderEditNote(editValue)}
              </div>
            </div>
          ))}
        </div>
        {!notes.length && newNote === null && <div className={classes.message}>There are currently no associated notes for this contact</div>}
      </div>
    </FormWrapper>
  );
};

ContactsNotesForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      author_name: PropTypes.string,
      created_at: PropTypes.shape({
        date: PropTypes.string
      }),
      is_owner: PropTypes.bool,
      note_text: PropTypes.string,
      user_avatar: PropTypes.string
    })
  ),
  saveNoteHandler: PropTypes.func
};

ContactsNotesForm.defaultProps = {
  permissions: [],
  notes: [],
  saveNoteHandler: () => {}
};
