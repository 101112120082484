import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { InputLabel, TextInput, Select, DataList, DataListAsync, DatePicker } from "@Root/components";

export const SubformInputGroup = ({ inputs, data, changeHandler, deleteIsAllowed, deleteHandler, isEditable, inputPath, error, children }) => {
  const errorMessage = inputField => {
    return error && error.inputPath === `${inputPath}/${inputField}` ? error.message : null;
  };

  return (
    <div className={classes.wrapper}>
      {inputs.map((input, i) => (
        <div className={classes.inputWrapper} key={i}>
          <InputLabel text={input.name} hasAsterisk={(input.validations || []).includes("required")} />
          {input.type === "textInput" && (
            <TextInput
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 370 }}
              value={data[input.field]}
              changeHandler={value => changeHandler(input.field, value)}
              isDisabled={!isEditable}
              error={errorMessage(input.field)}
              restriction={input.restriction}
              maxLength={input.maxLength}
            />
          )}
          {input.type === "select" && (
            <Select
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 370 }}
              options={input.options}
              value={data[input.field]}
              changeHandler={value => changeHandler(input.field, value)}
              isDisabled={!isEditable}
              error={errorMessage(input.field)}
            />
          )}
          {input.type === "dataList" && (
            <DataList
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 370 }}
              options={input.options}
              value={data[input.field]}
              changeHandler={value => changeHandler(input.field, value)}
              isDisabled={!isEditable}
              error={errorMessage(input.field)}
            />
          )}
          {input.type === "dataListAsync" && (
            <DataListAsync
              inputClassNames={!isEditable ? ["borderless"] : []}
              inputStyle={{ width: 370 }}
              value={data[input.field]}
              fetchLabelHandler={value => input.fetchLabelHandler(value)}
              fetchOptionsHandler={value => input.fetchOptionsHandler(value)}
              changeHandler={value => changeHandler(input.field, value)}
              isDisabled={!isEditable}
              error={errorMessage(input.field)}
            />
          )}
          {input.type === "datePicker" && (
            <DatePicker
              classNames={!isEditable ? ["borderless"] : []}
              style={{ width: 370 }}
              value={data[input.field]}
              changeHandler={value => changeHandler(input.field, value)}
              isDisabled={!isEditable}
              error={errorMessage(input.field)}
            />
          )}
        </div>
      ))}
      {children}
      {isEditable && deleteIsAllowed && (
        <div className={classes.buttonWrapper}>
          <button className={classes.buttonSecondary} onClick={deleteHandler} disabled={false}>
            Delele
          </button>
        </div>
      )}
    </div>
  );
};

SubformInputGroup.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      field: PropTypes.string,
      type: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          })
        ])
      )
    })
  ),
  data: PropTypes.object,
  changeHandler: PropTypes.func,
  deleteIsAllowed: PropTypes.bool,
  deleteHandler: PropTypes.func,
  isEditable: PropTypes.bool,
  inputPath: PropTypes.string,
  error: PropTypes.shape({
    inputPath: PropTypes.string,
    message: PropTypes.string
  }),
  children: PropTypes.node
};

SubformInputGroup.defaultProps = {
  inputs: [],
  data: {},
  changeHandler: () => {},
  deleteIsAllowed: false,
  deleteHandler: () => {},
  isEditable: false,
  inputPath: "",
  error: null,
  children: null
};
