import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, Select, TextInput } from "@Root/components";
export const VenueForm = ({ permissions, isNew, title, initialData, buttons, deleteHandler, saveHandler, options }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [data, setData] = useState(initialData);
  const [isSpinning, setIsSpinning] = useState(false);
  const [error, setError] = useState(null);

  const handleChangeInput = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const showError = (input, message) => setError({ input, message });

  const errorMessage = input => (error && error.input === input ? error.message : null);

  const formIsValid = () => {
    const { venue_name, venue_type_id, postcode, venue_ukprn } = data;
    if (!venue_name) {
      showError("venue_name", "Required");
      return false;
    }
    if (!venue_type_id) {
      showError("venue_type_id", "Required");
      return false;
    }
    if (!postcode) {
      showError("postcode", "Required");
      return false;
    }
    if (!venue_ukprn) {
      showError("venue_ukprn", "Required");
      return false;
    }
    return true;
  };

  const handleClickCancelButton = () => {
    setData(initialData);
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(
      () => {
        setIsSpinning(true);
      },
      () => {
        VenueForm.isMounted && setIsSpinning(false);
      }
    );
  };

  const handleClickSaveButton = async () => {
    if (!formIsValid()) return;
    setIsSpinning(true);
    await saveHandler(data, () => {
      VenueForm.isMounted && setIsEditable(false);
    });
    VenueForm.isMounted && setIsSpinning(false);
  };

  useEffect(() => {
    VenueForm.isMounted = true;
    return () => {
      VenueForm.isMounted = false;
    };
  }, []);

  useEffect(() => {
    VenueForm.isMounted && setData(initialData);
  }, [initialData]);

  useEffect(() => {
    error !== null && setError(null);
  }, [error]);

  return (
    <div className={classes.wrapper}>
      <FormWrapper
        buttons={isEditable ? buttons : []}
        buttonsNames={[{ button: "delete", name: "Delete this Venue" }]}
        buttonsAreDisabled={isSpinning}
        isSpinning={isSpinning}
        clickCancelButtonHandler={handleClickCancelButton}
        clickDeleteButtonHandler={handleClickDeleteButton}
        clickSaveButtonHandler={handleClickSaveButton}
      >
        <div className={classes.titleWrapper}>
          <SectionTitle title={isEditable ? title.onEdit : title.onRead} />
          {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.columnWrapper}>
            <div className={classes.inputWrapper}>
              <InputLabel text="Venue Name" hasAsterisk={isEditable} />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.venue_name}
                changeHandler={value => handleChangeInput("venue_name", value)}
                isDisabled={!isEditable}
                error={errorMessage("venue_name")}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Postcode" hasAsterisk={isEditable} />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.postcode}
                changeHandler={value => handleChangeInput("postcode", value)}
                isDisabled={!isEditable}
                error={errorMessage("postcode")}
              />
            </div>
          </div>
          <div className={classes.columnWrapper}>
            <div className={classes.inputWrapper}>
              <InputLabel text="Venue Type" hasAsterisk={isEditable} />
              <Select
                inputClassNames={!isEditable ? ["borderless"] : []}
                inputStyle={{ width: 260 }}
                options={options.venues_types}
                value={data.venue_type_id}
                changeHandler={value => handleChangeInput("venue_type_id", value)}
                isDisabled={!isEditable}
                error={errorMessage("venue_type_id")}
              />
            </div>
            <div className={classes.inputWrapper}>
              <InputLabel text="Venue UKPRN" hasAsterisk={isEditable} />
              <TextInput
                classNames={!isEditable ? ["borderless"] : []}
                style={{ width: 260 }}
                value={data.venue_ukprn}
                changeHandler={value => handleChangeInput("venue_ukprn", value)}
                isDisabled={!isEditable}
                error={errorMessage("venue_ukprn")}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

VenueForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  isNew: PropTypes.bool,
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    venue_name: PropTypes.string,
    venue_type_id: PropTypes.number,
    postcode: PropTypes.string,
    venue_ukprn: PropTypes.string
  }),
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func
};

VenueForm.defaultProps = {
  permissions: [],
  isNew: false,
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    venue_identifier: null,
    postcode: null,
    venue_name: null,
    venue_type_id: null,
    venue_ukprn: null
  },
  buttons: [],
  deleteHandler: () => {},
  saveHandler: () => {}
};
