import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput } from "@Root/components";

export const SnapshotForm = ({ permissions, initialData, deleteHandler }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const handleClickCancelButton = () => {
    setIsEditable(false);
  };

  const handleClickDeleteButton = () => {
    deleteHandler(isSpinning => {
      SnapshotForm.isMounted && setIsSpinning(isSpinning);
    });
  };

  useEffect(() => {
    SnapshotForm.isMounted = true;
    return () => {
      SnapshotForm.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <FormWrapper
        buttons={isEditable ? ["cancel", ...(permissions.includes("delete") ? ["delete"] : [])] : []}
        buttonsNames={[{ button: "delete", name: "Delete this Snapshot" }]}
        buttonsAreDisabled={isSpinning}
        isSpinning={isSpinning}
        clickCancelButtonHandler={handleClickCancelButton}
        clickDeleteButtonHandler={handleClickDeleteButton}
      >
        <div className={classes.titleWrapper}>
          <SectionTitle title={isEditable ? "Edit the Snapshot" : null} />
          {permissions.includes("delete") && !isEditable && <EditButton clickHandler={() => setIsEditable(true)} />}
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.columnWrapper}>
            <div className={classes.inputWrapper}>
              <InputLabel text="Created at" />
              <TextInput classNames={["borderless"]} style={{ width: 260 }} value={initialData.created_at} isDisabled />
            </div>
          </div>
          <div className={classes.columnWrapper}>
            <div className={classes.inputWrapper}>
              <InputLabel text="Contact Name" />
              <TextInput classNames={["borderless"]} style={{ width: 260 }} value={initialData.contact_name} isDisabled />
            </div>
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

SnapshotForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  initialData: PropTypes.shape({
    created_at: PropTypes.string,
    contact_name: PropTypes.string
  }),
  deleteHandler: PropTypes.func
};

SnapshotForm.defaultProps = {
  permissions: [],
  initialData: {
    created_at: null,
    contact_name: null
  },
  deleteHandler: () => {}
};
