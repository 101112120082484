import React, { Component } from "react";
import "./App.scss";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { axios } from "@Root/API";
import * as actions from "@Root/store";
import { HomePage, LoginPage, ContactUsPage, NotFoundPage, PasswordChangingPage, PasswordRecoveryPage } from "@Root/containers";

import { CustomScrollbar } from "@Root/HOCs";
import { SuccessNotification, Snackbar } from "@Root/components";

class App extends Component {
  constructor(props) {
    super(props);
    this.initialRoute = props.location.pathname;
    this.history = props.history;
    this.state = {};
  }

  initialRoute;

  updateToken = () => {
    this.props.setToken(localStorage.token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.token}`;
  };

  redirectToInitialRoute = () => {
    this.initialRoute !== "/" && this.props.history.replace(this.initialRoute);
  };

  watchServerErrors = () => {
    axios.interceptors.response.use(null, err => {
      const { status } = err.response;
      if (status === 401) {
        this.props.removeToken(this.props.history);
      }
      if (status === 404) {
        this.props.history.replace("/page-not-found");
      }
      return Promise.reject(err);
    });
  };

  componentWillMount() {
    this.setState({ prevLocation: window.location.pathname, location: window.location.pathname });
  }

  componentDidMount() {
    this.history.listen(location => {
      this.setState(prevState => ({
        prevLocation: prevState.location === location.pathname ? prevState.prevLocation : prevState.location || location.pathname,
        location: location.pathname
      }));
    });
    if (((localStorage.isKeptLoggedIn && JSON.parse(localStorage.isKeptLoggedIn)) || sessionStorage.isCurrentSession) && localStorage.token) {
      this.updateToken();
      this.redirectToInitialRoute();
    }
    this.watchServerErrors();
  }

  render() {
    const { token, snackbar, setSnackbar, successNotification, setSuccessNotification } = this.props;
    return (
      <div className="App">
        <CustomScrollbar>
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Route path="/home" render={() => (token ? <HomePage /> : <Redirect to="/login" />)} />
            <Route
              exact
              path="/login"
              render={() =>
                !token ? (
                  <LoginPage />
                ) : (
                  <Redirect
                    to={{
                      pathname: this.state.prevLocation !== "/login" ? this.state.prevLocation : "/home"
                    }}
                  />
                )
              }
            />
            {!token && <Route path="/password-recovery" exact component={PasswordRecoveryPage} />}
            {!token && <Route path="/password-changing" exact component={PasswordChangingPage} />}
            <Route exact path="/contact-us" component={ContactUsPage} />
            <Route exact path="/page-not-found" component={NotFoundPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </CustomScrollbar>
        {snackbar && <Snackbar text={snackbar.text} isError={snackbar.isError} changeHandler={setSnackbar} />}
        {successNotification && <SuccessNotification text={successNotification.text} changeHandler={setSuccessNotification} />}
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer, snackbarReducer }) => ({
  token: authReducer.token,
  snackbar: snackbarReducer.snackbar,
  successNotification: snackbarReducer.successNotification
});
const mapDispatchToProps = dispatch => {
  return {
    setToken: token => dispatch(actions.setToken(token)),
    logOut: history => dispatch(actions.logOut(history)),
    removeToken: history => dispatch(actions.removeToken(history)),
    setSnackbar: data => dispatch(actions.setSnackbar(data)),
    setSuccessNotification: data => dispatch(actions.setSuccessNotification(data))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
