import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { InputLabel, TextInput, Select } from "@Root/components";

export const EmbeddedForm = ({ isEditable, title, inputs, data, addIsAllowed, addHandler, changeHandler, deleteIsAllowed, deleteHandler, error }) => {
  const errorMessage = (index, field) => {
    return error && error.index === index && error.field === field ? error.message : null;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        {isEditable && addIsAllowed && (
          <div className={classes.button} onClick={addHandler}>
            Add new
          </div>
        )}
      </div>
      <div className={classes.main}>
        {data.map((row, i) => (
          <div className={classes.row} key={i}>
            {inputs.map((input, k) => (
              <div className={classes.inputs} key={k}>
                <InputLabel text={input.label} hasAsterisk={(input.validations || []).includes("required")} />
                {input.type === "textInput" && (
                  <TextInput
                    classNames={!isEditable ? ["borderless"] : []}
                    style={{ width: 370 }}
                    value={row[input.field]}
                    changeHandler={value => changeHandler(i, input.field, value)}
                    isDisabled={!isEditable}
                    error={errorMessage(i, input.field)}
                  />
                )}
                {input.type === "select" && (
                  <Select
                    inputClassNames={!isEditable ? ["borderless"] : []}
                    inputStyle={{ width: 370 }}
                    options={input.options}
                    value={row[input.field]}
                    changeHandler={value => changeHandler(i, input.field, value)}
                    isDisabled={!isEditable}
                    error={errorMessage(i, input.field)}
                  />
                )}
              </div>
            ))}
            {isEditable && deleteIsAllowed && (
              <div className={classes.buttonWrapper}>
                <button className={classes.button} onClick={() => deleteHandler(i)}>
                  Delele
                </button>
              </div>
            )}
          </div>
        ))}
        {!data.length && (
          <div className={classes.errorWrapper}>
            <div className={classes.error}>No data</div>
          </div>
        )}
      </div>
    </div>
  );
};

EmbeddedForm.propTypes = {
  isEditable: PropTypes.bool,
  title: PropTypes.string,
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      field: PropTypes.string,
      type: PropTypes.oneOf(["textInput", "select"]),
      options: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          })
        ])
      )
    })
  ),
  data: PropTypes.array,
  addIsAllowed: PropTypes.bool,
  addHandler: PropTypes.func,
  changeHandler: PropTypes.func,
  deleteIsAllowed: PropTypes.bool,
  deleteHandler: PropTypes.func,
  error: PropTypes.shape({
    index: PropTypes.number,
    field: PropTypes.string,
    message: PropTypes.string
  })
};

EmbeddedForm.defaultProps = {
  isEditable: false,
  title: null,
  inputs: [],
  data: [],
  addIsAllowed: false,
  addHandler: () => {},
  changeHandler: () => {},
  deleteIsAllowed: false,
  deleteHandler: () => {},
  error: null
};
