import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { API } from "@Root/API";
import { SectionWrapper } from "@Root/HOCs";
import { withPromise } from "@Root/helpers";
import classes from "./style.module.scss";
import * as actions from "@Root/store";
import { Spinner, StudiesModuleForm } from "@Root/components";

const Component = ({ history, getStudiesModuleOptions, studiesModuleOptions }) => {
  const [isShown, setIsShown] = useState(false);

  const fetchData = async () => {
    try {
      !studiesModuleOptions && (await getStudiesModuleOptions());
      Component.isMounted && setIsShown(true);
    } catch (error) {
      console.log(error);
      history.replace("/page-not-found");
    }
  };

  const saveProgramme = async payload => {
    const { data } = await API.createStudiesMudule(payload);
    history.push(`/home/studies/modules/${data.data.module_id}`);
  };

  useEffect(() => {
    Component.isMounted = true;
    fetchData();
    return () => {
      Component.isMounted = false;
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionsWrapper}>
        {isShown ? (
          <SectionWrapper hasBackButton backButtonText="Back to Modules" clickBackButtonHandler={() => history.push("/home/studies/modules")}>
            <StudiesModuleForm
              title={{ onEdit: "Create Module" }}
              initialIsEditable={true}
              generateId={true}
              options={studiesModuleOptions}
              buttons={["save"]}
              saveHandler={payload => saveProgramme(payload)}
            />
          </SectionWrapper>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ inputDataListsReducer }) => ({
  studiesModuleOptions: inputDataListsReducer.studiesModuleOptions
});

const mapDispatchToProps = dispatch => {
  return {
    getStudiesModuleOptions: () => withPromise(dispatch, actions.getStudiesModuleOptions)
  };
};

export const StudiesNewModule = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component)
);
