import React from "react";
import classes from "./style.module.scss";
import { ArrowIcon, CrossIcon, PlusIcon, BlackTickIcon } from "@Root/assets";
import { AddExceptsModal, AccordionStudentsRoleManager } from "@Root/components";

export class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      activeRole: props.activeRole,
      isEditable: props.isEditable
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ ...nextProps });
  }

  render() {
    return (
      <div {...{ className: classes.wrapper }}>
        <ul {...{ className: classes.accordion_list }}>
          {this.state.data.map((data, key) => {
            return (
              <li {...{ className: `${classes.accordion_list__item}`, key }}>
                <AccordionItem
                  {...data}
                  onDataChange={this.props.onDataChange}
                  onDataUserRolesChange={this.props.onDataUserRolesChange}
                  activeRole={this.state.activeRole}
                  isEditable={this.state.isEditable}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      isOpenAddException: false,
      isOpenAddExceptionStudentsRoles: false,
      title: props.title,
      selected: [...props.tabs],
      openedField: null,
      openedRole: null,
      activeRole: props.activeRole
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.activeRole !== nextProps.activeRole) {
      this.setState({
        title: nextProps.title,
        selected: nextProps.tabs,
        activeRole: nextProps.activeRole,
        isEditable: nextProps.isEditable
      });
    }
  }

  onChangeCategoryStatus = (tab, status) => {
    this.setState(prevState => {
      const newTab = { ...tab };
      newTab.removed = status;
      newTab.selected = !status;
      const unRemovedTab = prevState.selected.find(tab => !tab.removed && newTab.name !== tab.name);

      const selectedObject =
        prevState.selected.length > 0
          ? prevState.selected.map(tab => {
              if (tab.name === newTab.name) return newTab;
              else if (unRemovedTab && unRemovedTab.name === tab.name) return { ...tab, removed: false, selected: true };
              else return tab;
            })
          : [];
      const object = {
        ...prevState,
        selected: selectedObject
      };
      this.props.onDataChange(this.state.title)(selectedObject);
      return object;
    });
  };

  onSetActiveCategory = category => {
    const newCategory = { ...category, selected: true };
    this.setState(prevState => {
      const selectedObject = prevState.selected.map(category =>
        category.name === newCategory.name
          ? newCategory
          : {
              ...category,
              selected: false
            }
      );
      this.props.onDataChange(this.state.title)(selectedObject);
      return {
        ...prevState,
        selected: selectedObject
      };
    });
  };

  onSetActiveSubCategory = subTab => {
    const newSubTab = { ...subTab, selected: true };
    this.setState(prevState => {
      const selectedObject = prevState.selected.map(item => {
        if (item.selected) {
          return {
            ...item,
            subTabs: item.subTabs.map(subTab => {
              if (subTab.name === newSubTab.name) return newSubTab;
              return { ...subTab, selected: false };
            })
          };
        }
        return item;
      });
      this.props.onDataChange(this.state.title)(selectedObject);
      return {
        ...prevState,
        selected: selectedObject
      };
    });
  };

  onAddExceptions = () => {
    this.setState({ isOpenAddException: true });
  };

  onCloseExceptions = () => {
    this.setState({ isOpenAddException: false });
  };

  onCloseExceptionsForStudents = () => {
    this.setState({ isOpenAddExceptionStudentsRoles: false });
  };

  onSaveExceptions = exceptions => {
    this.setState(prevState => {
      const selectedObject = prevState.selected.map(item => {
        if (item.selected) {
          return {
            ...item,
            subTabs: item.subTabs.map(subTab => {
              if (subTab.selected) return { ...subTab, fields: exceptions };
              return subTab;
            })
          };
        }
        return item;
      });
      this.props.onDataChange(this.state.title)(selectedObject);
      return {
        isOpenAddException: false,
        selected: selectedObject
      };
    });
  };

  onSaveExceptionsForStudents = (fieldName, selectedItem) => exceptions => {
    this.setState(prevState => {
      const selectedObject = prevState.selected.map(item => {
        if (item.selected) {
          return {
            ...item,
            userRoles: item.userRoles.map(userRole => {
              if (userRole.name === prevState.openedRole.name)
                return {
                  ...userRole,
                  tabs: userRole.tabs.map(tab => {
                    if (tab.name === fieldName)
                      return {
                        ...tab,
                        fields: exceptions
                      };
                    return tab;
                  })
                };
              return userRole;
            })
          };
        }
        return item;
      });
      this.props.onDataUserRolesChange(this.state.title, selectedItem)(selectedObject);
      return {
        isOpenAddExceptionStudentsRoles: false,
        selected: selectedObject,
        tabs: selectedObject
      };
    });
  };

  onChangeFieldStatus = newField => {
    const changedField = { ...newField, disabled: false };
    this.setState(prevState => {
      const selectedObject = prevState.selected.map(item => {
        if (item.selected) {
          return {
            ...item,
            subTabs: item.subTabs.map(subTab => {
              if (subTab.selected)
                return {
                  ...subTab,
                  fields: subTab.fields.map(field => (field.name === newField.name ? changedField : field))
                };
              return subTab;
            })
          };
        }
        return item;
      });
      this.props.onDataChange(this.state.title)(selectedObject);
      return {
        ...prevState,
        selected: selectedObject
      };
    });
  };

  onOpenExceptionsForStudentsRoles = (role, field) => {
    this.setState({ isOpenAddExceptionStudentsRoles: true, openedField: field, openedRole: role });
  };

  onChangeFieldStatusForStudentsRoles = selectedItem => (role, activeTab, field) => {
    const changedField = { ...field, disabled: false };
    this.setState(prevState => {
      const selectedObject = prevState.selected.map(item => {
        if (item.selected) {
          return {
            ...item,
            userRoles: item.userRoles.map(userRole => {
              if (userRole.name === role.name)
                return {
                  ...userRole,
                  tabs: userRole.tabs.map(tab => {
                    if (tab.name === activeTab.name) {
                      return {
                        ...tab,
                        fields: tab.fields.map(field => {
                          if (field.name === changedField.name) return changedField;
                          return field;
                        })
                      };
                    }
                    return tab;
                  })
                };
              return userRole;
            })
          };
        }
        return item;
      });

      this.props.onDataUserRolesChange(this.state.title, selectedItem)(selectedObject);
      return {
        ...prevState,
        selected: selectedObject,
        tabs: selectedObject
      };
    });
  };

  render() {
    const {
      state: { opened, selected, title, isOpenAddException, isOpenAddExceptionStudentsRoles, openedField },
      props: { isEditable }
    } = this;
    const selectedItem = selected?.find(item => item.selected);
    const subTabs = (selectedItem && selectedItem.subTabs) || [];
    const subTab = subTabs?.find(subTab => subTab.selected) || [];
    const fields = subTab.fields || [];
    return (
      <div
        {...{
          className: `${classes.accordion_item}, ${opened && `${classes.accordion_item__opened}`}`
        }}
      >
        <div
          {...{
            className: `${classes.accordion_item__line}`,
            onClick: () => {
              this.setState({ opened: !opened });
            }
          }}
        >
          <h3 {...{ className: `${classes.accordion_item__title}` }}>{title}</h3>
          <img {...{ className: `${classes.accordion_item__icon}` }} src={ArrowIcon} />
        </div>
        <div {...{ className: `${classes.accordion_item__inner}` }}>
          <div {...{ className: `${classes.accordion_item__content}` }}>
            <span {...{ className: `${classes.accordion_item__subTitle}` }}>Categories</span>
            <div
              {...{
                className: `${classes.accordion_item__categories}`,
                style: { gridTemplateColumns: `repeat(${selected?.length}, max-content)` }
              }}
            >
              {selected?.map(tab => {
                const { name, removed } = tab;
                return (
                  <div key={name}>
                    <div
                      {...{
                        className: `${classes.accordion_item__categories__category} ${
                          !removed ? classes.accordion_item__categories__category__selected : classes.accordion_item__categories__category__removed
                        }`
                      }}
                    >
                      <span onClick={removed ? () => this.onChangeCategoryStatus(tab, false) : () => this.onSetActiveCategory(tab)}>{name}</span>
                      {!removed && isEditable ? (
                        <img
                          src={CrossIcon}
                          className={classes.accordion_item__categories__category_cross}
                          onClick={() => this.onChangeCategoryStatus(tab, true)}
                        />
                      ) : (
                        isEditable && <img src={BlackTickIcon} className={classes.accordion_item__categories__category_tick} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {subTabs?.length > 0 && (
              <div className={`PageNavLinks ${classes.pageNavLinks_role_manager}`}>
                {subTabs?.map(subTab => {
                  const { name, selected } = subTab;
                  return (
                    <a
                      className={selected ? classes.accordion_item__subtab__item_active : classes.accordion_item__subtab__item}
                      onClick={() => this.onSetActiveSubCategory(subTab)}
                      key={name}
                    >
                      {name}
                    </a>
                  );
                })}
              </div>
            )}
            {subTabs.length > 0 && (
              <div className={classes.accordion_item_excepts_block}>
                <div>
                  <div className={classes.accordion_item_excepts_block_title}>Excepts</div>
                  <div className={classes.accordion_item_excepts_actions}>
                    {isEditable && (
                      <div className={classes.accordion_item_excepts_block_add_button} onClick={subTabs.length > 0 ? this.onAddExceptions : null}>
                        <img src={PlusIcon} alt="" />
                        <span>Add</span>
                      </div>
                    )}
                    <div className={classes.disabled_fields}>
                      {fields &&
                        fields.map(
                          field =>
                            field.disabled && (
                              <div
                                className={`${classes.accordion_item__categories__category} ${classes.accordion_item__categories__category__selected}`}
                                key={field.name}
                              >
                                <span>{field.name}</span>
                                {isEditable && <img src={CrossIcon} alt="" onClick={() => this.onChangeFieldStatus(field)} />}
                              </div>
                            )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedItem && (
              <AccordionStudentsRoleManager
                isEditable={isEditable}
                data={selectedItem.userRoles}
                onOpenExceptionsForStudentsRoles={this.onOpenExceptionsForStudentsRoles}
                onChangeFieldStatusForStudentsRoles={this.onChangeFieldStatusForStudentsRoles(selectedItem)}
              />
            )}
          </div>
        </div>
        {isOpenAddException && subTabs.length > 0 && (
          <AddExceptsModal fields={fields} categoryName={subTab.name} onCancel={this.onCloseExceptions} onSaveExceptions={this.onSaveExceptions} />
        )}
        {isOpenAddExceptionStudentsRoles && openedField && (
          <AddExceptsModal
            fields={openedField.fields}
            categoryName={openedField.name}
            onCancel={this.onCloseExceptionsForStudents}
            onSaveExceptions={this.onSaveExceptionsForStudents(openedField.name, selectedItem)}
          />
        )}
      </div>
    );
  }
}
