import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import { all } from "@Root/helpers";
import triangleBlack from "../../../assets/icons/triangle-black.png";
import { ArrowButton } from "@Root/components";
import { DateRangePopup } from "./DateRangePopup/";

import { datePeriods } from "@Root/helpers";

class Dropdown extends Component {
  state = {
    popupIsShown: false,
    lastHoveredValue: null
  };

  handleClickArrowButton = offset => {
    const { value, changeHandler } = this.props;
    const periods = Object.keys(datePeriods);
    let newPeriodIndex = periods.indexOf(value) + offset;
    if (newPeriodIndex > periods.length - 1) newPeriodIndex = 0;
    if (newPeriodIndex < 0) newPeriodIndex = periods.length - 1;
    changeHandler(periods[newPeriodIndex]);
  };

  handleClickOutside = () => {
    this.setState({ popupIsShown: false });
  };

  render() {
    const { popupIsShown, lastHoveredValue } = this.state;
    const { value, changeHandler, style } = this.props;
    const { handleClickArrowButton } = this;
    return (
      <div className={classes.DatePeriodDropdown} style={style}>
        <div className={classes.buttons}>
          <div className={classes.input} onClick={() => this.setState({ popupIsShown: !popupIsShown })}>
            <div className={classes.value}>{value}</div>
            <div className={classes.icon}>
              <img style={popupIsShown ? { transform: "rotate(180deg)" } : null} src={triangleBlack} alt="" />
            </div>
          </div>
          <ArrowButton style={{ backgroundColor: "white" }} direction="left" clickHandler={() => handleClickArrowButton(-1)} />
          <ArrowButton style={{ backgroundColor: "white" }} direction="right" clickHandler={() => handleClickArrowButton(1)} />
        </div>
        {popupIsShown && (
          <div className={classes.popup} onMouseLeave={() => this.setState({ lastHoveredValue: null })}>
            {lastHoveredValue && lastHoveredValue !== "All" && lastHoveredValue !== "This year" && lastHoveredValue !== "Last year" && (
              <div className={classes.datePickerWrapper}>
                <DateRangePopup startDate={datePeriods[lastHoveredValue][0]} endDate={datePeriods[lastHoveredValue][1]} />
              </div>
            )}
            <div className={classes.periods}>
              {Object.keys(datePeriods).map(period => (
                <div
                  className={classes.period}
                  onClick={() => all(() => changeHandler(period), () => this.setState({ popupIsShown: false }))}
                  key={period}
                  onMouseEnter={() => this.setState({ lastHoveredValue: period })}
                >
                  {period}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Dropdown.propTypes = {
  value: PropTypes.string.isRequired,
  changeHandler: PropTypes.func.isRequired,
  style: PropTypes.object
};

export const DatePeriodDropdown = onClickOutside(Dropdown);
