import { axios } from "@Root/API";
// INPUT DATA LISTS

export const getCountryByName = countryName => {
  return axios.get(`countries/find/${countryName}`);
};

export const getTitles = () => {
  return axios.get("titles");
};

export const getGenders = () => {
  return axios.get("genders");
};

export const getCountries = () => {
  return axios.get("countries");
};

export const getRelationships = contactType => {
  return axios.get(`contacts/${contactType}/relationships`);
};

export const getStudiesSuspensionsOptions = () => {
  return axios.get("get-data-for-create-suspensions");
};

export const getStudiesModuleSubjectOptions = () => {
  return axios.get("get-data-for-create-module-subject");
};

export const getStudiesModuleDeliveryOptions = () => {
  return axios.get("get-data-for-create-module-delivery");
};

export const getStudiesModuleCostCenterOptions = () => {
  return axios.get("get-data-for-create-module-cost-centre");
};

export const getStudiesModuleInstanceOptions = () => {
  return axios.get("get-data-for-create-module-instance");
};

export const getStudiesModuleDeliveryRoleOptions = () => {
  return axios.get("get-data-for-create-module-delivery-roles");
};

export const getStudiesModuleDeliveryLocationOptions = () => {
  return axios.get("get-data-for-create-module-delivery-location");
};

export const getStudiesStudentModuleFeeOptions = () => {
  return axios.get("get-data-for-create-student-module-fee");
};

export const getAdminVenueOptions = () => {
  return axios.get("get-data-for-create-venue");
};

export const getAdmissionsApplicationsOptions = () => {
  return axios.get("list-selectors-for-change-student-application");
};

export const getFinanceFundingOptions = () => {
  return axios.get("list-selectors-for-change-student-application");
};

export const getPuringDataOptions = () => {
  return axios.get("list-selectors-for-change-student-application");
};

export const getColumnOptions = contactType => {
  return axios.get(`page-setting/columns?entity=${contactType}`);
};

export const saveColumnOptions = (contactType, options) => {
  const payload = {
    entity: contactType,
    properties: options
  };
  return axios.post("page-setting/columns", payload);
};
export const getPersons = params => {
  return axios.get("people", {
    params
  });
};
export const getOrganisations = params => {
  return axios.get("organisations", {
    params
  });
};
