import React, { PureComponent } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { delay } from "redux-saga";
import { all } from "@Root/helpers";
import onClickOutside from "react-onclickoutside";
import DatePickerPopup from "./DatePickerPopup/";
import { ArrowIcon, CrossIcon } from "@Root/assets";

class Picker extends PureComponent {
  state = { popupIsShown: false, error: null };

  handleClickOutside = () => {
    this.setState({ popupIsShown: false });
  };

  showError = async error => {
    this.setState({ error });
    await delay(3000);
    this.setState({ error: null });
  };

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.props.error && this.showError(this.props.error);
    }
  }

  render() {
    const { popupIsShown, error } = this.state;
    const { classNames, style, value, changeHandler, isDisabled, placeholder = "", minDate, maxDate } = this.props;

    return (
      <div
        className={`${classes.DatePicker} ${isDisabled ? classes.disabled : null} 
      ${classNames ? classNames.reduce((acc, className) => acc + ` ${classes[className]}`, "") : ""}
      `}
        style={{ ...style, borderColor: error ? "#D0021B" : null }}
      >
        <div
          className={classes.input}
          onClick={() => all(() => !isDisabled && this.setState({ popupIsShown: !popupIsShown }), () => this.setState({ error: null }))}
        >
          {value ? <div className={classes.value}>{value}</div> : <div className={`${classes.value} ${classes.empty}`}>{placeholder}</div>}
          {!isDisabled && (
            <>
              {value && (
                <div className={classes.xIcon} onClick={event => all(() => event.stopPropagation(), () => changeHandler(null))}>
                  <img src={CrossIcon} alt="" />
                </div>
              )}
              <div className={classes.icon}>
                <img style={popupIsShown ? { transform: "rotate(180deg)" } : null} src={ArrowIcon} alt="" />
              </div>
            </>
          )}
        </div>
        {error && <div className={classes.error}>{error}*</div>}
        {popupIsShown && (
          <div className={classes.popup}>
            <DatePickerPopup value={value} changeHandler={changeHandler} maxDate={maxDate} minDate={minDate} />
          </div>
        )}
      </div>
    );
  }
}

Picker.propTypes = {
  classNames: PropTypes.arrayOf(PropTypes.oneOf(["borderless", "transparent"])),
  style: PropTypes.object,
  value: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  error: PropTypes.string
};

Picker.defaultProps = {
  style: {}
};

export const DatePicker = onClickOutside(Picker);
