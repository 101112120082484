import { all, put, takeEvery } from "redux-saga/effects";
import { API } from "@Root/API";
import * as actions from "../actions";
import * as types from "../types";

export function* getUserOptions({ promise }) {
  try {
    const { data } = yield API.getUserOptions();
    yield put(actions.setUserOptions(data.data.map(x => ({ value: x.id, label: x.name }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getTitles({ promise }) {
  try {
    const { data } = yield API.getTitles();
    yield put(actions.setTitles(data.data.map(title => ({ value: title.id, label: title.title }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getGenders({ promise }) {
  try {
    const { data } = yield API.getGenders();
    yield put(actions.setGenders(data.data.map(gender => ({ value: gender.id, label: gender.label }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getCountries({ promise }) {
  try {
    const { data } = yield API.getCountries();
    yield put(actions.setCountries(data.data.map(country => ({ value: country.id, label: country.name }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getPersonRelationships({ promise }) {
  try {
    const { data } = yield API.getRelationships("person");
    yield put(actions.setPersonRelationships(data.data.map(relationship => ({ value: relationship.id, label: relationship.display_name }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getOrganisationRelationships({ promise }) {
  try {
    const { data } = yield API.getRelationships("organisation");
    yield put(actions.setOrganisationRelationships(data.data.map(relationship => ({ value: relationship.id, label: relationship.display_name }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getAcademicYears({ promise }) {
  try {
    const { data } = yield API.getAcademicYears();
    yield put(actions.setAcademicYears(data.map(academicYear => ({ value: academicYear.id, label: academicYear.name }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getInstitutionOptions({ promise }) {
  try {
    const { data } = yield API.getAdminInstitutions();
    yield put(actions.setInstitutionOptions(data.data.map(institution => ({ value: institution.id, label: institution.name }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getContactAddressesType({ promise }) {
  try {
    const { data } = yield API.getAddressesType();
    yield put(actions.setContactAddressesType(data.map(contactAddressType => ({ value: contactAddressType.id, label: contactAddressType.name }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getHesaDetailOptions({ promise }) {
  try {
    const { data } = yield API.getHesaDetailOptions();
    const { types } = data.data;
    const mapToValueAndLabel = arr => {
      return arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }));
    };
    const hesaDetailOptions = {
      student: {
        sex_identifier: mapToValueAndLabel(types.student.sex_identifier),
        title: types.student.title.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.title}` }))
      },
      care_leaver: {
        care_leaver_type: mapToValueAndLabel(types.care_leaver.care_leaver_type)
      },
      carer: {
        carer_type: mapToValueAndLabel(types.carer.carer_type)
      },
      contact_purpose: {
        contact_type: mapToValueAndLabel(types.contact_purpose.contact_type),
        person_address: {
          term_time_accommodation_type: mapToValueAndLabel(types.contact_purpose.person_address.term_time_accommodation_type),
          country_identifier: mapToValueAndLabel(types.contact_purpose.person_address.country_identifier)
        }
      },
      dependant: {
        dependant_type: mapToValueAndLabel(types.dependant.dependant_type)
      },
      disability: {
        disability_type: mapToValueAndLabel(types.disability.disability_type)
      },
      engagement: {
        access_programme: mapToValueAndLabel(types.engagement.access_programme),
        highest_qualification_on_entry: mapToValueAndLabel(types.engagement.highest_qualification_on_entry),
        previous_provider: mapToValueAndLabel(types.engagement.previous_provider),
        study_intention: mapToValueAndLabel(types.engagement.study_intention),
        z_permanent_address_region_on_entry_4_way_split: mapToValueAndLabel(types.engagement.z_permanent_address_region_on_entry_4_way_split),
        z_permanent_address_region_on_entry: mapToValueAndLabel(types.engagement.z_permanent_address_region_on_entry)
      },
      student_registration: {
        fee_eligibility: mapToValueAndLabel(types.student_registration.fee_eligibility),
        fee_status: mapToValueAndLabel(types.student_registration.fee_status),
        incoming_exchange: mapToValueAndLabel(types.student_registration.incoming_exchange),
        z_age_at_registration: mapToValueAndLabel(types.student_registration.z_age_at_registration),
        z_student_registration_population: mapToValueAndLabel(types.student_registration.z_student_registration_population),
        qualification_awarded: {
          // qualification_identifier: [],
          qualification_result: mapToValueAndLabel(types.student_registration.qualification_awarded.qualification_result),
          qualification_award_accreditation: {
            accreditation_identifier: mapToValueAndLabel(
              types.student_registration.qualification_awarded.qualification_award_accreditation.accreditation_identifier
            )
          }
        }
      },
      student_financial_support: {
        financial_support_type: mapToValueAndLabel(types.student_financial_support.financial_support_type)
      },
      leaver: {
        open_engagement_flag: mapToValueAndLabel(types.leaver.open_engagement_flag),
        reason_for_student_registration_ending: mapToValueAndLabel(types.leaver.reason_for_student_registration_ending)
      },
      entry_qualification_award: {
        qualification_result: mapToValueAndLabel(types.entry_qualification_award.qualification_result),
        qualification_type_identifier: mapToValueAndLabel(types.entry_qualification_award.qualification_type_identifier),
        entry_qualification_subject: {
          subject_identifier: mapToValueAndLabel(types.entry_qualification_award.entry_qualification_subject.subject_identifier)
        }
      },
      ethnicity: {
        ethnicity_type: mapToValueAndLabel(types.ethnicity.ethnicity_type)
      },
      gender_identity: {
        gender_identity_type: mapToValueAndLabel(types.gender_identity.gender_identity_type)
      },
      language_proficiency: {
        language_identifier: mapToValueAndLabel(types.language_proficiency.language_identifier),
        proficiency_type: mapToValueAndLabel(types.language_proficiency.proficiency_type),
        first_or_preferred_language_marker: mapToValueAndLabel(types.language_proficiency.first_or_preferred_language_marker),
        level_of_proficiency: mapToValueAndLabel(types.language_proficiency.level_of_proficiency)
      },
      marital_status: {
        marital_status_type: mapToValueAndLabel(types.marital_status.marital_status_type)
      },
      national_identity: {
        national_identity_type: mapToValueAndLabel(types.national_identity.national_identity_type)
      },
      nationality: {
        nationality_type: mapToValueAndLabel(types.nationality.nationality_type)
      },
      parental_education: {
        parental_education_type: mapToValueAndLabel(types.parental_education.parental_education_type)
      },
      person_identifier: {
        identifier_type_code: mapToValueAndLabel(types.person_identifier.identifier_type_code)
      },
      religion: {
        religion_type: mapToValueAndLabel(types.religion.religion_type)
      },
      religious_background: {
        religious_background_type: mapToValueAndLabel(types.religious_background.religious_background_type)
      },
      service_leaver: {
        service_leaver_type: mapToValueAndLabel(types.service_leaver.service_leaver_type)
      },
      sexual_orientation: {
        sexual_orientation_type: mapToValueAndLabel(types.sexual_orientation.sexual_orientation_type)
      },
      socio_economic_classification: {
        socio_economic_classification: mapToValueAndLabel(types.socio_economic_classification.socio_economic_classification)
      },
      standard_occupational_classification: {
        standard_occupational_classification_2010: mapToValueAndLabel(types.standard_occupational_classification.standard_occupational_classification_2010)
      }
    };
    yield put(actions.setHesaDetailOptions(hesaDetailOptions));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getHesaDetailCourseSessionOptions({ promise }) {
  try {
    const { data } = yield API.getHesaDetailCourseSessionOptions();
    yield put(actions.setHesaDetailCourseSessionOptions(data.map(obj => ({ value: obj.id, label: obj.label }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudentApplicationProgrammes({ promise }) {
  try {
    const { data } = yield API.getStudentApplicationProgrammes();
    yield put(actions.setStudentApplicationProgrammes(data));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getContactsAssociationOptions({ promise }) {
  try {
    const { data } = yield API.getContactsAssociationOptions();
    const { contact_preferences = [], frequencies = [] } = data.data;
    const mapToValueAndLabel = arr => {
      return arr.map(obj => ({ value: obj.id, label: obj.name }));
    };
    yield put(
      actions.setContactsAssociationOptions({
        contactPreferences: mapToValueAndLabel(contact_preferences),
        frequencies: mapToValueAndLabel(frequencies)
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesProgrammeOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesProgrammeOptions();
    const {
      program_types = [],
      programme_modes = [],
      programme_years = [],
      course_delivery_mode_type = [],
      course_delivery_type = [],
      designated_course = [],
      teacher_training_course_identifier = [],
      modules = [],
      venues = [],
      program_awarding_bodies = []
    } = data;
    yield put(
      actions.setStudiesProgrammeOptions({
        program_types: program_types.map(obj => ({ value: obj.id, label: obj.name })),
        programme_years: programme_years.map(obj => ({ value: obj.id, label: obj.label })),
        programme_modes: programme_modes.map(obj => ({ value: obj.id, label: obj.label })),
        course_delivery_mode_type: course_delivery_mode_type.map(obj => ({ value: obj.id, label: obj.label })),
        course_delivery_type: course_delivery_type.map(obj => ({ value: obj.id, label: obj.label })),
        designated_course: designated_course.map(obj => ({ value: obj.id, label: obj.label })),
        teacher_training_course_identifier: teacher_training_course_identifier.map(obj => ({ value: obj.id, label: obj.label })),
        modules: modules.map(obj => ({ value: obj.id, label: obj.module_title, credit: obj.credit_value_of_module })),
        venues: venues.map(obj => ({ value: obj.id, label: obj.venue_name })),
        program_awarding_bodies: program_awarding_bodies.map(obj => ({ value: obj.id, label: obj.name }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getCreateFinancialSupportOptions({ promise }) {
  try {
    const { data } = yield API.getCreateFinancialSupportOptions();
    const { financial_support_type = [] } = data;
    yield put(
      actions.setCreateFinancialSupportOptions({
        financial_support_type: financial_support_type.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getProgrammesListOptions({ promise }) {
  try {
    const { data } = yield API.getProgrammesListOptions();
    yield put(actions.setProgrammesListOptions(data.map(obj => ({ value: obj.id, label: obj.programme_name, type: obj.programme_type }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesStudyProgrammeFormOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesStudyProgrammeFormOptions();
    const {
      status = [],
      academic_year_list = [],
      study_years = [],
      context = [],
      managed_by = [],
      ordinand = [],
      learning_centre = [],
      auditing = [],
      eligible_for_support = [],
      SLC_Loan = [],
      HESA_Qual_code = [],
      HESA_Qual_awards = [],
      program_years_list = [],
      accommodation = [],
      sponsors = [],
      institution = [],
      model_of_study = [],
      diocese = [],
      In_HEAPES = [],
      financial_support = [],
      HESA_Qual_Class_code = [],
      student_registrations = [],
      reason_for_student_course_session_ending = [],
      fee_status = [],
      admission_bap_statuses = [],
      session_years = []
    } = data;
    yield put(
      actions.setStudiesStudyProgrammeFormOptions({
        status: status.map(obj => ({ value: obj.id, label: obj.label })),
        academic_year_list: academic_year_list.map(obj => ({ value: obj.id, label: obj.name })),
        study_years: study_years.map(obj => ({ value: obj.id, label: obj.name })),
        admission_bap_statuses: admission_bap_statuses.map(obj => ({ value: obj.id, label: obj.name })),
        context: context.map(obj => ({ value: obj.id, label: obj.name })),
        managed_by: managed_by.map(obj => ({ value: obj.id, label: obj.name })),
        ordinand: ordinand.map(obj => ({ value: obj.id, label: obj.name })),
        learning_centre: learning_centre.map(obj => ({ value: obj.id, label: obj.name })),
        auditing: auditing.map(obj => ({ value: obj.id, label: obj.name })),
        eligible_for_support: eligible_for_support.map(obj => ({ value: obj.id, label: obj.name })),
        fee_status: fee_status.map(obj => ({ value: obj.id, label: obj.label })),
        SLC_Loan: SLC_Loan.map(obj => ({ value: obj.id, label: obj.name })),
        HESA_Qual_code: HESA_Qual_code.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
        HESA_Qual_awards: HESA_Qual_awards.map(obj => ({ value: obj.id, label: obj.qualification_title })),
        program_years_list: program_years_list.map(obj => ({ value: obj.id, label: obj.label })),
        accommodation: accommodation.map(obj => ({ value: obj.id, label: obj.name })),
        sponsors: sponsors.map(obj => ({ value: obj.id, label: obj.label })),
        institution: institution.map(obj => ({ value: obj.id, label: obj.label })),
        model_of_study: model_of_study.map(obj => ({ value: obj.id, label: obj.label })),
        diocese: diocese.map(obj => ({ value: obj.id, label: obj.label })),
        In_HEAPES: In_HEAPES.map(obj => ({ value: obj.id, label: obj.name })),
        financial_support: financial_support.map(obj => ({ value: obj.id, label: obj.name })),
        HESA_Qual_Class_code: HESA_Qual_Class_code.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` })),
        student_registrations: student_registrations.map(obj => ({ value: obj.id, label: obj.student_registration_number })),
        reason_for_student_course_session_ending: reason_for_student_course_session_ending.map(obj => ({ value: obj.id, label: obj.label })),
        session_years: session_years.map(obj => ({ value: obj.id, label: obj.name }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getContactsClericOptions({ promise }) {
  try {
    const { data } = yield API.getContactsClericOptions();
    yield put(actions.setContactsClericOptions(data.data));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getContactsStudentTypeOptions({ promise }) {
  try {
    const { data } = yield API.getContactsStudentTypeOptions();
    yield put(actions.setContactsStudentTypeOptions(data.data.map(obj => ({ value: obj.id, label: obj.name }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesModuleOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesModuleOptions();
    const { credit_transfer_scheme = [], level_of_credit_points = [], mark_scheme = [], random_number = null } = data;
    yield put(
      actions.setStudiesModuleOptions({
        credit_transfer_scheme: credit_transfer_scheme.map(obj => ({ value: obj.id, label: obj.label })),
        level_of_credit_points: level_of_credit_points.map(obj => ({ value: obj.id, label: obj.label })),
        mark_scheme: mark_scheme.map(obj => ({ value: obj.id, label: obj.label })),
        random_number: random_number
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesEngagementOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesEngagementOptions();
    const { fee_eligibility = [], fee_status = [] } = data;
    yield put(
      actions.setStudiesEngagementOptions({
        fee_eligibility: fee_eligibility.map(obj => ({ value: obj.id, label: obj.label })),
        fee_status: fee_status.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesSuspensionsOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesSuspensionsOptions();
    const { institution = [], reasons = [] } = data;
    yield put(
      actions.setStudiesSuspensionsOptions({
        institution: institution.map(obj => ({ value: obj.id, label: obj.label })),
        reasons: reasons.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesModuleSubjectOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesModuleSubjectOptions();
    const { subject_identifiers = [] } = data;
    yield put(
      actions.setStudiesModuleSubjectOptions({
        subject_identifiers: subject_identifiers.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesModuleDeliveryOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesModuleDeliveryOptions();
    const { language_identifiers = [], educational_modules = [] } = data;
    yield put(
      actions.setStudiesModuleDeliveryOptions({
        language_identifiers: language_identifiers.map(obj => ({ value: obj.id, label: obj.label })),
        educational_modules: educational_modules.map(obj => ({ value: obj.id, label: obj.module_title }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesModuleCostCenterOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesModuleCostCenterOptions();
    const { hesa_cost_centres = [] } = data;
    yield put(
      actions.setStudiesModuleCostCenterOptions({
        hesa_cost_centres: hesa_cost_centres.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesModuleInstanceOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesModuleInstanceOptions();
    const { student_course_session_identificators = [], accreditation_of_priors = [], modules_counts = [], modules_outcomes = [], modules_results = [] } = data;
    yield put(
      actions.setStudiesModuleInstanceOptions({
        student_course_session_identificators: student_course_session_identificators.map(obj => ({ value: obj.id, label: obj.label })),
        accreditation_of_priors: accreditation_of_priors.map(obj => ({ value: obj.id, label: obj.label })),
        modules_counts: modules_counts.map(obj => ({ value: obj.id, label: obj.label })),
        modules_outcomes: modules_outcomes.map(obj => ({ value: obj.id, label: obj.label })),
        modules_results: modules_results.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesModuleDeliveryRoleOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesModuleDeliveryRoleOptions();
    const { franchise_indicators = [], role_types = [], hesa_identificators = [], venues = [] } = data;
    yield put(
      actions.setStudiesModuleDeliveryRoleOptions({
        franchise_indicators: franchise_indicators.map(obj => ({ value: obj.id, label: obj.label })),
        role_types: role_types.map(obj => ({ value: obj.id, label: obj.label })),
        hesa_identificators: hesa_identificators.map(obj => ({ value: obj.id, label: obj.label })),
        venues: venues.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesModuleDeliveryLocationOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesModuleDeliveryLocationOptions();
    const { venues = [] } = data;
    yield put(
      actions.setStudiesModuleDeliveryLocationOptions({
        venues: venues.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesStudentModuleFeeOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesStudentModuleFeeOptions();
    const { student_course_sessions = [], fee_collectors = [] } = data;
    yield put(
      actions.setStudiesStudentModuleFeeOptions({
        student_course_sessions: student_course_sessions.map(obj => ({ value: obj.id, label: obj.label })),
        fee_collectors: fee_collectors.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesStudyProgrammePostgradOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesStudyProgrammePostgradOptions();
    const { status = [], TCB_responce = [], UOB_responce = [] } = data;
    yield put(
      actions.setStudiesStudyProgrammePostgradOptions({
        status: status.map(obj => ({ value: obj.id, label: obj.label })),
        TCB_responce: TCB_responce.map(obj => ({ value: obj.id, label: obj.label })),
        UOB_responce: UOB_responce.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesStudyProgrammeFeesOptions({ promise }) {
  try {
    const { data = [] } = yield API.getStudiesStudyProgrammeFeesOptions();
    yield put(actions.setStudiesStudyProgrammeFeesOptions(data.map(obj => ({ value: obj.id, label: obj.label }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getStudiesStudyProgrammeFundingAndMonitoringOptions({ promise }) {
  try {
    const { data } = yield API.getStudiesStudyProgrammeFundingAndMonitoringOptions();
    const { allocatedPlace = [], equivalentQualification = [], fundingCompletion = [], regulatingBody = [], sandwichFee = [] } = data;
    const mapToValueAndLabel = arr => {
      return arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }));
    };
    yield put(
      actions.setStudiesStudyProgrammeFundingAndMonitoringOptions({
        allocatedPlace: mapToValueAndLabel(allocatedPlace),
        equivalentQualification: mapToValueAndLabel(equivalentQualification),
        fundingCompletion: mapToValueAndLabel(fundingCompletion),
        regulatingBody: mapToValueAndLabel(regulatingBody),
        sandwichFee: mapToValueAndLabel(sandwichFee)
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getAdminUserOptions({ promise }) {
  try {
    const data = yield Promise.all([API.getAdminUserRoleOptions(), API.getAdminUserStatusOptions()]);
    yield put(
      actions.setAdminUserOptions({
        roles: data[0].data.data.map(obj => ({ value: obj.id, label: obj.name })),
        statuses: data[1].data.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getAdminInstitutionOptions({ promise }) {
  try {
    const { data } = yield API.getAdminInstitutions();
    yield put(actions.setAdminInstitutionOptions(data.data.map(obj => ({ value: obj.id, label: obj.name }))));
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getAdminQualificationOptions({ promise }) {
  try {
    const { data } = yield API.getAdminQualificationOptions();
    const { qualifications, awarding_body_role, qualification_subject, z_qualification_subject } = data.data;
    const mapToValueAndLabel = arr => {
      return arr.map(obj => ({ value: obj.id, label: `${obj.code} - ${obj.label}` }));
    };
    yield put(
      actions.setAdminQualificationOptions({
        qualification_category: mapToValueAndLabel(qualifications.qualification_categories),
        z_level_of_study_six_way_split: mapToValueAndLabel(qualifications.z_level_of_study_six_way_split),
        z_level_of_study_ten_way_split: mapToValueAndLabel(qualifications.z_level_of_study_ten_way_split),
        awarding_body_role: {
          awarding_body: mapToValueAndLabel(awarding_body_role.awarding_body_role_ids),
          relationship: mapToValueAndLabel(awarding_body_role.relationships)
        },
        qualification_subject: {
          qualification_subject: mapToValueAndLabel(qualification_subject.qualification_subject_types),
          qualification_subject_cah: mapToValueAndLabel(qualification_subject.qualification_subject_cah_one)
        },
        z_qualification_subject: {
          z_qual_subject: mapToValueAndLabel(z_qualification_subject.qualification_subject_types)
        }
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getAdminVenueOptions({ promise }) {
  try {
    const { data } = yield API.getAdminVenueOptions();
    const { venues_types = [] } = data;
    yield put(
      actions.setAdminVenueOptions({
        venues_types: venues_types.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getAdmissionsApplicationsOptions({ promise }) {
  try {
    const { data } = yield API.getAdmissionsApplicationsOptions();
    const { applications_status = [], forms_status = [] } = data;
    yield put(
      actions.setAdmissionsApplicationsOptions({
        applications_status: applications_status.map(obj => ({ value: obj.id, label: obj.label })),
        forms_status: forms_status.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getFinanceFundingOptions({ promise }) {
  try {
    const { data } = yield API.getFinanceFundingOptions();
    const { programme = [], learning_venue = [], fees_paid = [], funding_body = [] } = data;
    yield put(
      actions.setFinanceFundingOptions({
        programme: programme.map(obj => ({ value: obj.id, label: obj.label })),
        learning_venue: learning_venue.map(obj => ({ value: obj.id, label: obj.label })),
        fees_paid: fees_paid.map(obj => ({ value: obj.id, label: obj.label })),
        funding_body: funding_body.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* getPuringDataOptions({ promise }) {
  try {
    const { data } = yield API.getPuringDataOptions();
    const { institution_house = [], left_date = [] } = data;
    yield put(
      actions.setPuringDataOptions({
        institution_house: institution_house.map(obj => ({ value: obj.id, label: obj.label })),
        left_date: left_date.map(obj => ({ value: obj.id, label: obj.label }))
      })
    );
    promise.resolve();
  } catch (err) {
    promise.reject(err);
  }
}

export function* rootSaga() {
  yield all([
    takeEvery(types.GET_USER_OPTIONS, getUserOptions),
    takeEvery(types.GET_TITLES, getTitles),
    takeEvery(types.GET_GENDERS, getGenders),
    takeEvery(types.GET_COUNTRIES, getCountries),
    takeEvery(types.GET_PERSON_RELATIONSHIPS, getPersonRelationships),
    takeEvery(types.GET_ORGANISATION_RELATIONSHIPS, getOrganisationRelationships),
    takeEvery(types.GET_HESA_DETAIL_OPTIONS, getHesaDetailOptions),
    takeEvery(types.GET_HESA_DETAIL_COURSE_SESSION_OPTIONS, getHesaDetailCourseSessionOptions),
    takeEvery(types.GET_STUDENT_APPLICATION_PROGRAMMES, getStudentApplicationProgrammes),
    takeEvery(types.GET_CONTACTS_ASSOCIATION_OPTIONS, getContactsAssociationOptions),
    takeEvery(types.GET_STUDIES_PROGRAMME_OPTIONS, getStudiesProgrammeOptions),
    takeEvery(types.GET_STUDIES_MODULE_OPTIONS, getStudiesModuleOptions),
    takeEvery(types.GET_STUDIES_MODULE_SUBJECT_OPTIONS, getStudiesModuleSubjectOptions),
    takeEvery(types.GET_STUDIES_MODULE_DELIVERY_OPTIONS, getStudiesModuleDeliveryOptions),
    takeEvery(types.GET_STUDIES_MODULE_INSTANCE_OPTIONS, getStudiesModuleInstanceOptions),
    takeEvery(types.GET_STUDIES_MODULE_COST_CENTRE_OPTIONS, getStudiesModuleCostCenterOptions),
    takeEvery(types.GET_STUDIES_MODULE_DELIVERY_ROLE_OPTIONS, getStudiesModuleDeliveryRoleOptions),
    takeEvery(types.GET_STUDIES_MODULE_DELIVERY_LOCATION_OPTIONS, getStudiesModuleDeliveryLocationOptions),
    takeEvery(types.GET_STUDIES_ENGAGEMENT_OPTIONS, getStudiesEngagementOptions),
    takeEvery(types.GET_STUDIES_SUSPENSIONS_OPTIONS, getStudiesSuspensionsOptions),
    takeEvery(types.GET_STUDIES_STUDENT_MODULE_FEE_OPTIONS, getStudiesStudentModuleFeeOptions),
    takeEvery(types.GET_CREATE_FINANCIAL_SUPPORT_OPTIONS, getCreateFinancialSupportOptions),
    takeEvery(types.GET_PROGRAMMES_LIST_OPTIONS, getProgrammesListOptions),
    takeEvery(types.GET_STUDIES_STUDY_PROGRAMME_FORM_OPTIONS, getStudiesStudyProgrammeFormOptions),
    takeEvery(types.GET_CONTACTS_CLERIC_OPTIONS, getContactsClericOptions),
    takeEvery(types.GET_CONTACTS_STUDENT_TYPE_OPTIONS, getContactsStudentTypeOptions),
    takeEvery(types.GET_STUDIES_STUDY_PROGRAMME_POSTGRAD_OPTIONS, getStudiesStudyProgrammePostgradOptions),
    takeEvery(types.GET_STUDIES_STUDY_PROGRAMME_FEES_OPTIONS, getStudiesStudyProgrammeFeesOptions),
    takeEvery(types.GET_STUDIES_STUDY_PROGRAMME_FUNDING_AND_MONITORING_OPTIONS, getStudiesStudyProgrammeFundingAndMonitoringOptions),
    takeEvery(types.GET_ADMIN_USER_OPTIONS, getAdminUserOptions),
    takeEvery(types.GET_ADMIN_INSTITUTION_OPTIONS, getAdminInstitutionOptions),
    takeEvery(types.GET_ADMIN_QUALIFICATION_OPTIONS, getAdminQualificationOptions),
    takeEvery(types.GET_ADMIN_VENUE_OPTIONS, getAdminVenueOptions),
    takeEvery(types.GET_ADMISSIONS_APPLICATIONS_OPTIONS, getAdmissionsApplicationsOptions),
    takeEvery(types.GET_FINANCE_FUNDING_OPTIONS, getFinanceFundingOptions),
    takeEvery(types.GET_PURING_DATA_OPTIONS, getPuringDataOptions),
    takeEvery(types.GET_ACADEMIC_YEARS, getAcademicYears),
    takeEvery(types.GET_CONTACT_ADDRESSES_TYPE, getContactAddressesType),
    takeEvery(types.GET_INSTITUTION_OPTIONS, getInstitutionOptions)
  ]);
}
