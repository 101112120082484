import React, { useState } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { all } from "@Root/helpers";
import closeIcon from "../../../assets/icons/close.png";
import { FileInput } from "@Root/components";

export const UploadingModal = ({ clickRejectButtonHandler, clickResolveButtonHandler }) => {
  const [value, setValue] = useState(null);
  const [file, setFile] = useState(null);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.text}>Please choose a file</div>
        <div className={classes.icon} onClick={clickRejectButtonHandler}>
          <img src={closeIcon} alt="" />
        </div>
      </div>
      <div className={classes.line} />
      <div className={classes.main}>
        <FileInput
          style={{ width: 258, height: 38 }}
          circleStyle={{ width: 30, height: 30 }}
          value={value}
          file={file}
          changeHandler={(value, file) => all(() => setValue(value), () => setFile(file))}
          removeHandler={() => all(() => setValue(null), () => setFile(null))}
          hasText={false}
          restrictions={["text", "application"]}
        />
        <button className={classes.button} onClick={() => clickResolveButtonHandler(file)} disabled={!file}>
          Continue
        </button>
      </div>
    </div>
  );
};

UploadingModal.propTypes = {
  clickRejectButtonHandler: PropTypes.func,
  clickResolveButtonHandler: PropTypes.func
};

UploadingModal.defaultProps = {
  clickRejectButtonHandler: () => {},
  clickResolveButtonHandler: () => {}
};
