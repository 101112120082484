import React, { useState } from "react";
import classes from "./style.module.scss";
import { CheckboxInput, InputLabel } from "@Root/components";

export const PermissionsCheckboxes = ({ initialData, onChangeHandler, isEditable }) => {
  const [data, setData] = useState(initialData);

  const onChangeValue = field => value => {
    setData(prevState => {
      onChangeHandler({ ...prevState, [field]: value });
      return { ...prevState, [field]: value };
    });
  };
  return (
    <div>
      <h3 className={classes.title}>Additional functions:</h3>
      <div className={classes.rows}>
        <div className={classes.row}>
          <InputLabel text="Students CSV Import" />
          <CheckboxInput isChecked={data.students_csv_import} changeHandler={value => onChangeValue("students_csv_import")(value)} isDisabled={!isEditable} />
        </div>{" "}
        <div className={classes.row}>
          <InputLabel text="Students CSV Export" />
          <CheckboxInput isChecked={data.students_csv_export} changeHandler={value => onChangeValue("students_csv_export")(value)} isDisabled={!isEditable} />
        </div>{" "}
        <div className={classes.row}>
          <InputLabel text="Students XML Export" />
          <CheckboxInput isChecked={data.students_xml_export} changeHandler={value => onChangeValue("students_xml_export")(value)} isDisabled={!isEditable} />
        </div>{" "}
        <div className={classes.row}>
          <InputLabel text="Students XML Export" />
          <CheckboxInput isChecked={data.push_programmes} changeHandler={value => onChangeValue("push_programmes")(value)} isDisabled={!isEditable} />
        </div>
      </div>
    </div>
  );
};
