import React, { PureComponent } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { CheckboxInput } from "@Root/components";

import TEIDLogo from "../../../icons/TEIDLogo/TEIDLogo";

export class LoginForm extends PureComponent {
  state = {
    login: "",
    password: ""
  };

  handleInput = (e, type) => {
    this.setState({
      [type]: e.target.value
    });
  };

  handleSubmit = e => {
    if (!e.target.checkValidity()) return;
    e.preventDefault();
    this.props.logInHandler(this.state.login.trim(), this.state.password.trim());
  };

  render() {
    const { login, password } = this.state;
    const { isKeptLoggedIn, error, keepLoggedInHandler, passwordRecoveryHandler } = this.props;
    const { handleSubmit } = this;

    return (
      <form className={classes.LoginForm} onSubmit={handleSubmit}>
        <TEIDLogo style={{ marginBottom: 20 }} />
        <div className={classes.title}>Login</div>
        <div className={classes.description}>Please enter your details to login to the system</div>
        <div className={classes.wrapper}>
          <div className={classes.row}>
            <div className={classes.label}>Username or Email</div>
            <input className={error === "login" ? classes.invalid : ""} type="text" value={login} onChange={e => this.handleInput(e, "login")} required />
          </div>
          {error === "login" && <div className={classes.error}>Please enter a correct email or username*</div>}
        </div>
        <div className={classes.wrapper}>
          <div className={classes.row}>
            <div className={classes.label}>Password</div>
            <input
              className={error === "password" ? classes.invalid : ""}
              type="password"
              minLength="5"
              value={password}
              onChange={e => this.handleInput(e, "password")}
              required
            />
          </div>
          {error === "password" && <div className={classes.error}>Please enter a correct password*</div>}
        </div>
        <div className={classes.divider} />
        <div className={classes.row}>
          <div>
            <button type="submit">Login</button>
          </div>
          <div>
            <CheckboxInput isChecked={isKeptLoggedIn} changeHandler={keepLoggedInHandler} label={{ text: "Keep me logged in" }} />
          </div>
        </div>
        <div className={classes.link}>
          <span onClick={() => passwordRecoveryHandler()}>Lost Password?</span>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  isKeptLoggedIn: PropTypes.bool.isRequired,
  error: PropTypes.string,
  logInHandler: PropTypes.func.isRequired,
  keepLoggedInHandler: PropTypes.func.isRequired,
  passwordRecoveryHandler: PropTypes.func.isRequired
};
