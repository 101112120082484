import React, { Component } from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";
import { FormWrapper } from "@Root/HOCs";
import { EditButton, SectionTitle, InputLabel, TextInput, Select, CheckboxInput } from "@Root/components";

export class StudiesModuleForm extends Component {
  constructor(props) {
    super(props);
    const { initialIsEditable, initialData, generateId } = props;
    this.state = {
      isEditable: initialIsEditable,
      error: null,
      isSaving: false,
      module_identifier: generateId ? Math.floor(Math.random() * (100000000000000 - 1 + 1)) + 1 : initialData.module_identifier,
      credit_value_of_module: initialData.credit_value_of_module,
      credit_transfer_scheme_id: initialData.credit_transfer_scheme_id,
      full_time_equivalence: initialData.full_time_equivalence,
      level_of_credit_points_id: initialData.level_of_credit_points_id,
      mark_scheme_id: initialData.mark_scheme_id,
      module_title: initialData.module_title,
      include_in_HESA: initialData.include_in_HESA
    };
  }

  formIsValid = () => {
    const { module_identifier, module_title, full_time_equivalence, credit_value_of_module } = this.state;
    if (!module_identifier) {
      this.showError("module_identifier", "Required");
      return false;
    }
    if (!module_title) {
      this.showError("module_title", "Required");
      return false;
    }
    if (!full_time_equivalence) {
      this.showError("full_time_equivalence", "Required");
      return false;
    }
    if (!credit_value_of_module) {
      this.showError("credit_value_of_module", "Required");
      return false;
    }
    return true;
  };

  showError = (input, message) => {
    this.setState({ error: { input, message } }, () => {
      this.setState({ error: null });
    });
  };

  errorMessage = input => {
    const { error } = this.state;
    return error && error.input === input ? error.message : null;
  };

  setInitialState = () => {
    const { initialData } = this.props;
    this.setState({
      isEditable: false,
      error: null,
      module_identifier: initialData.module_identifier,
      credit_value_of_module: initialData.credit_value_of_module,
      credit_transfer_scheme_id: initialData.credit_transfer_scheme_id,
      full_time_equivalence: initialData.full_time_equivalence,
      level_of_credit_points_id: initialData.level_of_credit_points_id,
      mark_scheme_id: initialData.mark_scheme_id,
      module_title: initialData.module_title,
      include_in_HESA: initialData.include_in_HESA
    });
  };

  handleClickCancelButton = () => {
    this.setInitialState();
  };

  handleClickDeleteButton = () => {
    this.props.deleteHandler(
      () => {
        this.setState({ isSaving: true });
      },
      () => {
        this.setState({ isSaving: false });
      }
    );
  };

  handleClickSaveButton = async () => {
    if (!this.formIsValid()) return;
    const { state } = this;
    this.setState({ isSaving: true });
    try {
      await this.props.saveHandler({
        module_identifier: state.module_identifier,
        credit_value_of_module: state.credit_value_of_module,
        credit_transfer_scheme_id: state.credit_transfer_scheme_id,
        full_time_equivalence: state.full_time_equivalence,
        level_of_credit_points_id: state.level_of_credit_points_id,
        mark_scheme_id: state.mark_scheme_id,
        module_title: state.module_title,
        include_in_HESA: state.include_in_HESA
      });
      !this.isUnmounted && this.setState({ isEditable: false });
    } catch (error) {
      console.log(error);
      alert("Unable to save the data");
    }
    !this.isUnmounted && this.setState({ isSaving: false });
  };

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const {
      isEditable,
      isSaving,
      module_identifier,
      credit_value_of_module,
      credit_transfer_scheme_id,
      full_time_equivalence,
      level_of_credit_points_id,
      mark_scheme_id,
      module_title,
      include_in_HESA
    } = this.state;

    const { permissions, title, options, buttons } = this.props;
    const { handleClickCancelButton, handleClickDeleteButton, handleClickSaveButton, errorMessage } = this;

    const { onRead: titleOnRead = "", onEdit: titleOnEdit = "" } = title;

    return (
      <div className={classes.StudiesModuleForm}>
        <FormWrapper
          buttons={isEditable ? buttons : []}
          buttonsNames={[{ button: "delete", name: "Delete this module" }]}
          buttonsAreDisabled={isSaving}
          isSpinning={isSaving}
          clickCancelButtonHandler={handleClickCancelButton}
          clickDeleteButtonHandler={handleClickDeleteButton}
          clickSaveButtonHandler={handleClickSaveButton}
        >
          <div className={classes.titleWrapper}>
            <SectionTitle title={isEditable ? titleOnEdit : titleOnRead} />
            {permissions.includes("edit") && !isEditable && <EditButton clickHandler={() => this.setState({ isEditable: true })} />}
          </div>
          <div className={classes.contentWrapper}>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module ID" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_identifier.toString()}
                  placeholder=""
                  isDisabled={true}
                  error={errorMessage("module_identifier")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Module title" hasAsterisk={isEditable} />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={module_title}
                  changeHandler={module_title => this.setState({ module_title })}
                  placeholder=""
                  isDisabled={!isEditable}
                  error={errorMessage("module_title")}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Credit value of module*" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={credit_value_of_module}
                  changeHandler={credit_value_of_module => this.setState({ credit_value_of_module })}
                  placeholder=""
                  isDisabled={!isEditable}
                  error={errorMessage("credit_value_of_module")}
                  maxLength={3}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Credit transfer scheme" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.credit_transfer_scheme}
                  value={credit_transfer_scheme_id}
                  changeHandler={value => this.setState({ credit_transfer_scheme_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
            <div className={classes.columnWrapper}>
              <div className={classes.inputWrapper}>
                <InputLabel text="Level of credit points" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.level_of_credit_points}
                  value={level_of_credit_points_id}
                  changeHandler={value => this.setState({ level_of_credit_points_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Mark scheme" />
                <Select
                  inputClassNames={!isEditable ? ["borderless"] : []}
                  inputStyle={{ width: 260 }}
                  options={options.mark_scheme}
                  value={mark_scheme_id}
                  changeHandler={value => this.setState({ mark_scheme_id: value })}
                  isDisabled={!isEditable}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Full time equivalence*" />
                <TextInput
                  classNames={!isEditable ? ["borderless"] : []}
                  style={{ width: 260 }}
                  value={full_time_equivalence}
                  changeHandler={full_time_equivalence => this.setState({ full_time_equivalence })}
                  placeholder=""
                  isDisabled={!isEditable}
                  error={errorMessage("full_time_equivalence")}
                  maxLength={4}
                />
              </div>
              <div className={classes.inputWrapper}>
                <InputLabel text="Include in HESA" />
                <CheckboxInput
                  style={{ position: "relative", top: 15 }}
                  isChecked={include_in_HESA}
                  changeHandler={value => this.setState({ include_in_HESA: value })}
                  isDisabled={!isEditable}
                />
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    );
  }
}

StudiesModuleForm.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.shape({
    onRead: PropTypes.string,
    onEdit: PropTypes.string
  }),
  initialData: PropTypes.shape({
    module_identifier: PropTypes.number,
    credit_value_of_module: PropTypes.number,
    credit_transfer_scheme_id: PropTypes.number,
    full_time_equivalence: PropTypes.string,
    level_of_credit_points_id: PropTypes.number,
    mark_scheme_id: PropTypes.number,
    module_title: PropTypes.string
  }),
  generateId: PropTypes.bool,
  initialIsEditable: PropTypes.bool,
  buttons: PropTypes.arrayOf(PropTypes.oneOf(["cancel", "delete", "save"])),
  cancelHandler: PropTypes.func,
  deleteHandler: PropTypes.func,
  saveHandler: PropTypes.func,
  include_in_HESA: PropTypes.bool
};

StudiesModuleForm.defaultProps = {
  permissions: [],
  title: {
    onRead: "",
    onEdit: ""
  },
  initialData: {
    module_identifier: null,
    credit_value_of_module: null,
    credit_transfer_scheme_id: null,
    full_time_equivalence: null,
    level_of_credit_points_id: null,
    mark_scheme_id: null,
    module_title: null,
    include_in_HESA: true
  },
  generateId: false,
  initialIsEditable: false,
  options: null,
  buttons: ["cancel", "delete", "save"],
  cancelHandler: () => {},
  deleteHandler: () => {},
  saveHandler: () => {}
};
