import React from "react";
import classes from "./style.module.scss";
import PropTypes from "prop-types";

export const InputLabel = ({ style, text, hasAsterisk, clickHandler }) => {
  return (
    text && (
      <div className={classes.InputLabel} style={style} onClick={clickHandler}>
        {text}
        {hasAsterisk ? "*" : ""}
      </div>
    )
  );
};

InputLabel.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string,
  hasAsterisk: PropTypes.bool,
  clickHandler: PropTypes.func
};

InputLabel.defaultProps = {
  style: {},
  text: "Label",
  hasAsterisk: false,
  clickHandler: () => {}
};
