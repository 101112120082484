import React from "react";
import classes from "./StudiesPage.module.scss";
import { connect } from "react-redux";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { getCurrentLinkName } from "@Root/helpers";
import DocumentTitle from "react-document-title";
import { PageTitle, PageNavLinks } from "@Root/components";
import { StudiesTable } from "./StudiesTable";

import { StudiesNewProgramme, StudiesProgramme, StudiesStudyProgramme, StudiesNewStudyProgramme } from "./programme";
import {
  StudiesNewModuleSubject,
  StudiesModuleSubject,
  StudiesNewModuleDelivery,
  StudiesNewModule,
  StudiesModule,
  StudiesDeliveryModule,
  StudiesNewModuleCostCenter,
  StudiesModuleCostCenter,
  StudiesNewModuleInstance,
  StudiesModuleInstance,
  StudiesNewModuleDeliveryRole,
  StudiesModuleDeliveryRole,
  StudiesNewModuleDeliveryLocation,
  StudiesModuleDeliveryLocation,
  StudiesNewStudentModuleFee,
  StudiesStudentModuleFee
} from "./module";

const StudiesPage = ({ location, permissions }) => {
  const links = [
    { name: "Records of Studies", path: "records-of-studies" },
    { name: "Programmes", path: "programmes" },
    { name: "Modules", path: "modules" },
    // { name: "Engagements", path: "engagements" },
    { name: "Postgrad Student Details", path: "postgrad-student-details" }
    // { name: "Suspensions", path: "suspensions" }
  ];
  const currentLinkName = getCurrentLinkName(links, location);

  const hasCreatePermission = permissions.includes("create");

  return (
    <DocumentTitle title={`${currentLinkName} - Studies`}>
      <div className={classes.wrapper}>
        <PageTitle title={currentLinkName} />
        <PageNavLinks style={{ marginBottom: 30 }} root={`/home/studies`} links={links} />
        <Switch>
          {links.map((link, i) => (
            <Route exact path={`/home/studies/${link.path}`} component={StudiesTable} key={i} />
          ))}
          {hasCreatePermission && <Route exact path="/home/studies/programmes/new" component={StudiesNewProgramme} />}
          <Route exact path="/home/studies/programmes/:programmeId" component={StudiesProgramme} />
          {hasCreatePermission && <Route exact path="/home/studies/modules/new" component={StudiesNewModule} />}
          <Route exact path="/home/studies/modules/:moduleId" component={StudiesModule} />
          {hasCreatePermission && <Route exact path="/home/studies/modules/:moduleId/module-subject/new" component={StudiesNewModuleSubject} />}
          <Route exact path="/home/studies/modules/:moduleId/module-subject/:moduleSubId" component={StudiesModuleSubject} />
          {hasCreatePermission && <Route exact path="/home/studies/modules/:moduleId/module-delivery/new" component={StudiesNewModuleDelivery} />}
          <Route exact path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId" component={StudiesDeliveryModule} />
          {hasCreatePermission && (
            <Route exact path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-cost-center/new" component={StudiesNewModuleCostCenter} />
          )}
          <Route
            exact
            path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-cost-center/:moduleCostCentId"
            component={StudiesModuleCostCenter}
          />
          {hasCreatePermission && (
            <Route exact path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-instance/new" component={StudiesNewModuleInstance} />
          )}
          <Route exact path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-instance/:moduleInstId" component={StudiesModuleInstance} />
          {hasCreatePermission && (
            <Route
              exact
              path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-delivery-role/new"
              component={StudiesNewModuleDeliveryRole}
            />
          )}
          <Route
            exact
            path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-delivery-role/:moduleRoleId"
            component={StudiesModuleDeliveryRole}
          />
          {hasCreatePermission && (
            <Route
              exact
              path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-delivery-location/new"
              component={StudiesNewModuleDeliveryLocation}
            />
          )}
          <Route
            exact
            path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/module-delivery-location/:moduleDelLocId"
            component={StudiesModuleDeliveryLocation}
          />
          {hasCreatePermission && (
            <Route exact path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/student-module-fee/new" component={StudiesNewStudentModuleFee} />
          )}
          <Route
            exact
            path="/home/studies/modules/:moduleId/module-delivery/:moduleDelId/student-module-fee/:moduleStFeeId"
            component={StudiesStudentModuleFee}
          />
          {hasCreatePermission && <Route exact path="/home/studies/programmes/study/new/:contactId/:programmeId" component={StudiesNewStudyProgramme} />}
          <Route exact path="/home/studies/programmes/study/:contactId/:studyProgrammeId" component={StudiesStudyProgramme} />
          <Redirect to="/home/studies/records-of-studies" />
        </Switch>
      </div>
    </DocumentTitle>
  );
};

const mapStateToProps = ({ authReducer }) => ({
  permissions: authReducer.user.permissions
});

export default withRouter(connect(mapStateToProps)(StudiesPage));
